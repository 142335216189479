import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { FeedbackStoreContext } from "../../../../store/Feedback";
import ModalContextStore from "../../../../store/Modal";
import Button from "../../Button";

import ModalBlock from "../../ModalBlock";

import modalStyles from "../SimpleModal.module.scss";
import styles from "./FeedbackSuccess.module.scss";

const FeedbackSuccess: React.FC = () => {
  const { closeModal } = useContext(ModalContextStore);
  const { issueId } = useContext(FeedbackStoreContext);

  return (
    <div className={styles.feedback}>
      <div className={modalStyles.modal__body}>
        <ModalBlock kind="common">
          <div>
            <h4>Спасибо!</h4>
            <p>
              Для решения вашей проблемы № {issueId} был собран консилиум величайших умов бизнеса. 
              Мы направим их ответ на ваш e-mail в ближайшее время.
            </p>
            <Button
              text="Хорошо"
              onClick={closeModal}
              orange
            />
          </div>
        </ModalBlock>
      </div>
    </div>
  );
};

export default observer(FeedbackSuccess);
