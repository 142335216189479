import { FC } from "react";

import layoutStyles from '../../layouts/Promo/Promo.module.scss';
import tycoon from "../../images/promo/tycoon.png";
import hr from "../../images/promo/hr.svg";
import gold from "../../images/promo/gold.svg";
import vest from "../../images/promo/vest.svg";
import { logos_welcome } from "../../constants/logos";

const AboutContent: FC = () => {
  return (
    <>
      <img src={tycoon} alt="" />
      <h1>«Tycoon»</h1>
      <p>
        Это игра, в которой вам предстоит пройти путь от небольшого провайдера услуг связи 
        до оператора федерального масштаба.
      </p>
      <ul className={layoutStyles.rules}>
        <li>
          <img src={vest} alt="" />
          <p>
            Стройте узлы связи и линии связи между городами.
          </p>
        </li>
        <li>
          <img src={gold} alt="" />
          <p>
            Предоставляйте жителям городов услуги связи и регулируйте тарифную политику компании.
          </p>
        </li>
        <li>
          <img src={hr} alt="" />
          <p>
            Следите за лояльностью абонентов, запускайте рекламные акции и программы лояльности. 
          </p>
        </li>
      </ul>
      <div className={layoutStyles.promo__content__block}>
        Столкнувшись с отказом узлов или сетей, устраняйте проблемы, 
        сохраняя тем самым удовлетворенность абонентов качеством предоставляемых услуг.
        <br/><br/>Используйте все инструменты и возможности, чтобы стать телеком-оператором номер один!
      </div>
      <p>Успех вашего предприятия не останется незамеченным, призы и подарки от Ростелекома ждут вас.</p>
      <div className={layoutStyles.rules__images}>
        {logos_welcome.map((img, i) => <img key={"about" + i} src={img} alt="" />)}
      </div>
    </>
  );
};

export default AboutContent;
