import axios, { AxiosRequestConfig } from 'axios';

export const apiBaseUrl = process.env.REACT_APP_BASE_URL || 'https://tycoon.dev.itrev.ru/api';

export const api = axios.create({
  baseURL: window.location.origin + '/api',
  //baseURL: apiBaseUrl,
  withCredentials: true,
  headers: {
    //Authorization: '3c42997c08e04dd385db1d5e719bfb89' //  fa54bd14f2844d47bd92af6c2163910b
  }
});

const getRequest = async (url: string, config?: AxiosRequestConfig) => {
  try {
    const response = await api.get(url, config);

    return response.data;
  } catch (err) {
    console.error(err);
  }
};

const postRequest = async (url: string, data?: Object, config?: AxiosRequestConfig) => {
  try {
    const response = await api.post(url, data, config);
    
    return response;
  } catch (err) {
      console.error(err);
  }
};

const API =  {
  getRequest,
  postRequest,
};

export default API;
