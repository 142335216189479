import API from "../../api";
import { IQuest, questTypes } from "../../interfaces/common";

const QUEST_URL = "quest";

export const getQuest = () => {
  return API.getRequest(QUEST_URL);
};

export const createQuest = () => {
  return API.postRequest(QUEST_URL);
};

export const applyQuest = (type: questTypes) => {
  return API.postRequest(QUEST_URL + "/apply", {
    questType: type,
  });
};

export const rejectQuest = (questId: string) => {
  return API.postRequest(QUEST_URL + `/${questId}/reject`, {
    id: questId,
  });
};

export const readQuest = (quest: IQuest) => {
  return API.postRequest(QUEST_URL + `/${quest.id}/read`, {
    id: quest.id,
  });
};

export const getQuestInfo = (qid: string) => {
  return API.getRequest(QUEST_URL + `/${qid}`, {});
};

export const resolveQuest = (quest: IQuest) => {
  return API.postRequest("/game/scene/event", {
    eventType: "QUEST_RESOLVE",
    currency: "GOLD",
    questId: quest.id,
  });
};

export const fixCrashForMoney = (quest: IQuest) => {
  return API.postRequest("/game/scene/event", {
    eventType: "CRASH_RESOLVE",
    constructionIndex: quest.params.constructionIndex,
  });
};
