import React, { useContext, useEffect } from "react";

import ModalBlock from "../../ModalBlock";
import Button from "../../Button";



import styles from "./DailyGiftCalendar.module.scss";
import "./giftSlider.scss";

import modalStyles from "../../SimpleModal/SimpleModal.module.scss";
import AwardList from "../../AwardList/AwardList";
import { viewNotification } from "../../../../services/notification";

import { incomingDays, prizeModalIcon } from "../../../../constants/prizes";
import Range from "rc-slider/lib/Range";
import ModalContextStore from "../../../../store/Modal";
import { NotificationsContext } from "../../../../store/Notifications";
import { prizeModalStoreContext } from "../../../../store/PrizeModal";
import { observer } from "mobx-react-lite";
import useScroller from "../../../hooks/useScroller";
import { getAwardsForDailyIncome, marks } from "../../../../constants/dailyGifts";
import ScrollArrow from "../../../ui/ScrollArrow/ScrollArrow";

const DailyGiftCalendar: React.FC = () => {
  const { closeModal, openModal } = useContext(ModalContextStore);
  const { incomingAward } = useContext(NotificationsContext);
  const { prize } = useContext(prizeModalStoreContext);
  const { ScrollContainer, isShowArrow } = useScroller();

  const openAward = () => {
    closeModal();
    if (prize) {
      openModal("prize", {modalImage: prizeModalIcon[prize.iconName]})
    }
  };

  const renderButton = (index: number) => {
    if (incomingAward?.prizeWined.reasonPrizeType) {
      if (incomingDays[incomingAward?.prizeWined.reasonPrizeType] > index) {
        return <span className={styles.awardIsReceived}>Получен</span>;
      }
      return (
        <Button
          onClick={() => openAward()}
          disabled={
            index > incomingDays[incomingAward?.prizeWined.reasonPrizeType]
          }
        >
          Открыть
        </Button>
      );
    }
    return <></>;
  };

  useEffect(() => {
    if (incomingAward) {
      viewNotification(incomingAward.id);
    }
  }, [incomingAward]);

  if (incomingAward) {
    const awardIndex = incomingDays[incomingAward?.prizeWined.reasonPrizeType];
    const handleStyles = [...Array(awardIndex)].map((n, index) => {
      return {
        display: index + 1 === awardIndex ? "none" : "block",
      };
    });
    return (
      <div className={styles.giftToday__body}>
        <div className={modalStyles.modal__body}>
          <ScrollContainer>
            <ModalBlock kind="common">
              <div>
                {incomingAward?.prizeWined.reasonPrizeType && (
                  <Range
                    min={1}
                    count={awardIndex}
                    defaultValue={Array.from(
                      {
                        length: awardIndex,
                      },
                      (_, i) => i + 1
                    )}
                    marks={marks}
                    disabled
                    dots={false}
                    railStyle={{ backgroundColor: "white" }}
                    dotStyle={{ display: "none" }}
                    tabIndex={[0, 1, 2, 3, 4, 5, 10]}
                    className={"giftSlider"}
                    max={6}
                    handleStyle={handleStyles}
                  />
                )}
                <p className={styles.giftToday__text}>
                  Не забывайте заходить в игру каждый день, чтобы не упустить шанс получить еще больше призов!
                </p>
                <AwardList awards={getAwardsForDailyIncome(renderButton)} />
              </div>
            </ModalBlock>
          </ScrollContainer>
        </div>
        {isShowArrow && <ScrollArrow />}
      </div>
    );
  }
  return <></>;
};

export default observer(DailyGiftCalendar);
