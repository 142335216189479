import React, { useEffect, useRef, useState } from "react";

import styles from "./Leaderboard.module.scss";
import { getAllTimeRating, getDailyRating } from "../../../../services/rating";
import LeaderboardLine from "./LeaderboardLine";
import { leaderboardType, leaderboardVariableKeyType } from "../../../../interfaces/Leaderboard";
import { leaderboardVariables } from "../../../../constants/leaderboards";

interface ILeaderboardTable {
  type: leaderboardVariableKeyType;
  description?: boolean;
}

const LeaderboardTable: React.FC<ILeaderboardTable> = ({
  type,
  description
}) => {
  const [leaderboards, setLeaderboards] = useState<leaderboardType>(leaderboardVariables);
  const userRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (type === leaderboardVariables.DAILY.value)
      getDailyRating().then((response) => {
        setLeaderboards((leaderboards) =>
          ({...leaderboards, DAILY: {...leaderboards.DAILY, rating: response.results.rating.data}})
        );
        userRef.current?.scrollIntoView({behavior: "smooth", block: "center"});
      });
    else
      getAllTimeRating().then((response) => {
        setLeaderboards((leaderboards) =>
          ({...leaderboards, ALL_TIME: {...leaderboards.ALL_TIME, rating: response.results.rating.data}})
        );
        userRef.current?.scrollIntoView({behavior: "smooth", block: "center"});
      });
  }, [type, userRef]);

  return (
    <>
      {description && 
        <>
          <h4>{leaderboards[type].header}</h4>
          <p>{leaderboards[type].description}</p>
        </>
      }
      <div className={styles.leaderboard__list}>
        {leaderboards[type].rating &&
          Object.entries(leaderboards[type].rating)
            .map(([key, val]) =>
              <LeaderboardLine
                key={key}
                position={Number(key)}
                type={type}
                userRef={val.currentUser ? userRef : undefined}
                {...val}
              />
            )
        }
      </div>
    </>
  );
};

export default LeaderboardTable;
