import { createContext } from "react";
import { makeObservable, observable, action } from "mobx";
import { IUser } from "../interfaces/common";

export class User {
  user?: IUser;

  constructor() {
    makeObservable(this, {
      user: observable,
      setUser: action.bound,
    });
  }

  setUser(userInfo?: IUser) {
    this.user = userInfo;
  }
}

export const UserStoreContext = createContext(new User());
