import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import AwardList from "../../AwardList/AwardList";
import ModalBlock from "../../ModalBlock";
import Button from "../../Button";

import { tenderAwards } from "../../../../constants/tenderAwards";
import { buyTender, getTenderPrice } from "../../../../services/store";
import { IMessage, ITender } from "../../../../interfaces/common";
import crystalIcon from "../../../../images/svg/diamond.svg";
import coinIcon from "../../../../images/svg/coin.svg";

import styles from "./Tender.module.scss";
import { ToastStoreContext } from "../../../../store/Toast";
import ModalContextStore from "../../../../store/Modal";
import { GameStateStoreContext } from "../../../../store/GameState";
import { IPrize } from "../../../../interfaces/Prize";
import { prizeModalStoreContext } from "../../../../store/PrizeModal";
import { prizeModalIcon } from "../../../../constants/prizes";
import SplittedNumber from "../../../ui/SplittedNumber/SplittedNumber";
import useScroller from "../../../hooks/useScroller";
import ScrollArrow from "../../../ui/ScrollArrow/ScrollArrow";

const Tender: React.FC = () => {
  const [tender, setTender] = useState<ITender>();
  const { pushToast } = useContext(ToastStoreContext);
  const { closeModal, openModal } = useContext(ModalContextStore);
  const { gold, crystal } = useContext(GameStateStoreContext);
  const { setPrize } = useContext(prizeModalStoreContext);

  const { ScrollContainer, isShowArrow } = useScroller();

  useEffect(() => {
    getTenderPrice().then((response) => {
      setTender(response.results.products.data[0]);
    });
    // we are sure that this hook is called only once when the component is mounted
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buy = (buyOption: "GOLD" | "CRYSTAL") => {
    buyTender(buyOption)
      .then((response) => {
        if (!response) throw new Error();
        pushToast("success", `Вы приняли участие в тендере`);
        closeModal();

        const prizeWined: IPrize | undefined = response.data.results?.messages?.data.find((message: IMessage) => message.messageType === "prizeWinMessage")?.prizeWined;
        
        if (prizeWined) {
          setPrize(prizeWined);
          openModal("prize", {modalImage: prizeModalIcon[prizeWined.iconName]})
        }
      })
      .catch((err) => {
        pushToast("error", `Что-то произошло`);
      });
  };

  const onBuyTender = () => {
    const buyOption = tender?.price.GOLD ? "GOLD" : "CRYSTAL";
    if (tender) {
      if (buyOption === "GOLD") {
        if (tender.price.GOLD && tender.price.GOLD <= gold) {
          buy("GOLD");
          closeModal()
        } else {
          openModal("notEnoughGold");
        }
      } else {
        if (tender.price.CRYSTAL && tender.price.CRYSTAL <= crystal) {
          buy("CRYSTAL");
          closeModal()
        } else {
          openModal("notEnoughCrystals");
        }
      }
    }
  };

  return (
    <div className={styles.tender}>
      <ScrollContainer>
        <ModalBlock kind="common">
          <div>
            <h4>Проводится тендер</h4>
            <p>{tender?.eventDescription}</p>
          </div>
          <div className={styles.tender__participate}>
            <h5>Участвовать в тендере</h5>
            <Button onClick={onBuyTender}>
              {tender?.price.CRYSTAL  &&  <img src={crystalIcon} alt="currency" />}
              {tender?.price.GOLD  &&  <img src={coinIcon} alt="currency" />}
              <SplittedNumber value={tender?.price.CRYSTAL || tender?.price.GOLD || 0} isAdaptive/>
            </Button>
          </div>
        </ModalBlock>
        <ModalBlock kind="common">
          <div>
            <h4>
              В случае успеха вы получите шанс выиграть:
            </h4>
            <div className={styles.tender__awards}>
              <AwardList awards={tenderAwards} />
            </div>
          </div>
        </ModalBlock>
      </ScrollContainer>
      {isShowArrow && <ScrollArrow />}
    </div>
  );
};

export default observer(Tender);
