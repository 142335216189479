import { leaderboardType } from "../interfaces/Leaderboard";
import daily from "../images/svg/calendar-daily.svg";
import allTime from "../images/svg/calendar-all-time.svg";

export const leaderboardVariables: leaderboardType = {
  DAILY: {
    header: "Рейтинг дня",
    text: "Сегодня",
    value: "DAILY",
    icon: daily,
    description: "Совет: используйте бустеры, чтобы защитить объекты связи от аварий!",
    rating: {}
  },
  ALL_TIME: {
    header: "Рейтинг за всё время",
    text: "Всё время",
    value: "ALL_TIME",
    icon: allTime,
    description: "Подсчет общих результатов назначен на 20 Января 2022 года. Постарайтесь набрать как можно больше очков репутации (сердец), чтобы ваш результат оказался среди лидеров. Первые 50 игроков в рейтинге получат самые ценные призы игры.",
    rating: {}
  }
};
