import React, { FC, useRef } from "react";

import { v4 as uuid } from 'uuid';

import layoutStyles from "../../layouts/Promo/Promo.module.scss";
import styles from "./Rules.module.scss";
import classNames from "classnames";
import { logos_rules } from "../../constants/logos";
import { prizesText } from "../../constants/prizes";

import { ReactComponent as HeartIcon } from "../../images/promo/indicators/heart.svg";
import { ReactComponent as ThumbIcon } from "../../images/promo/indicators/thumb.svg";
import { ReactComponent as GoldIcon } from "../../images/promo/indicators/gold.svg";
import { ReactComponent as CrystalIcon } from "../../images/promo/indicators/crystal.svg";
import { ReactComponent as CalendarIcon } from "../../images/promo/indicators/calendar.svg";
import { ReactComponent as UserIcon } from "../../images/promo/indicators/user.svg";
import { ReactComponent as HeaderIcon } from "../../images/promo/about.svg";

import ad from "../../images/promo/boosters/ad.png";
import loyalty from "../../images/promo/boosters/loyalty.png";
import crash from "../../images/promo/boosters/crash.png";
import maintenance from "../../images/promo/boosters/maintenance.png";
import BlurUpImage from "../../components/common/BlurUpImage/BlurUpImage";
import SideBanner from "../../components/common/promo/SideBanner/SideBanner";

const Rules: FC = () => {
  const headersRef = useRef<(HTMLHeadingElement | null)[]>([]);

  const handleAnchorClick = (
    e: React.MouseEvent<HTMLAnchorElement>,
    index: number
  ) => {
    e.preventDefault();
    if (
      headersRef &&
      headersRef.current.length &&
      headersRef.current[index] !== null
    )
      headersRef.current[index]?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
  };

  const getRef = (element: HTMLHeadingElement | null, index: number) => {
    headersRef.current[index] = element;
  };

  const startLinks = {
    rostelecomPersonalArea: "https://b2c.passport.rt.ru/auth/realms/b2c/protocol/openid-connect/auth?response_type=code&scope=openid&client_id=lk_b2c&redirect_uri=https%3A%2F%2Flk.rt.ru%2Fsso-auth%2F%3Fredirect%3Dhttps%253A%252F%252Flk.rt.ru%252F&state=%7B%22uuid%22%3A%22A2EF72FE-9F5E-4CF6-A2F2-010CB51A53E0%22%7D",
    myRostelecomIOS: "https://apps.apple.com/ru/app/%D0%BC%D0%BE%D0%B9-%D1%80%D0%BE%D1%81%D1%82%D0%B5%D0%BB%D0%B5%D0%BA%D0%BE%D0%BC/id561082205",
    myRostelecomAndroid: "https://play.google.com/store/apps/details?id=com.dartit.RTcabinet&hl=ru&gl=US",
    moscowPersonalArea: "https://my.rt.ru/",
    moscowAppIOS: "https://apps.apple.com/ru/app/%D1%80%D0%BE%D1%81%D1%82%D0%B5%D0%BB%D0%B5%D0%BA%D0%BE%D0%BC-%D0%BC%D0%BE%D1%81%D0%BA%D0%B2%D0%B0/id1163269838",
    moscowAppAndroid: "https://play.google.com/store/apps/details?id=ru.onlime.my.app&hl=ru&gl=US",
    moscowSignUp: "https://msk.rt.ru/bonus"
  }

  return (
    <div className={layoutStyles.promo}>
      <SideBanner />      
      <div className={styles.rules__images}>
        <BlurUpImage 
          placeholderImageUrl={`${process.env.PUBLIC_URL}/assets/promo/rules/1-low.png`}
          originalImageUrl={`${process.env.PUBLIC_URL}/assets/promo/rules/1.png`}
          className={styles["rules__rule1-img"]}
          alt=""
        />
        <BlurUpImage 
          placeholderImageUrl={`${process.env.PUBLIC_URL}/assets/promo/rules/2-low.png`}
          originalImageUrl={`${process.env.PUBLIC_URL}/assets/promo/rules/2.png`}
          className={styles["rules__rule2-img"]}
          alt=""
        />
        <BlurUpImage 
          placeholderImageUrl={`${process.env.PUBLIC_URL}/assets/promo/rules/3-low.png`}
          originalImageUrl={`${process.env.PUBLIC_URL}/assets/promo/rules/3.png`}
          className={styles["rules__rule3-img"]}
          alt=""
        />
        <BlurUpImage 
          placeholderImageUrl={`${process.env.PUBLIC_URL}/assets/promo/rules/4-low.png`}
          originalImageUrl={`${process.env.PUBLIC_URL}/assets/promo/rules/4.png`}
          className={styles["rules__rule4-img"]}
          alt=""
        />
        <BlurUpImage 
          placeholderImageUrl={`${process.env.PUBLIC_URL}/assets/promo/rules/5-low.png`}
          originalImageUrl={`${process.env.PUBLIC_URL}/assets/promo/rules/5.png`}
          className={styles["rules__rule5-img"]}
          alt=""
        />
        <BlurUpImage 
          placeholderImageUrl={`${process.env.PUBLIC_URL}/assets/promo/rules/6-low.png`}
          originalImageUrl={`${process.env.PUBLIC_URL}/assets/promo/rules/6.png`}
          className={styles["rules__rule6-img"]}
          alt=""
        />
        <BlurUpImage 
          placeholderImageUrl={`${process.env.PUBLIC_URL}/assets/promo/rules/7-low.png`}
          originalImageUrl={`${process.env.PUBLIC_URL}/assets/promo/rules/7.png`}
          className={styles["rules__rule7-img"]}
          alt=""
        />
        <BlurUpImage 
          placeholderImageUrl={`${process.env.PUBLIC_URL}/assets/promo/rules/8-low.png`}
          originalImageUrl={`${process.env.PUBLIC_URL}/assets/promo/rules/8.png`}
          className={styles["rules__rule8-img"]}
          alt=""
        />
        <BlurUpImage 
          placeholderImageUrl={`${process.env.PUBLIC_URL}/assets/promo/rules/9-low.png`}
          originalImageUrl={`${process.env.PUBLIC_URL}/assets/promo/rules/9.png`}
          className={styles["rules__rule9-img"]}
          alt=""
        />
        <BlurUpImage 
          placeholderImageUrl={`${process.env.PUBLIC_URL}/assets/promo/rules/10-low.png`}
          originalImageUrl={`${process.env.PUBLIC_URL}/assets/promo/rules/10.png`}
          className={styles["rules__rule10-img"]}
          alt=""
        />
        <BlurUpImage 
          placeholderImageUrl={`${process.env.PUBLIC_URL}/assets/promo/rules/11-low.png`}
          originalImageUrl={`${process.env.PUBLIC_URL}/assets/promo/rules/11.png`}
          className={styles["rules__rule11-img"]}
          alt=""
        />
      </div>
      <div className={classNames(layoutStyles.promo__content, styles.rules)}>
        <div>
          <HeaderIcon />
          <h1>Об игре</h1>
          <div>
            <p>Добро пожаловать в Онлайн-игру «Ростелеком Tycoon»!</p>
            <br />
            <p>
              Вам предстоит построить свой виртуальный бизнес в качестве
              руководителя телеком-компании – пройти путь от небольшого
              провайдера услуг связи до оператора федерального масштаба. Для
              этого необходимо развивать компанию, совершенствовать технологии и
              повышать лояльность абонентов.
              <br />
              <br />
              Сможете ли вы обеспечить связью всю страну и справиться с
              различными трудностями, которые встанут на пути? Все в ваших
              руках!
            </p>
            <br />
            <p>
              Откройте в себе талант предпринимателя, играя в «Ростелеком
              Tycoon».
            </p>
            <br />
            <p>За успехи вы будете вознаграждены ценными призами.</p>
            <br />
            <p>Не забывайте заходить в игру каждый день, чтобы получить еще больше подарков!</p>
          </div>
          <div className={styles.rules__contentsTable}>
            <div>
              <a href="#1" onClick={(e) => handleAnchorClick(e, 0)}>
                Призы
              </a>
              <a href="#2" onClick={(e) => handleAnchorClick(e, 1)}>
                Время проведения игры
              </a>
              <a href="#3" onClick={(e) => handleAnchorClick(e, 2)}>
                С чего начать
              </a>
              <a href="#4" onClick={(e) => handleAnchorClick(e, 3)}>
                Как играть
              </a>
              <a href="#5" onClick={(e) => handleAnchorClick(e, 4)}>
                Основные игровые показатели
              </a>
              <a href="#6" onClick={(e) => handleAnchorClick(e, 5)}>
                Аварии
              </a>
            </div>
            <div>
              <a href="#7" onClick={(e) => handleAnchorClick(e, 6)}>
                Центр развития технологий
              </a>
              <a href="#8" onClick={(e) => handleAnchorClick(e, 7)}>
                Центр развития персонала
              </a>
              <a href="#9" onClick={(e) => handleAnchorClick(e, 8)}>
                События в игре
              </a>
              <a href="#10" onClick={(e) => handleAnchorClick(e, 9)}>
                Бустеры
              </a>
              <a href="#11" onClick={(e) => handleAnchorClick(e, 10)}>
                Магазин
              </a>
              <a href="#12" onClick={(e) => handleAnchorClick(e, 11)}>
                Порядок выдачи призов
              </a>
            </div>
          </div>
        </div>
        <div className={styles.rules__prizesBlock}>
          <h2 id="1" ref={(e) => getRef(e, 0)}>
            Более 250 000 призов от Ростелекома и партнёров:
          </h2>
          <div className={styles.rules__prizesBlock__logoList}>
            {logos_rules.map((logo) => (
              <img src={logo} key={uuid()} alt="" />
            ))}
          </div>
          <ul className={styles.rules__dotList}>
            {prizesText.map((prize) => (
              <li key={uuid()}>
                <span>{prize}</span>
              </li>
            ))}
          </ul>
          <a
            href={process.env.PUBLIC_URL + "/assets/files/" + encodeURIComponent("Правила проведения онлайн-игры Tycoon.pdf")}
            className={styles.rules__pdf}
            download
          >
            Подробнее о призах и порядке
            <br />
            розыгрыша читайте в Правилах игры
          </a>
        </div>
        <div>
          <h2 id="2" ref={(e) => getRef(e, 1)}>
            Время проведения игры
          </h2>
          <div>
            <p>
              Игра проводится <b>с 10.11.2021 по 20.01.2022 г.</b>
            </p>
            <br />
            <p>
              Период выдачи призов <b>с 10.11.2021 по 14.02.2022 г.</b>
            </p>
            <br />
            <p>
              Подведение итогов и розыгрыш главных призов пройдет{" "}
              <b>20 января 2022 г.</b>
            </p>
          </div>
        </div>
        <div>
          <h2 id="3" ref={(e) => getRef(e, 2)}>
            С чего начать
          </h2>
          <div>
            <p>
              Чтобы принять участие в игре, зарегистрируйтесь или авторизуйтесь
              в <a target="_blank" rel="noreferrer" href={startLinks.rostelecomPersonalArea}>Едином личном кабинете Ростелекома</a>. Он доступен
              и на мобильных устройствах — в приложении «Мой Ростелеком» для{" "}
              <a target="_blank" rel="noreferrer" href={startLinks.myRostelecomIOS}>iOS</a> и <a target="_blank" rel="noreferrer" href={startLinks.myRostelecomAndroid}>Android</a>.
            </p>
            <br />
            <p>
              Абонентам г. Москвы необходимо зарегистрироваться или
              авторизоваться в{" "}
              <a target="_blank" rel="noreferrer" href={startLinks.moscowPersonalArea}>Личном кабинете для клиентов г. Москвы</a> либо через
              мобильное приложение <a target="_blank" rel="noreferrer" href={startLinks.moscowAppIOS}>iOS</a> и <a target="_blank" rel="noreferrer" href={startLinks.moscowAppAndroid}>Android</a>
              .
            </p>
            <br />
            <p>
              Если вы еще не являетесь участником программы «Бонус» Ростелекома,
              рекомендуем <a target="_blank" rel="noreferrer" href={startLinks.moscowSignUp}>зарегистрироваться</a> в ней прямо сейчас.
              Сразу после регистрации вы получите приветственные бонусные баллы,
              которые сможете использовать для покупки игровой валюты.
            </p>
          </div>
        </div>
        <div>
          <h1 id="4" ref={(e) => getRef(e, 3)}>
            Как играть
          </h1>
          <div>
            <p>
              Основная задача игры — охватить как можно большее количество
              городов и их жителей услугами связи. При этом необходимо улучшать
              технологии, справляться с авариями, бороться с конкурентами и
              зарабатывать очки репутации — «сердца», которые дают возможность
              выигрывать ценные призы в игре.
            </p>
            <br />
            <p>
              Чтобы развивать бизнес и привлекать новых абонентов, вам
              понадобится строить узлы связи, расположенные в городах, и линии
              связи между городами. От количества и работоспособности этих
              объектов зависит количество абонентов и их лояльность.
            </p>
          </div>
        </div>
        <div>
          <h2 id="5" ref={(e) => getRef(e, 4)}>
            Основные игровые показатели:
          </h2>
          <p>
            В главном окне игры располагается игровое поле — карта, на которой
            размещены 7 городов, а также отображаются основные игровые
            показатели:
          </p>
          <ul className={styles.rules__iconList}>
            <li>
              <div className={styles.normalSizeIconContainer}>
                <HeartIcon />
              </div>
              <div>
                <p>
                  <b>Очки репутации</b>
                </p>
                <p>
                  Главный игровой показатель, используемый для определения
                  победителей в игре. Прирост очков репутации зависит от
                  количества абонентов, их лояльности и валовой прибыли
                </p>
              </div>
            </li>
            <li>
              <div className={styles.normalSizeIconContainer}>
                <ThumbIcon />
              </div>
              <div>
                <p>
                  <b>Лояльность абонентов</b>
                </p>
                <p>
                  Отражает текущее отношение абонентов к тарифной политике
                  компании и качеству предоставляемых услуг связи (зависит от
                  количества и продолжительности аварий). Показатель влияет на
                  прирост и отток абонентов.
                </p>
              </div>
            </li>
            <li>
              <div className={styles.normalSizeIconContainer}>
                <GoldIcon />
              </div>
              <div>
                <p>
                  <b>Монеты</b>
                </p>
                <p>
                  Начисляются на игровой счет пропорционально количеству
                  активных абонентов и уровню установленного тарифа. Монеты
                  могут быть получены в качестве вознаграждения за участие в
                  игровых событиях, за покупки в интернет-магазинах партнеров
                  программы «Бонус» и за подключение услуг Ростелекома. Монеты
                  также можно приобрести за кристаллы в разделе <b>«Магазин»</b>
                  .
                </p>
              </div>
            </li>
            <li>
              <div className={styles.normalSizeIconContainer}>
                <CrystalIcon />
              </div>
              <div>
                <p>
                  <b>Кристаллы</b>
                </p>
                <p>
                  Позволяют приобретать бустеры и ускорять игровые события.
                  Кристаллы можно приобрести за бонусные баллы программы «Бонус»
                  или денежные средства с лицевого счета в Ростелекоме в разделе{" "}
                  <b>«Магазин»</b>.
                </p>
              </div>
            </li>
            <li>
              <div className={styles.normalSizeIconContainer}>
                <CalendarIcon />
              </div>
              <div>
                <p>
                  <b>Эра</b>
                </p>
                <p>
                  Отражает текущий уровень развития ваших технологий. 
                  Нажмите на иконку с наименованием текущей эры, чтобы получить информацию и перейти в следующую технологическую эру. 
                  Переход потребуется для того, чтобы открывать новые технологии в игре.
                </p>
              </div>
            </li>
            <li>
              <div className={styles.normalSizeIconContainer}>
                <UserIcon />
              </div>
              <div>
                <p>
                  <b>Абоненты</b>
                </p>
                <p>
                  Отражает суммарное количество абонентов со всех городов. 
                  Прирост абонентов увеличивает ваш доход и скорость начисления очков лояльности, 
                  но приводит к повышению нагрузки на узлы и линии связи.
                </p>
              </div>
            </li>
          </ul>
        </div>
        <div>
          <h2>Развивайте инфраструктуру — стройте игровые объекты</h2>
          <div>
            <p>
              Чтобы начать предоставлять услуги связи в городе, постройте в нем
              узел связи и подведите к нему линию связи.
            </p>
            <br />
            <p>
              Для постройки узла связи выберите на карте город и нажмите{" "}
              <b>«Построить»</b> во всплывающем окне. Указанная стоимость
              строительства в монетах будет списана с вашего игрового счета.
              Аналогично постройте линию высокоскоростной связи между городами,
              чтобы подвести Интернет.
            </p>
            <br />
            <p>
              Помните, что при подключении новых городов нагрузка на узлы и
              линии связи уже подключенных городов будет возрастать. Чтобы снять
              нагрузку, необходимо модернизировать объекты, повышая их
              технологический уровень.
            </p>
            <br />
            <p>
              Строительство и модернизация объектов занимает определенное время,
              но процесс можно ускорить, используя бустеры и кристаллы.
            </p>
          </div>
        </div>
        <div>
          <h2>Города — центры активности</h2>
          <div>
            <p>
              Выбрав на карте город, можно увидеть его основные характеристики:
              количество жителей, количество подключенных абонентов, их приток
              (отток) и лояльность, транзитный трафик из других городов,
              подключенных к узлу.
            </p>
            <br />
            <p>
              Также в окне города отображается технический статус узла связи —
              показатель, характеризующий нагрузку на узел связи в процентах от
              максимума. При приближении этого показателя к максимуму качество
              связи падает, что ведет к снижению лояльности и оттоку абонентов.
            </p>
          </div>
        </div>
        <div>
          <h3>Действия в городах:</h3>
          <ul className={styles.rules__dotList}>
            <li>
              Повысить лояльность — провести рекламную кампанию или внедрить
              программу лояльности для того, чтобы повысить удовлетворенность
              существующих абонентов и увеличить прирост новых абонентов
            </li>
            <li>
              Провести техническое обслуживание — на время исключает возможность
              возникновения аварии на узле
            </li>
            <li>
              Модернизировать узел связи — увеличить пропускную способность узла
              связи с целью подключения большего числа абонентов
            </li>
            <li>
              Отрегулировать тариф — снижение тарифа повышает лояльность и
              приток абонентов, но снижает вашу прибыль и увеличивает нагрузку
              на узел
            </li>
          </ul>
        </div>
        <div>
          <h2>
            Линии связи — основа
            <br />
            инфраструктуры
          </h2>
          <div>
            <p>
              Выбрав на карте линию связи, можно увидеть ее основные
              характеристики: транзитный трафик из городов, подключенных к
              линии, нагрузку в процентах от максимума, текущий уровень
              стабильности линии связи.
            </p>
            <br />
            <p>
              Следите за нагрузкой и вовремя модернизируйте линии связи для
              того, чтобы передавать больше данных, подключать новые города и
              абонентов.
            </p>
          </div>
        </div>
        <div>
          <h2 id="6" ref={(e) => getRef(e, 5)}>
            Аварии
          </h2>
          <div>
            <p>
              Время от времени в игре происходят непредвиденные события:
              подтопления, ураганы, ливни и т. д. Они могут вызывать аварии на
              узлах связи и линиях связи.
            </p>
            <br />
            <p>
              Для устранения аварии нажмите на значок неисправности на линии или
              в городе. Появится окно с информацией о происшествии и возможные
              пути устранения проблемы.
            </p>
            <br />
            <p>Для мгновенного устранения аварии применяйте бустер.</p>
            <br />
            <p>
              Не затягивайте с решением проблем, ведь аварии напрямую влияют на
              удовлетворенность и лояльность абонентов!
            </p>
          </div>
        </div>
        <div>
          <h2 id="7" ref={(e) => getRef(e, 6)}>
            Центр развития технологий
          </h2>
          <div>
            <p>
              По мере развития бизнеса вам потребуется осваивать новые
              технологии.
            </p>
            <br />
            <p>
              Сделать это можно, кликнув на иконку технологического центра в
              правом нижнем углу.
            </p>
            <br />
            <p>
              Для внедрения новой технологии должны быть выполнены следующие
              условия:
            </p>
          </div>
          <ul className={styles.rules__dotList}>
            <li>Открыта соответствующая технологическая эра;</li>
            <li>
              Достигнут необходимый уровень компетенций отдела строителей.
            </li>
          </ul>
        </div>
        <div>
          <h2 id="8" ref={(e) => getRef(e, 7)}>
            Центр развития персонала
          </h2>
          <div>
            <p>
              Успех бизнеса зависит от команды, которая будет работать вместе с
              вами. Повышайте уровень компетенций персонала, обучайте отдельных
              сотрудников или весь отдел сразу.
            </p>
            <br />
            <p>
              В окне Центра развития персонала отображается уровень следующих
              отделов:
            </p>
          </div>
          <ul className={styles.rules__dotList}>
            <li>
              <p>
                <b>Технический персонал</b>
              </p>
              Отвечает за устранение аварий. Повышение уровня компетенций этих
              сотрудников позволяет быстрее и дешевле устранять аварии. По мере
              продвижения в игре для устранения аварий потребуется дополнительно
              обучать персонал;
            </li>
            <li>
              <p>
                <b>Строители</b>
              </p>
              Отвечают за постройку более современных линий и узлов связи. От
              уровня компетенций этих сотрудников будет зависеть уровень
              доступных для внедрения технологий и скорость постройки объектов
            </li>
            <li>
              <p>
                <b>Клиентский сервис</b>
              </p>
              Отвечает за обслуживание клиентов. Повышение уровня компетенций
              этих сотрудников поможет повысить лояльность абонентов;
            </li>
            <li>
              <p>
                <b>HR и забота о персонале</b>
              </p>
              Эти сотрудники отвечают за лояльность работников к компании.
              Повышение уровня их компетенций способствует более эффективной
              работе остального персонала, сокращает отток сотрудников и
              позволяет привлечь новых, когда это необходимо.
            </li>
          </ul>
        </div>
        <div>
          <h2 id="9" ref={(e) => getRef(e, 8)}>
            События в игре
          </h2>
          <div>
            <p>
              Виртуальная страна живет своей жизнью, события происходят
              регулярно. От вашей скорости реакции будет зависеть лояльность
              абонентов и ваша выручка.
            </p>
            <br />
            <p>
              Следите за уведомлениями в игре, события могут приносить вам как
              выгоду, так и потери!
            </p>
          </div>
          <ul className={styles.rules__dotList}>
            <li>
              <p>
                <b>Розыгрыш тендеров.</b>
              </p>
              Участие в тендере платное, зато победа в нём дает мгновенный
              прирост абонентов в городе и шанс получить игровую валюту и
              специальные призы;
            </li>
            <li>
              <p>
                <b>Появление конкурентов.</b>
              </p>
              Часть абонентов уходит к конкуренту. Чтобы их вернуть, измените
              тариф, проведите рекламную кампанию или развивайте технологии;
            </li>
            <li>
              <p>
                <b>Городские мероприятия.</b>
              </p>
              Вам будет предложено стать спонсором различных мероприятий, что
              поможет увеличить лояльность абонентов;
            </li>
            <li>
              <p>
                <b>События в отделе кадров.</b>
              </p>
              Ваша команда живет своей повседневной жизнью. Сотрудники
              заинтересованы в росте и развитии, а конкуренты заинтересованы в
              том, чтобы переманить ваши таланты к себе.
            </li>
            <li>
              <p>
                <b>Инвестиции в стартап.</b>
              </p>
              Во время игры вы сможете пригласить друзей присоединиться к вам.
              Выберите квест <b>«Вложить деньги в многообещающий стартап»</b>,
              скопируйте ссылку из специального окна и поделитесь ей с другом.
              После регистрации по ссылке ваш друг получит 500 000 монет, а вам
              достанется ценный приз - 1 000 000 монет, когда друг совершит
              покупку кристаллов в магазине.
            </li>
          </ul>
        </div>
        <div>
          <h2 id="10" ref={(e) => getRef(e, 9)}>
            Бустеры
          </h2>
          <div>
            <p>
              У каждого телеком-оператора есть свои способы привлечь абонентов, 
              сделать сеть стабильнее или получить новые технологии раньше конкурентов. 
              Используйте весь арсенал бустеров, чтобы сделать ваш бизнес успешнее.
              Вам доступны различные типы бустеров, каждый из которых влияет на свой отдельный показатель: 
            </p>
            <br />
            <p>
              Вам доступны различные типы бустеров, каждый из которых влияет на
              свой отдельный показатель:
            </p>
          </div>
          <ul className={styles.rules__iconList}>
            <li>
              <img src={loyalty} alt="" className={styles.rules__booster} />
              <p>
                <b>Бустер «Программа лояльности»</b> — для привлечения и 
                повышения лояльности абонентов на текущий момент; 
              </p>
            </li>
            <li>
              <img src={ad} alt="" className={styles.rules__booster} />
              <p>
                <b>Бустер «Реклама»</b> — влияет на будущую лояльность абонентов, 
                постепенно повышая ее; 
              </p>
            </li>
            <li>
              <img src={maintenance} alt="" className={styles.rules__booster} />
              <p>
                <b>Бустер «Техническое обслуживание»</b> — для повышения отказоустойчивости сети;
              </p>
            </li>
            <li>
              <img src={crash} alt="" className={styles.rules__booster} />
              <p>
                <b>Бустер «Устранение аварий»</b> — позволяет 
                моментально устранить аварию на узле или линии связи.
              </p>
            </li>
          </ul>
          <p>
            Размер бустера влияет на продолжительность или эффективность его действия.
          </p>
        </div>
        <div>
          <h2 id="11" ref={(e) => getRef(e, 10)}>
            Магазин
          </h2>
          <div>
            <p>
              Вы можете приобрести игровую валюту - монеты и кристаллы, а также
              бустеры за бонусные баллы программы лояльности «Бонус» или
              денежные средства с лицевого счета в Ростелекоме.
            </p>
            <br />
            <p>
              Также вам доступны выгодные предложения — приобретайте пакеты
              игровой валюты и бустеров с хорошей скидкой.
            </p>
            <br />
            <p>
              Некоторые предложения действуют ограниченное время — не упустите
              выгоду!
            </p>
          </div>
        </div>
        <div>
          <h2 id="12" ref={(e) => getRef(e, 11)}>
            Порядок выдачи призов
          </h2>
          <p>Призы в игре делятся на 4 категории:</p>
          <ul className={styles.rules__dotList}>
            <li>
              <b>Внутриигровые</b> — монеты и бустеры;
            </li>
            <li>
              <b>Дополнительные призы от Ростелекома и партнеров</b> – выдаются
              за участие в игровых событиях;
            </li>
            <li>
              <b>Ценные призы от Ростелекома и партнеров</b> выдаются 50
              игрокам, набравшим наибольшее количество очков репутации (сердец)
              на момент подведения итогов игры;
            </li>
            <li>
              <b>
                Дополнительный Приз от Ростелекома за 1 место получает один
                Абонент
              </b>{" "}
              — победитель игры. Чтобы получить этот приз, на дату подведения
              итогов (20 января 2022 г.), игроку необходимо набрать наибольшее
              количество очков репутации (сердец) среди всех участников игры, то
              есть занять первую строчку в лидерборде.
            </li>
          </ul>
          <p>
            Внутриигровые призы отображаются на странице <b>«История»</b>.
            Остальные призы можно найти и активировать в разделе{" "}
            <b>«Мои призы»</b>. Не переживайте, если не успели их получить —
            раздел будет доступен даже после завершения игры,{" "}
            <b>до 14.02.2022 г</b>.
          </p>
        </div>
        <div id="17" ref={(e) => getRef(e, 16)}>
          <h2>Удачной игры!</h2>
          <a
            href={process.env.PUBLIC_URL + "/assets/files/Правила проведения онлайн-игры Tycoon.pdf"}
            className={styles.rules__pdf}
            download
          >
            Правила проведения
            <br />
            онлайн-игры «Tycoon»
          </a>
        </div>
      </div>
    </div>
  );
};

export default Rules;
