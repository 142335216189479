import { createContext } from "react";
import { makeObservable, observable, action } from "mobx";
import { currenciesPackCodes } from "../interfaces/Market";

export class FastBuyModal {
  kit?: currenciesPackCodes

  constructor() {
    makeObservable(this, {
      kit: observable,
      setKit: action.bound
    });
  }

  setKit(code: currenciesPackCodes) {
    this.kit = code;
  }

  
}

export const fastBuyModalStoreContext = createContext(new FastBuyModal());
