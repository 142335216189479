import { IModal, IModalsGroup} from '../../store/Modal'
import { isInstanceOfIModalsGroup } from '../type-guards/type-guards'

export function flatModalsArray(modals: (IModal | IModalsGroup)[]): IModal[] {
  let result: IModal[] = []
  modals.forEach(modal => {
    if (isInstanceOfIModalsGroup(modal)) {
      modal.items.forEach(item => result.push(item))
    }
    else {
      result.push(modal)
    }
  })
  return result
} 