import { createContext } from "react";
import { action, makeObservable, observable } from "mobx";
import { IQuest } from "../interfaces/common";

export class Quest {
  quests: IQuest[] = [];

  errors: IQuest[] = [];

  openedQuest?: IQuest | null = null;

  newQuests = 0;

  currentError = 0;

  constructor() {
    makeObservable(this, {
      quests: observable,
      errors: observable,
      newQuests: observable,
      openedQuest: observable,
      setQuests: action.bound,
      setOpenedQuest: action.bound,
      setNewQuests: action.bound,
      setErrors: action.bound,
      incCurrentError: action.bound
    });
  }

  setNewQuests(count: number) {
    this.newQuests = count;
  }

  setQuests(quests: IQuest[]) {
    this.quests = quests;
    this.setErrors(quests.filter(item => item.questType === 'CRASH'));
  }

  setOpenedQuest(quest: IQuest) {
    this.openedQuest = quest;
  }

  setErrors(errors: IQuest[]) {
    this.errors = errors;
  }

  incCurrentError() {
    const newIndex = this.currentError + 1;

    this.currentError = newIndex === this.errors.length ? 0 : newIndex;
  }
}

export const QuestStoreContext = createContext(new Quest());
