import { createContext } from "react";
import { makeObservable, observable, action } from "mobx";
import { IPrize } from "../interfaces/Prize";

export class PrizeModal {
  prize?: IPrize;

  constructor() {
    makeObservable(this, {
      prize: observable,
      setPrize: action.bound,
    });
  }

  setPrize(prize: IPrize) {
    this.prize = prize;
  }
}

export const prizeModalStoreContext = createContext(new PrizeModal());
