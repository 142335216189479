import React from "react"
import ReactDOM from "react-dom"
import { configure } from "mobx"
import { Router } from 'react-router'
import { createBrowserHistory } from 'history'

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import GameRouter from "./router/GameRouter"
import Navigation from "./components/common/Navigation/Navigation"

import "./index.scss"

import 'core-js';
import CookieAlert from "./components/common/CookieAlert/CookieAlert"

const CAPTCHA_KEY = '6Le2jLgcAAAAAKqwkJsewfZYS0Z237dIptvSe73O';
const history = createBrowserHistory();

configure({
  useProxies: "never",
})

ReactDOM.render(
  <React.StrictMode>
    <GoogleReCaptchaProvider reCaptchaKey={CAPTCHA_KEY} language="ru">
      <Router history={history}>
        <GameRouter />
        <Navigation />
        <CookieAlert />
      </Router>
    </GoogleReCaptchaProvider>
  </React.StrictMode>,
  document.getElementById("root")
)

