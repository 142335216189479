import classNames from "classnames";
import React, { useContext } from "react";
import { TutorialStoreContext } from "../../../store/Tutorial";

import style from "./ModalFooterSelector.module.scss";

type footerKindType = "staff"

type ModalFooterSelectorProps = {
  variables: {value: string, icon: string, text?: string}[];
  onSelected: (value: string) => void;
  selected: string;
  brightness?: boolean
  footerKind?: footerKindType
};

const ModalFooterSelector: React.FC<ModalFooterSelectorProps> = ({
  variables,
  selected,
  onSelected,
  brightness,
  footerKind
}) => {
  
  const { activeUIElement, nextStep } = useContext(TutorialStoreContext);

  const boosterClass = classNames([style.selector], {
    [style["selector--brightness"]]: brightness,
  });


  return (
    <div className={boosterClass}>
      {variables.map((variable, index) => {
        
        const selectedBoosterClass = classNames(
          style["selector__variable"], 
          style["selector-item"], 
          {
            [style["selector-item--selected"]]: selected === variable.value,
            [style["selector__variable--selected"]]: selected === variable.value,
            [style["selector-item--low-text"]]: variables.length > 4,
            [style["selector-item--staff"]]: footerKind === "staff",
            [style["selector__variable--staff"]]: footerKind === "staff",
            "highlighted with-arrow top": index === 3 && activeUIElement === 'boosterAdvSelection'
          }
        )

        const handleSelectorClick = (v: string) => {
          onSelected(v);
          if (activeUIElement === 'boosterAdvSelection') nextStep();
        }
        return (
          <div
            key={index}
            onClick={() => handleSelectorClick(variable.value)}
            className={selectedBoosterClass}
          >
            <img src={variable.icon} alt="" />
            {variable.text && <span>{variable.text}</span>}
          </div>
        );
      })}
    </div>
  );
};

export default ModalFooterSelector;
