import API from "../../api";
import { departmentVariableKeysType } from "../../interfaces/GameState";

const SCENE_URL = "game/scene";

export const getGameState = () => API.getRequest(SCENE_URL + "/state");

export const upBuildingLevel = (buildID: string, currency: "GOLD" | "CRYSTAL") => {
  return API.postRequest(SCENE_URL + "/event", {
    eventType: "UP_LEVEL",
    constructionIndex: buildID,
    currency,
  });
};

export const changeTariff = (cityID: string, value: number) => {
  return API.postRequest(SCENE_URL + "/event", {
    eventType: "CHANGE_TARIFF",
    constructionIndex: cityID,
    value,
  });
};

export const fixLineCrash = (lineID: string) => {
  return API.postRequest(SCENE_URL + "/event", {
    eventType: "CRUSH_RESOLVE",
    constructionIndex: lineID,
  });
};

export const applyBooster = (productCode: string, cityID: string) => {
  return API.postRequest(SCENE_URL + "/event", {
    eventType: "INVENTORY_APPLY",
    productCode: productCode,
    args: {
      constructionIndex: cityID,
    },
  });
};

export const upLevelEmployee = (
  id: string,
  departmentType: departmentVariableKeysType
) => {
  return API.postRequest(SCENE_URL + "/event", {
    eventType: "UP_LEVEL_EMPLOYEE",
    id: id,
    departmentType: departmentType,
  });
};

export const upLevelDepartment = (
  departmentType: departmentVariableKeysType
) => {
  return API.postRequest(SCENE_URL + "/event", {
    eventType: "UP_LEVEL_DEPARTMENT",
    departmentType: departmentType,
  });
};

export const newEmployee = (departmentType: departmentVariableKeysType) => {
  return API.postRequest(SCENE_URL + "/event", {
    eventType: "NEW_EMPLOYEE",
    departmentType: departmentType,
  })
}

export const discoverTechnology = (technology: string) => {
  return API.postRequest(SCENE_URL + "/event", {
    eventType: "DISCOVER_TECHNOLOGY",
    technologyType: technology,
  });
};

export const skipEpoch = () => {
  return API.postRequest(SCENE_URL + "/event", {
    eventType: "SKIP_EPOCH",
  });
};

export const getInventory = () => {
  return API.getRequest("inventory");
}

export const skipCrashResolve = (constructionIndex: string, ) => {
  return API.postRequest(SCENE_URL + "/event", {
    eventType: "SKIP_CRASH_RESOLVE",
    constructionIndex
  });
}

export const skipUpLevel = (constructionIndex: string) => {
  return API.postRequest(SCENE_URL + "/event", {
    eventType: "SKIP_UP_LEVEL",
    constructionIndex
  });
}

export const skipEmployeeUpLevel = (employeeId: string, departmentType: departmentVariableKeysType) => {
  return API.postRequest(SCENE_URL + "/event", {
    eventType: "SKIP_EMPLOYEE_UP_LEVEL",
    id: employeeId,
    departmentType: departmentType
  });
}

export const skipDepartmentUpLevel = (departmentType: departmentVariableKeysType) => {
  return API.postRequest(SCENE_URL + "/event", {
    eventType: "SKIP_DEPARTMENT_UP_LEVEL",
    departmentType: departmentType
  });
}

export const nextTutorialStep = (tutorialLevelId: number) => {
  return API.postRequest(SCENE_URL + "/event", {
    eventType: "TUTORIAL",
    tutorialLevelId
  });
};

export const getTutorialStep = () => {
  return API.getRequest(SCENE_URL + '/state/short')
}
