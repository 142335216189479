import { FC, useContext, useEffect, useState } from "react";

import styles from "./Feedback.module.scss";
import layoutStyles from "../../layouts/Promo/Promo.module.scss";
import mail from "../../images/promo/mail.svg";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { getFeedbackCategories, sendFeedback } from "../../services/feedback";
import { getFullProfile } from "../../services/user";
import { ICategory, IDropdownValue, IUser } from "../../interfaces/common";
import { observer } from "mobx-react-lite";
import { FeedbackStoreContext } from "../../store/Feedback";
import ModalContextStore from "../../store/Modal";
import { AuthorizationContext } from "../../store/Auth";
import Modal from "../../components/Modal";
import SideBanner from "../../components/common/promo/SideBanner/SideBanner";
import { IFeedbackForm } from "../../interfaces/Feedback";
import FeedbackForm from "../../components/common/FeedbackForm/FeedbackForm";

const Feedback: FC = () => {
  const [userInfo, setUserInfo] = useState<IUser>();
  const [categories, setCategories] = useState<IDropdownValue[]>([]);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { setIssueId } = useContext(FeedbackStoreContext);
  const { openModal } = useContext(ModalContextStore);
  const { isAuthorized } = useContext(AuthorizationContext);
  
  const handleFeedback = async (data: IFeedbackForm, files?: File[]) => {
    if (executeRecaptcha) {
      const captcha = await executeRecaptcha("feedback")
      const newFormData = new FormData();
      newFormData.append("firstName", data.firstName);
      newFormData.append("email", data.email);
      newFormData.append("login", data.login);
      newFormData.append("requestType", data.requestType[0].value);
      newFormData.append("comment", data.comment);
      newFormData.append("captchaResponse", captcha);
      if (files) {
        files.forEach((file) => {
          newFormData.append(`files[]`, file, file.name);
        });
      }
      const sendFeedbackResponce = await sendFeedback(newFormData)
      const issueId = sendFeedbackResponce?.data.results?.id.data;
      if (issueId) {
        setIssueId(issueId);
        openModal("feedbackSuccess");
      }
    };
  };

  useEffect(() => {
    getFeedbackCategories().then((response) => {
      if (response) {
        setCategories(
          response.results.categories.data.map((category: ICategory) => ({
            value: category.requestTypeName,
            label: category.label,
          }))
        );
      }
    });
    isAuthorized &&
      getFullProfile().then(
        (response) =>
          response &&
          setUserInfo({
            firstName: response.results.user.data.firstName,
            login: response.results.user.data.login,
            email: response.results.user.data.email,
          })
      );
  }, [isAuthorized]);
  
  return (
    <>
      <div className={layoutStyles.promo}>
        <SideBanner />
        <div className={layoutStyles.promo__content}>
          <a
            href={`${process.env.PUBLIC_URL}/assets/files/FAQ онлайн-игра Tycoon.pdf`}
            className={styles.feedbackForm__faq}
            download
          >
            Часто задаваемые вопросы
          </a>
          <img src={mail} alt="" />
          <h1>
            Нужна помощь?
          </h1>
          <h2 className={styles["bold-text"]}>
            Эксперты телеком-индустрии ответят на ваши вопросы! Полную историю обращений и ответы по ним вы найдете в разделе 
            "История" - "Обращения в поддержку".
          </h2>
          <FeedbackForm 
            firstNameInitial={userInfo?.firstName || ""}
            loginInitial={userInfo?.login || ""}
            emailInitial={userInfo?.email || ""}
            selectCategories={categories}
            isAuthorized={isAuthorized}
            onSubmit={handleFeedback}
            canAttachFiles
          />
        </div>
      </div>
      <Modal />
    </>
  );
};

export default observer(Feedback);
