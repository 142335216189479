import classNames from "classnames";
import React, { useContext } from "react";
import { IBooster } from "../../../interfaces/common";
import { boosters } from "../../../constants/market";

import styles from "./Booster.module.scss";
import Button from "../Button";
import { TutorialStoreContext } from "../../../store/Tutorial";
import SplittedNumber from "../../ui/SplittedNumber/SplittedNumber";
import CountBadge from "../CountBadge/CountBadge";

type boosterProps = {
  booster?: IBooster;
  code?: string;
  withSize?: boolean;
  count?: number;
  buttonText?: string;
  onClick?: () => void;
  buttonIcon?: string;
  label?: string;
  cityModal?: boolean;
};

const Booster: React.FC<boosterProps> = ({
  buttonText,
  withSize,
  code,
  count,
  onClick,
  buttonIcon,
  cityModal,
  booster = {
    type: code  === "repairCrash" ? "repairCrash" : code?.replace(/([A-Z]+)*([A-Z][a-z])/g, "$1 $2").split(" ")[0],
    size: code  === "repairCrash" ? "high" : code?.replace(/([A-Z]+)*([A-Z][a-z])/g, "$1 $2").split(" ")[1].toLowerCase(),
  } as IBooster,
  label
}) => {
  
  const { activeUIElement } = useContext(TutorialStoreContext);

  const renderBoosterSize = (size: IBooster["size"]) => {
    switch (size) {
      case "low":
        return <>Малый бустер</>;
      case "middle":
        return <>Средний бустер</>;
      case "high":
        return <>Большой бустер</>;
    }
  };

  const contentClass = classNames(styles.card__content, [
    styles[`card__content--${booster.size}`],
    {
      [styles[`card__content--absent`]]: count === 0,
    },
    {
      [styles[`card__content--withSize`]]: withSize,
    },
  ]);

  const isTutorialObject = activeUIElement === 'buyAdvBooster'
    && booster.size === 'middle'
    && booster.type === 'loyalty';

  const boosterCardClassName = classNames(styles.card, { "highlighted with-arrow right": isTutorialObject });

  return (
    <div className={boosterCardClassName} onClick={onClick}>
      <div className={contentClass}>
        {!!count && <CountBadge isPadded={cityModal} value={count} />}
        <img
          src={boosters[booster.type]?.[booster.size]?.image}
          alt={"booster"}
        />
        {withSize && (
          <p>
            {label || renderBoosterSize(booster.size)}
          </p>
        )}
      </div>
      {buttonText && (
        <Button>
          {buttonIcon && <img src={buttonIcon} alt="cur"/>}
          <SplittedNumber value={buttonText} className={styles["card__price-text"]} isAdaptive/>
        </Button>
      )}
    </div>
  );
};

export default Booster;