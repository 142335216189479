import dayjs from "dayjs";
import { IUpLevelTimer } from "../../interfaces/GameState";
import duration from "dayjs/plugin/duration";

import { Graphics as PIXIGraphics } from 'pixi.js';
import { degreesToRadians } from "../angles";


dayjs.extend(duration);

export const getUpLevelProgress = (
    timer: IUpLevelTimer
  ): { progress: number; time: string } => {
    const fullTime = +new Date(timer.endedAt) - +new Date(timer.startedAt);
    const left = +new Date() - +new Date(timer.startedAt);

    return {
      progress: left / (fullTime / 100),
      time: dayjs.duration(fullTime - left).format("HH:mm:ss"),
    };
  };


export function getFullDurationAndElapsedSeconds(
  startedAt: string, 
  endedAt: string,
  currentTime: string
) : {fullDurationMs: number, elapsedMs: number} {
  const startTimeMs = +new Date(startedAt)
  const endTimeMs = +new Date(endedAt)
  const currentTimeMs = +new Date(currentTime)
  const fullDurationMs = endTimeMs - startTimeMs  // общее время в миллисекундах
  const elapsedMs = currentTimeMs - startTimeMs
  return {fullDurationMs, elapsedMs}
}

export const drawTimerCircle = (g: PIXIGraphics, image: string | null, isTimerRunning: boolean, elapsedMs: number, fullDurationMs: number) => {
  if (!image) {
    g.clear()
  }
  if (isTimerRunning) {
    g.clear()
    g.lineStyle(10, 0xFFFFFF, 1);
    g.arc(200, 200, 30, degreesToRadians(-90), degreesToRadians(360 * (elapsedMs / fullDurationMs) - 90))
    g.endFill();
  }
}