import { action, makeObservable, observable } from "mobx"
import { createContext } from "react"
import {timerSubscriber} from '../types/timer'

export class Timer {
  
  timers1fps: timerSubscriber[] = []
  timers30fps: timerSubscriber[] = []

  constructor() {
    makeObservable(this, {
      timers1fps: observable,
      timers30fps: observable,
      subscribe1fps: action.bound,
      subscribe30fps: action.bound,
      unsubscribe1fps: action.bound,
      unsubscribe30fps: action.bound,
    })
    setInterval(() => {
      this.timers1fps.forEach(sub => sub.fn(1000))
    }, 1000)

    setInterval(() => {
      this.timers30fps.forEach(sub => sub.fn(34))
    }, 34)
  }

  subscribe1fps(sub: timerSubscriber) {
    this.timers1fps.push(sub)
  }

  subscribe30fps(sub: timerSubscriber) {
    this.timers30fps.push(sub)
  }

  unsubscribe1fps(sub: timerSubscriber) {
    this.timers1fps = this.timers1fps.filter(item => item.id !== sub.id)
  }

  unsubscribe30fps(sub: timerSubscriber) {
    this.timers30fps = this.timers30fps.filter(item => item.id !== sub.id)
  }
}

const timer = new Timer()

export const TimerContext = createContext(timer)