import React, { useContext } from 'react';

import { TutorialStoreContext } from '../../../store/Tutorial';
import Button from '../Button';

interface IProps {
  isShowNextButton: boolean;
}

const TutorialText: React.FC<IProps> = ({ isShowNextButton }) => {
  const { tutorialText, nextStep, step } = useContext(TutorialStoreContext);

  return (
    <>
      <span>{tutorialText}</span>
      {isShowNextButton && (
        <Button
          noDelay
          orange
          onClick={() => nextStep()}
          text={step === 0 ? "Начать обучение" : "Понятно, вперед!"}
        />
      )}
    </>
  );
};

export default TutorialText;
