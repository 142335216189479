import React from "react";
import Button from "../../Button";

import styles from "./Auth.module.scss";

const RTSignUpActionSteps: React.FC = () => {

  const onRegistryClickHandler = () => {
    if (window.dataLayer) {
      window.dataLayer.push({ 'event': 'b2с', 'type': 'registration_click', 'value': 'Зарегистрироваться' })
    }
  }
  return (
    <div className={styles.RTSignUpActionSteps}>
      <h4>Что делать?</h4>
      <p>
        Всего три действия отделяют вас от участия в игре и возможности получить
        ценные призы от Ростелекома и партнеров!
      </p>
      <ol>
        <li>
          Зарегистрируйтесь в <b>Личном кабинете</b>
        </li>
        <li>
          Привяжите <b>Лицевой счет</b>
        </li>
        <li>
          Станьте участником программы лояльности <b>«Бонус»</b>
        </li>
      </ol>
      <p>Поторопитесь принять участие в игре, количество призов ограничено!</p>

      <a
        target="_blank"
        rel="noreferrer"
        href="https://b2c.passport.rt.ru/auth/realms/b2c/protocol/openid-connect/registrations?response_type=code&scope=openid&client_id=lk_b2c&redirect_uri=https://igra.rt.ru/"
      >
        <Button
          onClick={onRegistryClickHandler}
          text="Зарегистрироваться"
          orange
          type="button"
          bordered
          noDelay
        />
      </a>
    </div>
  );
};

export default RTSignUpActionSteps;
