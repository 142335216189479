enum ROUTES {
  APP = "/",
  ABOUT = "/about",
  LEADERBOARD = "/leaderboard",
  PRIZES = "/prizes",
  HISTORY = "/history",
  FEEDBACK = "/feedback",
  AUTHORIZATION = "/sign-in"
}

export default ROUTES;
