import { ITimer } from "../../interfaces/GameState";
import { markerIconsType } from "../../types/markers";
import { communicationLineStatuses } from "../../types/statuses";

export function getTimer(
  status: communicationLineStatuses, 
  timer: ITimer | undefined, 
  maintenanceTimer: ITimer | undefined
) {
  if (timer && (status === 'CRASH_RESOLVING' || status === 'UPPING_LEVEL')) {
    return timer
  }
  else if (maintenanceTimer) {
    return maintenanceTimer
  }
  return undefined
}

export function getImage(
  status: communicationLineStatuses, 
  markerIcons: markerIconsType,
  maintenanceTimer: ITimer | undefined,
  maintenanceIcon: string,
  isCityMarker?: boolean
) : string | null {
  if (maintenanceTimer && (status !== 'CRASH_RESOLVING' && status !== 'UPPING_LEVEL')) {
    return maintenanceIcon
  }
  if (isCityMarker && status === 'CAN_BUILD') {
    return null
  }
  return markerIcons[status]
}
