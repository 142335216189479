import React from "react";
import { accountWarningText } from "../../../../constants/accountWarning";
import { IAccountWarning } from "../../../../interfaces/AccountWarningModal";

import ModalBlock from "../../ModalBlock";

import modalStyles from "../SimpleModal.module.scss";
import styles from "./AccountWarning.module.scss";

const AccountWarning: React.FC<IAccountWarning> = ({
  type
}) => {
  return (
    <div className={styles.warning}>
      <div className={modalStyles.modal__body}>
        <ModalBlock kind="common">
          <div>
            <h4>Выберите лицевой счет</h4>
            <p>
              {"Вам необходимо выбрать лицевой счёт, " + accountWarningText[type]}
            </p>
          </div>
        </ModalBlock>
      </div>
    </div>
  );
};

export default AccountWarning;
