import { FC, useContext } from "react"
import { AppStoreContext } from "../../../store/App"
import AnimatedImage from "./AnimatedImage"
import ProgressBar from "./ProgressBar"
import styles from "./style.module.scss"
import { observer } from "mobx-react-lite"
import cn from "classnames"

const GamePreloader: FC<{}> = () => {
  const { currentTilesLoadingPercent } = useContext(AppStoreContext)

  const loadingPercents = Math.trunc(currentTilesLoadingPercent);

  return (
    <div className={cn(styles["preloader"], "full-screen-absolute-container")}>
      <div className={styles["preloader__content"]}>
        <span className={styles["preloader__percent-text"]}>
          {`${Math.min(loadingPercents, 100)}%`}
        </span>
        <AnimatedImage />
        <ProgressBar
          percent={Math.min(currentTilesLoadingPercent, 100)}
          className={styles["preloader__progress-bar"]}
        />
      </div>
    </div>
  )
}

export default observer(GamePreloader)
