import { createContext } from "react";
import { makeObservable, observable, action } from "mobx";
import { IInfoModal } from "../interfaces/common";

export class InfoModal {
  info?: IInfoModal;

  constructor() {
    makeObservable(this, {
      info: observable,
      setInfo: action.bound,
    });
  }

  setInfo(info: IInfoModal) {
    this.info = info;
  }
}

export const InfoModalContext = createContext(new InfoModal());
