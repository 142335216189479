import { IAwardCard } from "../interfaces/common";

import awardIcon1 from "../images/tender/award1.png"
import awardIcon2 from "../images/tender/award2.png"
import awardIcon3 from "../images/tender/award3.png"
import awardIcon4 from "../images/tender/award4.png"
import awardIcon5 from "../images/tender/award5.png"
import awardIcon6 from "../images/tender/award6.png"

export const tenderAwards: IAwardCard[] = [
    {
        type: "another",
        icon: awardIcon1
    },
    {
        type: "another",
        icon: awardIcon2
    },
    {
        type: "another",
        icon: awardIcon3
    },
    {
        type: "another",
        icon: awardIcon4
    },
    {
        type: "another",
        icon: awardIcon5
    },
    {
        type: "another",
        icon: awardIcon6
    }
]