import React, { useContext } from "react";
import { confirmModalStoreContext } from "../../../../store/ConfirmModal";
import Button from "../../Button";

import ModalBlock from "../../ModalBlock";

import modalStyles from "../SimpleModal.module.scss";
import styles from "./Confirm.module.scss";

type confirmProps = {
  closeModal: () => void;
};

const Confirm: React.FC<confirmProps> = ({ closeModal }) => {
  const { confirmCallback, confirmHeader, confirmText, confirmButtonText } = useContext(
    confirmModalStoreContext
  );

  const ok = () => {
    if (confirmCallback) {
      confirmCallback();
    }
    closeModal();
  };

  return (
    <div className={styles.confirm}>
      <div className={modalStyles.modal__body}>
        <ModalBlock kind="common">
          <div>
            <h4>{confirmHeader}</h4>
            {confirmText && <p>{confirmText}</p>}
            <Button text={confirmButtonText || "Ок"} onClick={ok} />
          </div>
        </ModalBlock>
      </div>
    </div>
  );
};

export default Confirm;
