import React, { useContext } from "react";
import { observer } from "mobx-react-lite";

import ModalBlock from "../../ModalBlock";
import TechnologyCard from "./TechnologyCard";
import { GameStateStoreContext } from "../../../../store/GameState";
import { technologyStates } from "../../../../constants/technology";
import { ITechnology } from "../../../../interfaces/GameState";
import { discoverTechnology, getGameState } from "../../../../services/scene";
import ModalContextStore from "../../../../store/Modal";
import { ToastStoreContext } from "../../../../store/Toast";

import styles from "./Technologies.module.scss";
import modalStyles from "../SimpleModal.module.scss";
import useScroller from "../../../hooks/useScroller";
import ScrollArrow from "../../../ui/ScrollArrow/ScrollArrow";

const Technologies: React.FC = () => {
  const { technologiesByState, setGameState, isTutorial, gold } = useContext(GameStateStoreContext);
  const { openModal } = useContext(ModalContextStore);

  const { pushToast } = useContext(ToastStoreContext);

  const { ScrollContainer, isShowArrow } = useScroller();

  const onDiscoverTechnology = (technology: ITechnology) => {
    if (technology.state === "AVAILABLE") {
      if (gold >= technology.price.GOLD) {
        discoverTechnology(technology.code)
          .then((response) => {
            if (!response) throw new Error();
            getGameState().then(
              (response) =>
                response && setGameState(response.results.scene.data)
            );
            pushToast("success", "Технология внедрена");
          })
          .catch((err) => {
            pushToast("error", "Что-то произошло");
          });
      } else {
        openModal("notEnoughGold");
      }
    } else if (technology.state === "NEED_UP_LEVEL_DEPARTMENT") {
      openModal("staff/builders");
    }
  };

  return (
    <div>
      <div className={modalStyles.modal__body}>
        <ScrollContainer>
          <div className={styles.technologies}>
            {Object.keys(technologyStates).map((state, index) => {
              if (state !== "NOT_AVAILABLE") {
                const technologyСhapter =
                  technologiesByState?.[state as ITechnology["state"]];

                if (technologyСhapter && technologyСhapter.length) {
                  return (
                    <ModalBlock key={index} kind="common">
                      <div>
                        <h4 className={styles["technologies__list-head"]}>
                          {technologyStates[state as ITechnology["state"]]}
                        </h4>
                        <div className={styles.technologies__list}>
                          {technologyСhapter.map((technology, i) => {
                            return (
                              <TechnologyCard
                                key={i}
                                onButtonClick={onDiscoverTechnology}
                                technology={technology}
                                type={state as ITechnology["state"]}
                                isTutorial={
                                  isTutorial && index === 0 && i === 0
                                }
                              />
                            );
                          })}
                        </div>
                      </div>
                    </ModalBlock>
                  );
                } else {
                  return null;
                }
              }
              return "";
            })}
          </div>
        </ScrollContainer>
      </div>
      {!isTutorial && isShowArrow && <ScrollArrow />}
    </div>
  );
};

export default observer(Technologies);
