import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { UseBoosterStoreContext } from "../../../../store/UseBooster";

import { GameStateStoreContext } from "../../../../store/GameState";
import {
  getGameState,
  skipCrashResolve,
  skipUpLevel,
  upBuildingLevel,
} from "../../../../services/scene";
import { ToastStoreContext } from "../../../../store/Toast";
import { IQuest } from "../../../../interfaces/common";
import { getQuestInfo } from "../../../../services/quest";

import RegularTimer from "../../RegularTimer/RegularTimer";
import ModalContextStore from "../../../../store/Modal";
import ModalBlock from "../../ModalBlock";
import Button from "../../Button";
import GradientProgressBar from "../../GradientProgressBar";
import Crash from "../../Crash/Crash";

import uppingLevelIcon from "../../../../images/timers/uppingLevel.png";
import maintenanceIcon from "../../../../images/timers/maintenance.png";
import crashResolvingIcon from "../../../../images/timers/crashResolving.png";
import infoBadgeIcon from "../../../../images/hud/infoBadge-icon.png";

import coinImage from "../../../../images/store/currencies/gold-icon.png";
import crystalIcon from "../../../../images/svg/diamond.svg";

import styles from "./ConnectionNode.module.scss";
import { InfoModalContext } from "../../../../store/InfoModal";
import { TutorialStoreContext } from "../../../../store/Tutorial";
import classNames from "classnames";
import SplittedNumber from "../../../ui/SplittedNumber/SplittedNumber";
import useScroller from "../../../hooks/useScroller";
import ScrollArrow from "../../../ui/ScrollArrow/ScrollArrow";

interface ILine {
  lineID: string;
}

const ConnectionNode: React.FC<ILine> = ({ lineID }) => {
  const { getLine, network, setGameState, gold, crystal, isTutorial } = useContext(
    GameStateStoreContext
  );
  const { openModal, closeModals, closeModal } = useContext(ModalContextStore);

  const { pushToast } = useContext(ToastStoreContext);
  const { setConstruction, setAfterUseBooster } = useContext(UseBoosterStoreContext);
  const { activeUIElement } = useContext(TutorialStoreContext);

  const [crash, setCrash] = useState<IQuest>();

  const [lineInfo, setLineInfo] = useState(getLine(lineID));
  const isLineCrash = lineInfo?.state === "CRASH" && crash;
  const isLineOpen = (lineInfo?.currentLevelId || 0) > 0;
  const { setInfo } = useContext(InfoModalContext);

  const { ScrollContainer, isShowArrow } = useScroller()

  const loadInfo = {
    header: "Нагрузка на линию связи",
    description:
      "Чем больше абонентов подключены к сетям вашей телеком-компании, тем выше нагрузка на узлы и линии связи. Перегрузки приводят к снижению качества предоставляемых услуг и, как следствие, к снижению лояльности абонентов и ваших доходов. Чтобы предотвратить негативные последствия для вашего бизнеса, своевременно модернизируйте инфраструктурные объекты на игровом поле.",
  };

  useEffect(() => {
    if (lineInfo && lineInfo.state === "CRASH" && lineInfo.questId) {
      getQuestInfo(lineInfo.questId).then((response) => {
        setCrash(response.results.quests.data);
      });
    }
  }, [lineInfo]);

  useEffect(() => {
    setLineInfo(getLine(lineID));
  }, [getLine, lineID, network]);

  const skipCrashTimer = () => {
    lineInfo && skipCrashResolve(lineInfo?.constructionIndex).then(() => {
      updateGameState();
      closeModal()
    });
  };

  const skipUppingLevelTimer = () => {
    lineInfo && skipUpLevel(lineInfo?.constructionIndex).then(() => {
      updateGameState();
      closeModal()
    });
  };

  const getMaintenance = () => {
    if (lineInfo) {
      setConstruction(lineInfo?.constructionIndex);
      setAfterUseBooster(updateGameState);
      openModal("maintenance");
    }
  };

  const updateGameState = () => {
    getGameState().then(
      (response) => response && setGameState(response.results.scene.data)
    );
  };

  const openInfo = () => {
    setInfo(loadInfo);
    openModal("info");
  };

  const handleLvlUp = (type: "GOLD" | "CRYSTAL" = "GOLD") => {
    const currency = type === "GOLD" ? gold : crystal;
    if (
      lineInfo?.nextLevel?.priceUpLevel[type] &&
      currency >= lineInfo?.nextLevel?.priceUpLevel[type]
    ) {
      upBuildingLevel(lineID, type)
        .then((response) => {
          if (!response) throw new Error();
          updateGameState();
          pushToast(
            "success",
            `Линия связи будет ${isLineOpen ? "модернизирована" : "построена"} `
          );
          if (isTutorial) {closeModals()}
        })
        .catch((err) => {
          pushToast("error", `Что-то произошло`);
        })

    } else {
      if (type === "GOLD") {
        openModal("notEnoughGold");
      }
      else {
        openModal("notEnoughCrystals");
      }
    }
  };

  const buildNodeClassName = classNames({ "highlighted with-arrow bottom": activeUIElement === 'buildLine' });
  const isBothUpAvailable = lineInfo?.nextLevel?.priceUpLevel.GOLD && lineInfo.nextLevel.priceUpLevel.CRYSTAL;

  return (
    <div className={classNames(styles.body)}>
      {!isLineOpen ? (
      <>
        {lineInfo?.timer?.timerType !== "UPPING_LEVEL" ? (
          <div className={styles.container}>
            <ModalBlock kind={"common"}>
              <div className={classNames(styles.lineButtons)}>
                <h5 className={styles.headerText}>Построить линию связи</h5>
                <div className={buildNodeClassName}>
                  <Button
                    id="buildLine"
                    onClick={handleLvlUp}
                  >
                    <img src={coinImage} alt="coin" />
                    <SplittedNumber value={lineInfo?.nextLevel?.priceUpLevel.GOLD || 0} isAdaptive/>
                  </Button>
                </div>
              </div>
            </ModalBlock>
          </div>
        ) : (
          <div className={styles.container}>
            <ModalBlock kind={"common"}>
              <div className={classNames(styles.timerProcess)}>
                <div>
                  <h5>Линия строится!</h5>
                  <p>Будет готово через:</p>
                </div>
                <RegularTimer
                  isBlue
                  onSkip={skipUppingLevelTimer}
                  timer={lineInfo.timer}
                />
              </div>
            </ModalBlock>
          </div>
        )}
      </>
    ) : (
      <>
        <ScrollContainer>
          <div className={classNames(styles.container)}>
            <ModalBlock kind="common">
              <div className={styles.container__load}>
                <div>
                  <h5 className={styles.headerText}>Нагрузка на линию связи </h5>
                  <img onClick={openInfo} src={infoBadgeIcon} alt="" />
                </div>
                <div className={styles.load}>
                  <GradientProgressBar progress={lineInfo?.load || 0} />
                </div>
              </div>
            </ModalBlock>

            {lineInfo?.maintenanceTimer && (
              <ModalBlock kind="common">
                <div className={styles.timerProcess}>
                  <div>
                    <h5>
                      ТО проведено <img src={maintenanceIcon} alt="" />{" "}
                    </h5>
                    <p>Гарантийный период:</p>
                  </div>
                  <RegularTimer isBlue timer={lineInfo.maintenanceTimer} />
                </div>
              </ModalBlock>
            )}

            {!isLineCrash ? (
              <>
                {lineInfo && lineInfo.state === "UPPING_LEVEL" && lineInfo.timer && (
                  <ModalBlock kind={"common"}>
                    <div className={styles.timerProcess}>
                      <div>
                        <h5>
                          {lineInfo.nextLevel?.levelId} уровень{" "}
                          <img src={uppingLevelIcon} alt="" />
                        </h5>
                        <p>Будет готово через:</p>
                      </div>
                      <RegularTimer
                        isBlue
                        onSkip={skipUppingLevelTimer}
                        timer={lineInfo.timer}
                      />
                    </div>
                  </ModalBlock>
                )}

                {lineInfo?.state === "NORMAL" && !lineInfo.maintenanceTimer && (
                  <ModalBlock kind={"common"}>
                    <div className={styles.lineButtons}>
                      <h5 className={styles.headerText}>
                        Техобслуживание линии связи
                      </h5>
                      <Button text={"Провести ТО"} onClick={getMaintenance} />
                    </div>
                  </ModalBlock>
                )}

                {lineInfo?.state === "NORMAL" && lineInfo?.nextLevel && (
                  <ModalBlock kind={"common"}>
                    <div className={classNames(styles.lineButtons, { "wrap": isBothUpAvailable })}>
                      <h5 className={styles.headerText}>
                        Модернизировать до {lineInfo?.nextLevel.levelId}{" "}
                        уровня
                      </h5>
                      <div
                        className={classNames(
                          styles.lineButtons__container,
                          { [styles['lineButtons__container--full']]: isBothUpAvailable }
                        )}
                      >
                        {lineInfo?.nextLevel.priceUpLevel.GOLD && (
                          <Button
                            onClick={handleLvlUp}
                          >
                            <img src={coinImage} alt="coin" />
                            <SplittedNumber value={lineInfo?.nextLevel.priceUpLevel.GOLD} isAdaptive={!isBothUpAvailable} />
                          </Button>
                        )}
                        {lineInfo?.nextLevel.priceUpLevel.CRYSTAL && (
                          <Button
                            onClick={() => handleLvlUp("CRYSTAL")}
                          >
                            <img className={styles['crystal-price']} src={crystalIcon} alt="crystal" />
                            <SplittedNumber value={lineInfo?.nextLevel.priceUpLevel.CRYSTAL} isAdaptive={!isBothUpAvailable} />
                          </Button>
                        )}
                      </div>
                    </div>
                  </ModalBlock>
                )}

                {lineInfo?.state === "CRASH_RESOLVING" && lineInfo?.timer && (
                  <ModalBlock kind={"common"}>
                    <div className={styles.timerProcess}>
                      <div>
                        <h5>
                          Устраняем аварию <img src={crashResolvingIcon} alt="" />
                        </h5>
                        <p>Будет готово через:</p>
                      </div>
                      <RegularTimer
                        isBlue
                        onSkip={skipCrashTimer}
                        timer={lineInfo.timer}
                      />
                    </div>
                  </ModalBlock>
                )}
              </>
            ) : (
              <Crash
                filling={lineInfo.load || 0}
                onFix={() => {
                  updateGameState();
                }}
                action={crash}
              ></Crash>
            )}
          </div>
        </ScrollContainer>
      </>
    )}
    {!isTutorial && isShowArrow && <ScrollArrow />}
  </div>
)};

export default observer(ConnectionNode);
