import { createContext } from "react";
import { makeObservable, observable, action } from "mobx";

export class Feedback {
  issueId?: number;

  constructor() {
    makeObservable(this, {
      issueId: observable,
      setIssueId: action.bound,
    });
  }

  setIssueId(id: number) {
    this.issueId = id;
  }
}

export const FeedbackStoreContext = createContext(new Feedback());
