import { createContext } from "react";
import { action, makeObservable, observable } from "mobx";
import { nextTutorialStep } from "../services/scene";

interface ITextPos {
  mobile: { x: string, y: string },
  desktop: { x: string, y: string },
}

interface ITutorialScenario {
  isCityActive?: boolean;
  isLineActive?: boolean;
  activeUIElement?: string;
  openModal?: string;
  tutorialText: string;
  textPosition: ITextPos;
}

export const scenario: ITutorialScenario[] = [
  {
    tutorialText: 'Добро пожаловать в игру «Tycoon»!\n\n' +
    'Вы стали руководителем \nнебольшой телеком-компании.\n\n' +
    'Развивайте ваш бизнес, \nпривлекайте клиентов \nи выигрывайте призы! Их в игре более 250 000!',
    textPosition: {mobile: {x: '50%', y: '50%'}, desktop: {x: '50%', y: '50%'}},
  },
  {
    tutorialText: 'Ваша задача — заработать как \nможно больше очков \nрепутации (сердец),' +
    ' число \nкоторых зависит от количества \nи лояльности абонентов.\n\n50 игроков,' +
    ' набравших \nмаксимальное количество \nсердец, получат самые ценные \nпризы. Приступим!',
    activeUIElement: 'loyaltyBadge',
    textPosition: {mobile: {x: '50%', y: '50%'}, desktop: {x: '50%', y: '50%'}},
  },
  {
    isCityActive: true,
    tutorialText: 'Расширьте свою сеть, построив \nузел связи в новом городе',
    textPosition: {mobile: {x: '50%', y: '50%'}, desktop: {x: '50%', y: '50%'}},
  },
  {
    activeUIElement: 'buildNode',
    tutorialText: 'Запустите строительство',
    openModal: 'city?CITY_3',
    textPosition: {mobile: {x: '50%', y: '90%'}, desktop: {x: '50%', y: '75%'}},
  },
  {
    activeUIElement: 'forceBuildNode',
    tutorialText: 'Есть возможность ускорить \nстроительство. Нажмите \nна таймер',
    openModal: 'city?CITY_3',
    textPosition: {mobile: {x: '50%', y: '85%'}, desktop: {x: '50%', y: '80%'}},
  },
  {
    isLineActive: true,
    tutorialText: 'Отлично! Подключив новый \nузел к сети, вы начнете \nполучать прибыль! Приступим...',
    textPosition: {mobile: {x: '50%', y: '40%'}, desktop: {x: '55%', y: '50%'}},
  },
  {
    activeUIElement: 'buildLine',
    tutorialText: 'Запустите строительство',
    openModal: 'line?LINE_2',
    textPosition: {mobile: {x: '50%', y: '70%'}, desktop: {x: '50%', y: '75%'}},
  },
  {
    activeUIElement: 'joinEmploee',
    tutorialText: 'Пришло время нанять \nдополнительный персонал',
    textPosition: {mobile: {x: '50%', y: '65%'}, desktop: {x: '70%', y: '80%'}},
  },
  {
    activeUIElement: 'buyEmploeeTrain',
    tutorialText: 'Новый менеджер отдела \nклиентского сервиса увеличит \nприрост абонентов в ваших \nгородах',
    openModal: 'staff',
    textPosition: {mobile: {x: '50%', y: '25%'}, desktop: {x: '50%', y: '70%'}},
  },
  {
    activeUIElement: 'addNewTech',
    tutorialText: 'Пришло время внедрить новую \nтехнологию',
    textPosition: {mobile: {x: '50%', y: '65%'}, desktop: {x: '70%', y: '80%'}},
  },
  {
    activeUIElement: 'buyNewTech',
    tutorialText: 'Новые технологии помогут вам \nполучать больше прибыли',
    openModal: 'technologies',
    textPosition: {mobile: {x: '50%', y: '20%'}, desktop: {x: '50%', y: '70%'}},
  },
  {
    activeUIElement: 'addCoins',
    tutorialText: 'Пришло время посетить \nмагазин',
    textPosition: {mobile: {x: '50%', y: '30%'}, desktop: {x: '85%', y: '240px'}},
  },
  {
    activeUIElement: 'buyCoins',
    tutorialText: 'Дополнительные вложения \nпомогут вам быстрее поставить \nкомпанию на ноги',
    openModal: 'store/gold',
    textPosition: {mobile: {x: '50%', y: '75%'}, desktop: {x: '50%', y: '75%'}},
  },
  {
    activeUIElement: 'boosterAdvSelection',
    tutorialText: 'Также вам пригодится \nбустер «Дать рекламу»',
    openModal: 'store/gold',
    textPosition: {mobile: {x: '50%', y: '60%'}, desktop: {x: '50%', y: '50%'}},
  },
  {
    activeUIElement: 'buyAdvBooster',
    tutorialText: 'Купите бустер',
    openModal: 'store/booster',
    textPosition: {mobile: {x: '50%', y: '80%'}, desktop: {x: '50%', y: '26%'}},
  },
  {
    isCityActive: true,
    tutorialText: 'Давайте вернемся в город',
    textPosition: {mobile: {x: '50%', y: '50%'}, desktop: {x: '50%', y: '50%'}},
  },
  {
    activeUIElement: 'upLoyalty',
    tutorialText: 'Привлечем абонентов, \nиспользуя купленный бустер',
    openModal: 'city?CITY_3',
    textPosition: {mobile: {x: '50%', y: '40%'}, desktop: {x: '50%', y: '40%'}},
  },
  {
    activeUIElement: 'buyAdvBooster',
    tutorialText: 'Выберите бустер',
    openModal: 'loyalty',
    textPosition: {mobile: {x: '50%', y: '40%'}, desktop: {x: '50%', y: '40%'}},
  },
  {
    tutorialText: 'Поздравляем, вы прошли \nобучение! \n\nЗаберите ваш первый миллион \nмонет и' +
    ' начинайте \nстроительство телеком-\nимперии! \n\nОткрывайте новые \nтехнологические эры,' +
    ' стройте \nи модернизируйте объекты \nсвязи и радуйте абонентов \nвысоким качеством услуг.' +
    '\n\nЗагляните в раздел «Об игре», \nесли у вас остались вопросы.',
    textPosition: {mobile: {x: '50%', y: '50%'}, desktop: {x: '50%', y: '50%'}},
  },
]

export class Tutorial {
  
  public step = 0;

  public isCityActive = false;

  public isLineActive = false;
  public activeUIElement = '';

  public tutorialText = '';

  public openModalId: string | undefined = '';

  public textPos: ITextPos | undefined;

  constructor() {
    makeObservable(this, {
      isLineActive: observable,
      isCityActive: observable,
      activeUIElement: observable,
      openModalId: observable,
      tutorialText: observable,
      step: observable,
      textPos: observable,
      nextStep: action.bound,
      setStep: action.bound,
    });

    this.setCurrentState();
  }

  private setCurrentState(withAction = false) {
    if (scenario[this.step]) {
      this.isCityActive = scenario[this.step].isCityActive || false;
      this.isLineActive = scenario[this.step].isLineActive || false;
      this.activeUIElement = scenario[this.step].activeUIElement || '';
      this.tutorialText = scenario[this.step].tutorialText;
      this.openModalId = withAction ? scenario[this.step].openModal : '';
      this.textPos = scenario[this.step].textPosition;
    } else {
      this.isCityActive = false;
      this.isLineActive = false;
      this.activeUIElement = '';
      this.tutorialText = '';
      this.openModalId = '';
      this.textPos = undefined;
    } 
  }

  public async nextStep() {
    this.step = this.step + 1;
    nextTutorialStep(this.step);
    this.setCurrentState();
  }

  public setStep(step: number) {
    this.step = step;
    this.setCurrentState(true);
  }
}

export const TutorialStore = new Tutorial();

export const TutorialStoreContext = createContext(TutorialStore);
