import { applyDefaultProps, PixiComponent } from "@inlet/react-pixi"
import { Sprite, Application, Texture, Loader } from "pixi.js"
import { getTileUrl } from "../../../helpers/getUrl"

interface IFieldTileProps {
  application: Application
  name?: string
  x: number
  y: number
  addReadyTile: () => void
}

export default PixiComponent<IFieldTileProps, Sprite>("FieldTile", {
  create: (props) => {
    const loader = new Loader()
    props.name && loader.add(props.name, getTileUrl(props.name))
    const sprite = new Sprite()
    loader.load((loader, resources) => {
      if (props.name) sprite.texture = resources[props.name]?.texture as Texture
      props.addReadyTile()
    })
    return sprite
  },
  applyProps: (instance, oldProps, newProps) => {
    applyDefaultProps(instance, oldProps, newProps)
  },
})
