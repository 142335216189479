import React, { MutableRefObject, useContext, useRef } from "react";
import { observer } from "mobx-react-lite";

import { departmentVariableKeysType, IGameStateEmploye } from "../../../interfaces/GameState";
import Button from "../Button";
import RegularTimer from "../RegularTimer/RegularTimer";
import { employeeImages } from "../../../constants/staff";
import SplittedNumber from "../../ui/SplittedNumber/SplittedNumber";
import { getGameState, skipEmployeeUpLevel } from "../../../services/scene";
import { GameStateStoreContext } from "../../../store/GameState";

import coinIcon from "../../../images/svg/coin.svg";

import styles from "./StaffList.module.scss";
import classNames from "classnames";
import EmployeeView from "../EmployeeView/EmployeeView";

type employeeProps = {
  person: IGameStateEmploye;
  upLevel?: (person: IGameStateEmploye) => void;
  department: departmentVariableKeysType
};

const Employee: React.FC<employeeProps> = ({ person, upLevel, department}) => {
  const { setGameState } = useContext(GameStateStoreContext);
  const timerRef: MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement>(null);

  const personStyle = classNames(styles.staffList__person, {[styles["staffList__person--pointer"]]: person.nextLevel?.priceUpLevel});

  const skipUppingLevelTimer = () => {    
    skipEmployeeUpLevel(person.employeeId, department).then(()=>{
      getGameState().then(
        (response) => response && setGameState(response.results.scene.data)
      );
    });
  };

  const handleClick = () => {
    if (person.timer && person.timer.timerType === "UPPING_LEVEL") {
      timerRef.current?.click();
    } else if (upLevel && person.nextLevel?.priceUpLevel)
      upLevel(person);
  };

  return (
    <div className={personStyle}>
      <EmployeeView 
        name={person.employeeName}
        avatarUrl={employeeImages[person.imgType]}
        chevronVal={person.currentLevel.levelId}
        onClick={handleClick}
      />
      {(person.timer && person.timer.timerType === "UPPING_LEVEL" && 
          <div className={styles.staffList__person__timer}>
            <RegularTimer onSkip={skipUppingLevelTimer} withSmallText isBlue timer={person.timer} timerRef={timerRef}/>
          </div>
        ) || (upLevel && person.nextLevel?.priceUpLevel && 
                <Button onClick={handleClick}>
                  <img src={coinIcon} alt="coin" />
                  <SplittedNumber value={person?.nextLevel?.priceUpLevel?.GOLD || 0} format="thousands"/>
                </Button>
          ) || <p>обучен</p>
      }
    </div>
  );
};

export default observer(Employee);
