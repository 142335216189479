import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import { getProducts } from "../../../../services/store";
import { GameStateStoreContext } from "../../../../store/GameState";
import { MarketStoreContext } from "../../../../store/Market";
import ModalContextStore from "../../../../store/Modal";
import useScroller from "../../../hooks/useScroller";
import ScrollArrow from "../../../ui/ScrollArrow/ScrollArrow";
import ModalBlock from "../../ModalBlock";
import StoreCurrency from "../Market/Market.currency";

import modalStyles from "../SimpleModal.module.scss";

const NotEnoughGold: React.FC = () => {
  const { closeModal } = useContext(ModalContextStore);
  const { gold, crystal } = useContext(GameStateStoreContext);
  const { setMarketGoldKits, goldKits } = useContext(MarketStoreContext);
  const { ScrollContainer, isShowArrow } = useScroller();

  useEffect(() => {
    getProducts().then((response) => {
      const products = response.results.products.data;
      setMarketGoldKits(products.gold_pack);
    });
  }, [setMarketGoldKits]);

  return (
    <div>
      <div className={modalStyles.modal__body}>
        <ScrollContainer>
          <ModalBlock kind="common">
            <StoreCurrency
              balance={{ gold, crystal }}
              onBuy={closeModal}
              currency="gold"
              currencyPacks={goldKits}
            />
          </ModalBlock>
          {isShowArrow && <ScrollArrow />}
        </ScrollContainer>
      </div>
    </div>
  );
};

export default observer(NotEnoughGold);
