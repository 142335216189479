import { FC, useContext, useEffect, useState } from 'react'
import { IAssistantBlock } from '../../../../../interfaces/common'
import styles from './OfflineAssistants.module.scss'
import classNames from 'classnames'
import Assistant from './Assistant/Assistant'
import { confirmModalStoreContext } from '../../../../../store/ConfirmModal'
import { GameStateStoreContext } from '../../../../../store/GameState'
import {observer} from 'mobx-react-lite'
import { IGameStateAssistant } from '../../../../../interfaces/GameState'
import { activateAssistant } from '../../../../../services/assistants'
import ModalContextStore from '../../../../../store/Modal'
import { getGameState } from '../../../../../services/scene'

interface IOfflineAssistantsProps {
  assistantsInfoBlocks: IAssistantBlock[]
  className?: string
}

const OfflineAssistants: FC<IOfflineAssistantsProps> = ({assistantsInfoBlocks: contentData, className}) => {
  const { setConfirm } = useContext(confirmModalStoreContext)
  const { crystal, network, setGameState } = useContext(GameStateStoreContext)
  const { openModal } = useContext(ModalContextStore)

  const [assistantsData, setAssistantsData] = useState<IGameStateAssistant[] | null>(null)

  useEffect(() => {
    if (network) {
      const assistantsArr = Object.values(network.assistantEmployees).sort((a: IGameStateAssistant, b: IGameStateAssistant) => {
        if (a.assistantType === "FINANCE") {
          return -1
        }
        return 1
      })
      setAssistantsData(assistantsArr)
    }
  }, [network])

  const updateGameState = () => {
    getGameState().then(
      (response) => response && setGameState(response.results.scene.data)
    );
  };

  const onActivateAssistantBuy = (productCode: string, price: number) => {
    if (crystal >= price) {
      setConfirm(() => {
        activateAssistant(productCode).then(() => updateGameState())
      }, {confirmHeader: "Вы уверены?", confirmButtonText: "Да"})
      openModal("confirm")
    }
    else {
      openModal("notEnoughCrystals")
    }
  }

  return (
    <div className={classNames(styles["offline-assistants"], className)}>
      {contentData.map((staticData, i) => 
        <Assistant 
          key={i}  
          {...staticData}
          assistantType={assistantsData?.[i].assistantType || "FINANCE"}
          remainingSeconds={assistantsData?.[i].remainingSeconds || null}
          onActivateAssistantBuy={onActivateAssistantBuy} 
          timer={assistantsData?.[i].timer || null} 
          hirePrice={assistantsData?.[i].price.CRYSTAL || 0}
          className={styles["offline-assistants__assistant"]}
        />
      )}
    </div>
  )
}

export default observer(OfflineAssistants)