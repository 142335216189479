import { FC, useEffect, useState } from "react";
import { v4 as uuid } from 'uuid';

import layoutStyles from '../../layouts/Promo/Promo.module.scss';
import styles from './Prizes.module.scss';
import gift from "../../images/promo/gift.svg";
import PrizeDropdown from "./PrizeDropdown";
import { getPrizes } from "../../services/prize";
import { IPrize } from "../../interfaces/Prize";
import SideBanner from "../../components/common/promo/SideBanner/SideBanner";

const Prizes: FC = () => {
  const [prizes, setPrizes] = useState<IPrize[]>([]);

  useEffect(() => {
    getPrizes().then(response => setPrizes(response.results?.prizeList.data || []));
  }, []);

  const handleActivatePrizes = () => {
    getPrizes().then(response => setPrizes(response.results?.prizeList.data || []));
  }
  
  return (
    <div className={layoutStyles.promo}>
      <SideBanner />
      <div className={layoutStyles.promo__content}>
        <img src={gift} alt="" />
        <h1>
          Мои призы
        </h1>
        <p>Здесь отображаются все выигранные вами призы за всё время игры.</p>
        <div className={styles.prizes__list}>
          {prizes?.map((prize) => <PrizeDropdown {...prize} key={uuid()} onActivatePrize={handleActivatePrizes}/>)}
        </div>
      </div>
    </div>
  );
};

export default Prizes;
