import { action, makeObservable, observable } from "mobx"
import { createContext } from "react"
import { ISimplePrize } from "../interfaces/Prize"

class GameResultsStore {
  isGameEnded = false

  totalHours = 0

  totalAbonents = 0

  prizes: ISimplePrize[] = []

  constructor() {
    makeObservable(this, {
      isGameEnded: observable,
      totalHours: observable,
      totalAbonents: observable,
      prizes: observable,
      setGameResultsData: action.bound
    })
  }

  setGameResultsData(data: {totalHours: number, totalAbonents: number, prizes: ISimplePrize[]}) {
    this.totalHours = data.totalHours
    this.totalAbonents = data.totalAbonents
    this.prizes = data.prizes
    this.isGameEnded = true
  }
}

export const gameResultsStore = new GameResultsStore()

export const GameResultsStoreContext = createContext(gameResultsStore)