import React from "react";

import styles from "./GameHistory.module.scss";
import star from "../../images/svg/diamond.svg";
import { IHistoryItem } from "./GameHistory";
import { parseDate } from "../../utils/parseDate";

const GameHistoryLine: React.FC<IHistoryItem> = ({
  createDate,
  crystalAmount,
  description
}) => {
  return (
    <div className={styles["history-line"]}>
      <div className={styles["history-line__date"]}>{parseDate(createDate)}</div>
      {crystalAmount && 
        <div className={styles["history-line__price"]}>
          <img src={star} alt="" />
          <p>{crystalAmount}</p>
        </div>
      }
      <p className={styles["history-line__description"]}>{description}</p>
    </div>
  );
};

export default GameHistoryLine;
