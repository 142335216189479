import {FC} from 'react'
import { IAssistantBlock } from '../../../../../../interfaces/common'
import EmployeeView from '../../../../EmployeeView/EmployeeView'
import ModalBlock from '../../../../ModalBlock'
import modalBlockStyles from '../../../../ModalBlock/ModalBlock.module.scss'
import styles from './Assistant.module.scss'
import classNames from 'classnames'
import Button from '../../../../Button'
import diamondIcon from '../../../../../../images/svg/diamond.svg'
import SplittedNumber from '../../../../../ui/SplittedNumber/SplittedNumber'
import RegularTimer from '../../../../RegularTimer/RegularTimer'
import { assistantTypes, ITimer } from '../../../../../../interfaces/GameState'
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { formatToHhMm } from '../../../../../../utils/time-formats'

dayjs.extend(duration);

interface IAssistantProps extends IAssistantBlock {
  hirePrice: number
  timer: ITimer | null
  remainingSeconds: number | null
  assistantType: assistantTypes
  onActivateAssistantBuy: (productCode: string, price: number) => void
  className?: string
}

const Assistant: FC<IAssistantProps> = ({
  assistant, 
  header, 
  description, 
  offerText, 
  timer,
  remainingSeconds,
  assistantType,
  onActivateAssistantBuy,
  productCode,
  hirePrice,
  hiredHeader,
  contractLabel,
  contractIconUrl, 
  className
}) => {

  const onActivateButtonClick = () => {
    onActivateAssistantBuy(productCode, hirePrice)
  }
  
  const renderFooter = () => {
    const isFinanceAssistant = assistantType === "FINANCE"
    const isHired = isFinanceAssistant ? Boolean(remainingSeconds) : Boolean(timer)
    if (isHired) {
      return (
        <div className={styles["assistant__hired"]}>
          <div className={styles["assistant__left-side"]}>
            <div className={styles["assistant__contract-header"]}>{hiredHeader}</div>
            <div className={styles["assistant__contract-label"]}>
              {contractLabel}
            </div>
          </div>
          {isFinanceAssistant ? (
            <div 
              className={styles["assistant__reamining-time"]}
            >
              {formatToHhMm(remainingSeconds || 0, {hoursLabel: "ч.", minutesLabel: "мин."}, true)}
            </div>
          ) : (
            <RegularTimer 
              timer={timer as ITimer} 
              isBlue
              className={styles["assistant__timer"]}
            />
          )}
        </div>
      )
    }
    else {
      return (
        <div className={styles["assistant__hire-assistant"]}>
          <div className={styles["assistant__offer"]}>{offerText}</div>
          <Button 
            onClick={onActivateButtonClick}
            className={styles["assistant__pay-button"]}
          >
            <img src={diamondIcon} alt="diamonds" className={styles["assistant__crystal-icon"]}/>
            <SplittedNumber value={hirePrice}/>
          </Button>
        </div>
      )
    }
  }
  
  return (
    <div className={classNames(styles["assistant"], className)}>
      <ModalBlock 
        kind='common' 
        modifier="br-top"
        className={styles["assistant__body"]}
      >
        <EmployeeView 
          name={assistant.name} 
          avatarUrl={assistant.avatarUrl}
          className={classNames(styles["assistant__avatar"], modalBlockStyles["without-padding"])}
        />
        <div className={classNames(styles["assistant__info"], modalBlockStyles["without-padding"])}>
          <h4 className={styles["assistant__header"]}>{header}</h4>
          <div className={styles["assistant__description"]}>{description}</div>
        </div>
      </ModalBlock>
      <ModalBlock
        kind="common"
        modifier="br-bottom"
        className={classNames(styles["assistant__footer"])}
      >
        {renderFooter()}
      </ModalBlock>
    </div>
  )
}

export default Assistant