import { FC } from "react";
import { ITrends } from "../../../interfaces/GameState";

import emojiUp from "../../../images/svg/emoji-up.svg";
import emojiDown from "../../../images/svg/emoji-down.svg";
import arrowUp from "../../../images/svg/arrow-up.svg";
import arrowDown from "../../../images/svg/arrow-down.svg";

import styles from "./Estimation.module.scss"
import classNames from "classnames";

type estimationProps = {
  trends: ITrends;
  withPointer?: boolean;
  onClick?: () => void;
};

const Estimation: FC<estimationProps> = ({ trends, withPointer, onClick }) => {
  const estimatiomStyles = classNames(styles["estimation"], {[styles["estimation--pointer"]]: withPointer});


  const handleClick = () => {
    if (onClick) onClick();
  }

  return (
    <div className={estimatiomStyles} onClick={handleClick}>
      <img
        src={trends?.isThumbUp ? emojiUp : emojiDown}
        alt=""
      />
      <img
        src={trends?.isPositive ? arrowUp : arrowDown}
        alt=""
      />
      {trends?.percent && <span>{parseInt(trends.percent.toString())}%</span>}
    </div>
  );
};

export default Estimation;
