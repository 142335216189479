import { createContext } from "react";
import { makeObservable, observable, action } from "mobx";

export class UseBooster {
  constructionId?: string;

  afterUseBooster?: ()=>void

  constructor() {
    makeObservable(this, {
      constructionId: observable,
      afterUseBooster: observable,
      setConstruction: action.bound,
      setAfterUseBooster: action.bound
    });
  }

  setAfterUseBooster(afterUseBooster: ()=>void){
    this.afterUseBooster = afterUseBooster
  }

  setConstruction(constructionId: string) {
    this.constructionId = constructionId;
  }
}

export const UseBoosterStoreContext = createContext(new UseBooster());
