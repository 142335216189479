import {markerIconsType} from '../types/markers'

export const maintenanceIcon = `${process.env.PUBLIC_URL}/assets/markers/maintenance-icon.png`  // заменить на иконку ТО

export const markerIcons: markerIconsType = {
  "NORMAL": null,
  "UPPING_LEVEL": `${process.env.PUBLIC_URL}/assets/markers/modernization-icon.png`,
  "CAN_BUILD": `${process.env.PUBLIC_URL}/assets/markers/draft-icon.png`,
  "DRAFT": null,
  "CRASH": `${process.env.PUBLIC_URL}/assets/markers/crash-icon.png`,
  "CRASH_RESOLVING": `${process.env.PUBLIC_URL}/assets/markers/repairing-icon.png`,
}