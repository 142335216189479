type TileData = {
  name: string
  x: number
  y: number
  width: number
  height: number
  scaleX: number
  scaleY: number
}

export const matrixSize = 4
export const tileWidth = 1323
export const tileHeight = 1463

export const tilesArray: TileData[] = [
  {
    name: "1a-min",
    x: 0,
    y: 0,
    width: 100,
    height: 100,
    scaleX: 0.1,
    scaleY: 0.1,
  },
  {
    name: "1b-min",
    x: 0,
    y: 0,
    width: 200,
    height: 200,
    scaleX: 0.1,
    scaleY: 0.1,
  },
  {
    name: "1c-min",
    x: 0,
    y: 0,
    width: 200,
    height: 200,
    scaleX: 0.1,
    scaleY: 0.1,
  },
  {
    name: "1d-min",
    x: 0,
    y: 0,
    width: 200,
    height: 200,
    scaleX: 0.1,
    scaleY: 0.1,
  },
  {
    name: "2a-min",
    x: 0,
    y: 0,
    width: 100,
    height: 100,
    scaleX: 0.5,
    scaleY: 0.5,
  },
  {
    name: "2b-min",
    x: -500,
    y: 0,
    width: 200,
    height: 200,
    scaleX: 0.5,
    scaleY: 0.5,
  },
  {
    name: "2c-min",
    x: -1000,
    y: 0,
    width: 200,
    height: 200,
    scaleX: 0.5,
    scaleY: 0.5,
  },
  {
    name: "2d-min",
    x: -1500,
    y: 0,
    width: 200,
    height: 200,
    scaleX: 0.5,
    scaleY: 0.5,
  },
  {
    name: "3a-min",
    x: 0,
    y: 0,
    width: 100,
    height: 100,
    scaleX: 0.5,
    scaleY: 0.5,
  },
  {
    name: "3b-min",
    x: -500,
    y: 0,
    width: 200,
    height: 200,
    scaleX: 0.5,
    scaleY: 0.5,
  },
  {
    name: "3c-min",
    x: -1000,
    y: 0,
    width: 200,
    height: 200,
    scaleX: 0.5,
    scaleY: 0.5,
  },
  {
    name: "3d-min",
    x: -1500,
    y: 0,
    width: 200,
    height: 200,
    scaleX: 0.5,
    scaleY: 0.5,
  },
  {
    name: "4a-min",
    x: 0,
    y: 0,
    width: 100,
    height: 100,
    scaleX: 0.5,
    scaleY: 0.5,
  },
  {
    name: "4b-min",
    x: -500,
    y: 0,
    width: 200,
    height: 200,
    scaleX: 0.5,
    scaleY: 0.5,
  },
  {
    name: "4c-min",
    x: -1000,
    y: 0,
    width: 200,
    height: 200,
    scaleX: 0.5,
    scaleY: 0.5,
  },
  {
    name: "4d-min",
    x: -1500,
    y: 0,
    width: 200,
    height: 200,
    scaleX: 0.5,
    scaleY: 0.5,
  },
]
