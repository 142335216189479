import React from "react";
import { indicatorInfo } from "../../../../constants/indicatorInfo";
import { IIndicatorInfo } from "../../../../interfaces/IndicatorInfoModal";

import ModalBlock from "../../ModalBlock";

import modalStyles from "../SimpleModal.module.scss";
import styles from "./IndicatorInfo.module.scss";

const IndicatorInfo: React.FC<IIndicatorInfo> = ({
  type
}) => {
  return (
    <div className={modalStyles.modal__body}>
      <ModalBlock kind="common">
        <div className={styles.info}>
          <h4 className={styles.info__header}>{indicatorInfo[type].header}</h4>
          <p className={styles.info__text}>
            {indicatorInfo[type].text}
          </p>
        </div>
      </ModalBlock>
    </div>
  );
};

export default IndicatorInfo;
