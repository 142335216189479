import API from "../../api";
import { IDropdownValue } from "../../interfaces/common";

const PRIZE_URL = "/prize/setOptions"   // пока делаю так только для проверки

export const postPrizeSetOptions = (prizeWinedId: string, option?: IDropdownValue) => {
    API.postRequest(PRIZE_URL, {
        prizeWinedId,
        args: option || {}
    })
};

export const getPrizes = () => API.getRequest("prize/user/list");

export const getPrizeActivationOptions = (prizeWinedId: string) => API.getRequest(`prizeWined/${prizeWinedId}/withOptions`);
