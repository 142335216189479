import API from "../../api";
import {
  boosterSizeType,
  boosterTypes,
  packTypes,
} from "../../interfaces/common";
import { currenciesPackCodes } from "../../interfaces/Market";

const STORE_URL = "market";

export const buyGold = (productCode: currenciesPackCodes) => {
  return API.postRequest(STORE_URL + "/buy", {
    productCode: productCode,
    args: {
      paymentMethod: "CRYSTAL",
    },
  });
};

export const buyCrystal = (
  productCode: currenciesPackCodes,
  method: "bonus" | "money",
  account?: string
) => {
  return API.postRequest(STORE_URL + "/buy", {
    productCode: productCode,
    args: {
      paymentMethod: method,
      account: account,
    },
  });
};

export const buyBooster = (
  booster: boosterTypes,
  size: boosterSizeType,
  paymentMethod: "GOLD" | "CRYSTAL"
) => {
  // создаем код продукта из кода бустера + размера пакета
  // loyaltyHigh
  const productCode = booster + size[0].toUpperCase() + size.slice(1);

  return API.postRequest(STORE_URL + "/buy", {
    productCode: booster !== "repairCrash" ? productCode : booster,
    args: {
      paymentMethod,
    },
  });
};

export const buyKit = (kit: packTypes) => {
  return API.postRequest(STORE_URL + "/buy", {
    productCode: kit,
    args: {
      paymentMethod: "CRYSTAL",
    },
  });
};

export const buyOptions = () => {
  return API.postRequest(STORE_URL + "/buyOptions", {
    productCode: "crystalLight",
  });
};

export const getBankBooks = () => {
  return API.postRequest(STORE_URL + "/buyOptions", {
    productCode: "crystalMax",
    args: { paymentMethod: "money" },
  });
};

export const getTenderPrice = () => {
  return API.getRequest(STORE_URL + "/products?productCode=tender");
};

export const buyTender = (paymentMethod: "GOLD" | "CRYSTAL") => {
  return API.postRequest(STORE_URL + "/buy", {
    productCode: "tender",
    args: {
      paymentMethod: paymentMethod,
    },
  });
};

export const getProducts = () => {
  return API.getRequest(STORE_URL + "/products");
};

export const getPartnerTransitions = () => {
  return API.getRequest(STORE_URL + "/products?productCode=partnerTransition");
};

export const partnerTransition = () => {
  return API.postRequest(STORE_URL + "/buy", {
    productCode: "partnerTransition",
    args: {
      paymentMethod: "GOLD",
    },
  });
};


export const buyDoubleProfit = () => {
  return API.postRequest(STORE_URL + "/buy", {
    productCode: "doubleProfit",
    args: {
      paymentMethod: "CRYSTAL",
    },
  });
};