import React, { useContext } from "react";
import ModalBlock from "../../ModalBlock";

import { QuestStoreContext } from "../../../../store/Quest";

import goldIcon from "../../../../images/svg/coin.svg";

import styles from "./QuestInfo.module.scss";
import Button from "../../Button";

import { IQuest } from "../../../../interfaces/common";
import {
  getQuest,
  rejectQuest,
  resolveQuest,
} from "../../../../services/quest";
import { confirmModalStoreContext } from "../../../../store/ConfirmModal";
import ModalContextStore from "../../../../store/Modal";
import { ToastStoreContext } from "../../../../store/Toast";
import Crash from "../../Crash/Crash";
import { observer } from "mobx-react-lite";
import ClientsGone from "./ClientsGone";
import ManagerGone from "./ManagerGone";
import NewEmployee from "./NewEmployee";
import EventSponsorship from "./EventSponsorship";
import InvestStartup from "./InvestStartup";
import ServiceOffer from "./ServiceOffer";
import { GameStateStoreContext } from "../../../../store/GameState";
import SplittedNumber from "../../../ui/SplittedNumber/SplittedNumber";
import useScroller from "../../../hooks/useScroller";
import ScrollArrow from "../../../ui/ScrollArrow/ScrollArrow";

const QuestInfo: React.FC = () => {
  const { openedQuest, setQuests, quests } = useContext(QuestStoreContext);
  const { setConfirm } = useContext(confirmModalStoreContext);
  const { openModal, closeModal } = useContext(ModalContextStore);
  const { pushToast } = useContext(ToastStoreContext);
  const { gold } = useContext(GameStateStoreContext);
  
  const { ScrollContainer, isShowArrow } = useScroller();

  const getQuests = () => {
    getQuest()
      .then((response) => {
        if (!response) throw new Error();
        setQuests(response.results.quests.data);
      })
      .catch((err) => {
        pushToast("error", "Соединение потеряно");
      });
  };

  const resolve = (quest: IQuest) => {
    if (quest.questType === "CLIENTS_GONE") {
      resolveQuest(quest).then(() => {
        pushToast("success", "Квест выполнен");
        getQuests();
        removeQuestFromList()
        closeModal();
      });
    } else {
      setConfirm(
        () => {
          if (openedQuest && openedQuest.resolvePrice["GOLD"] <= gold ) {
            resolveQuest(quest).then(() => {
              pushToast("success", "Квест выполнен");
              getQuests();
              removeQuestFromList()
              closeModal();
            });
          } else {
            openModal("notEnoughGold");
          }
        },
        {confirmHeader: "Вы уверены ?"}
      );
      openModal("confirm");
    }
  };

  const reject = (quest: IQuest) => {
    rejectQuest(quest.id).then(() => {
      pushToast("success", "Квест отклонен");
      getQuests();
      removeQuestFromList()
      closeModal();
    });
  };

  const crashOnFix = () => {
    removeQuestFromList()
    closeModal();
  }

  const removeQuestFromList = () => {
    setQuests(quests.filter(quest => quest.id !== openedQuest?.id))
  }

  return (
    <div className={styles.questInfo__body}>      
      <ScrollContainer>
        {openedQuest?.questType === "CRASH" && (
          <Crash
            onFix={crashOnFix}
            action={openedQuest}
          />
        )}
        {openedQuest?.questType === "INVEST_STARTUP" && (
          <InvestStartup action={openedQuest} />
        )}

        {openedQuest?.questType === "SERVICE_OFFER" && (
          <ServiceOffer action={openedQuest} />
        )}

        {openedQuest?.questType !== "CRASH" &&
          openedQuest?.questType !== "INVEST_STARTUP" &&
          openedQuest?.questType !== "SERVICE_OFFER" && (
            <ModalBlock kind={"common"}>
              {openedQuest?.questType === "CLIENTS_GONE" && (
                <ClientsGone action={openedQuest} />
              )}

              {openedQuest?.questType === "MANAGER_GONE" && (
                <ManagerGone action={openedQuest} />
              )}

              {openedQuest?.questType === "NEW_EMPLOYEE" && (
                <NewEmployee action={openedQuest} />
              )}

              {openedQuest?.questType === "EVENT_SPONSORSHIP" && (
                <EventSponsorship action={openedQuest} />
              )}

              {openedQuest && (openedQuest.canResolve || openedQuest.canReject) && (
                <div className={styles.questInfo__buttons}>
                  {openedQuest.questType === "MANAGER_GONE" && (
                    <h4>сохранить сотрудника</h4>
                  )}
                  {openedQuest.questType === "EVENT_SPONSORSHIP" && (
                    <h4>Спонсировать мероприятие</h4>
                  )}
                  {openedQuest.questType === "NEW_EMPLOYEE" && (
                    <h4>Нанять сотрудника</h4>
                  )}

                  <div>
                    {openedQuest.canResolve && (
                      <Button onClick={() => resolve(openedQuest)}>
                        <img src={goldIcon} alt="gold" />
                        <SplittedNumber value={openedQuest.resolvePrice["GOLD"] || 0} isAdaptive/>
                      </Button>
                    )}

                    {openedQuest.canReject && (
                      <Button onClick={() => reject(openedQuest)} bordered>
                        Пропустить
                      </Button>
                    )}
                  </div>
                </div>
              )}
            </ModalBlock>
          )
        }
      </ScrollContainer>
      
      {isShowArrow && <ScrollArrow />}
    </div>
  );
};

export default observer(QuestInfo);
