import {Polygon, Point, Graphics} from 'pixi.js'
import { drawLine, getABCSinCos } from '../graphics/lines'
import { flatten } from 'lodash'

type pointsPair = [
    {
        index: number
        point: Point
    },
    {
        index: number
        point: Point
    },
]

function setOffsetToPointsPair(pointsPair: pointsPair, offset: number): pointsPair {
    const {sin, cos} = getABCSinCos(pointsPair[0].point, pointsPair[1].point)
    const result: pointsPair = [
        {
            index: pointsPair[0].index,
            point: new Point(pointsPair[0].point.x + offset * cos, pointsPair[0].point.y + offset * sin)
        },
        {
            index: pointsPair[1].index,
            point: new Point(pointsPair[1].point.x - offset * cos, pointsPair[1].point.y - offset * sin)
        }
    ]
    return result
}

export function getLinePolygonsSinglePoints(points: Point[], offset?: number, graphics?: Graphics): Polygon {
    if (points.length === 0) {
        return new Polygon()
    }
    let pointsPairs: pointsPair[] = []
    for (let i = 0; i < points.length / 2; i++) {
        pointsPairs.push([
            {
                index: i,
                point: points[i]
            },
            {
                index: points.length - i - 1,
                point: points[points.length - i - 1],
            } 
        ])
    }
    pointsPairs = pointsPairs.map(pair => setOffsetToPointsPair(pair, offset || 0))
    let pointsWithOffset = flatten(pointsPairs)
        .sort((pair1, pair2) => pair1.index - pair2.index)
        .map(pointWithIndex => pointWithIndex.point)
    pointsWithOffset.push(pointsWithOffset[0])
    if (graphics) {
        for (let i = 0; i < pointsWithOffset.length - 1; i++) {
            drawLine(graphics, {fromX: pointsWithOffset[i].x, fromY: pointsWithOffset[i].y, toX: pointsWithOffset[i + 1].x, toY: pointsWithOffset[i + 1].y, color: 0xff0000, height: 4})
        }
    }
    return new Polygon(pointsWithOffset)
}