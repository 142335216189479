import { createContext } from "react"
import { action, makeObservable, observable, computed } from "mobx"
import {
  tilesArray,
  tileWidth,
  tileHeight,
  matrixSize,
} from "../constants/tiles"
import {fieldScale} from '../constants/field'
import { Point } from 'pixi.js'

export class Field {
  windowSize = {
    width: window.innerWidth,
    height: window.innerHeight,
  }

  scale = fieldScale

  offsetX = 0;

  tileWidth = tileWidth
  
  tileHeight = tileHeight

  tiles = tilesArray

  coords = {
    x: 0,
    y: 0,
  }

  currentOffset = new Point(0, 0)

  constructor() {
    makeObservable(this, {
      windowSize: observable.ref,
      onResize: action.bound,
      setScale: action.bound,
      setCoords: action.bound,
      setCurrentOffset: action.bound,
      currentOffset: observable,
      scale: observable,
      coords: observable,
      windowCenter: computed,
      maxOffsetX: computed,
      maxOffsetY: computed,
      tiles: observable,
    })
    window.addEventListener("resize", this.onResize)
  }

  destroy() {
    window.removeEventListener("resize", this.onResize)
  }

  onResize() {
    this.windowSize = {
      width: window.innerWidth,
      height: window.innerHeight,
    }

    const mapWidth = tileWidth * this.scale * matrixSize;
    this.offsetX = (window.innerWidth - mapWidth) / 2;
  }

  setCurrentOffset(offset: Point) {
    this.currentOffset = offset
  }

  setScale(_scale: number) {
    this.scale = _scale;
    const mapWidth = tileWidth * this.scale * matrixSize;
    this.offsetX = (window.innerWidth - mapWidth) / 2;
  }

  setCoords(_x: number, _y: number) {
    this.coords = {
      x: -(_x - window.innerWidth),
      y: -(_y - window.innerHeight),
    };
  }

  get windowCenter() {
    return {
      x: this.windowSize.width / 2,
      y: this.windowSize.height / 2,
    }
  }
  
  get maxOffsetX() {
    return this.tileWidth * matrixSize - this.windowSize.width / this.scale
  }

  get maxOffsetY() {
    return (this.tileHeight * matrixSize + 52) - this.windowSize.height / this.scale
  }
}

export const fieldStore = new Field()

export const FieldStoreContext = createContext(fieldStore)
