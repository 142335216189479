import API from "../../api";

const NOTIFICATION_URL = "notifications";

type notificationType = "currentDay" | "firstUnread";

export const getNotifications = (type: notificationType) =>
  API.getRequest(NOTIFICATION_URL + `?${type}=true`);

export const viewNotification = (notificationId: string) =>
  API.postRequest(NOTIFICATION_URL + `/${notificationId}/view`);
