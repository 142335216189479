import classnames from "classnames";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";

import { modalInfo } from "../utils/modalInfo/modalInfo";

import ModalContextStore, { IModal } from "../store/Modal";
import { TutorialStoreContext } from "../store/Tutorial";
import PicturedModal from "./common/PicturedModal";
import SimpleModal from "./common/SimpleModal";
import { isInstanceOfIModalsGroup } from "../utils/type-guards/type-guards";

const Modal: React.FC = () => {

  const { modals, closeModal, closeModals } = useContext(ModalContextStore);
  const { activeUIElement } = useContext(TutorialStoreContext);
  
  useEffect(() => {
    return closeModals();
  }, [closeModals]);

  return (
    <>
      {modals.map((item, i) => {
        // item.items.length > 0 всегда
        let modal: IModal = isInstanceOfIModalsGroup(item) ? item.items[0] : item
        const modalContainerClassName = classnames("modal", {
          shadowed: i === modals.length - 1 && !activeUIElement,
        });

        const defaultProps = {
          onClose: () => {
            closeModal(modal.modalId);
          },
          kind: modal.kind,
          modalId: modal.modalId
        };
        return (
          // todo change keys for uuid()
          <div className={modalContainerClassName} key={modal.modalId}>
            {modal.options?.modalImage ? (
              <PicturedModal
                image={modal.options?.modalImage}
                {...defaultProps}
                chevron={modal.options.chevron}
                buildID={modal.options.gameObjectId}
                questId={modal.options.questId}
              />
            ) : (
              <SimpleModal
                {...defaultProps}
                headerText={modalInfo[defaultProps.kind].header}
              />
            )}
          </div>
        );
        
      })}
    </>
  );
};

export default observer(Modal);
