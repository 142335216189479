const intlFormat = (num: number) => {
  return new Intl.NumberFormat().format(Math.floor(num * 10) / 10);
};

export const makeFriendly = (num: number) => {
  if (num >= 1000000)
    return intlFormat(num / 1000000) + 'M';
  if (num >= 1000)
    return intlFormat(num / 1000) + 'k';
  return intlFormat(num);
};

export const shortThousandsFormat = (num: number) => {
 if (num >= 1000000) {
   return intlFormat(num / 1000) + 'k'
 } 
 return intlFormat(num)
}
