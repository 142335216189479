import { observer } from "mobx-react-lite";
import React, { FC, useContext, useEffect, useState } from "react";
import { Switch, Route, Redirect } from 'react-router-dom';
import { polyfill } from "seamless-scroll-polyfill";

import { checkSession, logout } from "../services/auth";
import { AuthorizationContext } from "../store/Auth";

import App from "../components/App";
import Authorization from "../pages/Authorization/Authorization";
import GameHistory from "../pages/GameHistory/GameHistory";
import Leaderboard from "../pages/Leaderboard/Leaderboard";
import Prizes from "../pages/Prizes/Prizes";
import Rules from "../pages/Rules/Rules";
import Feedback from "../pages/Feedback/Feedback";
import Welcome from "../pages/Welcome/Welcome";
import ErrorMessage from "../pages/ErrorMessage/ErrorMessage";
import { api } from "../api";
import ROUTES from "../enums/routes";
import { getFullProfile } from "../services/user";
import { UserStoreContext } from "../store/User";
import { bonusModalStoreContext } from "../store/BonusModal";
import GameEndResults from "../pages/GameEndResults/GameEndResults";
import { GameResultsStoreContext } from "../store/GameResults";
import { GameIntervalsStoreContext } from "../store/GameIntervals";

const GameRouter: FC = () => {
  const [status, setStatus] = useState<number>();
  const { isAuthorized, setIsAuthorized } = useContext(AuthorizationContext);
  const { setUser } = useContext(UserStoreContext);
  const { showBonusModal, setShowBonusModal } = useContext(bonusModalStoreContext);
  const { isGameEnded } = useContext(GameResultsStoreContext)
  const { questIntervalId, clearIntervals } = useContext(GameIntervalsStoreContext)

  polyfill();

  const getLayout = (layout: React.FC, isPublic?: boolean) => {
    if ((!isPublic && !isAuthorized) || showBonusModal)
      return Authorization;
    else if (status && status >= 500)
      return () => <ErrorMessage code={500} />;
    else return layout;
  };

  useEffect(() => {
    api.interceptors.response.use((response) => {
      return response;
    }, (error) => {
      if (error.response) {
        setStatus(error.response.status);
        if (error.response.status === 401)
          setIsAuthorized(false);
        if (error.response.status === 400)
          return error.response;
      }
      return Promise.reject(error);
    });

    checkSession().then(res => {
      if (res?.status === 200) {
        getFullProfile().then((response) => {
          setUser(response.results?.user?.data);
          if (response.results.user.data.bonusAvailable === false) {
            setShowBonusModal(true);
            logout().then(() => {
              checkSession();
              setUser();
            });
            return;
          }
          setIsAuthorized(true);
        });
      }
    });
  }, [setIsAuthorized, setShowBonusModal, setUser]);

  useEffect(() => {
    if (isGameEnded && questIntervalId) {
      clearIntervals()
    }
  }, [isGameEnded, questIntervalId, clearIntervals])

  const getAppRouteComponent = () => {
    if (isGameEnded) {
      return GameEndResults
    }
    return App
  }

  // window.onbeforeunload = () => {
  //   logout();
  //   return;
  // }

  return (
    <Switch>
      <Route exact path={ROUTES.APP} component={getLayout(isAuthorized ? getAppRouteComponent() : Welcome, true)} />
      <Route exact path={ROUTES.ABOUT} component={getLayout(Rules, true)} />
      <Route exact path={ROUTES.LEADERBOARD} component={getLayout(Leaderboard)} />
      <Route exact path={ROUTES.PRIZES} component={getLayout(Prizes)} />
      <Route exact path={ROUTES.HISTORY} component={getLayout(GameHistory)} />
      <Route exact path={ROUTES.FEEDBACK} component={getLayout(Feedback, true)} />
      <Route exact path="/invite/:ref" component={getLayout(Authorization, true)} />
      {!isAuthorized ? <Route exact path={ROUTES.AUTHORIZATION} component={getLayout(Authorization, true)} /> :
        <Redirect from={ROUTES.AUTHORIZATION} to={ROUTES.APP}  />
      }
      <Route render={() => <ErrorMessage code={404} />} />
    </Switch>
  );
};

export default observer(GameRouter);