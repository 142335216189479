import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { FC, useContext, useState } from "react";
import { Link, useLocation } from 'react-router-dom';

import logo from '../../../images/promo/logo.svg';
import menu from '../../../images/svg/menu.svg';
import close from '../../../images/svg/close-black.svg';
import { checkSession, logout } from "../../../services/auth";
import { AuthorizationContext } from "../../../store/Auth";
import styles from "./Navigation.module.scss";
import { navItems } from "../../../constants/navItems";
import ROUTES from "../../../enums/routes";

import { v4 as uuid } from 'uuid';
import { GameResultsStoreContext } from "../../../store/GameResults";

const Navigation: FC = () => {
  // Это все надо будет зарефакторить!
  const [dropdown, setDropdown] = useState(false);
  const { isAuthorized } = useContext(AuthorizationContext);
  const { isGameEnded } = useContext(GameResultsStoreContext)
  const location = useLocation();
  const isApp = location.pathname === ROUTES.APP;
  const isGameButton = isApp && isAuthorized && !isGameEnded

  const dropdownStyles = classNames([
    styles.navigation__list,
    {[styles["navigation__list--show"]]: dropdown},
    {[styles["navigation__list--left"]]: dropdown && isGameButton}
  ]);

  const navStyles = classNames([
    styles.navigation,
    {[styles["navigation--hide"]]: isGameButton}
  ]);

  const logoStyles = classNames([
    styles.navigation__logo,
    {[styles["navigation__logo--hide"]]: isGameButton}
  ]);

  const menuBarsStyles = classNames([
    styles["navigation__menu-bars"],
    {[styles["navigation__menu-bars--gradient"]]: isGameButton}
  ]);

  const closeButtonStyles = classNames([
    styles["navigation__close-button"],
    {[styles["navigation__close-button--left"]]: isGameButton}
  ]);
  
  const handleLogout = () => {
    logout().then(() => checkSession());
    handleMenuClick();
  }

  const handleMenuClick = () => {
    setDropdown(!dropdown);
  };

  return (
    <div className={navStyles}>
      <img src={logo} className={logoStyles} alt="Logo" />
      <img
        src={menu}
        className={menuBarsStyles}
        alt="Menu"
        onClick={handleMenuClick}
      />
      <ul className={dropdownStyles}>
        <img
          src={close}
          alt="Close"
          onClick={handleMenuClick}
          className={closeButtonStyles}
        />
        {
          navItems.map((item) => 
            <li
              key={uuid()}
              onClick={handleMenuClick}
            >
              <Link
                to={item.route}
                className={classNames([styles["nav-link"], {[styles["nav-link--active"]]: location.pathname === item.route}])}
              >
                {item.title}
              </Link>
            </li>
          )
        }
        {isAuthorized &&
          <li onClick={handleLogout}>
            <span className={styles["nav-link"]}>Выход</span>
          </li>}
      </ul>
    </div>
  );
};

export default observer(Navigation);
