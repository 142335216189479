import { useEffect, useState } from "react";
import { ITimer } from "../../interfaces/GameState";
import { getFullDurationAndElapsedSeconds } from "../../utils/timer";

const useTimer = (
  timer: ITimer | undefined,
  currentTime: string,
) => {

  const [isTimerRunning, setIsTimerRunning] = useState(false)
  const [fullDurationMs, setFullDurationMs] = useState(0)
  const [elapsedMs, setElapsedMs] = useState(0)

  useEffect(() => {
    if (timer && !isTimerRunning) {
      setIsTimerRunning(true);
      const { fullDurationMs: fullDur, elapsedMs: elMs } =
        getFullDurationAndElapsedSeconds(
          timer.startedAt,
          timer.endedAt,
          currentTime
        );
      setFullDurationMs(fullDur);
      setElapsedMs(elMs);
    } else if (!timer && isTimerRunning) {
      setIsTimerRunning(false);
      setFullDurationMs(0);
      setElapsedMs(0);
    }
  }, [
    timer,
    isTimerRunning,
    currentTime,
    setElapsedMs,
    setFullDurationMs,
    setIsTimerRunning,
  ]);

  return {fullDurationMs, elapsedMs, isTimerRunning, setElapsedMs}
};

export default useTimer;
