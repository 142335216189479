import { FC, useContext } from "react";
import styles from './Authorization.module.scss'

import layoutStyles from '../../layouts/Promo/Promo.module.scss';
import SimpleModal from "../../components/common/SimpleModal";
import classNames from "classnames";
import { useHistory } from "react-router";
import ROUTES from "../../enums/routes";
import useBlurredUpImage from "../../components/hooks/useBlurredUpImage";
import backgroundImage from "../../images/promo/game-city-hero.jpg"
import backgroundImageLow from "../../images/promo/game-city-hero.jpg"
import { AuthorizationContext } from "../../store/Auth";
import { UserStoreContext } from "../../store/User";
import { observer } from "mobx-react-lite";

const Authorization: FC = () => {
  const { isBlur } = useBlurredUpImage(backgroundImageLow, backgroundImage)
  const wrapperStyle = classNames(layoutStyles.promo, styles["auth-page"]);
  const history = useHistory();
  const { withError, setWithError, isShowRTSignUpActionSteps, setIsShowRTSignUpActionSteps } = useContext(AuthorizationContext);
  const { user, setUser } = useContext(UserStoreContext);

  const handleCloseModal = () => {
    if (user) {
      setUser();
    }

    if (isShowRTSignUpActionSteps) {
      setIsShowRTSignUpActionSteps(false);
    } else if (withError) {
      setWithError(false);
    } else {
      history.push(ROUTES.APP);
    }
  };
  // div.promo-hero-bg надо вынести в отдельный компонент
  return (
    <div className={wrapperStyle}>
      <div className={classNames(layoutStyles["promo-hero-bg"], {[layoutStyles["promo-hero-bg--bg-loaded"]]: !isBlur})}/>
      <div className={classNames(styles["auth-page__modal-container"], "modal shadowed")}>
        <SimpleModal onClose={handleCloseModal} kind="auth" />
      </div>
    </div>
  );
};

export default observer(Authorization);
