import React, { MutableRefObject } from "react";

import styles from "./Leaderboard.module.scss";
import classNames from "classnames";
import heart from "../../../../images/svg/heart-no-shadow.svg";
import star from "../../../../images/svg/star.svg";
import { IUserScore, leaderboardVariableKeyType } from "../../../../interfaces/Leaderboard";
import { digitsDivide } from "../../../../utils/digitsDivide";

interface ILeaderboardLine extends IUserScore {
  position: number;
  type: leaderboardVariableKeyType;
  userRef?: MutableRefObject<HTMLDivElement | null>;
}

const LeaderboardLine: React.FC<ILeaderboardLine> = ({
  alias,
  currentUser,
  score,
  position,
  type,
  userRef
}) => {
  const leaderboardLineClass = classNames([styles.line], {
    [styles["line--highlight"]]: currentUser,
  });

  return (
    <div className={leaderboardLineClass} ref={userRef}>
      <span>{position !== 0 && (position + ".")}</span>
      {currentUser && <img className={styles.line__star} alt="" src={star} />}
      <div className={styles["line__user-score"]}>
        <span>{alias + (currentUser ? " (Я)" : "")}</span>
        {score !== null &&
          <div className={styles.score}>
            {(type === "DAILY" ? "+" : "") + digitsDivide(score)}
            <img alt="" src={heart} />
          </div>
        }
      </div>
    </div>
  );
};

export default LeaderboardLine;
