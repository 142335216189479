import React, { useCallback, useContext, useState } from "react";

import { OutlineFilter } from "@pixi/filter-outline";
import { AdjustmentFilter } from "@pixi/filter-adjustment";
import { Container, Sprite, withFilters } from "@inlet/react-pixi";
import { Point, Polygon } from "@pixi/math";
import { InteractionEvent} from 'pixi.js'

import { ICityObject } from "../../../interfaces/CityObject";
import ModalContextStore from "../../../store/Modal";
import Building from "../Building";
import { baseStatuses } from "../../../types/statuses";
import Marker from '../Marker'
import { ITimer } from "../../../interfaces/GameState";
import { getImage, getTimer } from "../../../utils/markers/timer-and-image";
import { maintenanceIcon } from "../../../constants/marker-icons";
import { GameStateStoreContext } from "../../../store/GameState";
import { TutorialStoreContext } from "../../../store/Tutorial";
import { getPointsDistance } from "../../../utils/points/distance";

interface ICityObjectProps extends ICityObject {
  status: baseStatuses
  maintenanceTimer?: ITimer
  timer?: ITimer 
}

// плохая практика
let offsetBefore: Point | null = null
let isBuildingClicked = false

const CityObject: React.FC<ICityObjectProps> = ({
  resource,
  building,
  xPos,
  yPos,
  modalImg,
  uniqName,
  markerIcons,
  markerCoors,
  status,
  maintenanceTimer,
  timer
}) => {
  const { openModal } = useContext(ModalContextStore);
  const { nextStep } = useContext(TutorialStoreContext);
  const { isTutorial } = useContext(GameStateStoreContext);
  
  const [cityOutlineEnabled, /*setCityOutlineEnabled */] = useState(false);
  const [cityAdjustmentEnabled, setCityAdjustmentEnabled] = useState<boolean>();
  const [tint, setTint] = useState(0xffffff)

  const CityContainer = withFilters(Container, {
    outline: OutlineFilter,
    adjustment: AdjustmentFilter,
  });

  const onBuildingClick = () => {
    isBuildingClicked = true
  }

  const handleCityPointertup = (e: InteractionEvent) => {
    const currentPoint = new Point(e.data.global.x, e.data.global.y)
    if (!isBuildingClicked && (!offsetBefore || getPointsDistance(offsetBefore, currentPoint) < 50)) {
      if(isTutorial) nextStep().then(() => openModal('city', {modalImage: modalImg, gameObjectId: uniqName}))
      else openModal('city', {modalImage: modalImg, gameObjectId: uniqName})
    }
    isBuildingClicked = false // как только событие всплыло и отработало обнуляем флаг. event.stopPropagation не подходит, тк событие должно всплыть до Offset.tsx
  };

  const handleCityPointerdown = (e: InteractionEvent) => {
    offsetBefore = new Point(e.data.global.x, e.data.global.y)
  }

  const handleCityMouseOut = useCallback(() => {
    setCityAdjustmentEnabled(false);
    setTint(0xffffff)
  }, [setCityAdjustmentEnabled]);

  const handleCityMouseOver = useCallback(() => {
    setCityAdjustmentEnabled(true);
    setTint(0xd4d3cf)
  }, [setCityAdjustmentEnabled]);

  return (
    <>
    <CityContainer
      outline={{ enabled: cityOutlineEnabled, color: 0xffffff, thickness: 3 }}
      adjustment={{ enabled: cityAdjustmentEnabled, gamma: 2 }}
      hitArea={
        new Polygon([
          new Point(600, 630),
          new Point(1160, 350),
          new Point(600, 70),
          new Point(0, 360),
        ])
      }
      interactive={true}
      buttonMode={true}
      pointertap={handleCityPointertup}
      pointerdown={handleCityPointerdown}
      x={xPos}
      y={yPos}
    >
      <Sprite 
        image={resource} 
      />

      {building && (
        <Building
          value={building.value}
          resource={building.resource}
          xPos={building.xPos}
          yPos={building.yPos}
          onBuildingClick={onBuildingClick}
        />
      )}
    </CityContainer>
      <Marker 
        timer={getTimer(status, timer, maintenanceTimer)}
        image={getImage(status, markerIcons, maintenanceTimer, maintenanceIcon, true)}
        tint={tint}
        mouseout={handleCityMouseOut}
        mouseover={handleCityMouseOver}
        pointertap={handleCityPointertup}
        pointerdown={handleCityPointerdown}
        position={markerCoors}
      />
    </>
  );
};

export default CityObject;
