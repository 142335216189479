import { FC, useState } from "react";

import layoutStyles from '../../layouts/Promo/Promo.module.scss';
import medal from "../../images/promo/medal.svg";
import LeaderboardTable from "../../components/common/SimpleModal/Leaderboard/LeaderboardTable";
import { leaderboardVariables } from "../../constants/leaderboards";
import { leaderboardVariableKeyType } from "../../interfaces/Leaderboard";
import styles from "./Leaderboard.module.scss";
import SideBanner from "../../components/common/promo/SideBanner/SideBanner";

const Leaderboard: FC = () => {
  const [leaderboardVariable, setLeaderboardVariable] =
    useState<leaderboardVariableKeyType>(leaderboardVariables.ALL_TIME.value);

  return (
    <>
      <div className={layoutStyles.promo}>
        <SideBanner />
        <div className={layoutStyles.promo__content}>
          <img src={medal} alt="" />
          <h1>Лидерборд</h1>
          <div className={layoutStyles.promo__content__tabs}>
            <div onClick={() => setLeaderboardVariable(leaderboardVariables.DAILY.value)}>
              <p className={styles["bold-text"]}>Рейтинг дня</p>
              {leaderboardVariable === leaderboardVariables.DAILY.value && <div />}
            </div>
            <div onClick={() => setLeaderboardVariable(leaderboardVariables.ALL_TIME.value)}>
              <p className={styles["bold-text"]}>Рейтинг за всё время</p>
              {leaderboardVariable === leaderboardVariables.ALL_TIME.value && <div />}
            </div>
          </div>
          <p>
            {leaderboardVariables[leaderboardVariable].description}
          </p>
          <div className={layoutStyles.promo__content__block}>
            <LeaderboardTable type={leaderboardVariable} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Leaderboard;
