import API from '../../api';

const USER_URL = "user";

export const getProfile = () => API.getRequest(USER_URL + "/profile");

export const getFullProfile = () => API.getRequest(USER_URL);

export const getBonusBalance = () => {
  return API.getRequest(USER_URL + "/bonusBalance", {});
};
