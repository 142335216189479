import { createContext } from "react";
import { makeObservable, observable, action } from "mobx";

export class BonusModal {
  showBonusModal: boolean = false;

  constructor() {
    makeObservable(this, {
      showBonusModal: observable,
      setShowBonusModal: action.bound,
    });
  }

  setShowBonusModal(show: boolean) {
    this.showBonusModal = show;
  }
}

export const bonusModalStoreContext = createContext(new BonusModal());
