import { createContext } from "react";
import { makeObservable, observable, action } from "mobx";
import { IInventoryItem } from "../interfaces/common";

export class Inventory {
  inventory: IInventoryItem[] = [];

  constructor() {
    makeObservable(this, {
        inventory: observable,
        setInventory: action.bound,
    });
  }

  setInventory(inventory: IInventoryItem[]) {
    this.inventory = inventory;
  }
}

export const InventoryContext = createContext(new Inventory());
