
import booster from "../images/prizes/booster.png";
import gamer100 from "../images/prizes/gamer100.png";
import gold from "../images/prizes/gold.png";
import internet25 from "../images/prizes/internet25.png";
import internet100 from "../images/prizes/internet100.png";
import internet100_3 from "../images/prizes/internet100-3.png";
import litres25 from "../images/prizes/litres25.png";
import litres1000 from "../images/prizes/litres1000.png";
import lyceum from "../images/prizes/lyceum.png";
import multiphoto from "../images/prizes/multiphoto.png";
import ozon2000 from "../images/prizes/ozon2000.png";
import ozon4000 from "../images/prizes/ozon4000.png";
import ozon20000 from "../images/prizes/ozon20000.png";
import phone25 from "../images/prizes/phone25.png";
import phone100 from "../images/prizes/phone100.png";
import phone100_3 from "../images/prizes/phone100-3.png";
import rzhd from "../images/prizes/rzhd.png";
import sephora from "../images/prizes/sephora.png";
import tv25 from "../images/prizes/tv25.png";
import tv100 from "../images/prizes/tv100.png";
import wink from "../images/prizes/wink.png";
import yandex_plus from "../images/prizes/yandex-plus.png";
import yandex_taxi from "../images/prizes/yandex-taxi.png";
import { prizeIconNameType } from "../interfaces/Prize";

export const prizeModalIcon: {
  [type in prizeIconNameType]: string;
} = {
  "litres25": litres25,
  "litres1000": litres1000,
  "ozon2000": ozon2000,
  "ozon4000": ozon4000,
  "ozon20000": ozon20000,
  "phone25": phone25,
  "phone100": phone100,
  "phone100-3": phone100_3,
  "tv25": tv25,
  "tv100": tv100,
  "internet25": internet25,
  "internet100": internet100,
  "internet100-3": internet100_3,
  "yandex-plus": yandex_plus,
  "yandex-taxi": yandex_taxi,
  "gamer": gamer100,
  "rgd": rzhd,
  "ildebote": sephora,
  "wink": wink,
  "foto": multiphoto,
  "lyceum": lyceum,
  "booster": booster,
  "gold": gold
};

export const incomingDays = {
  INCOMING_1: 1,
  INCOMING_2: 2,
  INCOMING_3: 3,
  INCOMING_4: 4,
  INCOMING_5: 5,
  INCOMING_10: 6
}

export const incomingPrizeReasons = ['INCOMING_1', 'INCOMING_2', 'INCOMING_3', 'INCOMING_4', 'INCOMING_5', 'INCOMING_10'];

export const prizesText = [
  "10 000 баллов от «РЖД Бонус",
  "Электронный подарочный сертификат на \n2 000 рублей от Ozon.ru",
  "Электронный подарочный сертификат на \n4 000 рублей от Ozon.ru",
  "Электронный подарочный сертификат на \n20 000 рублей от Ozon.ru",
  "Скидка 100% на абонентскую плату услуги «Домашний интернет»",
  "Скидка 100% на абонентскую плату услуги «Домашний телефон»",
  "Скидка 25% на абонентскую плату услуги «Цифровое телевидение»",
  "Скидка 100% на абонентскую плату услуги «Домашний интернет» на 3 месяца",
  "Скидка 100% на абонентскую плату услуги «Цифровое телевидение»",
  "Скидка 100% на абонентскую плату услуги «Домашний телефон» на 3 месяца",
  "Скидка 100% на абонентскую плату тарифа «Игровой»",
  "Скидка 25% на абонентскую плату услуги «Домашний интернет»",
  "Скидка 25% на абонентскую плату услуги «Домашний телефон»",
  "Промокод на подписку «Премиум» видеосервиса Wink на 90 дней",
  "Промокод на подписку «Коллекция «Концерты»» на 30 дней",
  "Промокод на подписку «Трансформер» на 30 дней",
  "Промокод на подписку «Wink» на 30 дней",
  "Промокод на подписку «Wink + more.tv» видеосервиса Wink на 30 дней",
  "Промокод на подписку «more.tv» видеосервиса Wink на 30 дней",
  "Промокод на стандартный календарь – плакат А3",
  "Промокод на скидку 25% и 3 книги из подборки от ЛитРес",
  "Промокод на 1000 рублей от Литрес",
  "Подписка Ростелеком Лицей на 30 дней без ограничений",
  "Промокод на скидку до 40% в интернет-магазине и в мобильном приложении SEPHORA",
  "Электронный подарочный сертификат на 3000 рублей на поездки от Яндекс Go",
  "Промокод на подписку Яндекс Плюс на 12 месяцев"
];
