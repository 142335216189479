import { useState, useCallback, useContext, PropsWithChildren, useEffect } from "react"
import PIXI, { Point } from 'pixi.js';

import { observer } from "mobx-react-lite"
import { Container } from "@inlet/react-pixi"
import { FieldStoreContext } from "../store/Field"
import { GameStateStoreContext } from "../store/GameState";

function Offset({ children }: PropsWithChildren<{}>) {
  const { maxOffsetX, maxOffsetY, scale, coords, setCurrentOffset } =
    useContext(FieldStoreContext)
  const { isTutorial } = useContext(GameStateStoreContext);

  const [offset, setOffset] = useState<Point>(new Point(0, 0))
  const [dragging, setDragging] = useState<Point | false>(false)

  const setOffsetLimited = useCallback(
    (offset: Point) => {
      const x = Math.min(maxOffsetX, Math.abs(offset.x))
      const y = Math.min(maxOffsetY, Math.abs(offset.y))
      let point = new Point(
        (offset.x < 0 ? -1 : 1) * (x < 0 ? 0 : x),
        (offset.y < 0 ? -1 : 1) * (y < 0 ? 0 : y)
      )
      point = point.x > 0 ? new Point(0, point.y) : point
      point = point.y > 0 ? new Point(point.x, 0) : point
      setOffset(point)
    },
    [setOffset, maxOffsetX, maxOffsetY]
  )

  const onPointerDown = useCallback(
    (e: PIXI.InteractionEvent) => {
      e.stopPropagation()
      if (isTutorial) return;
      setDragging(new Point(e.data.global.x, e.data.global.y))
    },
    [setDragging, isTutorial]
  )
  const onPointerUp = useCallback(
    (e: PIXI.InteractionEvent) => {
      e.stopPropagation()
      dragging &&
        setOffsetLimited(
          new Point(
            offset.x + (e.data.global.x - dragging.x) / scale,
            offset.y + (e.data.global.y - dragging.y) / scale
          )
        )
      setDragging(false)
    },
    [offset, dragging, setDragging, setOffsetLimited, scale]
  )
  const onPointerMove = useCallback(
    (e: PIXI.InteractionEvent) => {
      e.stopPropagation()
      if (isTutorial) return;
      if (dragging) {
        setOffsetLimited(
          new Point(
            offset.x + (e.data.global.x - dragging.x) / scale,
            offset.y + (e.data.global.y - dragging.y) / scale
          )
        )
        setCurrentOffset(new Point(
          offset.x + (e.data.global.x - dragging.x) / scale,
          offset.y + (e.data.global.y - dragging.y) / scale
        ))
        setDragging(new Point(e.data.global.x, e.data.global.y))
      }
    },
    [offset, dragging, setDragging, setOffsetLimited, scale, setCurrentOffset, isTutorial]
  )
  const onPointerOut = useCallback(
    (e: PIXI.InteractionEvent) => {
      e.stopPropagation()
      setDragging(false)
    },
    [setDragging]
  )

  useEffect(() => {
    setOffsetLimited(new Point(coords.x, coords.y));
  }, [coords, setOffsetLimited]);
  
  return (
    <Container
      pointerdown={onPointerDown}
      pointerup={onPointerUp}
      pointermove={onPointerMove}
      pointerout={onPointerOut}
      interactive={true}
    >
      <Container x={offset.x} y={offset.y}>
        {children}
      </Container>
    </Container>
  )
}

export default observer(Offset)
