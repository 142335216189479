import React from "react";
import { IQuest } from "../../../../interfaces/common";
import Button from "../../Button";
import ModalBlock from "../../ModalBlock";

import styles from "./QuestInfo.module.scss";

type newEmployeeProps = {
  action: IQuest;
};

const ServiceOffer: React.FC<newEmployeeProps> = ({ action }) => {  
  const onInfoClickHandler = () => {
    if (window.dataLayer) {
      window.dataLayer.push({ 'event': 'b2с', 'type': 'bonus_click', 'value': 'Подробнее о программе «Бонус»' })
    }
  }
  
  return (
    <>
      {action && (
        <div>
          <ModalBlock kind={"common"}>
            <div className={styles.questInfo}>
              <div className={styles.questInfo__serviceOffer}>
                <h4>Повышение статуса участника программы «Бонус»</h4>
                <p>
                  Пора переходить на новый уровень! Подключайте больше услуг
                  Ростелекома и получайте монеты на счёт в игре.
                </p>
                <p>
                  <a href={"https://rt.ru/bonus"} onClick={onInfoClickHandler} target="_blank" rel="noopener noreferrer">
                    Подробнее о программе «Бонус»
                  </a>
                </p>

                <a href="https://lk.rt.ru/new/#bonus" target="_blank" rel="noopener noreferrer">
                  <Button>Подключить услугу</Button>
                </a>
              </div>
            </div>
          </ModalBlock>
        </div>
      )}
    </>
  );
};

export default ServiceOffer;
