import { indicatorInfoType } from "../interfaces/IndicatorInfoModal";

export const indicatorInfo: indicatorInfoType = {
  "clients": {
    header: "Общее количество абонентов",
    text: "Чтобы привлекать и удерживать абонентов, уровень лояльности в городе должен быть выше 45%.\nДля этого предотвращайте аварии, используйте бустеры рекламы и программы лояльности."
  },
  "loyalty": {
    header: "Показатель лояльности",
    text: "Общая лояльность отражает отношение абонентов во всех городах к вашему бизнесу.\n\nВ окнах городов отражается лояльность для каждого города в отдельности.\n\n- Бустеры программы лояльности повышают лояльность на текущий момент.\n\n- Бустеры рекламы также повышают лояльность, постепенно формируя положительный опыт у абонентов."
  },
  "score": {
    header: "Очки репутации",
    text: "Развивайте свой бизнес и стремитесь заработать как можно больше очков репутации. Их прирост зависит от количества абонентов, их лояльности и прибыли компании. Игроки с самым большим количеством очков репутации получат самые ценные призы! Подведение итогов назначено на 20.01.2022"
  }
};
