import { ICommonModal } from "../../interfaces/common";

export const modalInfo: {
  [key in ICommonModal["kind"]]: {
    header?: string;
    icon?: string;
  };
} = {
  staff: {
    header: "Центр развития персонала",
  },
  "staff/offlineAssistant": {
    header: "Центр развития персонала",
  },
  "staff/builders": {
    header: "Центр развития персонала",
  },
  technologies: {
    header: "Технологии",
  },
  booster: {
    header: "Бустеры",
  },
  city: {
    header: "Город",
  },
  line: {
    header: "Линия связи",
  },
  partnership: {
    header: "Партнёрство",
  },
  "partnership/aliexpress": {
    header: "Партнёрство",
  },
  "partnership/mvideo": {
    header: "Партнёрство",
  },
  "partnership/litres": {
    header: "Партнёрство",
  },
  "partnership/sephora": {
    header: "Партнёрство",
  },
  "partnership/lamoda": {
    header: "Партнёрство",
  },
  epoch: {
    header: "Эры",
  },
  "store/gold": {
    header: "Магазин",
  },
  "store/crystal": {
    header: "Магазин",
  },
  "store/booster": {
    header: "Магазин",
  },
  fastBuy: {
    header: "Быстрая покупка",
  },
  confirm: {},
  loyalty: {
    header: "Улучшить лояльность",
  },
  maintenance: {
    header: "Техобслуживание",
  },
  crash: {
    header: "Устранить аварию",
  },
  leaderboard: {
    header: "Лидерборд",
  },
  quest: {
    header: "Квесты",
  },
  questMaxClientGrow: {},
  questFinanceAssistant: {},
  questSupportAssistant: {},
  questInfo: {},
  "quest/crash": {},

  notEnoughGold: {
    header: "Недостаточно монет",
  },
  auth: {},
  notEnoughCrystals: {
    header: "Недостаточно кристаллов",
  },
  store: {
    header: "Магазин",
  },
  tender: {},
  prize: {},
  feedbackSuccess: {},
  skipTimer: {},
  info: {},
  "problem/bonus/balance": {},
  "problem/money/balance": {},
  "problem/money/lock": {},
  calendar: {
    header: "Календарь наград",
  },
  "warning/account/payment": {},
  "warning/account/prize": {},
  offlineProfit: {
    header: "Вы заработали монеты, пока вас не было",
  },
  "info/clients": {},
  "info/loyalty": {},
  "info/score": {}
};
