import React from "react";
import { IQuest } from "../../../../interfaces/common";

import styles from "./QuestInfo.module.scss";
import Employee from "../../StaffList/Employee";
import {departmentVariables} from "../../../../utils/departments/departments";

type managerGoneProps = {
  action: IQuest;
};

const ManagerGone: React.FC<managerGoneProps> = ({ action }) => {
  return (
    <>
      {action && (
        <>
          <div>
            <div className={styles.questInfo}>
              <h4>{action.params.eventName}</h4>
              <p>{action.params.eventDescription}</p>
            </div>
          </div>
          <div>
            <div className={styles.questInfo__manager}>
              {action.params.employee && (
                <Employee
                  department={"CUSTOMER_SERVICE"}
                  person={action.params.employee}
                />
              )}

              <div>
                <p>{departmentVariables["CUSTOMER_SERVICE"].header}</p>
                <h4>
                  {action.params.employee?.employeeName} собирается перейти к
                  конкуренту
                </h4>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ManagerGone;
