import React, { useContext, useState } from "react";
import classNames from "classnames";

import { IButton } from "../../../interfaces/common";

import styles from "./Button.module.scss";
import { TutorialStoreContext } from "../../../store/Tutorial";

const Button: React.FC<IButton> = ({
  onClick = () => {},
  children,
  disabled,
  bordered,
  text,
  Image,
  textBefore,
  type,
  noDelay,
  orange,
  isRed,
  id,
  className
}) => {
  const { activeUIElement, nextStep } = useContext(TutorialStoreContext);
  const [pending, setPending] = useState(false);
  const btnClass = classNames({
    [styles.button]: true,
    [styles["button--bordered"]]: bordered,
    [styles["button--disabled"]]: disabled || pending,
    [styles["button--orange"]]: orange,
    [styles["button--red"]]: isRed,
    [styles["button--bordered--orange"]]: bordered && orange,
  }, className);

  const handleClick = () => {
    if (activeUIElement === id) nextStep();
  
    if (noDelay)
      onClick();
    else {
      setPending(true);
      onClick();
      setTimeout(() => {
        setPending(false);
      }, 2000);
    }
  };

  return (
    <button
      id={id}
      disabled={disabled || pending}
      className={btnClass}
      onClick={handleClick}
      type={type}
    >
      {children ? (
        children
      ) : (
        <>
          {textBefore} {Image && <img src={Image} alt="" />} {text}
        </>
      )}
    </button>
  );
};

export default Button;
