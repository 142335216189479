import {FC} from 'react'
import expandArrow from '../../../../images/svg/expand-arrow.svg'
import styles from './ExpandArrowButton.module.scss'
import classNames from 'classnames'

type ExpandArrowButtonProps = {
  isOpen: boolean
  onClick: () => void
  className?: string
}

const ExpandArrowButton: FC<ExpandArrowButtonProps> = ({isOpen, onClick, className}) => {
  return (
    <img 
      src={expandArrow} 
      alt="expand"
      className={
        classNames(
          styles["expand-arrow-button"], 
          {
            [styles["expand-arrow-button--down"]]: isOpen
          }, 
          className
        )
      }
      onClick={onClick} 
    />
  )
}

export default ExpandArrowButton