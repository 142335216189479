import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import ModalBlock from "../../ModalBlock";
import Button from "../../Button";

import styles from "./Prize.module.scss";
import ModalContextStore from "../../../../store/Modal";
import { Link } from "react-router-dom";
import { prizeModalStoreContext } from "../../../../store/PrizeModal";
import { postPrizeSetOptions } from "../../../../services/prize";
import useScroller from "../../../hooks/useScroller";
import ScrollArrow from "../../../ui/ScrollArrow/ScrollArrow";

const Prize: React.FC = () => {
  const { closeModal } = useContext(ModalContextStore);
  const { prize } = useContext(prizeModalStoreContext);
  const { ScrollContainer, isShowArrow } = useScroller()

  useEffect(() => {
    if (prize?.status === "OPTIONS_AWAITING" && prize.categoryCode === "ITREV") {
      postPrizeSetOptions(prize.id);
    }
  }, [prize]);

  return (
    <div className={styles.prize}>
      <ScrollContainer>
        <ModalBlock kind="common">
          <div>
            <p className={styles.prize__header}>
              {prize?.reasonForPrize}
            </p>
            <p className={styles.prize__prizeText}>{prize?.winPopupLabel}</p>
            <p className={styles.prize__bottomText}>
              Все выигранные подарки вы найдёте в разделе игры «
              <Link
                to="/prizes"
                onClick={() => closeModal()}>
                  Мои призы
              </Link>»
            </p>
            <Button
              text="За новыми призами!"
              onClick={() => closeModal()}
              orange
            />
          </div>
        </ModalBlock>
      </ScrollContainer>
      {isShowArrow && <ScrollArrow />}
    </div>
  );
};

export default observer(Prize);
