import { BoosterModalType } from "../interfaces/BoosterModal";


export const boosterModals: BoosterModalType = {
  loyalty: [{
    header: 'Программа лояльности',
    text: 'Довольный клиент — это ценный ресурс, который помогает бренду расти и становиться еще лучше.',
    boosters: [
      {
        size: "low", type: "loyalty"
      },
      {
        size: "middle", type: "loyalty"
      },
      {
        size: "high", type: "loyalty"
      },
    ]
  },
  {
    header: 'Реклама',
    text: 'Эти бустеры помогут повысить узнаваемость и доверие к вашему бренду.',
    boosters: [
      {
        size: "low", type: "advertising"
      },
      {
        size: "middle", type: "advertising"
      },
      {
        size: "high", type: "advertising"
      },
    ]
  }],
  maintenance: [{
    header: 'Провести техобслуживание',
    text: 'Эти бустеры защитят объекты связи от аварий на некоторое время.',
    boosters: [
      {
        size: "low", type: "maintenance"
      },
      {
        size: "middle", type: "maintenance"
      },
      {
        size: "high", type: "maintenance"
      },
    ]
  }],
  crash: [{
    header: 'Бесплатно устранить аварию',
    text: 'Работа объекта связи нарушена. Чтобы наладить связь, устраните аварию при помощи бустера.',
    boosters: [
      {
        size: "high", type: "repairCrash", label: "Бустер"
      },
    ]
  }]
};
