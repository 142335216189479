import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { UserStoreContext } from "../../../../store/User";
import Button from "../../Button";

import styles from "./Auth.module.scss";

const BonusWarning: React.FC = () => {
  const { user } = useContext(UserStoreContext);
  
  const onRegistryClickHandler = () => {
    if (window.dataLayer) {
      window.dataLayer.push({ 'event': 'b2с', 'type': 'bonus_click', 'value': 'Присоединиться к программе «Бонус»' })
    }
  }

  return (
    <div className={styles.warning}>
      <h4>Вы еще не подключили программу «Бонус»</h4>
      <p>
        Бонусы – это игровая валюта, увеличивающая ваши шансы на победу.
      </p><br/>
      <p>
        Как их получить? Всё просто.
      </p><br/>
      <p>
        Зарегистрируйтесь в программе «Бонус» и оплачивайте услуги Ростелекома
      </p><br/>
      <p>
        <a href="https://rt.ru/bonus">Подробнее о программе «Бонус»</a>
      </p>
      <a href={user?.providerCode === "OLM" ? "https://msk.rt.ru/bonus" : "https://spb.rt.ru/bonus"}>
        <Button
          onClick={onRegistryClickHandler}
          text="Присоединиться к программе «Бонус»"
          orange
        />
      </a>
    </div>
  );
};

export default observer(BonusWarning);
