import {FC} from 'react'
import styles from './CloseModalButton.module.scss'
import { ReactComponent as CrossIcon } from '../../../images/svg/cross-icon.svg'

interface ICloseModalButtonProps {
  onClick?: () => void
  className?: string
}

const CloseModalButton: FC<ICloseModalButtonProps> = ({onClick, className}) => {
  return (
    <div className={className}>
      <button 
        className={styles["close-modal-button"]}
        onClick={onClick}
      >
        <CrossIcon className={styles["close-modal-button__icon"]}/>
      </button>
    </div>
  )
}

export default CloseModalButton