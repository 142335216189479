import { FC, useContext } from "react";
import classNames from "classnames";

import styles from "./HudButton.module.scss";
import { TutorialStoreContext } from "../../../store/Tutorial";

type hudButtonPropsType = {
  size: "normal" | "big" | "small";
  onClick?: () => void;
  icon?: string;
  warning?: boolean;
  text?: string;
  badge?: number | string | null;
  id?: string;
};

const HudButton: FC<hudButtonPropsType> = ({
  size,
  onClick = () => {},
  icon,
  text,
  warning,
  badge,
  id,
}) => {
  
  const { activeUIElement, nextStep } = useContext(TutorialStoreContext);
  const className = classNames(styles["hud-button"], [
    styles[`hud-button--${size}`],
    {
      [styles[`hud-button--warning`]]: warning,
    },
  ]);

  const handleClick = () => {
    if (activeUIElement === id) nextStep();

    onClick();
  }

  return (
    <button className={className} onClick={handleClick} id={id}>
      {icon && <img src={icon} alt="icon" />}
      {text && <span>{text}</span>}
      {!!badge && (
        <div className={styles["hud-button__badge"]}>
          <span>{badge}</span>
        </div>
      )}
    </button>
  );
};

export default HudButton;
