import { FC, useContext, useEffect } from 'react'
import { financeAssistantNotificationContent } from '../../../../constants/modal-notifications-content'
import { IAssistantNotificaitonModal } from '../../../../interfaces/QuestNotificationsModals'
import { rejectQuest } from '../../../../services/quest'
import ModalContextStore from '../../../../store/Modal'
import ModalNotificationView from '../ModalNotficationView/ModalNotificationView'

const FinanceAssistantNotificationModal: FC<IAssistantNotificaitonModal> = ({questId, modalId}) => {
  const { closeModal, openModal } = useContext(ModalContextStore)

  useEffect(() => {
    if (questId) {
      rejectQuest(questId)
    }
  }, [questId])

  const buttonOnClick = () => {
    openModal("staff/offlineAssistant")
    closeModal(modalId)
  }
  
  return (
    <ModalNotificationView 
      {...financeAssistantNotificationContent}
      buttonOnClickHandler={buttonOnClick}
    />
  )
}

export default FinanceAssistantNotificationModal