import { createContext } from "react";
import { makeObservable, observable, action } from "mobx";

export class ConfirmModal {
  confirmCallback?: () => void;
  confirmHeader?: string;
  confirmText?: string;
  confirmButtonText?: string

  constructor() {
    makeObservable(this, {
      confirmCallback: observable,
      confirmHeader: observable,
      confirmText: observable,
      confirmButtonText: observable,
      setConfirm: action.bound,
    });
  }

  setConfirm(
    onConfirm: () => void,
    modalContent: {
      confirmHeader: string
      confirmText?: string
      confirmButtonText?: string
    }
  ) {
    this.confirmCallback = onConfirm;
    this.confirmHeader = modalContent.confirmHeader;
    this.confirmText = modalContent.confirmText;
    this.confirmButtonText = modalContent.confirmButtonText
  }
}

export const confirmModalStoreContext = createContext(new ConfirmModal());
