import {FC, useContext, useEffect, useState, useCallback} from "react";
import {Graphics} from '@inlet/react-pixi'
import { Point, InteractionEvent } from 'pixi.js';
import { Container, Sprite } from "@inlet/react-pixi";
import {observer} from 'mobx-react-lite'
import { ITimer } from "../../../interfaces/GameState";
import { TimerContext } from "../../../store/Timer";
import { GameStateStoreContext } from "../../../store/GameState";
import uniqid from 'uniqid'
import useTimer from "../../hooks/useTimer";
import { drawTimerCircle } from "../../../utils/timer";
import { relativeCoordinatesForTimers } from "../../../constants/cities";

interface IMarkerProps {
  image: string | null
  position: Point
  timer?: ITimer
  tint?: number
  mouseout?: (event: InteractionEvent) => void
  mouseover?: (event: InteractionEvent) => void
  pointertap?: (event: InteractionEvent) => void
  pointerup?: (event: InteractionEvent) => void
  pointerdown?: (event: InteractionEvent) => void
}

const Marker: FC<IMarkerProps> = ({image, position, timer, tint, mouseout, mouseover, pointertap, pointerdown, pointerup}) => {
  const {subscribe30fps, unsubscribe30fps} = useContext(TimerContext)
  const {currentTime} = useContext(GameStateStoreContext)

  const [timerUniqId, setTimerUniqId] = useState<string>("")

  const { fullDurationMs, elapsedMs, setElapsedMs, isTimerRunning } = useTimer(timer, currentTime);

  useEffect(() => {
    setTimerUniqId(uniqid())
  }, [])
  
  const onTick = useCallback((delta: number) => {
    setElapsedMs(elapsedMs + delta)
  }, [elapsedMs, setElapsedMs])

  useEffect(() => {
    if (timerUniqId) {
      subscribe30fps({id: timerUniqId, fn: onTick})
    }
    return () => {
      if (timerUniqId) {
        unsubscribe30fps({id: timerUniqId, fn: onTick})
      }
    }
  }, [elapsedMs, setElapsedMs, timerUniqId, subscribe30fps, unsubscribe30fps, onTick])

  useTimer(timer, currentTime);

  const drawTimer = useCallback((g) => 
    drawTimerCircle(g, image, isTimerRunning, elapsedMs, fullDurationMs), [image, isTimerRunning, elapsedMs, fullDurationMs])

  return (
    <Container
      x={position.x}
      y={position.y}
    >
      {image &&
        <Sprite 
          tint={tint}
          image={image}
          interactive={true}
          mouseout={mouseout}
          mouseover={mouseover}
          pointerup={pointerup}
          pointertap={pointertap}
          pointerdown={pointerdown}
        />
      }
      <Graphics 
        draw={drawTimer}
        {...relativeCoordinatesForTimers}
      />
    </Container>
  )
}

export default observer(Marker)