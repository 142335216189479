import { FC } from "react";

import styles from "./ErrorMessage.module.scss";
import Button from "../../components/common/Button";
import { IError } from "../../interfaces/Error";
import { errors } from "../../constants/errors";
import { useHistory } from "react-router";
import ROUTES from "../../enums/routes";

const ErrorMessage: FC<IError> = ({
  code
}) => {
  const history = useHistory();

  const handleBackClick = () => {
    if (code === 404)
      history.push(ROUTES.APP);
    else if (code === 500)
      history.go(0);
  };

  return (
    <div className={styles.error}>
      <img src={errors[code].image} alt="" />
      <div className={styles.error__message}>
        <h1>{errors[code].header}</h1>
        <p>{errors[code].message}</p>
        <Button
          text={errors[code].buttonText}
          onClick={handleBackClick}
          noDelay
        />
      </div>
    </div>
  );
};

export default ErrorMessage;
