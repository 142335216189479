import React, { useContext, useEffect, useState } from "react";

import { fastBuyModalStoreContext } from "../../../../store/FastBuyModal";
import ModalFooterSelector from "../../modalFooterSelector/ModalFooterSelector";
import { confirmModalStoreContext } from "../../../../store/ConfirmModal";
import ModalContextStore from "../../../../store/Modal";
import ModalBlock from "../../ModalBlock";
import * as MarketData from "../../../../constants/market";

import {
  buyCrystal,
  getBankBooks,
  getProducts,
} from "../../../../services/store";
import { ToastStoreContext } from "../../../../store/Toast";
import { getBonusBalance } from "../../../../services/user";

import MultipurposeCard from "../../MultipurposeCard/MultipurposeCard";
import Button from "../../Button";

import bonusesIcon from "../../../../images/store/tabs/bonuses-tab-icon.png";
import rublesIcon from "../../../../images/store/tabs/rubles-tab-icon.png";

import storeStyles from "../Market/Market.module.scss";
import modalStyles from "../SimpleModal.module.scss";
import styles from "./FastBuy.module.scss";
import { GameStateStoreContext } from "../../../../store/GameState";
import { MarketStoreContext } from "../../../../store/Market";
import { IMarketCurrencyPack } from "../../../../interfaces/Market";
import { fastBuyCurrency, packSizeType } from "../../../../interfaces/common";
import { observer } from "mobx-react-lite";
import Selectbox from "../../Selectbox/Selectbox";
import { digitsDivide } from "../../../../utils/digitsDivide";
import SplittedNumber from "../../../ui/SplittedNumber/SplittedNumber";
import useScroller from "../../../hooks/useScroller";
import CountBadge from "../../CountBadge/CountBadge";
import ScrollArrow from "../../../ui/ScrollArrow/ScrollArrow";

type fastBuyProps = {
  closeModal: () => void;
  notEnought?: boolean;
};

type bankBooktype = {
  id: string;
  label: string;
};

const FastBuy: React.FC<fastBuyProps> = ({ closeModal, notEnought }) => {
  const { setConfirm } = useContext(confirmModalStoreContext);
  const { openModal } = useContext(ModalContextStore);
  const { pushToast } = useContext(ToastStoreContext);
  const { setCrystal } = useContext(GameStateStoreContext);
  const [bankBook, setBankBook] = useState<string>();
  const [bankBooks, setBankBooks] = useState<bankBooktype[]>();
  const [bonusBalance, setBonusBalance] = useState<number>();
  const { kit } = useContext(fastBuyModalStoreContext);
  const { setMarketCrystalKits, crystalKits } = useContext(MarketStoreContext);
  const { ScrollContainer, isShowArrow } = useScroller();

  useEffect(() => {
    getProducts().then((response) => {
      const products = response.results.products.data;
      setMarketCrystalKits(products.crystal_pack);
    });

    getBonusBalance().then((response) => {
      setBonusBalance(response.results.bonusBalance.data);
    });

    getBankBooks().then((response) => {
      setBankBooks(response?.data.results.buyOptions.data.accounts);
    });
    // we are sure that this hook is called only once when the component is mounted\
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fastBuyCurrencies = MarketData.fastBuyCurrencies;

  const [currencyVariable, setCurrencyVariable] = useState<fastBuyCurrency>(
    "bonus"
  );

  const onCurrencySelected = (value: string) => {
    if (isValueCurrency(value)) {
      setCurrencyVariable(value);
    }
  };

  const isValueCurrency = (value: string): value is fastBuyCurrency => {
    if (value) {
      const arrayofCurrencies: string[] = fastBuyCurrencies.map(
        (currency) => currency.value
      );
      return arrayofCurrencies.indexOf(value) !== -1;
    }
    return false;
  };

  const renderButton = (product: IMarketCurrencyPack) => {
    if (product) {
      const price = product.price?.[currencyVariable]?.toString();

      return (
        <Button>
          <img 
            src={currencyVariable === "bonus" ? bonusesIcon : rublesIcon} 
            alt={currencyVariable} 
          />
          <SplittedNumber value={price || 0} isAdaptive/>
        </Button>
      );
    }
  };

  const getCurrentKitsArray = (productCode: IMarketCurrencyPack["code"]) => {
    return (crystalKits as IMarketCurrencyPack[]).find(
      (product) => product.code?.toLowerCase() === productCode
    );
  };

  const getBuy = (product: IMarketCurrencyPack) => {
    if (product.code)
      buyCrystal(product.code, currencyVariable, bankBook)
        .then((response) => {
          if (response?.data.status === "SUCCESS") {
            pushToast("success", "Покупка прошла успешно!");
            setCrystal(response?.data.results.messages.data[0].scene.crystal);
            closeModal();
          } 
          else if (response?.data.status === "FAIL") {
            if (response.data.problem.code === "U0206") {
              if (currencyVariable === "money")
                openModal("problem/money/balance");
              if (currencyVariable === "bonus")
                openModal("problem/bonus/balance");
            }
            else if (response.data.problem.code === "U0208")
                openModal("problem/money/lock");
            else if (response.data.problem.code === "U0209")
              pushToast("error", response.data.problem.title)
            else
              pushToast("error", "Что-то произошло");
          }
        })
        .catch(() => {
          pushToast("error", "Что-то произошло");
        });
  };

  const buy = (product: IMarketCurrencyPack) => {
    if (product) {
      const price = product.price?.[currencyVariable];
      const count = product.amount;

      if (currencyVariable === "money" && !bankBook) {
        openModal("warning/account/payment");
        return;
      }

      setConfirm(
        () => {
          // коллбек для конфирма
          if (product.code) {
            getBuy(product);
          }
        },
        {
          confirmHeader: `Купить набор кристаллов «${product.label}»?`,
          confirmText: `${count} кристаллов за ${price} ${
                          currencyVariable === "bonus" ? "бонусов" : "рублей"
                        }`
        },
      );
    }

    openModal("confirm");
  };

  return (
    <div className={styles.fastbuy}>
      <ScrollContainer>
        <div className={modalStyles.modal__body}>
          <ModalBlock kind="common">
            <div>
              {currencyVariable === "bonus" ? (
                <div className={styles.fastbuy__score}>
                  <img src={bonusesIcon} alt="" />
                  <h4>Бонусов: {digitsDivide(bonusBalance || 0)}</h4>
                </div>
              ) : (
                <div>
                  {bankBooks && (
                    <Selectbox
                      selected={bankBook}
                      params={bankBooks.map((book) => {
                        return { ...book, label: "Л/с № " + book.label };
                      })}
                      onSelect={setBankBook}
                    />
                  )}
                </div>
              )}
            </div>
          </ModalBlock>
          <ModalBlock kind="common">
            <div className={storeStyles.store__currency}>
              {MarketData.packSizes.map(
                (size: { code: packSizeType; name: string }, index) => {
                  const productCode = "crystal" + size.code;

                  const product = notEnought
                    ? getCurrentKitsArray(
                        productCode as IMarketCurrencyPack["code"]
                      )
                    : kit && kit.toLowerCase() === productCode
                    ? getCurrentKitsArray(
                        productCode as IMarketCurrencyPack["code"]
                      )
                    : null;

                  if (product && bonusBalance) {
                    return (
                      <MultipurposeCard
                        renderButton={() => renderButton(product)}
                        onClickHandler={() => buy(product)}
                        key={index}
                      >
                        <div className={storeStyles.store__currency__header}>
                          <h5>{size.name}</h5>
                        </div>
                        <div className={storeStyles.store__currency_body}>
                          <img src={product.image} alt="" />
                          {product.amount && <CountBadge value={product.amount}/>}
                        </div>
                      </MultipurposeCard>
                    );
                  }
                  return null;
                }
              )}
            </div>
          </ModalBlock>
        </div>
      </ScrollContainer>
      <div className={modalStyles.modal__footer + " " + styles.selector}>
        <ModalFooterSelector
          selected={currencyVariable}
          onSelected={(value) => onCurrencySelected(value)}
          variables={fastBuyCurrencies}
        />
      </div>
      
      {isShowArrow && <ScrollArrow />}
    </div>
  );
};

export default observer(FastBuy);
