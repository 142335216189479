import React, { useContext, useEffect } from "react";

import { observer } from "mobx-react-lite";
import {
  actionDataByType,
  crashImages,
  eventSponsorshipImages,
} from "../../../../constants/Actions";
import { readQuest } from "../../../../services/quest";
import { crashModalStoreContext } from "../../../../store/CrashModal";

import { QuestStoreContext } from "../../../../store/Quest";
import Button from "../../Button";
import ModalBlock from "../../ModalBlock";

import { cityIdToName } from "../../../../constants/cities";
import { IQuest } from "../../../../interfaces/common";
import ModalContextStore from "../../../../store/Modal";

import modalStyles from "../SimpleModal.module.scss";
import styles from "./Quest.module.scss";
import RegularTimer from "../../RegularTimer/RegularTimer";
import useScroller from "../../../hooks/useScroller";
import { blurUpImageType } from "../../../../interfaces/SimpleModal";
import BlurUpImage from "../../BlurUpImage/BlurUpImage";
import ScrollArrow from "../../../ui/ScrollArrow/ScrollArrow";
import { notificationsQuestsTypes } from "../../../../constants/notificationsQuests";

type questPropsType = {
  closeModal: () => void;
};

const Quest: React.FC<questPropsType> = ({ closeModal }) => {
  const { quests, setOpenedQuest, setNewQuests } =
    useContext(QuestStoreContext);
  const { openModal } = useContext(ModalContextStore);
  const { setConstructionName } = useContext(crashModalStoreContext);
  
  const { ScrollContainer, isShowArrow } = useScroller();

  const getQuestPicture = (quest: IQuest, type: "icon" | "banner"): string | blurUpImageType => {
    if (
      quest.questType !== "EVENT_SPONSORSHIP" &&
      quest.questType !== "CRASH"
    ) {
      if (actionDataByType[quest.questType] !== undefined) {
        return actionDataByType[quest.questType][type];
      }
    }
    if (quest.questType === "CRASH") {
      if (quest.params.constructionIndex.indexOf("CITY") !== -1) {
        return crashImages.city[type];
      } else {
        return crashImages.line[type];
      }
    }
    if (quest.questType === "EVENT_SPONSORSHIP") {
      return eventSponsorshipImages[quest.params.eventType][type];
    }
    return "";
  };

  const openQuestInfo = (quest: IQuest) => {
    setOpenedQuest(quest);
    if (quest.questType === "CRASH") {
      setConstructionName(quest.params.constructionName);
      openModal("quest/crash", {modalImage: getQuestPicture(quest, "banner"), chevron: 0, gameObjectId: quest.params.constructionIndex})
    } else {
      openModal("questInfo", {modalImage: getQuestPicture(quest, "banner"), chevron: 0})
    }
  };

  useEffect(() => {
    Promise.all(
      quests.map((quest) => {
        return readQuest(quest);
      })
    ).then(() => {
      setNewQuests(0);
    });
    // we are sure that this hook is called only once when the component is mounted
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderQuestPreviewImage = (image: string | blurUpImageType) => {
    if (typeof image === "string") {
      return <img src={image} alt="" />
    }
    else {
      return (
        <BlurUpImage 
          placeholderImageUrl={image.srcTiny}
          originalImageUrl={image.src}
          alt=""
        />
      )
    }
  } 

  return (
    <div className={styles.quest}>
      <ScrollContainer>
        <div className={modalStyles.modal__body}>
          <div className={styles.questContainer}>
            {!quests.length ? (
              <ModalBlock kind="common">
                <div>
                  <h4>Все квесты закончились</h4>
                  <p>Попробуйте зайти чуть позже</p>
                  <Button onClick={closeModal}>Ок</Button>
                </div>
              </ModalBlock>
            ) : (
              quests.filter(q => !notificationsQuestsTypes.includes(q.questType)).map((quest, index) => {
                return (
                  <ModalBlock key={index} kind="common">
                    <div className={styles.questContainer__description}>
                      <div>
                        {renderQuestPreviewImage(getQuestPicture(quest, "icon"))}
                      </div>
                      <div>
                        <span>
                          {quest.params.constructionIndex && (
                            <span>
                              {cityIdToName[quest.params.constructionIndex]}
                            </span>
                          )}
                        </span>
                        <h4>
                          {quest.questType === "SERVICE_OFFER"
                            ? "Повышение статуса участника программы «Бонус»"
                            : quest.params.eventName}
                        </h4>
                        <Button
                          onClick={() => {
                            openQuestInfo(quest);
                          }}
                        >
                          Подробнее
                        </Button>
                      </div>
                    </div>

                    {quest.timer && quest.timer.endedAt && (
                      <div className={styles.questContainer__timer}>
                        <span>Осталось времени:</span>

                        <div className={styles.questContainer__timer__progressbar}>
                          <RegularTimer timer={quest.timer} isBlue/>
                        </div>
                      </div>
                    )}
                  </ModalBlock>
                );
              })
            )}
          </div>
        </div>
      </ScrollContainer>
      
      {isShowArrow && <ScrollArrow />}
    </div>
  );
};

export default observer(Quest);
