import { FC, useRef } from "react";

import styles from "./Welcome.module.scss";
import layoutStyles from '../../layouts/Promo/Promo.module.scss';
import Button from "../../components/common/Button";
import classNames from "classnames";
import { useHistory } from "react-router";
import AboutContent from "./AboutContent";
import ROUTES from "../../enums/routes";
import backgroundImage from '../../images/promo/game-city-hero.jpg'
import backgroundImageTiny from '../../images/promo/game-city-hero-tiny.jpg'
import useBlurredUpImage from "../../components/hooks/useBlurredUpImage";

const Welcome: FC = () => {
  const { isBlur } = useBlurredUpImage(backgroundImageTiny, backgroundImage)
  const titleRef = useRef<null | HTMLDivElement>(null);
  const history = useHistory();

  const handleMoreClick = () => {
    if (titleRef && titleRef.current)
      titleRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleGameClick = () => {
    history.push(ROUTES.AUTHORIZATION);
  };

  return (
    <div className={classNames([layoutStyles.promo, styles.welcome])}>
      <div className={classNames(styles.welcome__top__wrapper)}>
        <div className={classNames(layoutStyles["promo-hero-bg"], {[layoutStyles["promo-hero-bg--bg-loaded"]]: !isBlur})}/>
        <div className={styles.welcome__info}>
          <h1>Онлайн-игра «Tycoon»</h1>
          <p>Станьте директором виртуальной телеком-компании и выиграйте ценные призы в новой игре от Ростелекома!</p>
          <div className={styles.welcome__info__buttons}>
            <Button
              text="Вступить в игру"
              onClick={handleGameClick}
              noDelay
              orange
            />
            <Button
              text="Подробнее"
              onClick={handleMoreClick}
              bordered
              noDelay
              orange
            />
          </div>
        </div>
      </div>
      <div className={layoutStyles.promo__content} ref={titleRef}>
        <AboutContent />
        <div className={styles.welcome__buttonWrapper}>
          <Button
            text="Вступить в игру"
            onClick={handleGameClick}
            noDelay
            orange
          />
        </div>
      </div>
    </div>
  );
};

export default Welcome;
