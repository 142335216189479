import React from "react";
import { IQuest } from "../../../../interfaces/common";

import styles from "./QuestInfo.module.scss";
import Employee from "../../StaffList/Employee";
import {departmentVariables} from "../../../../utils/departments/departments";

type newEmployeeProps = {
  action: IQuest;
};

const NewEmployee: React.FC<newEmployeeProps> = ({ action }) => {
  return (
    <>
      {action && action.params.departmentType && (
        <>
          <div className={styles.questInfo}>
            <h4>{action.params.eventName}</h4>
            <p>{action.params.eventDescription}</p>
          </div>
          <div className={styles.questInfo__manager}>
            {action.params.employee && (
              <Employee department={action.params.departmentType} person={action.params.employee} />
            )}

            <div>
              <p>{departmentVariables[action.params.departmentType].header}</p>
              <h4>
                {action.params.employee?.employeeName} заменит{" "}
                {action.params.replacementEmployee?.employeeNameGenitive}
              </h4>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default NewEmployee;
