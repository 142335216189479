import { action, makeObservable, observable } from "mobx"
import { createContext } from "react"

class GameIntervalsStore {
  questIntervalId: ReturnType<typeof setInterval> | null = null

  gameStateIntervalId: ReturnType<typeof setInterval> | null = null

  notificationsIntervalId: ReturnType<typeof setInterval> | null = null

  constructor() {
    makeObservable(this, {
      questIntervalId: observable,
      gameStateIntervalId: observable,
      notificationsIntervalId: observable,
      setQuestIntervalId: action.bound,
      setGameStateIntervalId: action.bound,
      setNotificationsIntervalId: action.bound,
      clearIntervals: action.bound
    })
  }

  setQuestIntervalId(questIntervalId: ReturnType<typeof setInterval>) {
    this.questIntervalId = questIntervalId
  }

  setGameStateIntervalId(gameStateIntervalId: ReturnType<typeof setInterval>) {
    this.gameStateIntervalId = gameStateIntervalId
  }

  setNotificationsIntervalId(notificationsIntervalId: ReturnType<typeof setInterval>) {
    this.notificationsIntervalId = notificationsIntervalId
  }

  clearIntervals() {
    if (this.questIntervalId) {
      clearInterval(this.questIntervalId)
      this.questIntervalId = null
    }
    if (this.gameStateIntervalId) {
      clearInterval(this.gameStateIntervalId)
      this.gameStateIntervalId = null
    }
    if (this.notificationsIntervalId) {
      clearInterval(this.notificationsIntervalId)
      this.notificationsIntervalId = null
    }
  }
}

export const gameIntervalsStore = new GameIntervalsStore()

export const GameIntervalsStoreContext = createContext(gameIntervalsStore)
