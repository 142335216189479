import {baseStatuses, communicationLineStatuses, timerTypes} from '../types/statuses'

export type ages = "3G" | "4G" | null;
export interface IGameState {
  trends: ITrends;
  health: number | null;
  age: ages;
  date: string | null;
  maxClients: number;
  totalClients: number;
  currentTime: string;
  crystal: number;
  gold: number;
  network: IGameStateNetwork | null;
  tutorialLevelId: number;
}

export interface IStaff {}

export interface ITrends {
  percent: number | null;
  isPositive: boolean | null;
  isThumbUp: boolean | null;
}

export interface IGameStateResponse {
  crystal: number;
  currentLoyalty: number;
  currentTime: string
  featureLoyalty: number;
  maxClients: number;
  totalCount: number;
  gold: number;
  totalClients: number;
  date: string;
  network: IGameStateNetwork;
  age: ages;
  tutorialLevelId: number;
  score: number;
}

export interface IGameStateNetwork {
  assistantEmployees: IGameStateAssistantEmployees
  cities: IGameStateCity[];
  department: IGameStateDepartment;
  lines: IGameStateLine[];
  epoch: IGameEpoch;
  technology: { [key: string]: ITechnology };
}

export interface ITechnology {
  epoch: string;
  name: string;
  price: {
    GOLD: number;
  };
  profit: number;
  state: technologyState;
  code: string;
}

export type technologyState =
  | "NOT_AVAILABLE"
  | "AVAILABLE"
  | "NEED_UP_LEVEL_DEPARTMENT"
  | "DISCOVERED";

export type epochTypesType = "E_1G" | "E_2G" | "E_3G" | "E_4G" | "E_5G";

export interface IGameEpoch {
  currentDate: string;
  epochName: string;
  epochType: epochTypesType;
  nextEpochDate: string;
  technologyTypes: string[];
  timer: {
    durationSeconds: number;
    endedAt: string;
    priceForSkip: {
      CRYSTAL: number;
    };
    startedAt: string;
    timerType: string;
  };
}

export interface ITimer {     // ниже есть интерфейс IUpLevelTimer надо сделать его универсальным для всех таймеров и заменить здесь
  endedAt: string
  priceForSkip?: {
    CRYSTAL: number
  }
  startedAt: string
  timerType: timerTypes
}

export interface IGameStateBuild {
  questId?: string;
  constructionIndex: string;
  countClients: number;
  currentLevel: IGameStateLevel;
  currentLevelId: number;
  currentLoyalty: number;
  endedAt: string | null;
  featureLoyalty: number;
  indulgenceFromCrashToAt: unknown; //undefined type
  maxClients: number;
  nextLevel: IGameStateLevel | null;
  resolvePrice: number;
  startedAt: string | null;
  throughput: number;
  maintenanceTimer?: ITimer   // приходит во время ТО
  timer?: ITimer 
  load: number  // уровень нагрузки
}

export type assistantTypes = 'FINANCE' | 'SUPPORT'

export interface IGameStateAssistant {
  assistantType: assistantTypes
  remainingSeconds: number | null
  timer: ITimer | null
  price: {
    CRYSTAL: number
  }
}

export interface IGameStateAssistantEmployees {
  FINANCE: IGameStateAssistant
  SUPPORT: IGameStateAssistant
}

export interface IGameStateLine extends IGameStateBuild {
  state: communicationLineStatuses;
}

export interface IGameStateCity extends IGameStateBuild {
  tariffOnHour: number;
  incomingClients: number;
  state: baseStatuses;
}

export interface IGameStateDepartment {
  BUILDERS: IGameStateDepartmentStruck;
  CUSTOMER_SERVICE: IGameStateDepartmentStruck;
  HR: IGameStateDepartmentStruck;
  TECHNICAL: IGameStateDepartmentStruck;
}

export interface IGameStateDepartmentStruck {
  type: string;
  employees: IGameStateEmploye[];
  currentLevel: IGameStateLevel;
  nextLevel: IGameStateLevel | null;
  timer: IUpLevelTimer;
  priceForNewEmployee: {
    GOLD: number
  }
}

export interface IUpLevelTimer {
  endedAt: string;
  priceForSkip: {
    CRYSTAL: number;
  };
  startedAt: string;
  timerType: string;
}

export interface IGameStateEmploye {
  icon?: string;
  currentLevel: IGameStateLevel;
  employeeId: string;
  employeeName: string;
  employeeNameGenitive: string;
  endedAt: string | null;
  nextLevel: IGameStateLevel;
  startedAt: string | null;
  timer: IUpLevelTimer;
  imgType: string
}

export const deparmentVariableKeysVals = ["CUSTOMER_SERVICE", "HR", "TECHNICAL", "BUILDERS"] as const
export type departmentVariableKeysType = typeof deparmentVariableKeysVals[number]

export const otherStaffPageKeysVals = ["OFFLINE_ASSISTANT"] as const
export type otherStaffPageKeysType = typeof otherStaffPageKeysVals[number];

export type staffPagesKeysType = departmentVariableKeysType | otherStaffPageKeysType

export interface IGameStateLevel {
  levelId: number;
  durationUpLevel: number;
  priceUpLevel: { GOLD: number, CRYSTAL: number };
  upLevelPriceOnGold?: number;
  throughput?: number; // idk what is it
}
