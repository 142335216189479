import React, { useContext } from "react";
import { IBooster } from "../../../../interfaces/common";
import { confirmModalStoreContext } from "../../../../store/ConfirmModal";
import ModalContextStore from "../../../../store/Modal";
import Booster from "../../Booster/Booster";

import crystalIcon from "../../../../images/store/currencies/crystal-icon.png";
import coinIcon from "../../../../images/store/currencies/gold-icon.png";

import { GameStateStoreContext } from "../../../../store/GameState";
import { buyBooster } from "../../../../services/store";
import { ToastStoreContext } from "../../../../store/Toast";

import styles from "./Market.module.scss";
import { getInventory } from "../../../../services/scene";
import { InventoryContext } from "../../../../store/Inventory";
import { digitsDivide } from "../../../../utils/digitsDivide";
import { TutorialStoreContext } from "../../../../store/Tutorial";

type kitItem = {
  price: { GOLD?: number; CRYSTAL?: number };
  booster: IBooster;
};

type storeKitProps = {
  name: string;
  items: kitItem[];
  balance: { gold: number; crystal: number };
};

const StoreBoosters: React.FC<storeKitProps> = ({ name, items, balance }) => {
  const { openModal, closeModals, closeModal} = useContext(ModalContextStore);
  const { setConfirm } = useContext(confirmModalStoreContext);
  const { pushToast } = useContext(ToastStoreContext);
  const { setCrystal, isTutorial } = useContext(GameStateStoreContext);
  const { nextStep } = useContext(TutorialStoreContext);
  const { setInventory } = useContext(InventoryContext);

  const buy = (item: kitItem) => {
    buyBooster(
      item.booster.type,
      item.booster.size,
      item.price.GOLD ? "GOLD" : "CRYSTAL"
    )
      .then((response) => {
        if (!response || !response.data) throw new Error();
        if (response?.data.status === "SUCCESS") {
          getInventory().then((response) => {
            setInventory(response.results.inventory.data);
          });
          pushToast("success", "Покупка прошла успешно!");
          setCrystal(response?.data.results.messages.data[0].scene.crystal);
          closeModal()
          if (isTutorial) nextStep().then(() => { closeModals() });
        }
      })
      .catch(() => {
        pushToast("error", "Что-то произошло");
      });
  };

  const onBoosterBuy = (item: kitItem) => {
    setConfirm(
      () => {
        if (item.price.CRYSTAL) {
          if (balance.crystal >= item.price.CRYSTAL) {
            buy(item);
            closeModal()
          } else {
            openModal("notEnoughCrystals");
          }
        } else if (item.price.GOLD) {
          if (balance.gold >= item.price.GOLD) {
            buy(item);
          } else {
            openModal("notEnoughGold");
          }
        }
      },
      {
        confirmHeader: `Купить бустер ?`,
        confirmText: `Бустер за ${digitsDivide(item.price.GOLD || item.price.CRYSTAL || 0)} ${
                      item.price.GOLD ? "монет" : "кристаллов"
                    }`
      }
    );
    openModal("confirm");
  };

  return (
    <div>
      <div className={styles.store__boosters}>
        <div>
          <h2>{name}</h2>
        </div>
        <div>
          {items.map((item, index) => {
            return (
              <Booster
                key={index}
                withSize
                onClick={() => onBoosterBuy(item)}
                buttonIcon={item.price["GOLD"] ? coinIcon : crystalIcon}
                buttonText={
                  item.price["GOLD"]?.toString() ||
                  item.price["CRYSTAL"]?.toString() ||
                  ""
                }
                booster={item.booster}
              ></Booster>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default StoreBoosters;
