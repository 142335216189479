import StaffIcon1 from "../images/staff/staff1.png";
import StaffIcon2 from "../images/staff/staff2.png";
import StaffIcon3 from "../images/staff/staff3.png";
import StaffIcon4 from "../images/staff/staff4.png";
import StaffIcon5 from "../images/staff/staff5.png";
import StaffIcon6 from "../images/staff/staff6.png";
import StaffIcon7 from "../images/staff/staff7.png";
import StaffIcon8 from "../images/staff/staff8.png";
import StaffIcon9 from "../images/staff/staff9.png";
import StaffIcon10 from "../images/staff/staff10.png";
import StaffIcon11 from "../images/staff/staff11.png";
import StaffIcon12 from "../images/staff/staff12.png";
import StaffIcon13 from "../images/staff/staff13.png";
import StaffIcon14 from "../images/staff/staff14.png";
import StaffIcon15 from "../images/staff/staff15.png";
import StaffIcon16 from "../images/staff/staff16.png";
import StaffIcon17 from "../images/staff/staff17.png";
import StaffIcon18 from "../images/staff/staff18.png";
import StaffIcon19 from "../images/staff/staff19.png";
import StaffIcon20 from "../images/staff/staff20.png";
import offlineAssistant1 from "../images/staff/offlineAssistent1.png";
import offlineAssistant2 from "../images/staff/offlineAssistent2.png";

export const employeeImages: {[key: string]: string} = {
  img1: StaffIcon1,
  img2: StaffIcon2,
  img3: StaffIcon3,
  img4: StaffIcon4,
  img5: StaffIcon5,
  img6: StaffIcon6,
  img7: StaffIcon7,
  img8: StaffIcon8,
  img9: StaffIcon9,
  img10: StaffIcon10,
  img11: StaffIcon11,
  img12: StaffIcon12,
  img13: StaffIcon13,
  img14: StaffIcon14,
  img15: StaffIcon15,
  img16: StaffIcon16,
  img17: StaffIcon17,
  img18: StaffIcon18,
  img19: StaffIcon19,
  img20: StaffIcon20,
  offlineAssistant1: offlineAssistant1,
  offlineAssistant2: offlineAssistant2,
};
