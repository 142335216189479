import { employeeImages } from "../../constants/staff";
import { IAssistantsInfo, IDepartment } from "../../interfaces/common";
import { departmentVariableKeysType, staffPagesKeysType } from "../../interfaces/GameState";
import { isInstanceOfIDepartment } from "../type-guards/type-guards";
import coinIcon from '../../images/svg/coin.svg'
import emojiUpIcon from '../../images/svg/emoji-up.svg'

export const staffPagesStaticData: {
  [key in staffPagesKeysType]: IDepartment | IAssistantsInfo
} = {
  CUSTOMER_SERVICE: {
    header: "Клиентский сервис",
    text: "Поддержка",
    value: "CUSTOMER_SERVICE",
    icon: `${process.env.PUBLIC_URL}/assets/staff/sp-icon.svg`,
    texts: [
      "Чтобы улучшить уровень клиентского обслуживания, необходимо чтобы все менеджеры имели максимальный уровень компетенций.",
      "",
      "увеличение лояльности клиентов.",
    ],
  },
  HR: {
    header: "HR и забота о персонале",
    text: "HR",
    value: "HR",
    icon: `${process.env.PUBLIC_URL}/assets/staff/hr-icon.svg`,
    texts: [
      "Чтобы улучшить отдел необходимо, чтобы все сотрудники имели максимальный уровень.",
      "",
      "сокращается отток персонала и повышается качество работы всех отделов. "
    ],
  },
  TECHNICAL: {
    header: "Технический персонал",
    text: "Техники",
    value: "TECHNICAL",
    icon: `${process.env.PUBLIC_URL}/assets/staff/technics-icon.svg`,
    texts: [
      "Чтобы улучшить отдел необходимо, чтобы все сотрудники имели максимальный уровень.",
      "",
      "ускорение времени ремонта аварий"
    ],
  },
  BUILDERS: {
    header: "Строители",
    text: "Строители",
    value: "BUILDERS",
    icon: `${process.env.PUBLIC_URL}/assets/staff/builders-icon.svg`,
    texts: [
      "Чтобы улучшить отдел необходимо, чтобы все сотрудники имели максимальный уровень.",
      "",   // TODO: добавить отдельное поле для влияния на показатели
      "открывается возможность внедрить новую технологию, строить более современные линии и узлы связи."
    ],
  },
  OFFLINE_ASSISTANT: {
    icon: `${process.env.PUBLIC_URL}/assets/staff/offline-assistant-icon.svg`,
    text: "Ассистенты",
    value: "OFFLINE_ASSISTANT",
    assistantsBlocks: [{
        assistant: {
          name: "Виктор",
          avatarUrl: employeeImages.offlineAssistant1,
        },
        header: "Финансовый ассистент",
        description: 
          `Эксперт по финансам
          с опытом работы
          в нескольких крупных
          компаниях. Поможет
          получить прибыль,
          когда вас нет в игре.`,
        offerText: "Нанять ассистента на 24 часа",
        hiredHeader: "Ассистент нанят",
        contractLabel: "Оставшееся время:",
        contractIconUrl: coinIcon,
        productCode: "assistant_financing",
      },
      {
        assistant: {
          name: "Роман",
          avatarUrl: employeeImages.offlineAssistant2,
        },
        header: "Ассистент поддержки",
        description: 
          `Опытный сотрудник,
          прекрасно понимающий
          людей. Разрешает даже
          самые сложные ситуации,
          убеждает абонентов
          остаться и не переходить
          к конкурентам.`,
        offerText: "Нанять ассистента на 24 часа",
        hiredHeader: "Ассистент нанят",
        contractLabel: "Контракт действует:",
        contractIconUrl: emojiUpIcon,
        productCode: "assistant_support",
      }
    ]
  }
};

type departmentVariablesInfoType = {
  [key in departmentVariableKeysType]: IDepartment
}

export const departmentVariables: departmentVariablesInfoType = Object.entries(staffPagesStaticData)
    .filter(([key, value]) => isInstanceOfIDepartment(value))
    .reduce((acc, [key, value]) => {
      return {...acc, [key]: value}
    }, {}) as departmentVariablesInfoType
