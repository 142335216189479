import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";

import City from "./City";
import ConnectionNode from "./ConnectionNode";
import Epoch from "./Epoch/Epoch";
import { IPicturedModal } from "../../../interfaces/PicturedModal";

import Chevron from "../Сhevron/Сhevron";
import { cityIdToName } from "../../../constants/cities";
import { CITIES } from "../../../enums/cities";

import QuestInfo from "./QuestInfo/QuestInfo";
import { LineIdToName } from "../../../constants/communication-lines";
import { COMMUNICATION_LINES } from "../../../enums/communication-lines";

import styles from "./PicturedModal.module.scss";
import { GameStateStoreContext } from "../../../store/GameState";
import ModalContextStore from "../../../store/Modal";
import Tender from "./Tender/Tender";
import { ICommonModal, modalKindType } from "../../../interfaces/common";
import { IGameStateCity, IGameStateLine } from "../../../interfaces/GameState";
import Prize from "./Prize/Prize";
import classNames from "classnames";
import OfflineProfit from "./OfflineProfit/OfflineProfit";
import BlurUpImage from "../BlurUpImage/BlurUpImage";
import MaxClientGrowQuest from "./MaxClientGrowQuest/MaxClientGrowQuest";
import FinanceAssistantNotificationModal from "./FinanceAssistantNotificationModal/FinanceAssistantNotificationModal";
import SupportAssistantNotificationModal from "./SupportAssistantNotificationModal/SupportAssistantNotificationModal";
import CloseModalButton from "../../ui/CloseModalButton/CloseModalButton";

const questKinds = ["questInfo", "quest/crash"];

//add your modal kind there to render body component
const renderBody = (kind: ICommonModal["kind"], buildID = "", modalId?: string, questId?: string) => {
  if (kind === "city") return <City cityID={buildID} />;
  else if (kind === "line") return <ConnectionNode lineID={buildID} />;
  else if (kind === "epoch") return <Epoch />;
  else if (questKinds.includes(kind)) return <QuestInfo />;
  else if (kind === "tender") return <Tender />;
  else if (kind === "prize") return <Prize />;
  else if (kind === "offlineProfit") return <OfflineProfit modalId={modalId as string}/>;
  else if (kind === "questMaxClientGrow") return <MaxClientGrowQuest questId={questId as string}/>;
  else if (kind === "questFinanceAssistant") return <FinanceAssistantNotificationModal modalId={modalId as string} questId={questId as string}/>
  else if (kind === "questSupportAssistant") return <SupportAssistantNotificationModal modalId={modalId as string} questId={questId as string}/>
  else return null;
};

const getModalHeaderText = (kind: ICommonModal["kind"], buildID = ""): string => {
  if(kind === "offlineProfit"){
    return "Вы заработали монеты, пока вас не было"
  }

  if (buildID) {
    if (kind === "city") return cityIdToName[buildID as CITIES];
    else if (
      kind === "line" ||
      (kind === "quest/crash" && buildID.indexOf("LINE") !== -1)
    )
      return `Линия высокоскоростной связи — ${
        LineIdToName[buildID as COMMUNICATION_LINES]
      }`;
    else if (kind === "quest/crash" && buildID.indexOf("CITY") !== -1)
      return `${cityIdToName[buildID as CITIES]}`;
    else return "";
  } else return "";
};

const PicturedModal: React.FC<IPicturedModal> = ({
  image,
  onClose,
  kind,
  chevron,
  buildID,
  modalId,
  questId
}) => {
  const { getEpoch, network, getCity, getLine, isTutorial } = useContext(GameStateStoreContext);
  const [epoch, setEpoch] = useState(getEpoch());
  const { onModalClose, setOnModalClose } = useContext(ModalContextStore);
  
  const imageStyles = classNames(
    styles.image, 
    {
      [styles["image--big"]]: kind === "prize"
    },
    {
      [styles["image--bg-gradient"]]: kind === "prize"
    },
    {
      [styles["image--min"]]: questKinds.includes(kind) || 
                              kind === "tender" || 
                              kind === "epoch" || 
                              kind === "questMaxClientGrow" || 
                              kind === "questSupportAssistant" ||
                              kind === "questFinanceAssistant"
    },
    {
      [styles["image--line-banner"]]: kind === "line"
    }
  );

  const closeButtonStyles = classNames(styles.closeButton,
    {[styles["closeButton--min-top-offset"]]: kind === "line"}
  );

  const [city, setCity] = useState<IGameStateCity>();
  const [line, setLine] = useState<IGameStateLine>();

  useEffect(() => {
    if (kind === "epoch") {
      setEpoch(getEpoch());
    } else if (kind === "city" && buildID) {
      const city = getCity(buildID);
      city && setCity(city);
    }else if(kind === "line" && buildID){
      const line = getLine(buildID);
      line && setLine(line);
    }
  }, [buildID, getCity, getEpoch, getLine, kind, network]);

  const closeModal = () => {
    if (onModalClose) {
      onModalClose();
      setOnModalClose();
    }
    onClose();
  };

  const renderLevelChevron = (level: number) => {
    if (level > 0) {
      return <Chevron value={level}/>
    }
    return null
  };

  const renderChevron = (kindParam: modalKindType, chevron: number | undefined) => {
    if (kindParam === "epoch") {
      if (epoch) return <Chevron value={epoch.index} />
    }
    else if (kindParam === "city") {
      return renderLevelChevron(city?.currentLevel.levelId || 0)
    }
    else if (kindParam === "line") {
      return renderLevelChevron(line?.currentLevel.levelId || 0)
    }
    else if (chevron) {
      return <Chevron value={chevron} />
    }
    return null
  }

  const renderImage = (kindParam: modalKindType) => {
    if (kindParam === "epoch") {
      return <img src={epoch?.image} alt="epoch img" className={styles["banner-img"]}/>
    }
    else {
      if (typeof image === "string") {
        return <img src={image} alt="" className={classNames(styles["banner-img"])}/>
      }
      else {
        return (
          <BlurUpImage 
            placeholderImageUrl={image.srcTiny} 
            originalImageUrl={image.src} 
            alt=""
            className={classNames(styles["banner-img"])}
          />
        )
      }
    }
  }

  const handleCloseButtonClick = () => {
    if (!isTutorial) closeModal()
  }

  return (
    <div className="modal__body">
      <div className={imageStyles}>
        <div className={styles.chevron}>
          {renderChevron(kind, chevron)}
        </div>
        {renderImage(kind)}
        <span className={styles.headerText}>
          {kind === "epoch" && <>{epoch?.epochName}</>}
          {getModalHeaderText(kind, buildID)}
        </span>
        <CloseModalButton 
          className={closeButtonStyles}
          onClick={handleCloseButtonClick}
        />
      </div>
      {renderBody(kind, buildID, modalId, questId)}
     </div>
  );
};

export default observer(PicturedModal);