import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";

import ModalBlock from "../../ModalBlock";

import styles from "./Boosters.module.scss";
import modalStyles from "../SimpleModal.module.scss";
import Booster from "../../Booster/Booster";
import { boosterModals } from "../../../../constants/booster-modals";
import { boosterModalContentType } from "../../../../interfaces/BoosterModal";
import { UseBoosterStoreContext } from "../../../../store/UseBooster";
import { applyBooster, getInventory } from "../../../../services/scene";
import ModalContextStore from "../../../../store/Modal";
import { ToastStoreContext } from "../../../../store/Toast";
import { IBooster } from "../../../../interfaces/common";
import { InventoryContext } from "../../../../store/Inventory";
import { GameStateStoreContext } from "../../../../store/GameState";
import { TutorialStoreContext } from "../../../../store/Tutorial";
import useScroller from "../../../hooks/useScroller";
import ScrollArrow from "../../../ui/ScrollArrow/ScrollArrow";
import { InfoModalContext } from "../../../../store/InfoModal";

const boosterNotNecessaryText = {
  header: "",
  description: "На данный момент нет необходимости использовать этот бустер. Попробуйте применить его на другой город.",
}

const maintenanceBoosterNotNecessary = {
  header: "",
  description: "На данный момент нет возможности применить этот бустер. Сперва необходимо устранить аварию.",
}

interface IBoosters {
  type: boosterModalContentType;
}

const Boosters: React.FC<IBoosters> = ({ type }) => {
  const { constructionId, afterUseBooster } = useContext(UseBoosterStoreContext);
  const { closeModal, openModal, closeModals } = useContext(ModalContextStore);
  const { pushToast } = useContext(ToastStoreContext);
  const {inventory, setInventory} = useContext(InventoryContext);
  const { isTutorial } = useContext(GameStateStoreContext);
  const { nextStep } = useContext(TutorialStoreContext);
  const { setInfo } = useContext(InfoModalContext)

  const { ScrollContainer, isShowArrow } = useScroller();

  const updateInventory = () => {
    getInventory().then((response) => {
      setInventory(response.results.inventory.data);
    });
  };

  useEffect(() => {
    updateInventory();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (booster: IBooster) => {
    if (getBoosterCount(booster)) {
      if (constructionId) {        
        applyBooster(
          booster.type === "repairCrash"
            ? booster.type
            : booster.type +
                booster.size[0].toUpperCase() +
                booster.size.slice(1),
          constructionId
        ).then((res) => {
          if (res?.data.status === "SUCCESS") {
            closeModal();
            afterUseBooster && afterUseBooster();
            pushToast("success", "Бустер применен");
          }
          else {
            openModal("info")
            if (booster.type === "maintenance") {
              setInfo(maintenanceBoosterNotNecessary)  
            }
            else {
              setInfo(boosterNotNecessaryText)
            }
          }
          if (isTutorial) nextStep().then(() => closeModals()); 
        });
      }
    } else {
      openModal("store/booster");
    }
  };

  const getBoosterCount = (booster: { size: string; type: string }): number => {
    if (inventory)
      return (
        inventory.find((item) => {
          if (booster.type === "repairCrash") {
            return item.code === booster.type;
          } else {
            return (
              item.code ===
              booster.type +
                booster.size[0].toUpperCase() +
                booster.size.slice(1)
            );
          }
        })?.amount || 0
      );
    return 0;
  };

  return (
    <div className={styles.boosters__body}>
      <ScrollContainer>
        <div className={modalStyles.modal__body}>
          {boosterModals[type].map((block, i) => (
            <ModalBlock key={i} kind="common">
              <div className={styles.boosters}>
                <h4>{block.header}</h4>
                <p className={styles.boosters__text}>{block.text}</p>
                <div className={styles.boosters__list}>
                  {block.boosters.map((booster, i) => (
                    <Booster
                      buttonText={
                        getBoosterCount(booster) ? "Применить" : "В магазин"
                      }
                      withSize
                      key={i}
                      booster={booster}
                      count={getBoosterCount(booster)}
                      onClick={() => handleClick(booster)}
                      label={booster.label}
                      cityModal
                    />
                  ))}
                </div>
              </div>
            </ModalBlock>
          ))}
        </div>
      </ScrollContainer>
      
      {!isTutorial && isShowArrow && <ScrollArrow />}
    </div>
  );
};

export default observer(Boosters);
