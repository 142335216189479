import { FC, useContext } from "react"
import { observer } from "mobx-react-lite"

import FieldTile from "./common/FieldTile/FieldTile"
import { FieldStoreContext } from "../store/Field"
import { AppStoreContext } from "../store/App"
import Offset from "./Offset"
import { tileWidth, tileHeight, matrixSize } from "../constants/tiles"
import GameFieldBorders from "./common/GameFieldBorders/GameFieldBorders"

interface IFieldProps {
  isTutorial?: boolean
}

const Field: FC<IFieldProps> = ({ children, isTutorial = false }) => {
  const { application, addReadyTile } = useContext(AppStoreContext)
  const { tiles } = useContext(FieldStoreContext)

  return application ? (
    <Offset>
      {tiles.map(({ name }, index) => (
        <FieldTile
          application={application}
          x={(index % matrixSize) * tileWidth}
          y={Math.trunc(index / matrixSize) * tileHeight}
          name={!isTutorial ? name : ''}
          key={name}
          addReadyTile={addReadyTile}
        />
      ))}
      <GameFieldBorders />
      {children}
    </Offset>
  ) : null
}

export default observer(Field)
