import { communicationLineStatuses } from "../../types/statuses";

export function getLineStatusValue(status: communicationLineStatuses): number {
    if (status === "CRASH" || status === "CRASH_RESOLVING") {
        return 3
    }
    else if (status === "NORMAL" || status === "UPPING_LEVEL") {
        return 2
    }
    return 1
}