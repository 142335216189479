import classNames from "classnames";
import React, { useContext } from "react";
import Button from "../../Button";

import implementedIcon from "../../../../images/svg/implemented-icon.svg";
import coinIcon from "../../../../images/svg/coin.svg";

import styles from "./Technologies.module.scss";
import { ITechnology } from "../../../../interfaces/GameState";
import { makeFriendly } from "../../../../utils/balance";
import { TutorialStoreContext } from "../../../../store/Tutorial";
import ModalContextStore from "../../../../store/Modal";
import { digitsDivide } from "../../../../utils/digitsDivide";

type PropsType = {
  type: ITechnology["state"];
  technology: ITechnology;
  onButtonClick: (technology: ITechnology) => void;
  isTutorial?: boolean;
};

const TechnologyCard: React.FC<PropsType> = ({
  type,
  technology,
  onButtonClick,
  isTutorial
}) => {
  const { nextStep, activeUIElement } = useContext(TutorialStoreContext);
  const { closeModals } = useContext(ModalContextStore);
  const cardClass = classNames(styles.card, [styles[`card--${type}`]]);
  const containerClass = classNames(styles.cardContainer, {"highlighted with-arrow right": isTutorial}, {[styles["cardContainer--pointer"]]: type !== "DISCOVERED"});

  const handleCardClick = () => {
    if (type !== "DISCOVERED") {
      onButtonClick(technology);
      if (type === "AVAILABLE" && isTutorial && activeUIElement === 'buyNewTech')
        nextStep().then(() => closeModals());
    }
  };

  return (
    <div className={containerClass} onClick={handleCardClick}>
      <div className={cardClass}>
        {type === "DISCOVERED" && (
          <img src={implementedIcon} alt="implemented-icon"></img>
        )}
        <div className={styles.card__header}>
          <h6>{technology.name}</h6>
        </div>
        <div className={styles.card__body}>
          <p>Пропускная способность:</p>
          <p>
            <span className={styles.card__body__throughput}>
              +{digitsDivide(technology.profit)}
            </span>{" "}
            абонентов
          </p>
        </div>
      </div>
      {type !== "DISCOVERED" &&
        (type === "AVAILABLE" ? (
          <Button
            textBefore={"Внедрить"}
            Image={coinIcon}
            text={`${makeFriendly(technology.price.GOLD)}`}
          />
        ) : type === "NEED_UP_LEVEL_DEPARTMENT" ? (
          <Button
            text={"Обучить персонал"}
          />
        ) : null)}
    </div>
  );
};

export default TechnologyCard;
