import { Point } from "pixi.js"
import { ICommunicationLine } from "../interfaces/CommunicationLine"
import {
  COMMUNICATION_LINES,
} from "../enums/communication-lines"
import { DrawLineBaseOptions } from "../interfaces/DrawLines"
import { ICircleOptions } from "../interfaces/DrawCircles"
import {communicationLineStatuses} from '../types/statuses'
import {markerIcons} from './marker-icons'
import { warningPoints } from "./warning"

export const communicationLineColors: {
  [k in communicationLineStatuses]: number
} = {
  "NORMAL": 0xebff00,
  "UPPING_LEVEL": 0xebff00,
  "DRAFT": 0xffffff,
  "CRASH": 0xff00000,
  "CRASH_RESOLVING": 0xff00000,
  "CAN_BUILD": 0x00ffa3,
}

export const communicationLines: ICommunicationLine[] = [
  {
    points: [
      new Point(650, 2180),
      new Point(300, 2355),
      new Point(300, 2550),
      new Point(1145, 2980),
      new Point(1550, 2770),
      new Point(1530, 2760),
      new Point(1145, 2950),
      new Point(325, 2540),
      new Point(325, 2370),
      new Point(670, 2205),
    ],
    markerIcons: markerIcons,
    lineImages: {
      CAN_BUILD: `${process.env.PUBLIC_URL}/assets/communication-lines/CAN_BUILD/line1-can-build.png`,
      CRASH: `${process.env.PUBLIC_URL}/assets/communication-lines/CRASH/line1-crash.png`,
      NORMAL: `${process.env.PUBLIC_URL}/assets/communication-lines/NORMAL/line1-normal.png`,
    },
    lineImageCoors: new Point(300, 2140),
    uniqName: COMMUNICATION_LINES.LINE_1,
    modalImg: {
      src: `${process.env.PUBLIC_URL}/assets/modals/communication_line_modal.png`,
      srcTiny: `${process.env.PUBLIC_URL}/assets/modals/communication_line_modal-tiny.png`,
    },
    markerCoors: new Point(warningPoints[COMMUNICATION_LINES.LINE_1].x, warningPoints[COMMUNICATION_LINES.LINE_1].y),
  },
  {
    points: [
      new Point(1560, 2802),
      new Point(1145, 3005),
      new Point(1145, 3175),
      new Point(1438, 3350),
      new Point(1438, 3455),
      new Point(665, 3870),
      new Point(665, 3985),
      new Point(1050, 4210),
      new Point(1065, 4195),
      new Point(687, 3970),
      new Point(687, 3885),
      new Point(1459, 3470),
      new Point(1459, 3340),
      new Point(1170, 3168),
      new Point(1170, 3020),
      new Point(1570, 2820),
    ],
    markerIcons: markerIcons,
    lineImages: {
      CAN_BUILD: `${process.env.PUBLIC_URL}/assets/communication-lines/CAN_BUILD/line2-can-build.png`,
      CRASH: `${process.env.PUBLIC_URL}/assets/communication-lines/CRASH/line2-crash.png`,
      NORMAL: `${process.env.PUBLIC_URL}/assets/communication-lines/NORMAL/line2-normal.png`,
    },
    lineImageCoors: new Point(666, 2736),
    uniqName: COMMUNICATION_LINES.LINE_2,
    modalImg: {
      src: `${process.env.PUBLIC_URL}/assets/modals/communication_line_modal.png`,
      srcTiny: `${process.env.PUBLIC_URL}/assets/modals/communication_line_modal-tiny.png`,
    },
    markerCoors: new Point(warningPoints[COMMUNICATION_LINES.LINE_2].x, warningPoints[COMMUNICATION_LINES.LINE_2].y),
  },
  {
    points: [
      new Point(1060, 4230),
      new Point(630, 4440),
      new Point(630, 4570),
      new Point(1620, 5067),
      new Point(1632, 5045),
      new Point(655, 4557),
      new Point(655, 4450),
      new Point(1060, 4250),
    ],
    markerIcons: markerIcons,
    lineImages: {
      CAN_BUILD: `${process.env.PUBLIC_URL}/assets/communication-lines/CAN_BUILD/line3-can-build.png`,
      CRASH: `${process.env.PUBLIC_URL}/assets/communication-lines/CRASH/line3-crash.png`,
      NORMAL: `${process.env.PUBLIC_URL}/assets/communication-lines/NORMAL/line3-normal.png`,
    },
    lineImageCoors: new Point(634, 4188),
    uniqName: COMMUNICATION_LINES.LINE_3,
    modalImg: {
      src: `${process.env.PUBLIC_URL}/assets/modals/communication_line_modal.png`,
      srcTiny: `${process.env.PUBLIC_URL}/assets/modals/communication_line_modal-tiny.png`,
    },
    markerCoors: new Point(warningPoints[COMMUNICATION_LINES.LINE_3].x, warningPoints[COMMUNICATION_LINES.LINE_3].y),
  },
  {
    points: [
      new Point(2530, 2740),
      new Point(2630, 2675),
      new Point(3355, 3060),
      new Point(3340, 3075),
      new Point(2632, 2705),
      new Point(2540, 2760),
    ],
    markerIcons: markerIcons,
    lineImages: {
      CAN_BUILD: `${process.env.PUBLIC_URL}/assets/communication-lines/CAN_BUILD/line4-can-build.png`,
      CRASH: `${process.env.PUBLIC_URL}/assets/communication-lines/CRASH/line4-crash.png`,
      NORMAL: `${process.env.PUBLIC_URL}/assets/communication-lines/NORMAL/line4-normal.png`,
    },
    lineImageCoors: new Point(2465, 2680),
    uniqName: COMMUNICATION_LINES.LINE_4,
    modalImg: {
      src: `${process.env.PUBLIC_URL}/assets/modals/communication_line_modal.png`,
      srcTiny: `${process.env.PUBLIC_URL}/assets/modals/communication_line_modal-tiny.png`,
    },
    markerCoors: new Point(warningPoints[COMMUNICATION_LINES.LINE_4].x, warningPoints[COMMUNICATION_LINES.LINE_4].y),
  },
  {
    points: [
      new Point(4335, 3090),
      new Point(4595, 2960),
      new Point(4595, 2825),
      new Point(2720, 1875),
      new Point(2720, 1725),
      new Point(2930, 1615),
      new Point(2920, 1600),
      new Point(2700, 1710),
      new Point(2700, 1890),
      new Point(4575, 2835),
      new Point(4575, 2940),
      new Point(4330, 3070),
    ],
    markerIcons: markerIcons,
    lineImages: {
      CAN_BUILD: `${process.env.PUBLIC_URL}/assets/communication-lines/CAN_BUILD/line5-can-build.png`,
      CRASH: `${process.env.PUBLIC_URL}/assets/communication-lines/CRASH/line5-crash.png`,
      NORMAL: `${process.env.PUBLIC_URL}/assets/communication-lines/NORMAL/line5-normal.png`,
    },
    lineImageCoors: new Point(2700, 1548),
    uniqName: COMMUNICATION_LINES.LINE_5,
    modalImg: {
      src: `${process.env.PUBLIC_URL}/assets/modals/communication_line_modal.png`,
      srcTiny: `${process.env.PUBLIC_URL}/assets/modals/communication_line_modal-tiny.png`,
    },
    markerCoors: new Point(warningPoints[COMMUNICATION_LINES.LINE_5].x, warningPoints[COMMUNICATION_LINES.LINE_5].y),
  },
  {
    points: [
      new Point(3920, 1585),
      new Point(4130, 1480),
      new Point(4130, 1330),
      new Point(2845, 675),
      new Point(2045, 1080),
      new Point(1390, 748),
      new Point(1390, 685),
      new Point(1670, 550),
      new Point(1660, 535),
      new Point(1367, 670),
      new Point(1367, 758),
      new Point(2050, 1110),
      new Point(2850, 700),
      new Point(4105, 1345),
      new Point(4105, 1465),
      new Point(3910, 1560),
    ],
    markerIcons: markerIcons,
    lineImages: {
      CAN_BUILD: `${process.env.PUBLIC_URL}/assets/communication-lines/CAN_BUILD/line6-can-build.png`,
      CRASH: `${process.env.PUBLIC_URL}/assets/communication-lines/CRASH/line6-crash.png`,
      NORMAL: `${process.env.PUBLIC_URL}/assets/communication-lines/NORMAL/line6-normal.png`,
    },
    lineImageCoors: new Point(1365, 490),
    uniqName: COMMUNICATION_LINES.LINE_6,
    modalImg: {
      src: `${process.env.PUBLIC_URL}/assets/modals/communication_line_modal.png`,
      srcTiny: `${process.env.PUBLIC_URL}/assets/modals/communication_line_modal-tiny.png`,
    },
    markerCoors: new Point(3291, 748),
  },
]

export const activeLineBaseOptions: DrawLineBaseOptions = {
  color: communicationLineColors.NORMAL,
  height: 20,
  dash: 40,
  gap: 10,
}

export const inactiveLineBaseOptions: DrawLineBaseOptions = {
  color: communicationLineColors.CAN_BUILD,
  height: 20,
  dash: 40,
  gap: 30,
}

export const crashedLineBaseOptions: DrawLineBaseOptions = {
  color: communicationLineColors.CRASH,
  height: 20,
  dash: 40,
  gap: 10,
}

export const communicationCenterCircleBaseOptions: Omit<
  ICircleOptions,
  "color"
> = {
  radius: 25,
  borderLineHeight: 20,
}

export const LineIdToName: {[key in COMMUNICATION_LINES]: string} = {
  LINE_1: "Модемск - Телекомск",
  LINE_2: "Телекомск - Усть-Вебово",
  LINE_3: "Усть-Вебово - Быстробайтовск",
  LINE_4: "Телекомск - Онлайнск",
  LINE_5: "Онлайнск - Верхневинск",
  LINE_6: "Верхневинск - Усть-гигабайтск",
}