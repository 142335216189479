import React, { FC, useEffect, useState } from "react";

import styles from './Prizes.module.scss';
import dropdownArrow from "../../images/svg/dropdown-arrow.svg";
import classNames from "classnames";
import { IPrize } from "../../interfaces/Prize";
import { logos_prizes } from "../../constants/logos";
import { getPrizeActivationOptions, postPrizeSetOptions } from "../../services/prize";
import { IDropdownValue } from "../../interfaces/common";
import Select from "react-dropdown-select";
import selectbox from "../Feedback/Feedback.module.scss";
import { parseDate } from "../../utils/parseDate";
import Button from "../../components/common/Button";

const PrizeDropdown: FC<IPrize> = ({
  activateDescription,
  dateWined,
  iconName,
  id,
  winPopupLabel,
  promoCode,
  status,
  categoryCode,
  onActivatePrize,
}) => {
  const [showContent, setShowContent] = useState(false);
  const [activationOptions, setActivationOptions] = useState<IDropdownValue[]>();
  const [selectedOption, setSelectedOption] = useState<IDropdownValue[]>([]);

  const headerStyle = classNames(
    styles.prizes__prize__header__description__header, 
    {[styles["prizes__prize__header__description__header--active"]]: status === "COMPLETED"}
  );

  const dropdownStyle = classNames(
    styles.prizes__prize__dropdown, 
    {[styles["prizes__prize__dropdown--active"]]: showContent}
  );

  const toggleDropdown = () => {
    setShowContent(!showContent);
  };

  useEffect(() => {
    if (status === "OPTIONS_AWAITING") {
      getPrizeActivationOptions(id).then(response => setActivationOptions(response.results?.prizeWined.data.activationOptions?.options));
    }
  }, [status, categoryCode, id]);

  const handleActivation = () => {
    postPrizeSetOptions(id, selectedOption[0]);
    onActivatePrize();
  };

  const getStatus = () => {
    if (status === "COMPLETED") return "Активирован"
    else if (status === "PROCESSING") return "На активации"
    
    return "Не активирован";
  }

  return (
    <div className={styles.prizes__prize}>
      <div className={styles.prizes__prize__header} onClick={toggleDropdown}>
        <div className={styles.prizes__prize__header__imageWrapper}>
          <img src={logos_prizes[iconName]} alt="" />
        </div>
        <div className={styles.prizes__prize__header__description}>
          <p className={headerStyle}>
            <span className={styles["prizes__prize__date-text"]}>{parseDate(dateWined)}</span>
            <span className={styles["prizes__prize__status-text"]}>{getStatus()}</span>
          </p>
          <p>{winPopupLabel}</p>
        </div>
        <img src={dropdownArrow} alt="" className={styles.prizes__prize__header__arrow} />
      </div>
      <div className={dropdownStyle}>
        {activationOptions && (
          <div className={styles.prizes__prize__dropdown__activate}>
            <p>
              На указанный в Личном кабинете почтовый адрес будет отправлено письмо — в нём вы найдёте подробную информацию, как получить приз!
            </p>
            <Select
              placeholder="Выбрать лицевой счёт"
              className={selectbox.select}
              labelField={"label"}
              valueField={"id"}
              values={selectedOption}
              searchable={false}
              onChange={(values) => {
                setSelectedOption(values);
              }}
              name="select"
              options={activationOptions}
            />
            <Button
              text="Активировать"
              onClick={handleActivation}
              noDelay
              orange
            />
          </div>
        )}
        {promoCode && <p className={styles.prizes__prize__dropdown__code}>Промокод: <span>{promoCode}</span></p>}
        <div
          dangerouslySetInnerHTML={{__html: activateDescription}}
          className={styles.prizes__prize__dropdown__activation}
        />
      </div>
    </div>
  );
};

export default PrizeDropdown;
