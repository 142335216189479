import {FC, useContext} from 'react'
import {Graphics as PIXIGraphics} from 'pixi.js'
import {Graphics} from '@inlet/react-pixi'
import { drawLine } from '../../../utils/graphics/lines'
import { observer } from 'mobx-react-lite'
import { FieldStoreContext } from '../../../store/Field'
import { matrixSize } from '../../../constants/tiles'

const GameFieldBorders: FC<{}> = () => {
  const {tileWidth, tileHeight} = useContext(FieldStoreContext)

  const drawBorders = (g: PIXIGraphics) => {
    g.clear()
    drawLine(g, {fromX: 0, fromY: 0, toX: 0, toY: tileHeight * matrixSize, color: 0x00580e, height: 10})
    drawLine(g, {fromX: tileWidth * matrixSize, fromY: 0, toX: tileWidth * matrixSize, toY: tileHeight * matrixSize, color: 0x00580e, height: 10})
  }

  return (
    <>
      <Graphics draw={drawBorders}/>
    </>
  )
}

export default observer(GameFieldBorders)