import { createContext } from "react";
import { makeObservable, observable, action } from "mobx";

export class Toast {
  toasts: { kind: "success" | "error"; text: string }[] = [];

  constructor() {
    makeObservable(this, {
      toasts: observable,
      pushToast: action.bound,
      deleteToast: action.bound,
    });
  }

  deleteToast(index: number) {
    if (this.toasts.length) {
      this.toasts.splice(index, 1);
    }
  }

  pushToast(kind: "success" | "error", text: string) {
    this.toasts.push({
      kind,
      text,
    });
    setTimeout(()=>{
      this.toasts.splice(0, 1);
    }, 3000)
  }
}

export const ToastStoreContext = createContext(new Toast());
