import React from "react";

import styles from "./Auth.module.scss";
import Button from "../../Button";
import classNames from "classnames";
import { PASS_RESET, SIGN_UP } from "../../../../constants/links";

interface IErrorMessage {
  handleProceed: () => void;
}

const ErrorMessage: React.FC<IErrorMessage> = ({
  handleProceed
}) => {
  const handleClick = () => {
    handleProceed();
  };

  return (
    <div className={styles.auth}>
      <h4>Ошибка авторизации</h4>
      <p className={styles.message}>
        Неверно введен логин или пароль.<br/>Пожалуйста, убедитесь в правильности написания и повторите попытку.
        <br/><br/>
        <a href={PASS_RESET}>Забыли пароль?</a>
      </p>
      <div className={classNames(styles.auth__contentLine, styles.auth__contentLine__error)}>
        <Button
          text="Войти ещё раз"
          onClick={handleClick}
          orange
        />
        <a href={SIGN_UP}>
          <Button 
            text="Зарегистрироваться"
            orange
            bordered
            noDelay
          />
        </a>
      </div>
    </div>
  );
};

export default ErrorMessage;
