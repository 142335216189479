import { FC } from 'react'
import { departmentVariableKeysType, IGameStateDepartment } from '../../../../../interfaces/GameState'
import { departmentVariables } from '../../../../../utils/departments/departments'
import SplittedNumber from '../../../../ui/SplittedNumber/SplittedNumber'
import Button from '../../../Button'
import ModalBlock from '../../../ModalBlock'
import RegularTimer from '../../../RegularTimer/RegularTimer'
import StaffList from '../../../StaffList/StaffList'
import Chevron from '../../../Сhevron/Сhevron'
import coinIcon from "../../../../../images/svg/coin.svg";
import styles from "../Staff.module.scss"
import classNames from 'classnames'

interface IDepartmentPageProps {
  departments: IGameStateDepartment
  departmentVariable: departmentVariableKeysType
  getCanUpdateDepartment: () => boolean
  onSkipUpLevelDepartment: () => void
  upLevel: () => void
}

const DepartmentPage: FC<IDepartmentPageProps> = ({
  departments, 
  departmentVariable, 
  getCanUpdateDepartment, 
  onSkipUpLevelDepartment,
  upLevel
}) => {
 
  return (
    <div className={styles.staff}>
      <Chevron
        value={departments[departmentVariable].currentLevel.levelId}
      />
        <ModalBlock kind="common">
          <div>
            <h3 className={styles["staff__department-header"]}>{departmentVariables[departmentVariable].header}</h3>
            <h4>Обучите и повысьте уровень компетенций сотрудника</h4>
            <StaffList
              staff={departments[departmentVariable].employees}
              priceForNewEmployee={departments[departmentVariable].priceForNewEmployee.GOLD}
              department={departmentVariable}
            />
            <div className={styles["staff__description"]}>
              <p>{departmentVariables[departmentVariable].texts[0]}</p>
              <p>{departmentVariables[departmentVariable].texts[1]}</p>
            </div>
            <div className={styles.staff__impact}>
              <b>Влияние на показатели: </b>
              <span className={styles["staff__normal-text"]}>
                {departmentVariables[departmentVariable].texts[2]}
              </span>
            </div>
          </div>
          {
            departments[departmentVariable].nextLevel &&
              <div className={styles.raiseDepartment}>
                <div>
                  <h3
                    className={classNames(
                      {
                        [styles.grayHeader]: !getCanUpdateDepartment()
                      }
                    )}
                  >
                    Повысить отдел до{" "}
                    {departments[departmentVariable].nextLevel?.levelId} уровня
                  </h3>
                </div>
                {departments[departmentVariable].timer?.timerType === "DEPARTMENT_UP_LEVEL"
                  ? (
                    <div className={styles.raiseDepartment__progress}>
                      <RegularTimer onSkip={onSkipUpLevelDepartment} isBlue={true} timer={departments[departmentVariable].timer}/>
                    </div>
                  ) : (
                    <Button
                      disabled={!getCanUpdateDepartment()}
                      onClick={upLevel}
                    >
                      <img src={coinIcon} alt="coin" />
                      <SplittedNumber value={departments[departmentVariable].nextLevel?.priceUpLevel?.GOLD || 0} isAdaptive/>
                    </Button>
                  )  
                }
              </div>
          }
      </ModalBlock>
    </div>
  )
}

export default DepartmentPage