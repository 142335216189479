import React from "react";

import styles from "./Auth.module.scss";
import Button from "../../Button";

interface ITerms {
  handleProceed: () => void;
}

const Terms: React.FC<ITerms> = ({ handleProceed }) => {
  const handleClick = () => {
    if (!localStorage.getItem("terms")) {
      localStorage.setItem("terms", "false");
      handleProceed();
    }
  };

  return (
    <div className={styles.auth}>
      <p className={styles.message}>
        Я ознакомлен(а) и соглашаюсь с{" "}
        <a
          href={process.env.PUBLIC_URL + "/assets/files/" + encodeURIComponent("Правила проведения онлайн-игры Tycoon.pdf")}
          download
        >
          Правилами игры
        </a>
        .
      </p>
      <Button text="Продолжить" onClick={handleClick} orange />
    </div>
  );
};

export default Terms;
