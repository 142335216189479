import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import ModalContextStore from "../../../store/Modal";

import classNames from "classnames";

import Button from "../Button";
import Employee from "./Employee";

import coinIcon from "../../../images/svg/coin.svg";

import {
  departmentVariableKeysType,
  IGameStateEmploye,
} from "../../../interfaces/GameState";

import {
  getGameState,
  newEmployee,
  upLevelEmployee,
} from "../../../services/scene";

import { GameStateStoreContext } from "../../../store/GameState";
import { ToastStoreContext } from "../../../store/Toast";
import { TutorialStoreContext } from "../../../store/Tutorial";
import SplittedNumber from "../../ui/SplittedNumber/SplittedNumber";

import styles from "./StaffList.module.scss";

type StaffListProps = {
  staff: IGameStateEmploye[];
  priceForNewEmployee: number
  department: departmentVariableKeysType;
};

const StaffList: React.FC<StaffListProps> = ({ staff, department, priceForNewEmployee }) => {
  const { openModal, closeModals } = useContext(ModalContextStore);
  const { pushToast } = useContext(ToastStoreContext);
  const { setGameState, gold, isTutorial } = useContext(GameStateStoreContext);
  const { nextStep, activeUIElement } = useContext(TutorialStoreContext);

  const updateGameState = () => {
    getGameState().then(
      (response) => response && setGameState(response.results.scene.data)
    );
  };

  const upLevel = (person: IGameStateEmploye, index: number) => {
    if (
      person.nextLevel.priceUpLevel &&
      gold >= person.nextLevel.priceUpLevel.GOLD
    ) {
      upLevelEmployee(person.employeeId, department)
        .then((response) => {
          if (!response) throw new Error();
          updateGameState();
          pushToast("success", "Обучение сотрудника началось");
        })
        .catch((err) => {
          pushToast("error", "Что-то произошло");
        })
       
    } else {
      openModal("notEnoughGold");
    }
  };

  const getNewEmployee = () => {
    if (priceForNewEmployee && gold >= priceForNewEmployee) {
      newEmployee(department)
        .then((response) => {
          if (!response) throw new Error();
          updateGameState();
          pushToast("success", "Сотрудик нанят");
        })
        .catch((err) => {
          pushToast("error", "Что-то произошло");
        })
        
    } else {
      openModal("notEnoughGold");
    }
    
    if (isTutorial) nextStep().then(() => closeModals());
  };

  const isTutorialObject = (index: number) => {
    return isTutorial && index === 0 && activeUIElement === 'buyEmploeeTrain';
  }

  return (
    <div className={styles.staffList}>
      {staff.map((person, index) => {
        return (
          <Employee
            department={department}
            key={department + index}
            person={person}
            upLevel={(person) => {
              upLevel(person, index);
            }}
          />
        );
      })}
      {staff.length < 3 &&
        [...Array(3 - staff.length)].map((x, index) => {

          const staffClassName = classNames(styles.staffList__person,
            styles["staffList__person--pointer"],
            {
              "highlighted with-arrow right": isTutorialObject(index),
            }
          );

          return (
            <div
              key={index}
              className={staffClassName}
              onClick={() => getNewEmployee()}
            >
              <div className={styles.staffList__person__card} id={isTutorialObject(index) ? 'buyEmploeeTrain' : ''}>
                <span className={styles.staffList__person__card__newEmployee}>
                  Нанять сотрудника
                </span>
              </div>
              <Button>
                <img src={coinIcon} alt="coin" />
                <SplittedNumber value={priceForNewEmployee || 0} isAdaptive/>
              </Button>
            </div>
          );
        })}
    </div>
  );
};

export default observer(StaffList);
