import React, { useContext } from "react";
import { observer } from "mobx-react-lite";

import { ToastStoreContext } from "../../../store/Toast";
import classNames from "classnames";

import checkIcon from "../../../images/toast/check-icon.png";
import closeIcon from "../../../images/toast/close-icon.png";
import errorIcon from "../../../images/toast/error-icon.png";

import styles from "./Toast.module.scss";

const Toast: React.FC = () => {
  const { toasts, deleteToast } = useContext(ToastStoreContext);

  const getClassName = (kind: "error" | "success") => {
    return classNames(styles["toasts__toast"], [
      styles[`toasts__toast--${kind}`],
    ]);
  };

  return (
    <div className={styles.toasts}>
      {toasts.map((toast, index) => {
        return (
          <div key={index} className={getClassName(toast.kind)}>
            <div>
              <img
                src={toast.kind === "success" ? checkIcon : errorIcon}
                alt=""
              />
              <span>{toast.text}</span>
              <button onClick={() => deleteToast(index)}>
                <img src={closeIcon} alt="" />
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default observer(Toast);
