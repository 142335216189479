import { createContext } from "react";
import { makeObservable, observable, action } from "mobx";
import { IIncomingAward } from "../interfaces/Prize";

export class Notifications {
  incomingAward?: IIncomingAward;

  constructor() {
    makeObservable(this, {
        incomingAward: observable,
        setIncomingAward: action.bound,
    });
  }

  setIncomingAward(award: IIncomingAward | undefined) {
    this.incomingAward = award;
  }
}

export const NotificationsContext = createContext(new Notifications());
