import React from "react";
import { cityIdToName } from "../../../../constants/cities";
import { IQuest } from "../../../../interfaces/common";

import styles from "./QuestInfo.module.scss";

type eventSponsorshipProps = {
  action: IQuest;
};
  
const EventSponsorship: React.FC<eventSponsorshipProps> = ({ action }) => {

  return (
    <div>
      {action && (
        <>
          <div className={styles.questInfo}>
            <h4>{action.params.eventName}</h4>
            <p>{action.params.eventDescription}</p>

            <div className={styles.questInfo__addClients}>
              <div>
                <b>Место проведения: </b>
                <p>г. {cityIdToName[action.params.constructionIndex]}</p>
              </div>
              <div>
                <b>Бонус к лояльности: </b>
                <p className={styles["questInfo__addClients__count--plus"]}>
                  +{action.params.addCurrentLoyalty}%
                </p>
              </div>
              <div>
                <b>Прирост абонентов: </b>
                <p>{action.params.addClients}</p>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default EventSponsorship;
