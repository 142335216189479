import classNames from 'classnames'
import {FC} from 'react'
import { shortThousandsFormat } from '../../../utils/balance'
import { digitsDivide } from '../../../utils/digitsDivide'
import styles from './SplittedNumber.module.scss'

type formats = 'thousands' | 'thousands-millions'

interface ISplittedNumberProps {
  value: string | number
  isAdaptive?: boolean
  format?: formats
  className?: string
}

const SplittedNumber: FC<ISplittedNumberProps> = ({ value, isAdaptive, format, className }) => {

  // проверяет длинее ли число 6 разрядов
  const isBigNumber = () => {
    const num = Number(value)
    return num > 999999
  }

  const renderNumber = () => {
    if (format === 'thousands') {
      return shortThousandsFormat(Number(value))
    }
    return digitsDivide(value)
  }

  return (
    <span 
      className={classNames(
        styles["splitted-number"], 
        {
          [styles["splitted-number--low"]]: isBigNumber() && isAdaptive
        },
        className
      )}
    >
      {renderNumber()}
    </span>
  )
}

export default SplittedNumber