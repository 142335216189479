import ROUTES from "../enums/routes";
import { INavigationItem } from "../interfaces/common";

export const navItems: INavigationItem[] = [
  {
    title: "Tycoon",
    route: ROUTES.APP
  },
  {
    title: "Об игре",
    route: ROUTES.ABOUT
  },
  {
    title: "Мои призы",
    route: ROUTES.PRIZES
  },
  {
    title: "Лидерборд",
    route: ROUTES.LEADERBOARD
  },
  {
    title: "История",
    route: ROUTES.HISTORY
  },
  {
    title: "Написать нам",
    route: ROUTES.FEEDBACK
  }
];
