import PIXI, { Point } from "pixi.js"
import {
  IDrawLineOptions,
  IDrawDashedLineOptions,
} from "../../interfaces/DrawLines"

export function drawLine(graphics: PIXI.Graphics, options: IDrawLineOptions) {
  graphics.moveTo(options.fromX, options.fromY)
  graphics.lineStyle(options.height, options.color)
  graphics.lineTo(options.toX, options.toY)
}

// возвращает катеты, гипотенузу, синус и косинус
// прилежащего угла гипотенузы прямоугольного треугольника
// (точка прямого угла - пересечения линий пареллелльных оси и проходящих через исходные точки)
export function getABCSinCos(
  from: PIXI.Point,
  to: PIXI.Point
): { sin: number; cos: number; a: number; b: number; c: number } {
  const a = to.x - from.x
  const b = to.y - from.y
  const c = Math.sqrt(a * a + b * b)
  const sin = b / c
  const cos = a / c
  return { a, b, c, sin, cos }
}

export function drawDashedLine(
  graphics: PIXI.Graphics,
  options: IDrawDashedLineOptions
) {
  graphics.moveTo(options.fromX, options.fromY)
  graphics.lineStyle(options.height, options.color)
  const { c, sin, cos } = getABCSinCos(
    new Point(options.fromX, options.fromY),
    new Point(options.toX, options.toY)
  )
  let currentLineLegnth = 0
  let isPrevDash = false
  while (currentLineLegnth + (isPrevDash ? options.gap : options.dash) <= c) {
    if (isPrevDash) {
      currentLineLegnth += options.gap
      graphics.moveTo(
        options.fromX + currentLineLegnth * cos,
        options.fromY + currentLineLegnth * sin
      )
      isPrevDash = false
    } else {
      graphics.drawCircle(
        options.fromX + currentLineLegnth * cos,
        options.fromY + currentLineLegnth * sin,
        0.5
      )
      currentLineLegnth += options.dash
      graphics.lineTo(
        options.fromX + currentLineLegnth * cos,
        options.fromY + currentLineLegnth * sin
      )
      graphics.drawCircle(
        options.fromX + currentLineLegnth * cos,
        options.fromY + currentLineLegnth * sin,
        0.5
      )
      isPrevDash = true
    }
  }
}
