import { createContext } from "react";
import { action, makeObservable, observable } from "mobx";
import {
  crystalPackCodes,
  goldPackCodes,
  IMarketCurrencyPack,
} from "../interfaces/Market";
import { currencyPacks } from "../constants/market";

export class Market {
  goldKits: IMarketCurrencyPack[] = [];
  crystalKits: IMarketCurrencyPack[] = [];

  constructor() {
    makeObservable(this, {
      goldKits: observable,
      crystalKits: observable,
      setMarketCrystalKits: action.bound,
      setMarketGoldKits: action.bound,
    });
  }

  setMarketCrystalKits(crystalKits: IMarketCurrencyPack[]) {
    this.crystalKits = crystalKits.map((kit) => {
      return {
        ...currencyPacks["crystal"][kit.code as crystalPackCodes],
        ...kit,
      };
    });
  }

  setMarketGoldKits(goldKits: IMarketCurrencyPack[]) {
    this.goldKits = goldKits.map((kit)=>{
        return {
            ...currencyPacks["gold"][kit.code as goldPackCodes],
            ...kit,
          };
    });
  }

}

export const MarketStoreContext = createContext(new Market());
