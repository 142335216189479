import { createContext } from "react"
import { Application } from "pixi.js"
import { makeObservable, observable, action, computed } from "mobx"
import { matrixSize } from "../constants/tiles"

export class App {
  application?: Application

  isTilesLoading: boolean = false

  readyTilesCount: number = 0

  maxTilesCount: number = matrixSize * matrixSize

  constructor() {
    makeObservable(this, {
      application: observable.ref,
      mount: action.bound,
      unmount: action.bound,
      isTilesLoading: observable,
      readyTilesCount: observable,
      maxTilesCount: observable,
      startLoading: action.bound,
      stopLoading: action.bound,
      addReadyTile: action.bound,
      currentTilesLoadingPercent: computed,
    })
  }

  mount(application: Application) {
    this.application = application
    this.startLoading()
  }

  unmount() {
    this.application = undefined
    this.readyTilesCount = 0
  }

  startLoading() {
    if (!this.isTilesLoading) {
      this.isTilesLoading = true
    }
  }

  stopLoading() {
    this.isTilesLoading = false
  }

  addReadyTile() {
    this.readyTilesCount += 1
    if (this.readyTilesCount === this.maxTilesCount) {
      this.stopLoading()
    }
  }

  get currentTilesLoadingPercent() {
    return (this.readyTilesCount / this.maxTilesCount) * 100
  }
}

export const appStore = new App()

export const AppStoreContext = createContext(appStore)
