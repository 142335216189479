import epoch1Image from "../../images/epoch/epoch1.png";
import epoch2Image from "../../images/epoch/epoch2.png";
import epoch3Image from "../../images/epoch/epoch3.png";
import epoch4Image from "../../images/epoch/epoch4.png";
import epoch5Image from "../../images/epoch/epoch5.png";

import { epochTypesType } from "../../interfaces/GameState";

type epochType = {
  index: number;
  epochType: epochTypesType;
  epochName?: string;
  image: string;
  texts: string[];
};

const epochs: epochType[] = [
  {
    index: 1,
    epochType: "E_1G",
    image: epoch1Image,
    texts: [
      "Происходит зарождение технологий доступа в Интернет в России. Для доступа в Интернет используется телефонная линия. Невысокая скорость передачи данных, невысокая стабильность соединения. В 1993 г. завершается официальная регистрация компании Ростелеком, которая приступает к расширению и модернизации собственной цифровой магистральной сети.",
    ],
  },
  {
    index: 2,
    epochType: "E_2G",
    image: epoch2Image,
    texts: [
      "Цифровая абонентская линия — технология высокоскоростного доступа в интернет с использованием телефонной сети. Высокая скорость передачи данных и стабильность соединения делают ее привлекательнее dial-up Интернета. В 2006 году Ростелеком завершает постройку высокоскоростного наземного канала Благовещенск – Хэйхэ на основе современной технологии DWDM и становится магистральным оператором связи.",
    ],
  },
  {
    index: 3,
    epochType: "E_3G",
    image: epoch3Image,
    texts: [
      "Высокая скорость передачи данных и повышенная отказоустойчивость делают Fast Ethernet привлекательным для абонентов, а низкая стоимость кабеля делает эту технологию интересной для оператора связи, ускоряя возможности цифровизации. В декабре 2009 года Ростелеком открывает интернет-портал Госуслуги, предназначенный для обеспечения доступа граждан к информации и услугам, предоставляемых российскими государственными структурами.",
    ],
  },
  {
    index: 4,
    epochType: "E_4G",
    image: epoch4Image,
    texts: [
      "Технология пассивных оптических сетей. Ее основными преимуществами являются высокоскоростное соединение с Интернетом и высокая стабильность. Ростелеком продолжает развивать Волоконно-оптические линии связи, повышая стабильность и скорость интернет-соединения, что позволяет предоставлять конечным пользователям все более развитые цифровые сервисы. В 2014 году Ростелеком назначен единственным исполнителем федеральной программы ликвидации цифрового неравенства, призванной обеспечить высокоскоростным доступом в Интернет всех граждан страны, включая труднодоступные и малонаселенные пункты.",
    ],
  },
  {
    index: 5,
    epochType: "E_5G",
    epochName: "Наше время",
    image: epoch5Image,
    texts: [
      "Абоненты могут подключить Интернет со скоростями, превышающими 1 Гбит/сек. Эра характеризуется глобальным ростом и развитием цифровых сервисов и онлайн порталов. Активно развиваются технологии интернета вещей (IoT) и проекты умных городов. В марте 2018 года Ростелеком, Nokia и Фонд «Сколково» запускают первую в России открытую опытную зону сети нового поколения 5G.",
    ],
  },
];

const getCurrentEpoch = (epochType: epochTypesType) => {
  return (
    epochs.find((epoch) => {
      return epoch.epochType === epochType;
    }) || epochs[0]
  );
};

export default getCurrentEpoch;
