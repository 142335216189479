import React, { useContext, useEffect } from "react";
import { cityIdToName } from "../../../../constants/cities";
import { IQuest } from "../../../../interfaces/common";
import { getQuest, rejectQuest } from "../../../../services/quest";
import ModalContextStore from "../../../../store/Modal";
import { QuestStoreContext } from "../../../../store/Quest";
import { ToastStoreContext } from "../../../../store/Toast";

import styles from "./QuestInfo.module.scss";

type clientsGoneProps = {
  action: IQuest;
};

const ClientsGone: React.FC<clientsGoneProps> = ({ action }) => {

  const {setOnModalClose} = useContext(ModalContextStore)
  const { setQuests } = useContext(QuestStoreContext);
  const { pushToast } = useContext(ToastStoreContext);

  useEffect(() => {
    setOnModalClose(()=>{
      rejectQuest(action.id).then(() => {
        getQuest()
        .then((response) => {
          if (!response) throw new Error();
          setQuests(response.results.quests.data);
        })
        .catch((err) => {
          pushToast("error", "Соединение потеряно");
        });
      });
    })
    // we are sure that this hook is only called when partnerVariable changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      {action && (
        <>
          <div className={styles.questInfo}>
            <h4>{action.params.eventName}</h4>
            <p>{action.params.eventDescription}</p>

            <div className={styles.questInfo__addClients}>
              <div>
                <b>Место проведения: </b>
                <p>г. {cityIdToName[action.params.constructionIndex]}</p>
              </div>
              <div>
                <b>Потеря абонентов: </b>
                <p className={styles["questInfo__addClients__count--minus"]}>
                  {action.params.addClients}
                </p>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ClientsGone;
