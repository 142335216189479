import { ICityObject } from "../interfaces/CityObject";
import { CITIES } from "../enums/cities";
import { markerIcons } from "./marker-icons";
import { Point } from "@pixi/math";
import { warningPoints } from "./warning";

export const gameCitites: ICityObject[] = [
  {
    resource: `${process.env.PUBLIC_URL}/assets/cities/modemsk.png`,
    modalImg: {
      src: `${process.env.PUBLIC_URL}/assets/modals/cities/modemsk-modal-banner.png`,
      srcTiny: `${process.env.PUBLIC_URL}/assets/modals/cities/modemsk-modal-banner-tiny.png`,
    },
    building: {
      value: 'mvideo',
      xPos: 680,
      yPos: 188,
      resource: `${process.env.PUBLIC_URL}/assets/cities/modemsk-mvideo.png`,
    },
    xPos: 625,
    yPos: 1891,
    markerIcons: markerIcons,
    markerCoors: new Point(warningPoints[CITIES.CITY_1].x, warningPoints[CITIES.CITY_1].y),
    uniqName: CITIES.CITY_1,
  },
  {
    resource: `${process.env.PUBLIC_URL}/assets/cities/telekomsk.png`,
    modalImg: {
      src: `${process.env.PUBLIC_URL}/assets/modals/cities/telekomsk-modal-banner.png`,
      srcTiny: `${process.env.PUBLIC_URL}/assets/modals/cities/telekomsk-modal-banner-tiny.png`,
    },
    xPos: 1516,
    yPos: 2491,
    building: {
      value: "aliexpress",
      resource: `${process.env.PUBLIC_URL}/assets/cities/telekomsk-aliexpress.png`,
      xPos: 575,
      yPos: 200,
    },
    markerIcons: markerIcons,
    markerCoors: new Point(warningPoints[CITIES.CITY_2].x, warningPoints[CITIES.CITY_2].y),
    uniqName: CITIES.CITY_2,
  },
  {
    resource: `${process.env.PUBLIC_URL}/assets/cities/ust-vebovo.png`,
    modalImg: {
      src: `${process.env.PUBLIC_URL}/assets/modals/cities/ust-vebovo-modal-banner.png`,
      srcTiny: `${process.env.PUBLIC_URL}/assets/modals/cities/ust-vebovo-modal-banner-tiny.png`,
    },
    building: {
      value: "litres",
      resource: `${process.env.PUBLIC_URL}/assets/cities/ust-vebovo-litres.png`,
      xPos: 480,
      yPos: 170,
    },
    xPos: 1025, 
    yPos: 3935,
    markerIcons: markerIcons,
    markerCoors: new Point(warningPoints[CITIES.CITY_3].x, warningPoints[CITIES.CITY_3].y),
    uniqName: CITIES.CITY_3,
  },
  {
    resource: `${process.env.PUBLIC_URL}/assets/cities/bistrobaytovsk.png`,
    modalImg: {
      src: `${process.env.PUBLIC_URL}/assets/modals/cities/bistrobaytovsk-modal-banner.png`,
      srcTiny: `${process.env.PUBLIC_URL}/assets/modals/cities/bistrobaytovsk-modal-banner-tiny.png`,
    },
    xPos: 1595,
    yPos: 4755,
    markerIcons: markerIcons,
    markerCoors: new Point(warningPoints[CITIES.CITY_4].x, warningPoints[CITIES.CITY_4].y),
    uniqName: CITIES.CITY_4,
  },
  {
    resource: `${process.env.PUBLIC_URL}/assets/cities/onlinemsk.png`,
    building: {
      value: "sephora",
      resource: `${process.env.PUBLIC_URL}/assets/cities/onlinemsk-sephora.png`,
      xPos: 205,
      yPos: 170,
    },
    modalImg: {
      src: `${process.env.PUBLIC_URL}/assets/modals/cities/onlinemsk-modal-banner.png`,
      srcTiny: `${process.env.PUBLIC_URL}/assets/modals/cities/onlinemsk-modal-banner-tiny.png`,
    },
    xPos: 3314,
    yPos: 2780,
    markerIcons: markerIcons,
    markerCoors: new Point(warningPoints[CITIES.CITY_5].x, warningPoints[CITIES.CITY_5].y),
    uniqName: CITIES.CITY_5,
  },
  {
    resource: `${process.env.PUBLIC_URL}/assets/cities/verhnevinsk.png`,
    modalImg: {
      src: `${process.env.PUBLIC_URL}/assets/modals/cities/verhnevinsk-modal-banner.png`,
      srcTiny: `${process.env.PUBLIC_URL}/assets/modals/cities/verhnevinsk-modal-banner-tiny.png`,
    },
    xPos: 2895, 
    yPos: 1290,
    markerIcons: markerIcons,
    markerCoors: new Point(warningPoints[CITIES.CITY_6].x, warningPoints[CITIES.CITY_6].y),
    uniqName: CITIES.CITY_6,
  },
  {
    resource: `${process.env.PUBLIC_URL}/assets/cities/ust-gigabaytovsk.png`,
    modalImg: {
      src: `${process.env.PUBLIC_URL}/assets/modals/cities/ust-gigabaytovsk-modal-banner.png`,
      srcTiny: `${process.env.PUBLIC_URL}/assets/modals/cities/ust-gigabaytovsk-modal-banner-tiny.png`,
    },
    building: {
      value: "lamoda",
      resource: `${process.env.PUBLIC_URL}/assets/cities/ust-gigabaytovsk-lamoda.png`,
      xPos: 460,
      yPos: 233,
    },
    xPos: 1630,
    yPos: 230,
    markerIcons: markerIcons,
    markerCoors: new Point(warningPoints[CITIES.CITY_7].x, warningPoints[CITIES.CITY_7].y),
    uniqName: CITIES.CITY_7,
  },
];

export const cityIdToName: { [key in CITIES]: string } = {
  CITY_1: "Модемск",
  CITY_2: "Телекомск",
  CITY_3: "Усть-Вебово",
  CITY_4: "Быстробайтовск",
  CITY_5: "Онлайнск",
  CITY_6: "Верхневинск",
  CITY_7: "Усть-Гигабайтск",
};

export const relativeCoordinatesForTimers = {
  x: -162.5,
  y: -160
}