import { FC } from "react";
import Select from "react-dropdown-select";
import styles from "./Selectbox.module.scss";
import './SelectboxCustom.scss'

type selectItem = {
  id: string
  label: string
}

type selectboxProps = {
  params: selectItem[];
  onSelect: (value: string) => void;
  selected?: string;
};

const Selectbox: FC<selectboxProps> = ({ params, onSelect, selected }) => {

  const selectOnChange = (values: selectItem[]) => {
    onSelect(values[0].id)
  }

  return (
    <Select
      searchable={false}
      placeholder="Выбрать лицевой счёт"
      className={styles.selectbox}
      color={"inherit"}
      labelField={"label"}
      valueField={"id"}
      values={[]}
      onChange={selectOnChange}
      options={params}
    />
  );
};

export default Selectbox;
