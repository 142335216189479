import { FC } from 'react'
import { ISimplePrize } from '../../../interfaces/Prize'
import styles from './SimplePrize.module.scss'
import classNames from 'classnames'

interface ISimplePrizeProps extends ISimplePrize {
  className?: string
}

const SimplePrize: FC<ISimplePrizeProps> = ({icon, text, className}) => {
  return (
    <div className={classNames(styles["simple-prize"], className)}>
      <div className={styles["simple-prize__icon-wrapper"]}>
        <img 
          src={icon} 
          alt="icon"
          className={styles["simple-prize__icon"]}
        />
      </div>
      <div className={styles["simple-prize__text"]}>
        {text}
      </div>
    </div>
  )
}

export default SimplePrize