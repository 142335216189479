import { createContext } from "react";
import { makeObservable, observable, action } from "mobx";

export class CrashModal {
  constructionName: string = "";

  constructor() {
    makeObservable(this, {
      constructionName: observable,
      setConstructionName: action.bound,
    });
  }

  setConstructionName(constructionName: string) {
    this.constructionName = constructionName;
  }
}

export const crashModalStoreContext = createContext(new CrashModal());
