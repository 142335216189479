import React, { useContext } from "react"
import { Container } from "@inlet/react-pixi"
import {observer} from 'mobx-react-lite'
import Field from "./Field"
import CityObject from "./common/CityObject"
import CommunicationLine from "./common/CommunicationLine"
import { gameCitites } from "../constants/cities"
import { GameStateStoreContext } from "../store/GameState"
import { markerIcons } from "../constants/marker-icons"
import { FieldStoreContext } from "../store/Field"

import { v4 as uuid } from 'uuid';

const Game: React.FC = () => {
  const { getCities, communicationLinesArrayForRender } = useContext(GameStateStoreContext);
  const { scale, offsetX } = useContext(FieldStoreContext);
  const citiesData = getCities

  return (
    <Container position={{x: offsetX > 0 ? offsetX : 0, y: 52}} scale={scale}>
      <Field>
        {gameCitites.map((item, i) => (
          <CityObject
            key={item.uniqName}
            resource={item.resource}
            xPos={item.xPos}
            yPos={item.yPos}
            building={item.building}
            modalImg={item.modalImg}
            uniqName={item.uniqName}
            markerIcons={markerIcons}
            markerCoors={item.markerCoors}
            status={citiesData.length === gameCitites.length ? citiesData[i].state : "DRAFT"}
            maintenanceTimer={citiesData.length === gameCitites.length ? citiesData[i].maintenanceTimer : undefined}
            timer={citiesData.length === gameCitites.length ? citiesData[i].timer : undefined}
          />
        ))}
        {communicationLinesArrayForRender.map((lineData) => (
          <CommunicationLine key={uuid()} {...lineData} />
        ))}
      </Field>
    </Container>
  )
}

export default observer(Game)
