import React, { useContext, useEffect, useState } from "react";

import Slider from "rc-slider";
import RegularTimer from "../../RegularTimer/RegularTimer";
import { ToastStoreContext } from "../../../../store/Toast";

import { UseBoosterStoreContext } from "../../../../store/UseBooster";
import Estimation from "../../Estimation/Estimation";
import ModalBlock from "../../ModalBlock";
import Button from "../../Button";
import GradientProgressBar from "../../GradientProgressBar";
import ModalContextStore from "../../../../store/Modal";
import { GameStateStoreContext } from "../../../../store/GameState";
import {
  changeTariff,
  getGameState,
  skipCrashResolve,
  skipUpLevel,
  upBuildingLevel,
} from "../../../../services/scene";
import coinImage from "../../../../images/store/currencies/gold-icon.png";
import crystalIcon from "../../../../images/svg/diamond.svg";

import uppingLevelIcon from "../../../../images/timers/uppingLevel.png";
import maintenanceIcon from "../../../../images/timers/maintenance.png";
import crashResolvingIcon from "../../../../images/timers/crashResolving.png";
import infoBadgeIcon from "../../../../images/hud/infoBadge-icon.png";

import Crash from "../../Crash/Crash";
import { getQuestInfo } from "../../../../services/quest";
import { IQuest } from "../../../../interfaces/common";

import styles from "./City.module.scss";
import "rc-slider/assets/index.css";
import { InfoModalContext } from "../../../../store/InfoModal";
import { cityIdToName } from "../../../../constants/cities";
import { CITIES } from "../../../../enums/cities";
import { TutorialStoreContext } from "../../../../store/Tutorial";
import classNames from "classnames";
import SplittedNumber from "../../../ui/SplittedNumber/SplittedNumber";
import useScroller from "../../../hooks/useScroller";
import ScrollArrow from "../../../ui/ScrollArrow/ScrollArrow";

const MAX = 199;
const MIN = 99;

interface ICity {
  cityID: string;
}

//backend accept value from 0,5 to 1,5 with step 0,01
const converTariffValue = (value: number, isUpload = false) => {
  return isUpload ? (value - 49) / 100 : value * 100 + 49;
};

const SKIP_TUTORIAL_TIMER = {
  startedAt: '2021-11-10T01:00:00.347Z',
  endedAt: '3021-11-10T23:59:00.347Z',
  priceForSkip: {CRYSTAL: 3},
  timerType: "UPPING_LEVEL",
}

const City: React.FC<ICity> = ({ cityID }) => {
  const [currentTariff, setCurrentTariff] = useState<number | null>(null);
  const { setGameState, gold, crystal, isTutorial } = useContext(GameStateStoreContext);
  const { openModal, closeModal } = useContext(ModalContextStore);
  const { pushToast } = useContext(ToastStoreContext);
  const { getCity } = useContext(GameStateStoreContext);
  const { activeUIElement, nextStep } = useContext(TutorialStoreContext);
  const [crash, setCrash] = useState<IQuest>();
  const { setInfo } = useContext(InfoModalContext);
  const { setConstruction, setAfterUseBooster } = useContext(UseBoosterStoreContext);

  const { ScrollContainer, isShowArrow } = useScroller();

  const handleLastChange = () => {
    if (currentTariff) {
      changeTariff(cityID, converTariffValue(currentTariff, true));
    }
  };

  const cityInfo = getCity(cityID);
  // undefined coz of library types(Slider value takes only number or undefined)
  const tariff =
    currentTariff ||
    (cityInfo ? converTariffValue(cityInfo.tariffOnHour) : undefined);
  const isLineCrash = cityInfo?.state === "CRASH" && crash;
  const isCityOpen = (cityInfo?.currentLevelId || 0) > 0;

  const buildNodeClassName = classNames(
    { "highlighted with-arrow bottom": activeUIElement === 'buildNode' || activeUIElement === 'upLoyalty' }
  );
  const processTimerClassName = classNames(
    { "highlighted with-arrow bottom": activeUIElement === 'forceBuildNode' }
  );


  const crystalEnought =
    cityInfo &&
    cityInfo.timer?.priceForSkip?.CRYSTAL &&
    cityInfo.timer?.priceForSkip?.CRYSTAL <= crystal;

  useEffect(() => {
    if (cityInfo && cityInfo.state === "CRASH" && cityInfo.questId) {
      getQuestInfo(cityInfo.questId).then((response) => {
        setCrash(response.results.quests.data);
      });
    }
  }, [cityInfo, getCity]);

  const loadInfo = {
    header: "Нагрузка на узел связи",
    description:
      "Чем больше абонентов подключены к сетям вашей телеком-компании, тем выше нагрузка на узлы и линии связи. Перегрузки приводят к снижению качества предоставляемых услуг и, как следствие, к снижению лояльности абонентов и ваших доходов. Чтобы предотвратить негативные последствия для вашего бизнеса, своевременно модернизируйте инфраструктурные объекты на игровом поле.",
  };

  const isBothUpAvailable = cityInfo?.nextLevel?.priceUpLevel.GOLD && cityInfo.nextLevel.priceUpLevel.CRYSTAL;

  const getMaintenance = () => {
    if (cityInfo) {
      setConstruction(cityInfo?.constructionIndex);
      setAfterUseBooster(updateGameState);

      openModal("maintenance");
    }
  };

  const updateGameState = () => {
    getGameState().then(
      (response) => response && setGameState(response.results.scene.data)
    );
  };

  const skipCrashTimer = () => {
    if (crystalEnought) {
      skipCrashResolve(cityInfo?.constructionIndex).then(() => {
        updateGameState();
        closeModal()
      });
    } else {
      openModal("notEnoughCrystals");
    }
  };

  const skipUppingLevelTimer = () => {
    cityInfo && skipUpLevel(cityInfo?.constructionIndex).then(() => {
      updateGameState();
      closeModal()
      if (isTutorial) nextStep();
    });

    if (isTutorial) closeModal();
  };

  const openInfo = () => {
    setInfo(loadInfo);
    openModal("info");
  };

  const handleUpLevelClick = (type: "GOLD" | "CRYSTAL" = "GOLD") => {
    const currency = type === "GOLD" ? gold : crystal;
    if (
      cityInfo?.nextLevel?.priceUpLevel[type] &&
      currency >= cityInfo?.nextLevel?.priceUpLevel[type]
    ) {
      upBuildingLevel(cityID, type)
        .then((response) => {
          if (!response) throw new Error();
          updateGameState();
          pushToast("success", isCityOpen ? 'Узел связи будет модернизирован' : 'Узел связи будет построен');
        })
        .catch((err) => {
          pushToast("error", `Что-то произошло`);
        })
    } else {
      if (type === "GOLD") {
        openModal("notEnoughGold")
      }
      else {
        openModal("notEnoughCrystals");
      }
    }
  };

  const handleUpLoyaltyClick = () => {
    if (cityInfo) {
      setConstruction(cityInfo?.constructionIndex);
      openModal("loyalty");
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.container__estimation}>
        {cityInfo && (
          <Estimation
            trends={{
              isPositive: cityInfo.featureLoyalty >= cityInfo.currentLoyalty, 
              isThumbUp: cityInfo.incomingClients >= 1,
              percent: cityInfo.currentLoyalty,
            }}
          />
        )}
      </div>
      <ScrollContainer>
        <ModalBlock kind="common">
          {cityInfo && (
            <div className={classNames(styles["info-block"])}>
              <div className={classNames(styles["info-block__headers"], styles["info-block__column"])}>
                <h5 className={styles.headerText}>Жителей города:</h5>
                <h5 className={styles.headerText}>Абонентов:</h5>
                <h5 className={styles.headerText}>Абонентов в мин.:</h5>
              </div>
              <div className={classNames(styles["info-block__values"], styles["info-block__column"])}>
                <div><SplittedNumber value={cityInfo.maxClients} /></div>
                <div><SplittedNumber value={cityInfo.countClients} /></div>         
                <div className={cityInfo.incomingClients >= 0 ? styles["greenText"] : styles["redText"]}>
                  {cityInfo.incomingClients > 0 && "+"}
                  <SplittedNumber value={cityInfo.incomingClients}/>
                </div>
              </div>
            </div>
          )}

          {cityInfo?.state === "DRAFT" && (
            <div className={classNames(styles.lineButtons)}>
              <h5 className={styles.headerText}>Построить узел связи в г. {cityIdToName[cityInfo.constructionIndex as CITIES]}</h5>
              <div className={buildNodeClassName}>
                <Button
                  id="buildNode"
                  onClick={handleUpLevelClick}
                  className={styles["city-modal-button"]}
                >
                  <img src={coinImage} alt="coin" />
                  <SplittedNumber value={cityInfo?.nextLevel?.priceUpLevel.GOLD || 0} isAdaptive/>
                </Button>
              </div>
            </div>
          )} 

          {isCityOpen && (
            <>
              <div className={classNames(styles.container__load)}>
                <div>
                  <h5 className={styles.headerText}>Нагрузка на узел связи </h5>
                  <img onClick={openInfo} src={infoBadgeIcon} alt="" />
                </div>
                <GradientProgressBar progress={cityInfo?.load || 0} />
              </div>
              <div className={classNames(styles.lineButtons)}>
                <h5 className={styles.headerText}>Лояльность города</h5>
                <div className={buildNodeClassName}>
                  <Button
                    id="upLoyalty"
                    text={"Повысить"}
                    onClick={handleUpLoyaltyClick}
                    className={styles["city-modal-button"]}
                  />
                </div>
              </div>
            </>
          )}
        </ModalBlock>
        {cityInfo && cityInfo.state === "NORMAL" && !cityInfo.maintenanceTimer && (
          <ModalBlock kind="common">
            <div className={classNames(styles.lineButtons)}>
              <h5 className={styles.headerText}>Техобслуживание узла связи</h5>
              <Button text={"Провести ТО"} onClick={getMaintenance} className={styles["city-modal-button"]}/>
            </div>
          </ModalBlock>
        )}
        {cityInfo?.maintenanceTimer && (
          <ModalBlock kind="common">
            <div className={classNames(styles.timerProcess)}>
              <div>
                <h5>
                  ТО проведено <img src={maintenanceIcon} alt="" />{" "}
                </h5>
                <p>Гарантийный период:</p>
              </div>
              <RegularTimer isBlue timer={cityInfo.maintenanceTimer} />
            </div>
          </ModalBlock>
        )}
        {cityInfo?.state === "UPPING_LEVEL" && (cityInfo.timer || isTutorial) && (
          <ModalBlock kind="common">
            <div className={classNames(styles.timerProcess)}>
              <div>
                <h5>
                  {isCityOpen ? (
                    <>
                      {cityInfo.nextLevel?.levelId} уровень{" "}
                      <img src={uppingLevelIcon} alt="" />
                    </>
                  ) : (
                    "Узел строится!"
                  )}
                </h5>
                <p>Будет готово через:</p>
              </div>
              <div className={processTimerClassName} id="forceBuildNode">
                <RegularTimer
                  onSkip={skipUppingLevelTimer}
                  isBlue
                  timer={cityInfo.timer ? cityInfo.timer : SKIP_TUTORIAL_TIMER}
                />
              </div>
            </div>
          </ModalBlock>
        )}
        {cityInfo?.state === "CRASH_RESOLVING" && cityInfo.timer && (
          <ModalBlock kind="common">
            <div className={classNames(styles.timerProcess)}>
              <div>
                <h5>
                  Устраняем аварию <img src={crashResolvingIcon} alt="" />
                </h5>
                <p>Будет готово через:</p>
              </div>
              <RegularTimer onSkip={skipCrashTimer} isBlue timer={cityInfo.timer} />
            </div>
          </ModalBlock>
        )}

        {!isTutorial && isCityOpen && (
          <>
            {isLineCrash && (
              <Crash
                onFix={() => {
                  updateGameState();
                }}
                action={crash}
              ></Crash>
            )}

            {cityInfo?.state === "NORMAL" && cityInfo?.nextLevel && (
              <ModalBlock kind="common">
                <div className={classNames(styles.lineButtons, {"wrap": isBothUpAvailable})}>
                  <h5 className={styles.headerText}>
                    Модернизировать до {cityInfo.nextLevel.levelId} уровня
                  </h5>
                  <div
                    className={classNames(
                      styles.lineButtons__container,
                      { [styles['lineButtons__container--full']]: isBothUpAvailable }
                    )}
                  >
                    {cityInfo.nextLevel.priceUpLevel.GOLD && (
                      <Button
                        onClick={handleUpLevelClick}
                        className={styles["city-modal-button"]}
                      >
                        <img src={coinImage} alt="coin" />
                        <SplittedNumber value={cityInfo.nextLevel.priceUpLevel.GOLD} isAdaptive={!isBothUpAvailable} />
                      </Button>
                    )}
                    {cityInfo.nextLevel.priceUpLevel.CRYSTAL && (
                      <Button
                        onClick={() => handleUpLevelClick("CRYSTAL")}
                        className={styles["city-modal-button"]}
                      >
                        <img className={styles['crystal-price']} src={crystalIcon} alt="crystal" />
                        <SplittedNumber value={cityInfo.nextLevel.priceUpLevel.CRYSTAL} isAdaptive={!isBothUpAvailable} />
                      </Button>
                    )}
                  </div>
                </div>
              </ModalBlock>
            )}

            <ModalBlock kind="common">
              <div>
                <h5 className={styles.headerText}>
                  Отрегулировать стоимость тарифа
                </h5>
                <div className={styles.range}>
                  <span>{MIN}</span>
                  <span>
                    <img src={coinImage} alt="" />
                    {tariff}
                  </span>
                  <span>{MAX}</span>
                </div>
                <Slider
                  min={MIN}
                  max={MAX}
                  trackStyle={{ backgroundColor: "#A68CE3" }}
                  handleStyle={{ border: "#A68CE3 1px solid" }}
                  railStyle={{backgroundColor: "#FFFFFF"}}
                  value={tariff}
                  onChange={setCurrentTariff}
                  onAfterChange={handleLastChange}
                />
              </div>
            </ModalBlock>
          </>
        )}
      </ScrollContainer>
      {!isTutorial && isShowArrow && <ScrollArrow />}
    </div>
  );
};

export default City;
