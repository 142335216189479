import ozon from "../images/promo/logos/ozon.png";
import litres from "../images/promo/logos/litres.svg";
import rzhd from "../images/promo/logos/rzhd.png";
import wink from "../images/promo/logos/wink.svg";
import rt from "../images/promo/logos/rt.svg";
import sephora from "../images/promo/logos/sephora.svg";
import plus from "../images/promo/logos/ya-plus.png";
import multiphoto from "../images/promo/logos/multiphoto.svg";
import go from "../images/promo/logos/ya-go.png";
import lyceum from "../images/promo/logos/lyceum.png";
import gamer from "../images/promo/logos/gamer.svg";
import tv from "../images/promo/logos/tv.svg";
import internet from "../images/promo/logos/internet.svg";
import phone from "../images/promo/logos/phone.svg";
import booster from "../images/prizes/booster.png";
import gold from "../images/prizes/gold.png";
import { prizeIconNameType } from "../interfaces/Prize";

export const logos_welcome = [
  ozon,
  litres,
  rzhd,
  wink,
  rt,
  sephora,
  plus,
  multiphoto,
  go
];

export const logos_rules = [
  ozon,
  gamer,
  litres,
  wink,
  lyceum,
  sephora,
  rzhd,
  plus,
  go,
  multiphoto
];

export const logos_landing = [
  ozon,
  rt,
  sephora,
  plus,
  litres,
  rzhd,
  multiphoto,
  go,
  wink
];

export const logos_prizes: {
  [type in prizeIconNameType]: string;
} = {
  "litres25": litres,
  "litres1000": litres,
  "ozon2000": ozon,
  "ozon4000": ozon,
  "ozon20000": ozon,
  "phone25": phone,
  "phone100": phone,
  "phone100-3": phone,
  "tv25": tv,
  "tv100": tv,
  "internet25": internet,
  "internet100": internet,
  "internet100-3": internet,
  "yandex-plus": plus,
  "yandex-taxi": go,
  "gamer": gamer,
  "rgd": rzhd,
  "ildebote": sephora,
  "wink": wink,
  "foto": multiphoto,
  "lyceum": lyceum,
  "booster": booster,
  "gold": gold
};