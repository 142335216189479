import classNames from "classnames";
import React, { MutableRefObject } from "react";

import skipIcon from "../../../images/timers/skip-icon.png";
import { digitsDivide } from "../../../utils/digitsDivide";
import styles from "./GradientProgressBar.module.scss";

interface IGradientProgressBar {
  progress: number;
  time?: string;
  canSkip?: boolean;
  onClick?: () => void;
  type?: "default" | "blue" | "purple";
  withSmallText?: boolean;
  barRef?: MutableRefObject<HTMLDivElement | null>;
  className?: string;
}

const GradientProgressBar: React.FC<IGradientProgressBar> = ({
  children,
  progress,
  type = "default",
  onClick,
  canSkip = false,
  time,
  barRef,
  withSmallText,
  className
}) => {

  const currentProgress = progress > 100 ? `100%` : `${progress}%`;

  const progressbarLineClass = classNames(
    styles["progressbar-line"],
    styles[`progressbar-line--${type}`]
  );

  const progressbarClass = classNames(styles["progressbar"], className, {
    [styles["progressbar--pointer"]]: onClick,
  });

  const indicatorClass = classNames(styles.progressbar__indicator, {[styles["progressbar__indicator--small"]]: withSmallText});

  const renderTextInfo = () => {
    if (time) {
      return <span className={indicatorClass}>{digitsDivide(time)}</span>;
    }
    return <span className={indicatorClass}>{currentProgress}</span>;
  };

  const setRef = (element: HTMLDivElement | null) => {
    if (barRef !== undefined) 
      barRef.current = element;
  };

  return (
    <div onClick={onClick} className={progressbarClass} ref={el => setRef(el)}>
      <p className={progressbarLineClass} style={{ width: currentProgress }}>
        {canSkip && <img src={skipIcon} alt="" />}
      </p>
      {children && <span className={indicatorClass}>{children}</span>}
      {!children && renderTextInfo()}
    </div>
  );
};

export default GradientProgressBar;
