import { FC } from "react"
import imageSrc from "../../../../images/game-preloader-animated-image.png"
import styles from "./style.module.scss"
import cn from "classnames"

interface IAnimatedImageProps {
  className?: string
}

const AnimatedImage: FC<IAnimatedImageProps> = ({ className }) => {
  return (
    <div className={cn(styles["animated-image"], className)}>
      <img
        src={imageSrc}
        alt="Tile"
        className={styles["animated-image__image"]}
      />
    </div>
  )
}

export default AnimatedImage
