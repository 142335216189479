import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { InfoModalContext } from "../../../../store/InfoModal";

import ModalBlock from "../../ModalBlock";

import modalStyles from "../SimpleModal.module.scss";
import styles from "./Info.module.scss";

const Info: React.FC = () => {
  const { info } = useContext(InfoModalContext);

  return (
    <div className={modalStyles.modal__body}>
      <ModalBlock kind="common">
        <div className={styles.info}>
          {info?.header && <h5>{info?.header}</h5>}
          <p>{info?.description}</p>
        </div>
      </ModalBlock>
    </div>
  );
};

export default observer(Info);
