import { FC, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { v4 as uuid } from 'uuid';

import layoutStyles from '../../layouts/Promo/Promo.module.scss';

import history from "../../images/promo/book.svg";
import GameHistoryLine from "./GameHistoryLine";
import { getFeedbacks } from "../../services/feedback";
import { getProfile } from "../../services/user";
import styles from "./GameHistory.module.scss"
import SideBanner from "../../components/common/promo/SideBanner/SideBanner";
import SupportRequestItem from "./SupportRequestItem/SupportRequestItem";
import {IFeedback} from './SupportRequestItem/SupportRequestItem'

export interface IHistoryItem {
  createDate: string;
  crystalAmount?: string;
  description: string;
}

const GameHistory: FC = () => {
  const [isFeedbacks, setIsFeedbacks] = useState(false);
  const [historyData, setHistoryData] = useState<IHistoryItem[]>([]);
  const [feedbacks, setFeedbacks] = useState<IFeedback[]>([]);

  useEffect(() => {
    if (isFeedbacks)
      getFeedbacks().then((response) => {
        setFeedbacks(response.results.feedbacks.data.map((data: any) => ({
          ...data,
          description: `запрос № ${data.id} отправлен в тех.поддержку`
        })))
      }
    );
    else 
      getProfile().then(response => setHistoryData(response.results.profile.data.buyHistorys));
  }, [isFeedbacks]);

  const updateFeedbackItem = (feedbackId: number) => {
    
  }
  
  return (
    <>
      <div className={layoutStyles.promo}>
        <SideBanner />
        <div className={layoutStyles.promo__content}>
          <img src={history} alt="" />
          <h1>История</h1>
          <div className={layoutStyles.promo__content__tabs}>
          <div onClick={() => setIsFeedbacks(false)}>
              <p className={styles["bold-text"]}>Баланс кристаллов</p>
              {!isFeedbacks && <div />}
            </div>
            <div onClick={() => setIsFeedbacks(true)}>
              <p className={styles["bold-text"]}>Обращения в поддержку</p>
              {isFeedbacks && <div />}
            </div>
          </div>
          <p>
            {isFeedbacks ? 
              "В разделе отображается история ваших обращений в техническую поддержку." : 
              "В разделе отображаются изменения вашего баланса кристаллов."
            }
          </p>
          {
              isFeedbacks ?
                <div className={styles["support-requests"]}>
                  {feedbacks.map(data => 
                    <SupportRequestItem 
                      id={data.id}
                      updateFeedbackItem={updateFeedbackItem}
                      resolved={data.resolved}
                      createDate={data.createDate} 
                      description={data.description}
                      messages={data.messages}
                      className={styles["support-requests__item"]}
                      key={data.id}
                    />
                    )
                  }
                </div>
                :
                <div className={layoutStyles.promo__content__block}>
                  {historyData.map((item) => <GameHistoryLine key={uuid()} {...item} />)}
                </div>  
            }
        </div>
      </div>
    </>
  );
};

export default observer(GameHistory);
