import { IModalNotificationsContent } from "../interfaces/ModalNotificatioinsContent"

export const maxClientGrowNotificationContent: IModalNotificationsContent = {
  header: "Прогресс не стоит на месте",
  description: `Скоростной интернет и качественные
  услуги связи делают ваши города
  привлекательными для новых жителей!
  Максимальное число жителей городов
  увеличивается, а для вас становятся
  доступны узлы и линии связи более
  высокого уровня!`,
  buttonText: "Понятно"
}

export const supportAssistantNotificationContent: IModalNotificationsContent = {
  header: "Удержание абонентов",
  description: `Отличные новости! Теперь вы можете
  усилить свою команду опытным
  маркетологом, который поможет
  удержать абонентов! Загляните
  в Центр развития персонала, чтобы
  нанять нового сотрудника и получать
  больше прибыли!`,
  buttonText: "Центр развития персонала"
}

export const financeAssistantNotificationContent: IModalNotificationsContent = {
  header: "Не упустите прибыль!",
  description: `Хорошие новости! Теперь вы можете
  нанять ассистента, который будет
  собирать прибыль во время вашего
  отсутствия. Загляните в Центр 
  развития персонала, чтобы нанять
  нового сотрудника и получать больше
  прибыли!`,
  buttonText: "Центр развития персонала"
}