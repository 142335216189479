import React from "react";

import styles from "./AwardList.module.scss";
import { IAwardCard } from "../../../interfaces/common";
import Booster from "../Booster/Booster";

type awardList = {
  awards: IAwardCard[];
};

const AwardList: React.FC<awardList> = ({ awards }) => {
  return (
    <div className={styles.awards}>
      {awards.map((award, index) => {
        if (award.type === "booster") {
          return <Booster withSize booster={award.booster}  key={index} />;
        } else {
          return (
          <div className={styles.awards_award} key={index}>
            <div>
              <img src={award.icon} alt="award icon"/>
              {award.text && <p>{award.text}</p>}
            </div>
            {award.renderButton && award.renderButton()}
          </div>
          );
        }
      })}
    </div>
  );
};

export default AwardList;
