import error404 from "../images/promo/404.png";
import error500 from "../images/promo/500.png";
import { errorContentType } from "../interfaces/Error";

export const errors: errorContentType = {
  404: {
    header: "404",
    message: "Страница не найдена\n\nПроверьте правильность введенного адреса или вернитесь назад.",
    buttonText: "На главную",
    image: error404
  },
  500: {
    header: "500",
    message: "Что-то пошло не так.\n\nИгра временно недоступна. Пожалуйста, повторите попытку позже.",
    buttonText: "Понятно",
    image: error500
  }
};
