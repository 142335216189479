import { createContext } from "react";
import { action, computed, makeObservable, observable } from "mobx";
import getCurrentEpoch from "../utils/epochs/epochs";

import {
  IGameState,
  IGameStateCity,
  IGameStateDepartment,
  IGameStateLine,
  IGameStateResponse,
  ITechnology,
} from "../interfaces/GameState";
import { technologyStates } from "../constants/technology";
import { scenario } from "./Tutorial";
import { communicationLines } from "../constants/communication-lines";
import { ICommunicationLineProps } from "../components/common/CommunicationLine";
import { getLineStatusValue } from "../utils/statuses/getLineStatusValue";
import { IOfflineProfitMessage } from "../interfaces/common";

export class GameState {
  health: IGameState["health"];

  age: IGameState["age"];

  date: IGameState["date"];
  
  currentTime: IGameState["currentTime"] = ""; 

  trends: IGameState["trends"];

  maxClients: IGameState["maxClients"];

  totalClients: IGameState["totalClients"];

  network: IGameState["network"];

  gold: IGameState["gold"];

  crystal: IGameState["crystal"];

  departments?: IGameStateDepartment;

  tutorialLevelId: number | null;

  technologiesByState?: { [key in ITechnology["state"]]?: ITechnology[] };

  messageAboutOfflineProfit?: IOfflineProfitMessage | null;

  constructor() {
    makeObservable(this, {
      health: observable,
      age: observable,
      date: observable,
      currentTime: observable,
      trends: observable,
      network: observable,
      gold: observable,
      crystal: observable,
      departments: observable,
      technologiesByState: observable,
      tutorialLevelId: observable,
      setGameState: action.bound,
      resetGameState: action.bound,
      getCity: action.bound,
      getLine: action.bound,
      getLines: computed,
      getCities: computed,
      getDepartments: action.bound,
      getEpoch: action.bound,
      communicationLinesArrayForRender: computed,
      setCrystal: action.bound,
      getTechnologies: action.bound,
      setGold: action.bound,
      setMessageAboutOfflineProfit: action.bound
    });

    this.health = null;
    this.age = null;
    this.date = null;
    this.gold = 0;
    this.crystal = 0;
    this.maxClients = 0;
    this.totalClients = 0;
    this.tutorialLevelId = 0;
    this.trends = {
      percent: null,
      isPositive: null,
      isThumbUp: null
    };
    this.network = null;
  }

  resetGameState() {
    this.health = null;
    this.messageAboutOfflineProfit = null;
    this.age = null;
    this.date = null;
    this.gold = 0;
    this.crystal = 0;
    this.maxClients = 0;
    this.totalClients = 0;
    this.tutorialLevelId = 0;
    this.trends = {
      percent: null,
      isPositive: null,
      isThumbUp: null
    };
    this.network = null;
  }

  setMessageAboutOfflineProfit(message: IOfflineProfitMessage | null) {
    this.messageAboutOfflineProfit = message;
  }

  setGameState(gameState: IGameStateResponse) {
    this.health = gameState.score;
    this.age = gameState.age;
    this.date = gameState.date || null;
    this.currentTime = gameState.currentTime
    this.gold = gameState.gold;
    this.maxClients = gameState.maxClients;
    this.totalClients = gameState.totalClients;
    this.crystal = gameState.crystal;
    this.trends = {
      isPositive: gameState.featureLoyalty >= gameState.currentLoyalty, 
      isThumbUp: gameState.currentLoyalty >= 21,
      percent: gameState.currentLoyalty,
    };
    this.network = gameState.network;
    this.departments = gameState.network.department;
    this.tutorialLevelId = gameState.tutorialLevelId;
    this.technologiesByState = Object.fromEntries(
      Object.keys(technologyStates).map((state) => {
        return [
          state as ITechnology["state"],
          Object.values(gameState.network.technology)
            .map((technology, index) => {
              return {
                ...technology,
                code: Object.keys(gameState.network.technology)[index],
              };
            }).filter((technology) => technology.state === state),
        ];
      })
    );
  }

  setCrystal(crystalCount: number) {
    this.crystal = crystalCount;
  }

  setGold(gold: number) {
    this.gold = gold;
  }

  getCity(cityID: string): IGameStateCity | null {
    const cityInfo =
      this.network?.cities.find((v) => v.constructionIndex === cityID) || null;
    return cityInfo;
  }

  getLine(lineId: string): IGameStateLine | null {
    return (
      this.network?.lines.find((v) => v.constructionIndex === lineId) || null
    );
  }

  get getCities() {
    return this.network?.cities || []
  }

  get getLines() {
    return this.network?.lines || [];
  }

  get communicationLinesArrayForRender() {
    // сортировка для правильного zindex в соответствии со статусом линии
    const sortedLinesData = [...this.getLines].sort((line1, line2) => getLineStatusValue(line1.state) - getLineStatusValue(line2.state))
    const linesArrayToRender: ICommunicationLineProps[] = sortedLinesData.map(lineData => {
      const lineConstantData = communicationLines.filter(line => line.uniqName === lineData.constructionIndex)[0]
      return ({
        points: lineConstantData.points,
        status: lineData.state,
        markerIcons: lineConstantData.markerIcons,
        lineLevel: lineData.currentLevelId,
        construcitonIndex: lineData.constructionIndex,
        modalImg: lineConstantData.modalImg,
        markerCoors: lineConstantData.markerCoors,
        maintenanceTimer: lineData.maintenanceTimer,
        timer: lineData.timer,
        lineImageCoors: lineConstantData.lineImageCoors,
        lineImages: lineConstantData.lineImages
      })
    })
    return linesArrayToRender
  }

  get isTutorial() {
    return !((this.tutorialLevelId !== null ? this.tutorialLevelId : 20) > (scenario.length - 1));
  }

  getDepartments() {
    return this.network?.department || null;
  }

  getEpoch() {
    if (this.network?.epoch.epochType) {
      return {
        ...this.network?.epoch,
        ...getCurrentEpoch(this.network?.epoch.epochType),
      };
    }
  }

  getTechnologies() {
    return this.network?.technology;
  }
}

export const GameStateStore = new GameState();

export const GameStateStoreContext = createContext(GameStateStore);
