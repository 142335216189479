import {FC} from 'react'
import styles from './ScrollArrow.module.scss'
import classNames from 'classnames'

type ScrollArrowProps = {
  className?: string
}

const ScrollArrow: FC<ScrollArrowProps> = ({className}) => {
  return (
    <div className={classNames(styles["scroll-arrow"], className)} />
  )
}

export default ScrollArrow