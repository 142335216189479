import API from "../../api";

export const signIn = (username: string, password: string, captcha: string, refLink = '') => {
  return API.postRequest('/auth/login', {
    username,
    password,
    captchaResponse: captcha
  }, {
    headers: {
      "Referral-Code": refLink,  
    }
  });
};

export const logout = () => {
  return API.getRequest('/logout');
};

export const checkSession = () => {
  return API.postRequest('/auth/checkSession');
};

export const getZone = () => {
  return API.getRequest('/public/settings', { baseURL: window.location.origin });
};
