import { FC, useContext, useEffect, useState } from "react"
import { Point, Polygon, InteractionEvent } from 'pixi.js';
import { Graphics, Sprite } from "@inlet/react-pixi"
import ModalContextStore from "../../../store/Modal"
import { observer } from "mobx-react-lite"
import { getLinePolygonsSinglePoints } from "../../../utils/polygons/line-polygons";
import {communicationLineStatuses} from '../../../types/statuses'
import Marker from '../Marker'
import { ITimer } from "../../../interfaces/GameState";
import { getImage, getTimer } from "../../../utils/markers/timer-and-image";
import { markerIconsType } from "../../../types/markers";
import {maintenanceIcon} from '../../../constants/marker-icons'
import { GameStateStoreContext } from "../../../store/GameState";
import { TutorialStoreContext } from "../../../store/Tutorial";
import { ILineImages } from "../../../interfaces/CommunicationLine";
import { getPointsDistance } from "../../../utils/points/distance";
import { blurUpImageType } from "../../../interfaces/SimpleModal";

export interface ICommunicationLineProps {
  points: Point[]
  status: communicationLineStatuses
  markerIcons: markerIconsType
  lineLevel: number
  construcitonIndex: string
  modalImg: string | blurUpImageType
  markerCoors: Point
  maintenanceTimer?: ITimer
  timer?: ITimer
  lineImageCoors: Point
  lineImages: ILineImages
}

let offsetBefore: Point | null = null

const CommunicationLine: FC<ICommunicationLineProps> = ({
  points,
  status,
  markerIcons,
  construcitonIndex,
  modalImg,
  markerCoors,
  maintenanceTimer,
  timer,
  lineImageCoors,
  lineImages
}) => {
  const { openModal } = useContext(ModalContextStore);
  const { nextStep } = useContext(TutorialStoreContext);
  const { isTutorial } = useContext(GameStateStoreContext);

  const [hitAreaPolygon, setHitAreaPolygon] = useState<Polygon>(new Polygon())
  const [tint, setTint] = useState(0xffffff)

  useEffect(() => {
    const polygon = getLinePolygonsSinglePoints(points, 40)
    setHitAreaPolygon(polygon)
  }, [points])

  const getLineImage = () => {
    if (status === "NORMAL" || status === 'UPPING_LEVEL') {
      return lineImages.NORMAL
    } 
    else if (status === 'DRAFT' || status === 'CAN_BUILD') {
      return lineImages.CAN_BUILD
    } 
    else {  // CRASH CRASH_RESOLVING
      return lineImages.CRASH
    }
  }

  const handlePointertap = (e: InteractionEvent) => {
    const currentOffset = new Point(e.data.global.x, e.data.global.y)
    if (!offsetBefore || getPointsDistance(offsetBefore, currentOffset) < 50) {
      if(isTutorial) nextStep().then(() => openModal('line', {modalImage: modalImg, gameObjectId: construcitonIndex}));
      openModal('line', {modalImage: modalImg, gameObjectId: construcitonIndex})
    }
  }

  const handlePointerdown = (e: InteractionEvent) => {
    offsetBefore = new Point(e.data.global.x, e.data.global.y)
  }
  
  /*
  // для отрисовки полигона линии (добавить draw={drawPolygons} у Graphics)
  const drawPolygons = (g: PIXIGraphics) => {
    getLinePolygonsSinglePoints(points, 40, g)
  }
  */

  return (
    <>
      {status !== "DRAFT" && 
        <>
          <Sprite 
            tint={tint}
            x={lineImageCoors.x}
            y={lineImageCoors.y}
            image={getLineImage()}
          />
          <Graphics 
            interactive={true}
            hitArea={hitAreaPolygon}
            pointertap={handlePointertap}
            pointerdown={handlePointerdown}
            mouseout={()=>setTint(0xffffff)} 
            mouseover={()=>setTint(0xd4d3cf)}
          />
          <Marker 
            timer={getTimer(status, timer, maintenanceTimer)}
            image={getImage(status, markerIcons, maintenanceTimer, maintenanceIcon)} 
            pointertap={handlePointertap}
            pointerdown={handlePointerdown}
            tint={tint}
            position={markerCoors}
            mouseout={()=>setTint(0xffffff)} 
            mouseover={()=>setTint(0xd4d3cf)}
          />
        </>
      }
    </>
  )
}

export default observer(CommunicationLine)
