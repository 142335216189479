import {FC, useContext, useEffect} from 'react'
import { maxClientGrowNotificationContent } from '../../../../constants/modal-notifications-content'
import { IQuestNotificationModalProps } from '../../../../interfaces/QuestNotificationsModals'
import { rejectQuest } from '../../../../services/quest'
import ModalContextStore from '../../../../store/Modal'
import ModalNotificationView from '../ModalNotficationView/ModalNotificationView'

const MaxClientGrowQuest: FC<IQuestNotificationModalProps> = ({questId}) => {
  const { closeModal } = useContext(ModalContextStore)
  
  useEffect(() => {
    if (questId) {
      rejectQuest(questId)
    }
  }, [questId])

  const onButtonClick = () => {
    closeModal()
  }

  return (
    <ModalNotificationView 
      {...maxClientGrowNotificationContent}
      buttonOnClickHandler={onButtonClick}
    />
  )
}

export default MaxClientGrowQuest