import React, { useState } from "react";

import ModalBlock from "../../ModalBlock";

import styles from "./Leaderboard.module.scss";
import modalStyles from "../SimpleModal.module.scss";
import ModalFooterSelector from "../../modalFooterSelector/ModalFooterSelector";
import { leaderboardType, leaderboardVariableKeyType } from "../../../../interfaces/Leaderboard";
import { leaderboardVariables } from "../../../../constants/leaderboards";
import LeaderboardTable from "./LeaderboardTable";
import useScroller from "../../../hooks/useScroller";
import ScrollArrow from "../../../ui/ScrollArrow/ScrollArrow";

const Leaderboard: React.FC = () => {
  const [leaderboardVariable, setLeaderboardVariable] =
    useState<leaderboardVariableKeyType>(leaderboardVariables.DAILY.value);
  const [leaderboards] = useState<leaderboardType>(leaderboardVariables);

  const { ScrollContainer, isShowArrow } = useScroller();

  const onLeaderboardSet = (leaderboard: string) => {
    if (isLeaderboard(leaderboard)) {
      setLeaderboardVariable(leaderboard);
    }
  };

  const isLeaderboard = (
    leaderboard: string
  ): leaderboard is leaderboardVariableKeyType => {
    if (leaderboards) {
      return Object.keys(leaderboards).indexOf(leaderboard) !== -1;
    }
    return false;
  };

  return (
    <div>
      <ScrollContainer>
        <div className={modalStyles.modal__body}>
          <ModalBlock kind="common">
            <div className={styles.leaderboard}>
              <LeaderboardTable type={leaderboardVariable} description />
            </div>
          </ModalBlock>
        </div>
      </ScrollContainer>
      
      <div className={modalStyles.modal__footer}>
        <ModalFooterSelector
          selected={leaderboardVariable}
          onSelected={onLeaderboardSet}
          variables={Object.values(leaderboardVariables)}
        />
      </div>
      {isShowArrow && <ScrollArrow />}
    </div>
  );
};

export default Leaderboard;
