import React from "react";
import Button from "../Button";

import styles from "./MultipurposeCard.module.scss";

type MultipurposeCardPropsType = {
  buttonText?: string;
  buttonIcon?: string;
  blocked?: boolean;
  renderButton?: () => void;
  onClickHandler?: () => void;
  className?: string;
};

// card for list of some inline block with buttons and

const MultipurposeCard: React.FC<MultipurposeCardPropsType> = ({
  children,
  buttonText,
  blocked,
  renderButton,
  onClickHandler,
  className
}) => {
  return (
    <div className={styles.card} onClick={onClickHandler}>
      <div className={className}>
        <div className={styles.card__content}>{children}</div>
        {buttonText && <Button disabled={blocked} text={buttonText} />}
        {renderButton && renderButton()}
      </div>
    </div>
  );
};

export default MultipurposeCard;
