import React, { MutableRefObject, useCallback, useContext, useEffect, useState } from "react";
import uniqid from "uniqid";

import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

import { ITimer } from "../../../interfaces/GameState";
import { GameStateStoreContext } from "../../../store/GameState";
import { TimerContext } from "../../../store/Timer";
import GradientProgressBar from "../GradientProgressBar";
import ModalContextStore from "../../../store/Modal";
import { SkipTimerContext } from "../../../store/SkipTimer";
import useTimer from "../../hooks/useTimer";
import { getPercentage } from "../../../utils/getPercentage";

dayjs.extend(duration);

type crashFixingProps = {
  timer: ITimer;
  isBlue?: boolean;
  onSkip?: () => void;
  withSmallText?: boolean
  timerRef?: MutableRefObject<HTMLDivElement | null>;
  className?: string;
};

const RegularTimer: React.FC<crashFixingProps> = ({ timer, isBlue, onSkip, timerRef, className }) => {  
  const { subscribe30fps, unsubscribe30fps } = useContext(TimerContext);
  const { currentTime } = useContext(GameStateStoreContext);
  const { openModal } = useContext(ModalContextStore);
  const { setSkipTimer } = useContext(SkipTimerContext)
  const [timerUniqId, setTimerUniqId] = useState<string>("");

  const { fullDurationMs, elapsedMs, setElapsedMs } = useTimer(timer, currentTime);


  useEffect(() => {
    setTimerUniqId(uniqid());
  }, []);

  const onTick = useCallback(
    (delta: number) => {
      setElapsedMs(elapsedMs + delta);
    },
    [elapsedMs, setElapsedMs]
  );

  useEffect(() => {
    if (timerUniqId) {
      subscribe30fps({ id: timerUniqId, fn: onTick });
    }
    return () => {
      if (timerUniqId) {
        unsubscribe30fps({ id: timerUniqId, fn: onTick });
      }
    };
  }, [
    elapsedMs,
    setElapsedMs,
    timerUniqId,
    subscribe30fps,
    unsubscribe30fps,
    onTick,
  ]);


  const skipTimer = () => {
    if (timer.priceForSkip?.CRYSTAL && onSkip) {
      setSkipTimer(timer.priceForSkip?.CRYSTAL, onSkip)
      openModal("skipTimer")
    };
  };

  const timeValue = fullDurationMs - elapsedMs;
  
  return (
    <GradientProgressBar
      withSmallText
      barRef={timerRef}
      type={isBlue ? "blue" : "default"}
      canSkip={!!onSkip}
      onClick={skipTimer}
      time={dayjs.duration(timeValue > 0 ? timeValue : 0).format("HH:mm:ss")}
      progress={getPercentage(elapsedMs, fullDurationMs)}
      className={className}
    />
  );
};

export default RegularTimer;
