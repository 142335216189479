import React, { useContext, useEffect } from "react"
import { Container } from "@inlet/react-pixi"
import {observer} from 'mobx-react-lite'
import Field from "./Field"
import CityObject from "./common/CityObject"
import CommunicationLine from "./common/CommunicationLine"
import { gameCitites } from "../constants/cities"
import { communicationLines } from "../constants/communication-lines"
import { GameStateStoreContext } from "../store/GameState"
import { markerIcons } from "../constants/marker-icons"
import { FieldStoreContext } from "../store/Field"
import { TutorialStoreContext } from "../store/Tutorial"
import { warningPoints } from "../constants/warning"
import { CITIES } from "../enums/cities"
import { COMMUNICATION_LINES } from "../enums/communication-lines"
import ModalContextStore from "../store/Modal"
import { modalKindType } from "../interfaces/common"
import { UseBoosterStoreContext } from "../store/UseBooster"

const CITY_ID = 2;
const LINE_ID = 1;

const Tutorial: React.FC = () => {
  const { getLines, getCities } = useContext(GameStateStoreContext);
  const { scale, offsetX, setCoords } = useContext(FieldStoreContext);
  const { isLineActive, isCityActive, openModalId } = useContext(TutorialStoreContext);
  const { openModal } = useContext(ModalContextStore);
  const { setConstruction } = useContext(UseBoosterStoreContext);

  const linesData = getLines
  const citiesData = getCities

  const line = communicationLines[LINE_ID];
  const city = gameCitites[CITY_ID];

  useEffect(() => {
    if (isCityActive) setCoords(warningPoints[CITIES.CITY_3].x, warningPoints[CITIES.CITY_3].y);
    else if (isLineActive) {
      setCoords(warningPoints[COMMUNICATION_LINES.LINE_2].x, warningPoints[COMMUNICATION_LINES.LINE_2].y);
    }
    
  }, [isCityActive, isLineActive, setCoords]);

  useEffect(() => {
    const modalInfo = openModalId ? openModalId.split('?') : null;

    if (!modalInfo) return;

    if (modalInfo.length > 1) {
      const pic = modalInfo[0] === 'city' ? gameCitites[2].modalImg : communicationLines[1].modalImg;
      openModal(modalInfo[0] as modalKindType, {modalImage: pic, gameObjectId: modalInfo[1]})
    } else {
      if (modalInfo[0] === "loyalty") setConstruction("CITY_3");
      openModal(modalInfo[0] as modalKindType);
    }
  }, [openModalId, openModal, setConstruction]);

  return (
    <Container position={{x: offsetX > 0 ? offsetX : 0, y: 0}} scale={scale}>
      <Field isTutorial>
        {isCityActive && (
          <CityObject
            key={city.uniqName}
            resource={city.resource}
            xPos={city.xPos}
            yPos={city.yPos}
            building={city.building}
            modalImg={city.modalImg}
            uniqName={city.uniqName}
            markerIcons={markerIcons}
            markerCoors={city.markerCoors}
            status={citiesData.length === gameCitites.length ? citiesData[CITY_ID].state : "DRAFT"}
            maintenanceTimer={citiesData.length === gameCitites.length ? citiesData[CITY_ID].maintenanceTimer : undefined}
            timer={citiesData.length === gameCitites.length ? citiesData[CITY_ID].timer : undefined}
          />
        )}
        {isLineActive && (
          <CommunicationLine
            points={line.points}
            key={line.uniqName}
            status={linesData.length === communicationLines.length ? linesData[LINE_ID].state : "DRAFT"}
            construcitonIndex={linesData.length === communicationLines.length ? linesData[LINE_ID].constructionIndex : "LINE_1"}
            lineLevel={linesData.length === communicationLines.length ? linesData[LINE_ID].currentLevelId : 1}
            markerIcons={line.markerIcons}
            modalImg={line.modalImg}
            markerCoors={line.markerCoors}
            maintenanceTimer={linesData.length ? linesData[LINE_ID].maintenanceTimer : undefined}
            timer={linesData.length ? linesData[LINE_ID].timer : undefined}
            lineImageCoors={line.lineImageCoors}
            lineImages={line.lineImages}
          />
        )}
      </Field>
    </Container>
  )
}

export default observer(Tutorial)
