import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { paymentModal } from "../../../../constants/paymentProblem";
import { fastBuyCurrency } from "../../../../interfaces/common";
import { paymentProblemType } from "../../../../interfaces/PaymentProblemModal";
import ModalContextStore from "../../../../store/Modal";
import { UserStoreContext } from "../../../../store/User";
import Button from "../../Button";

import ModalBlock from "../../ModalBlock";

import modalStyles from "../SimpleModal.module.scss";
import styles from "./PaymentProblem.module.scss";

interface IPaymentProblem {
  currency: fastBuyCurrency,
  problem: paymentProblemType
}

const PaymentProblem: React.FC<IPaymentProblem> = ({
  currency,
  problem
}) => {
  const { closeModal } = useContext(ModalContextStore);
  const { user } = useContext(UserStoreContext);

  return (
    <div className={styles.problem}>
      <div className={modalStyles.modal__body}>
        <ModalBlock kind="common">
          <div>
            <h4>{paymentModal[currency][problem]?.header}</h4>
            <p className={styles.problem__text}>
              {paymentModal[currency][problem]?.text}
            </p>
            <a href={paymentModal[currency][problem]?.buttonLink[user?.providerCode || "RT"] || "/"}>
              <Button
                text={paymentModal[currency][problem]?.buttonText}
                onClick={closeModal}
                orange
              />
            </a>
            <p className={styles.problem__smallText}>
              {paymentModal[currency][problem]?.smallText}
            </p>
          </div>
        </ModalBlock>
      </div>
    </div>
  );
};

export default observer(PaymentProblem);
