import React, { useContext } from "react";

import Staff from "./Staff/Staff";
import { ISimpleModal } from "../../../interfaces/SimpleModal";

import Technologies from "./Technologies/Technologies";
import Partnership from "../Partnership/Partnership";
import Market from "./Market/Market";
import FastBuy from "./FastBuy/FastBuy";
import Confirm from "./Сonfirm/Сonfirm";
import Boosters from "./Boosters/Boosters";
import Leaderboard from "./Leaderboard/Leaderboard";
import Quest from "./Quest/Quest";

import styles from "./SimpleModal.module.scss";
import NotEnoughGold from "./NotEnoughGold/NotEnoughGold";
import Auth from "./Auth/Auth";
import FeedbackSuccess from "./FeedbackSuccess/FeedbackSuccess";
import PaymentProblem from "./PaymentProblem/PaymentProblem";
import SkipTimer from "./SkipTimer/SkipTimer";
import DailyGiftCalendar from "./DailyGiftCalendar";
import Info from "./Info/Info";
import AccountWarning from "./AccountWarning/AccountWarning";
import { GameStateStoreContext } from "../../../store/GameState";
import { AuthorizationContext } from "../../../store/Auth";
import { observer } from "mobx-react-lite";
import IndicatorInfo from "./IndicatorInfo/IndicatorInfo";
import CloseModalButton from "../../ui/CloseModalButton/CloseModalButton";
import { GameResultsStoreContext } from "../../../store/GameResults";

//add your modal kind there to render body componentW
const renderBody = (kind: ISimpleModal["kind"], onClose: () => void) => {
  switch (kind) {
    case "staff":
      return <Staff />;
    case "staff/offlineAssistant":
      return <Staff department="OFFLINE_ASSISTANT"/>;
    case "staff/builders":
      return <Staff department={"BUILDERS"} />;
    case "technologies":
      return <Technologies />;
    case "partnership":
      return <Partnership />;
    case "store":
      return <Market />;
    case "store/gold":
      return <Market initialTab="gold" />;
    case "store/crystal":
      return <Market initialTab="crystal" />;
    case "store/booster":
      return <Market initialTab="booster" />;
    case "fastBuy":
      return <FastBuy closeModal={onClose} />;
    case "notEnoughCrystals":
      return <FastBuy notEnought closeModal={onClose} />;
    case "confirm":
      return <Confirm closeModal={onClose} />;
    case "loyalty":
      return <Boosters type="loyalty" />;
    case "maintenance":
      return <Boosters type="maintenance" />;
    case "crash":
      return <Boosters type="crash" />;
    case "leaderboard":
      return <Leaderboard />;
    case "booster":
      return <Boosters type="crash" />;
    case "quest":
      return <Quest closeModal={onClose} />;
    case "notEnoughGold":
      return <NotEnoughGold />;
    case "auth":
      return <Auth />
    case "feedbackSuccess":
      return <FeedbackSuccess />;
    case "skipTimer":
      return <SkipTimer />;
    case "problem/money/balance":
      return <PaymentProblem currency="money" problem="U0206" />;
    case "problem/money/lock":
      return <PaymentProblem currency="money" problem="U0208" />;
    case "problem/bonus/balance":
      return <PaymentProblem currency="bonus" problem="U0206" />;
    case "calendar":
      return <DailyGiftCalendar />;
    case "partnership/aliexpress":
      return <Partnership defaultPartner={"aliexpress"} />;
    case "partnership/mvideo":
      return <Partnership defaultPartner={"mvideo"} />;
    case "partnership/litres":
      return <Partnership defaultPartner={"litres"} />;
    case "partnership/lamoda":
      return <Partnership defaultPartner={"lamoda"} />;
    case "partnership/sephora":
      return <Partnership defaultPartner={"sephora"} />;
    case "info":
      return <Info />;
    case "warning/account/payment":
      return <AccountWarning type="payment" />;
    case "warning/account/prize":
      return <AccountWarning type="prizeActivation" />;
    case "info/clients":
      return <IndicatorInfo type="clients" />;
    case "info/loyalty":
      return <IndicatorInfo type="loyalty" />;
    case "info/score":
      return <IndicatorInfo type="score" />;
  }
};

const SimpleModal: React.FC<ISimpleModal> = ({
  onClose,
  headerText,
  headerIcon, // WIP: get headerIcon with kind arg
  kind,
}) => {
  const { isTutorial } = useContext(GameStateStoreContext);
  const { isAuthorized } = useContext(AuthorizationContext);
  const { isGameEnded } = useContext(GameResultsStoreContext)

  const handleCloseButtonClick = () => {
    if (!isTutorial || !isAuthorized || isGameEnded) {
      onClose();
    }
  }
  
  return (
    <div className={styles.modal}>
      {headerText && (
        <div className={styles.modal__header}>
          <span className={styles["modal__header-text"]}>{headerText}</span>
          <CloseModalButton 
            onClick={handleCloseButtonClick}
            className={styles.modal__closeButton}
          />
          {headerIcon && (
            <div className={styles.modal__header__headerIcon}>
              <img src={headerIcon} alt="" />
            </div>
          )}
        </div>
      )}
      {!headerText && (
        <CloseModalButton 
          onClick={handleCloseButtonClick}
          className={styles.modal__closeButton}
        />
      )}
      {renderBody(kind, onClose)}
    </div>
  );
};

export default observer(SimpleModal);
