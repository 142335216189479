import { FC } from "react"
import styles from "./style.module.scss"
import cn from "classnames"

interface IProgressBarProps {
  percent: number
  className?: string
}

const ProgressBar: FC<IProgressBarProps> = ({ percent, className }) => {
  return (
    <div className={cn(styles["progress-bar"], className)}>
      <div
        className={styles["progress-bar__value-bar"]}
        style={{ width: `${percent}%` }}
      ></div>
    </div>
  )
}

export default ProgressBar
