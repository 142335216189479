import aliexpressLogo from "../images/partners/aliexpress-logo.svg";
import aliexpressBanner from "../images/partners/aliexpress-banner.jpg";
import aliexpressBannerTiny from "../images/partners/aliexpress-banner-tiny.jpg";
import lamodaBanner from "../images/partners/lamoda-banner.jpg";
import lamodaBannerTiny from "../images/partners/lamoda-banner-tiny.jpg"
import litresBanner from "../images/partners/litres-banner.jpg";
import litresBannerTiny from "../images/partners/litres-banner-tiny.jpg";
import mvideoBanner from "../images/partners/mvideo-banner.jpg";
import mvideoBannerTiny from "../images/partners/mvideo-banner-tiny.jpg";
import sephoraBanner from "../images/partners/sephora-banner.jpg";
import sephoraBannerTiny from "../images/partners/sephora-banner.jpg";


import mvideoLogo from "../images/partners/mvideo-logo.svg";

import litresLogo from "../images/partners/litres-logo.svg";

import sephoraLogo from "../images/partners/sephora-logo.svg";

import lamodaLogo from "../images/partners/lamoda-logo.svg";

import awardIcon5 from "../images/awards/award5.png";
import awardIcon12 from "../images/awards/award12.png";
import awardIcon13 from "../images/awards/award13.png";

import { IAwardCard, IPartner } from "../interfaces/common";

export const partnership: IPartner[] = [
  {
    value: "mvideo",
    icon: mvideoLogo,
    image: {
      src: mvideoBanner,
      srcTiny: mvideoBannerTiny,
    },
    bonus: 300,
    link: "https://ad.admitad.com/g/c9f1ad68bc036c77b9b63a3184f61a/",
    credited: false,
  },
  {
    value: "litres",
    icon: litresLogo,
    image: {
      src: litresBanner,
      srcTiny: litresBannerTiny,
    },
    bonus: 300,
    link: "https://www.litres.ru/?lfrom=386737181&pin=%25partnerID%25",
    credited: true,
  },
  {
    value: "sephora",
    icon: sephoraLogo,
    image: {
      src: sephoraBanner,
      srcTiny: sephoraBannerTiny,
    },
    bonus: 300,
    link: "https://ad.admitad.com/g/thk3mfvq7x036c77b9b62d4411df80/",
    credited: false,
  },
  {
    value: "lamoda",
    icon: lamodaLogo,
    image: {
      src: lamodaBanner,
      srcTiny: lamodaBannerTiny,
    },
    bonus: 300,
    link: "https://modato.ru/g/3f2779c2d4036c77b9b64e8640d77b/",
    credited: false,
  },
  {
    value: "aliexpress",
    icon: aliexpressLogo,
    image: {
      src: aliexpressBanner,
      srcTiny: aliexpressBannerTiny,
    },
    bonus: 300,
    link: "https://alitems.com/g/vv3q4oey1v036c77b9b6b6d1781017/",
    credited: false,
  },
];

export const partnerAwards: IAwardCard[] = [
  {
    type: "another",
    icon: awardIcon5
  },
  {
    type: "another",
    icon: awardIcon12
  },
  {
    type: "another",
    icon: awardIcon13
  }
]