import classNames from "classnames";
import React from "react";
import { digitsDivide } from "../../../utils/digitsDivide";

import styles from "./CountBadge.module.scss";

interface ICountBadge {
  value: number;
  isPadded?: boolean;
}

const CountBadge: React.FC<ICountBadge> = ({ value, isPadded }) => {
  const countBadge = classNames(styles["countBadge"], {
    "countBadge--padded": isPadded,
  });

  return (
    <span className={countBadge}>
      x{digitsDivide(value)}
    </span>
  );
};

export default CountBadge;
