import { IAwardCard } from "../interfaces/common";

import gift1 from "../images/dailyGifts/gift1.png";
import gift2 from "../images/dailyGifts/gift2.png";
import gift3 from "../images/dailyGifts/gift3.png";
import gift4 from "../images/dailyGifts/gift4.png";
import gift5 from "../images/dailyGifts/gift6.png";
import { ReactFragment } from "react";

export const getAwardsForDailyIncome = (
  renderButton: (index: number) => ReactFragment
): IAwardCard[] => {
  return [
    {
      icon: gift1,
      type: "another",
      text: "1 день",
      renderButton: () => renderButton(1),
    },
    {
      icon: gift2,
      type: "another",
      text: "2 день",
      renderButton: () => renderButton(2),
    },
    {
      icon: gift3,
      type: "another",
      text: "3 день",
      renderButton: () => renderButton(3),
    },
    {
      icon: gift4,
      type: "another",
      text: "4 день",
      renderButton: () => renderButton(4),
    },
    {
      icon: gift5,
      type: "another",
      text: "5 день",
      renderButton: () => renderButton(5),
    },
    {
      icon: gift5,
      type: "another",
      text: "10 день",
      renderButton: () => renderButton(6),
    },
  ];
};

export const marks = {
  1: { label: "1" },
  2: { label: "2" },
  3: { label: "3" },
  4: { label: "4" },
  5: { label: "5" },
  6: { label: "10" },
};
