import { FC, useContext, useEffect, useState } from "react";
import classNames from "classnames";

import HudButton from "../HudButton/HudButton";
import Currency from "../Currency/Currency";

import { observer } from "mobx-react-lite";
import { GameStateStoreContext } from "../../../store/GameState";

import { makeFriendly } from "../../../utils/balance";
import { QuestStoreContext } from "../../../store/Quest";
import { FieldStoreContext } from "../../../store/Field";

import heart from "../../../images/svg/heart.svg";

import check from "../../../images/svg/check.svg";
import questsIcon from "../../../images/hud/quests-icon.svg";
import partnersIcon from "../../../images/hud/partners-icon.png";
import iconWarn from "../../../images/svg/icon-warn.svg";
import technologiesIcon from "../../../images/hud/technologies-icon.png";
import {ReactComponent as ClientsCountIcon} from "../../../images/hud/clientsCount.svg";

import staffIcon from "../../../images/hud/staff-icon.png";
import leaderboardIcon from "../../../images/hud/leaderboard-icon.png";
import tenderIcon from "../../../images/hud/tender-icon.png";

import tenderBanner from "../../../images/quest/banners/tender-banner.jpg";

import styles from "./GameHud.module.scss";
import ModalContextStore from "../../../store/Modal";
import Estimation from "../Estimation/Estimation";
import GradientProgressBar from "../GradientProgressBar";
import { warningPoints } from "../../../constants/warning";
import { TutorialStoreContext } from "../../../store/Tutorial";
import TutorialText from "../TutorialText";
import { getPercentage } from "../../../utils/getPercentage";
import SplittedNumber from "../../ui/SplittedNumber/SplittedNumber";

const GameHud: FC = () => {
  const {
    trends,
    health,
    gold,
    crystal,
    getEpoch,
    network,
    maxClients,
    totalClients,
    isTutorial,
  } = useContext(GameStateStoreContext);
  const { openModal, isMarketModalOpen } = useContext(ModalContextStore);
  const { setScale, setCoords } = useContext(FieldStoreContext);
  const { newQuests, errors } = useContext(QuestStoreContext);
  const { activeUIElement, step, textPos, tutorialText } = useContext(TutorialStoreContext);

  const [epoch, setEpoch] = useState(getEpoch());
  const [currentError, setCurrentError] = useState(0);

  const handleEpochClick = () => {
    if (epoch && !isTutorial) openModal('epoch', {modalImage: epoch.image});
  };

  const getIsTendersAccessibly = (): boolean => {
    if (network)
      return network?.cities.filter(
        (city) => city.state !== "DRAFT" && city.state !== "UPPING_LEVEL"
      ).length >= 4
    
    return false;
  };

  const handleWarningClick = () => {
    const { x, y } =
      warningPoints[errors[currentError].params.constructionIndex];
    setCurrentError((prevState) =>
      ++prevState === errors.length ? 0 : prevState
    );
    setCoords(x, y);
  };

  const handleTopInfoClick = (kind: "clients" | "loyalty" | "score") => {
    if (isTutorial) return;

    if (kind === "score") openModal("info/score");
    else if (kind === "loyalty") openModal("info/loyalty");
    else if (kind === "clients") openModal("info/clients");
  }

  useEffect(() => {
    setEpoch(getEpoch());
  }, [network, setEpoch, getEpoch]);

  const clientsProgress = getPercentage(totalClients, maxClients);

  const staffWrapperClassName = classNames({ "highlighted with-arrow bottom": activeUIElement === 'joinEmploee' });
  const techWrapperClassName = classNames({ "highlighted with-arrow bottom": activeUIElement === 'addNewTech' });
  const coinsWrapperClassName = classNames({ "highlighted with-arrow top": activeUIElement === 'addCoins' });
  const loayltyWrapperClassName = classNames(styles["game-hud__header__indicators"], {
    "highlighted": activeUIElement === 'loyaltyBadge'
  });
  const hudHeaderClassName = classNames(styles["game-hud__header"],
    { [styles["game-hud__header--shadowed"]]: isTutorial }
  );
  const isShowNextButton = step === 0 || step === 1 || step === 18;

  const hudShouldUp = staffWrapperClassName || techWrapperClassName;
  const headerShouldUp = coinsWrapperClassName || activeUIElement === 'loyaltyBadge';
  return (
    <>
      <div className={styles["game-hud"]}>
        <div className={hudHeaderClassName} style={!!headerShouldUp ? {zIndex: 1} : undefined}>
          <div 
            className={classNames(
              styles["game-hud__header__currency"], 
              { [styles["game-hud__header__currency--hide"]]: isMarketModalOpen }
            )}
          >
            <div className={coinsWrapperClassName}>
              <Currency
                id="addCoins"
                onAdd={() => {
                  openModal("store/gold");
                }}
                currency="gold"
                count={gold}
              />
            </div>
            <Currency
              onAdd={() => {
                openModal("store/crystal");
              }}
              currency="crystal"
              count={crystal}
            />
          </div>
          <div className={loayltyWrapperClassName}>
            <div className={styles["game-hud__header__indicators__health"]} onClick={() => handleTopInfoClick("score")}>
              <img src={heart} alt="" />
              {health && <span> {makeFriendly(health)}</span>}
            </div>
            <div>
              <div
                className={styles["game-hud__header__indicators__age"]}
                onClick={handleEpochClick}
              >
                <img
                  className={styles["game-hud__header__indicators__icon"]}
                  src={check}
                  alt=""
                />
                <span>{epoch?.epochName}</span>
              </div>
              <Estimation trends={trends} withPointer onClick={() => handleTopInfoClick("loyalty")} />              
              <GradientProgressBar type={"purple"} progress={clientsProgress} onClick={() => handleTopInfoClick("clients")}>
                <ClientsCountIcon />
                <span className={styles["game-hud__clients-count"]}><SplittedNumber isAdaptive value={totalClients}/></span>
              </GradientProgressBar>
            </div>
          </div>
        </div>

        <div className={styles["game-hud__footer__zoom"]}>
          <HudButton
            text="+"
            size="small"
            onClick={() => {
              setScale(0.4);
            }}
          />
          <HudButton
            text="-"
            size="small"
            onClick={() => {
              setScale(0.25);
            }}
          />
        </div>

        {errors.length > 0 && (
          <div className={styles["game-hud__footer__error"]}>
            <HudButton
              icon={iconWarn}
              size="normal"
              warning
              onClick={handleWarningClick}
            />
          </div>
        )}

        {getIsTendersAccessibly() && (
          <div className={styles["game-hud__footer__tenders"]}>
            <HudButton
              onClick={() => openModal('tender', {modalImage: tenderBanner})}
              icon={tenderIcon}
              size="normal"
            />
          </div>
        )}

        <div className={styles["game-hud__footer__main"]} style={!!hudShouldUp ? {zIndex: 1} : undefined}>
          <div>
            <HudButton
              onClick={() => openModal("leaderboard")}
              icon={leaderboardIcon}
              size="normal"
            />
            <HudButton
              onClick={() => openModal("partnership")}
              icon={partnersIcon}
              size="normal"
            />
          </div>
          <div>
            <HudButton
              onClick={() => openModal("quest")}
              icon={questsIcon}
              size="big"
              badge={newQuests}
            />
          </div>
          <div>
            <div className={staffWrapperClassName}>
              <HudButton
                onClick={() => openModal("staff")}
                icon={staffIcon}
                size="normal"
                id="joinEmploee"
              />
            </div>
            <div className={techWrapperClassName}>
              <HudButton
                onClick={() => openModal("technologies")}
                icon={technologiesIcon}
                size="normal"
                id="addNewTech"
              />
            </div>
          </div>
        </div>
        {isTutorial && tutorialText && (
          <>
            <div className={styles.tutorial__mobile} style={{ left: textPos?.mobile?.x, top: textPos?.mobile?.y }}>
              <TutorialText isShowNextButton={isShowNextButton}/>
            </div>
            
            <div className={styles.tutorial__desktop} style={{ left: textPos?.desktop.x, top: textPos?.desktop.y }}>
              <TutorialText isShowNextButton={isShowNextButton}/>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default observer(GameHud);
