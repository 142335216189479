import React, { useContext } from "react";

import Button from "../../Button";

import * as MarketData from "../../../../constants/market";
import { ToastStoreContext } from "../../../../store/Toast";

import { fastBuyModalStoreContext } from "../../../../store/FastBuyModal";
import { confirmModalStoreContext } from "../../../../store/ConfirmModal";
import { currencyType, packSizeType } from "../../../../interfaces/common";
import { GameStateStoreContext } from "../../../../store/GameState";

import bonusesIcon from "../../../../images/store/currencies/bonuses-icon.png";
import rublesIcon from "../../../../images/store/currencies/rubles-icon.png";
import crystalIcon from "../../../../images/store/currencies/crystal-icon.png";

import MultipurposeCard from "../../MultipurposeCard/MultipurposeCard";
import ModalContextStore from "../../../../store/Modal";

import { buyGold } from "../../../../services/store";

import styles from "./Market.module.scss";

import {
  currenciesPackCodes,
  IMarketCurrencyPack,
} from "../../../../interfaces/Market";
import { observer } from "mobx-react-lite";
import { TutorialStoreContext } from "../../../../store/Tutorial";
import classNames from "classnames";
import { digitsDivide } from "../../../../utils/digitsDivide";
import SplittedNumber from "../../../ui/SplittedNumber/SplittedNumber";
import CountBadge from "../../CountBadge/CountBadge";

type storeCurrencyProps = {
  currency: currencyType;
  onBuy?: () => void;
  currencyPacks: IMarketCurrencyPack[]
  balance: {gold: number, crystal: number}
};

const StoreCurrency: React.FC<storeCurrencyProps> = ({ currency, onBuy, currencyPacks, balance }) => {
  const { openModal } = useContext(ModalContextStore);
  const { setConfirm } = useContext(confirmModalStoreContext);
  const { setKit } = useContext(fastBuyModalStoreContext);
  const { pushToast } = useContext(ToastStoreContext);
  const { setCrystal, setGold } = useContext(GameStateStoreContext);
  const { activeUIElement, nextStep } = useContext(TutorialStoreContext);

  const buyCrystal = (code?: currenciesPackCodes) => {
    if (code) {
      setKit(code);
      openModal("fastBuy");
    }
  };

  const onGoldBuy = (product: IMarketCurrencyPack) => {
    setConfirm(
      () => {
        if (product && product.code && product.price?.CRYSTAL) {
          if(product.price?.CRYSTAL <= balance.crystal){
            buyGold(product.code)
            .then((response) => {
              if (!response || !response.data) throw new Error();
              if (response?.data.status === "SUCCESS") {
                if (onBuy) onBuy();
                nextStep();
                setCrystal(response?.data.results.messages.data[0].scene.crystal);
                setGold(response?.data.results.messages.data[0].scene.gold)
                pushToast("success", "Покупка прошла успешно!");
              }
            })
            .catch(() => {
              pushToast("error", "Что-то произошло");
            })
          }else{
            openModal("notEnoughCrystals")
          }
          
        }
      },
      {
        confirmHeader: `Купить набор монет «${product.label}»?`,
        confirmText: `${digitsDivide(product.amount || 0)} монет за ${product.price?.CRYSTAL} кристалла`,
      }
    );

    openModal("confirm");
  };

  const renderButton = (product: IMarketCurrencyPack) => {

    if (currency === "gold") {
      return (
        <Button>
          <img src={crystalIcon} alt="crystal" />
          <SplittedNumber value={product.price?.CRYSTAL || 0} className={styles["price-text"]} isAdaptive/>
        </Button>
      );
    }
    return (
      <Button>
        <img src={bonusesIcon} alt="" />
        <span>
          <SplittedNumber value={product.price?.bonus || 0} className={styles["price-text"]} isAdaptive/>
          {" /"}
        </span>
        <img src={rublesIcon} alt="" />
        <span><SplittedNumber value={product.price?.money || 0} className={styles["price-text"]} isAdaptive/></span>
      </Button>
    );
  };

  return (
    <div className={styles.store__currency}>
      {MarketData.packSizes.map(
        (size: { code: packSizeType; name: string }, index) => {
          const productCode = currency + size.code;
          
          const product = (currencyPacks as IMarketCurrencyPack[]).find(
            (product) => product.code?.toLowerCase() === productCode
          );
          
          if (product) {
            const isTutorialItem = index === 1 && activeUIElement === 'buyCoins';
            const storeCardClassName = classNames({ "highlighted with-arrow left": isTutorialItem });
            return (
              <MultipurposeCard
                className={storeCardClassName}
                onClickHandler={currency === "gold" ? () => onGoldBuy(product) : () => { buyCrystal(product.code)}}
                renderButton={() => renderButton(product)}
                key={index}
              >
                <div className={styles.store__currency__header}>
                  <h5>{size.name}</h5>
                </div>
                <div className={styles.store__currency_body}>
                  <img src={product.image} alt="" />
                  {product.amount && <CountBadge value={product.amount}/>}
                </div>
              </MultipurposeCard>
            );
          }
          return null;
        }
      )}
    </div>
  );
};

export default observer(StoreCurrency);
