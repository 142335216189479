import {
  boosterSizeType,
  boosterTypes,
  IBooster,
  packSizeType,
  storeTabs,
} from "../interfaces/common";
import kitIcon from "../images/svg/kits-icon.svg";
import diamondIcon from "../images/svg/diamond.svg";
import coinIcon from "../images/svg/coin.svg";

import boosterIcon from "../images/store/tabs/boosters-tab-icon.svg";

import diamondLightIcon from "../images/store/crystals/diamond_light-icon.png";
import diamondStandartIcon from "../images/store/crystals/diamond_standart-icon.png";
import diamondMaxiIcon from "../images/store/crystals/diamond_maxi-icon.png";
import diamondVipIcon from "../images/store/crystals/diamond_vip-icon.png";

import goldLightIcon from "../images/store/gold/gold_light-icon.png";
import goldStandartIcon from "../images/store/gold/gold_standart-icon.png";
import goldMaxiIcon from "../images/store/gold/gold_maxi-icon.png";
import goldVipIcon from "../images/store/gold/gold_vip-icon.png";

import bonusesIcon from "../images/store/tabs/bonuses-tab-icon.png";
import rublesIcon from "../images/store/tabs/rubles-tab-icon.png";

import loyalityLowIcon from "../images/store/boosters/loyality/loyality_low-icon.png";
import loyalityMiddleIcon from "../images/store/boosters/loyality/loyality_middle-icon.png";
import loyalityHighIcon from "../images/store/boosters/loyality/loyality_high-icon.png";

import adLowIcon from "../images/store/boosters/ad/ad_low-icon.png";
import adMiddleIcon from "../images/store/boosters/ad/ad_middle-icon.png";
import adHighIcon from "../images/store/boosters/ad/ad_high-icon.png";

import maintenanceLowIcon from "../images/store/boosters/maintenance/maintenance_low-icon.png";
import maintenanceMiddleIcon from "../images/store/boosters/maintenance/maintenance_middle-icon.png";
import maintenanceHighIcon from "../images/store/boosters/maintenance/maintenance_high-icon.png";

import repairCrashHighIcon from "../images/store/boosters/repairCrash/repairCrash-icon.png";
import {
  crystalPackCodes,
  goldPackCodes,
  IMarketCurrencyPack
} from "../interfaces/Market";

export const boosterSizes: boosterSizeType[] = ["low", "middle", "high"];

export const packSizes: { code: packSizeType; name: string }[] = [
  { code: "light", name: "Лайт" },
  { code: "standard", name: "Стандарт" },
  { code: "max", name: "Макси" },
  { code: "vip", name: "VIP" },
];

export const boosters: {
  [key in boosterTypes]: { [key in boosterSizeType]: { image: string } };
} = {
  loyalty: {
    low: { image: loyalityLowIcon },
    middle: { image: loyalityMiddleIcon },
    high: { image: loyalityHighIcon },
  },
  advertising: {
    low: { image: adLowIcon },
    middle: { image: adMiddleIcon },
    high: { image: adHighIcon },
  },
  maintenance: {
    low: { image: maintenanceLowIcon },
    middle: { image: maintenanceMiddleIcon },
    high: { image: maintenanceHighIcon },
  },
  repairCrash: {
    low: { image: repairCrashHighIcon },
    middle: { image: repairCrashHighIcon },
    high: { image: repairCrashHighIcon },
  },
};

export const fastBuyCurrencies = [
  {
    icon: bonusesIcon,
    value: "bonus",
    text: "За бонусы",
  },
  {
    icon: rublesIcon,
    value: "money",
    text: "За деньги",
  },
];

export const storeBoosters: {
  name: string;
  items: { price: number; booster: IBooster }[];
}[] = [
  {
    name: "Программа лояльности",
    items: [
      {
        price: 1,
        booster: { size: "low", type: "loyalty" },
      },
      {
        price: 3,
        booster: { size: "middle", type: "loyalty" },
      },
      {
        price: 4,
        booster: { size: "high", type: "loyalty" },
      },
    ],
  },
  {
    name: "Реклама",
    items: [
      {
        price: 2,
        booster: { size: "low", type: "advertising" },
      },
      {
        price: 4,
        booster: { size: "middle", type: "advertising" },
      },
      {
        price: 5,
        booster: { size: "high", type: "advertising" },
      },
    ],
  },
  {
    name: "Техническое обслуживание",
    items: [
      {
        price: 2,
        booster: { size: "low", type: "maintenance" },
      },
      {
        price: 3,
        booster: { size: "middle", type: "maintenance" },
      },
      {
        price: 4,
        booster: { size: "high", type: "maintenance" },
      },
    ],
  },
  {
    name: "Устранение аварий",
    items: [
      {
        price: 100,
        booster: { size: "high", type: "repairCrash" },
      },
    ],
  },
];

export const tabs: { value: storeTabs; icon: string; text: string }[] = [
  { value: "kit", icon: kitIcon, text: "Наборы" },
  { value: "crystal", icon: diamondIcon, text: "Кристаллы" },
  { value: "gold", icon: coinIcon, text: "Монеты" },
  { value: "booster", icon: boosterIcon, text: "Бустеры" },
];

export const currencyPacks: {
  gold: { [key in goldPackCodes]: IMarketCurrencyPack };
  crystal: { [key in crystalPackCodes]: IMarketCurrencyPack };
} = {
  gold: {
    goldLight: {
      price: {
        CRYSTAL: 31,
      },
      image: goldLightIcon,
    },
    goldStandard: {
      price: {
        CRYSTAL: 31,
      },
      image: goldStandartIcon,
    },
    goldMax: {
      price: {
        CRYSTAL: 31,
      },
      image: goldMaxiIcon,
    },
    goldVip: {
      price: {
        CRYSTAL: 31,
      },
      image: goldVipIcon,
    },
  },
  crystal: {
    crystalLight: {
      price: {
        bonus: 15,
        money: 19,
      },
      image: diamondLightIcon,
    },
    crystalStandard: {
      price: {
        bonus: 59,
        money: 89,
      },
      image: diamondStandartIcon,
    },
    crystalMax: {
      price: {
        bonus: 159,
        money: 249,
      },
      image: diamondMaxiIcon,
    },
    crystalVip: {
      price: {
        bonus: 349,
        money: 499,
      },
      image: diamondVipIcon,
    },
  },
};
