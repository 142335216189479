import { FC, MutableRefObject } from 'react'
import { IMessage } from '../Message/Message'
import Message from '../Message/Message'
import styles from './MessageList.module.scss'
import classNames from 'classnames'

type MessageListProps = {
  messages: IMessage[]
  containerRef: MutableRefObject<HTMLDivElement>
  className?: string
}

const MessageList: FC<MessageListProps> = ({messages, containerRef, className}) => {
  return (
    <div className={classNames(styles["message-list"], className)} ref={containerRef}>
      {
        messages.map(({text, createAt, supportMessage, id}) => 
          <Message 
            id={id}
            text={text} 
            createAt={createAt} 
            supportMessage={supportMessage} 
            className={styles["message-list__message"]}
            key={id}
          />)
      }
    </div>
  )
}

export default MessageList