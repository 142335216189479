import { FC, useContext, useEffect, useRef, useState } from 'react'
import styles from './GameEndResults.module.scss'
import layoutStyles from '../../layouts/Promo/Promo.module.scss'
import classNames from 'classnames'
import bgSunEllipse from '../../images/promo/sun-like-ellipse-blurred-big.png'
import gameEndResultsHero from '../../images/promo/game-end-results-hero.png'
import gameEndResultsHeroTiny from '../../images/promo/game-end-results-hero-tiny.png'
import Button from '../../components/common/Button'
import peopleIcon from '../../images/promo/hr.svg'
import clocksIcon from '../../images/promo/clocks-icon.svg'
import { digitsDivide } from '../../utils/digitsDivide'
import SimplePrize from '../Prizes/SimplePrize/SimplePrize'
import { useHistory } from 'react-router'
import ROUTES from '../../enums/routes'
import BlurUpImage from '../../components/common/BlurUpImage/BlurUpImage'
import { GameResultsStoreContext } from '../../store/GameResults'
import FeedbackForm from '../../components/common/FeedbackForm/FeedbackForm'
import { AuthorizationContext } from '../../store/Auth'
import { IDropdownValue, IUser } from '../../interfaces/common'
import { sendFeedback } from '../../services/feedback'
import { getFullProfile } from '../../services/user'
import { IFeedbackForm } from '../../interfaces/Feedback'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import ModalContextStore from '../../store/Modal'
import { FeedbackStoreContext } from '../../store/Feedback'
import Modal from '../../components/Modal'

const GameEndResults: FC<{}> = () => {
  const { totalHours, totalAbonents, prizes } = useContext(GameResultsStoreContext)
  const { isAuthorized } = useContext(AuthorizationContext)
  const { openModal } = useContext(ModalContextStore)
  const { setIssueId } = useContext(FeedbackStoreContext)
  
  
  const [categories, setCategories] = useState<IDropdownValue[]>([])
  const [userInfo, setUserInfo] = useState<IUser>()

  const contentBlockRef = useRef<null | HTMLDivElement>(null)
  
  const history = useHistory()

  const { executeRecaptcha } = useGoogleReCaptcha()

  useEffect(() => {
    setCategories([{
      value: "RQ7",
      label: "Пожелания и предложения"
    }])
    isAuthorized &&
      getFullProfile().then(
        (response) =>
          response &&
          setUserInfo({
            firstName: response.results.user.data.firstName,
            login: response.results.user.data.login,
            email: response.results.user.data.email,
          })
      );
  }, [isAuthorized, setCategories])

  const rememberButtonOnClick = () => {
    if (contentBlockRef && contentBlockRef.current) {
      contentBlockRef.current.scrollIntoView({behavior: "smooth"})
    }
  }

  const allGiftsButtonClick = () => {
    history.push(ROUTES.PRIZES)
  }

  const handleFeedback = async (data: IFeedbackForm) => {
    if (executeRecaptcha) {
      const captcha = await executeRecaptcha("feedback")
      const newFormData = new FormData();
      newFormData.append("firstName", data.firstName);
      newFormData.append("email", data.email);
      newFormData.append("login", data.login);
      newFormData.append("requestType", data.requestType[0].value);
      newFormData.append("comment", data.comment);
      newFormData.append("captchaResponse", captcha);
      const sendFeedbackResponce = await sendFeedback(newFormData)
      const issueId = sendFeedbackResponce?.data.results?.id.data;
      if (issueId) {
        setIssueId(issueId);
        openModal("feedbackSuccess");
      }
    };
  };

  return (
    <>
      <div className={classNames(layoutStyles["promo"], styles["game-end-results"])}>
        <div className={styles["game-end-results__head-wrapper"]}>
          <div className={styles["game-end-results__hero-bg"]}></div>
          <div className={styles["game-end-results__head-content-wrapper"]}>
            <img
              className={styles["game-end-results__blurred-sun"]} 
              src={bgSunEllipse} 
              alt="blur" 
            />
            <BlurUpImage 
              placeholderImageUrl={gameEndResultsHeroTiny}
              originalImageUrl={gameEndResultsHero}
              alt="hero"
              className={styles["game-end-results__hero-img"]}
            />
            <div className={styles["game-end-results__head-content"]}>
              <div className={styles["game-end-results__head-content-header"]}>Спасибо,<br/> что играли в Tycoon!</div>
              <div className={styles["game-end-results__head-content-subheader"]}>
                Подведены итоги игры!
              </div>
              <Button 
                className={styles["game-end-results__remember-button"]}
                isRed
                noDelay
                text="Вспомнить как это было"
                onClick={rememberButtonOnClick}
              />
            </div>
          </div>
        </div>
        <div className={styles["game-end-results__content"]} ref={contentBlockRef}>
          <div className={classNames(styles["game-end-results__list"], styles["game-end-results__results"])}>
            <h2 className={styles["game-end-results__content-header"]}>
              Вы достигли впечатляющих результатов!
            </h2>
            <div 
              className={classNames(styles["game-end-results__result-item"], styles["result-item"])}
            >
              <img src={clocksIcon} alt="time" className={styles["result-item__icon"]}/>
              <div className={styles["result-item__text"]}>
                {"Вы развивали связь на протяжении "}
                <span className={styles["result-item__value"]}>
                  {digitsDivide(totalHours)}
                </span>
                {" часов"}
              </div>
            </div>
            <div 
              className={classNames(styles["game-end-results__result-item"], styles["result-item"])}
            >
              <img src={peopleIcon} alt="abonents" className={styles["result-item__icon"]}/>
              <div className={styles["result-item__text"]}>
                <span className={styles["result-item__value"]}>
                  {digitsDivide(totalAbonents)}
                </span>
                {" абонентов выбрали вашу телеком-компанию!"}
              </div>
            </div>
          </div>
          <div className={classNames(styles["game-end-results__list"], styles["game-end-results__prizes"])}>
            <h2 className={styles["game-end-results__content-header"]}>
              Вы выиграли следующие призы:
            </h2>
            {prizes.map((item, ind) => 
              <SimplePrize 
                icon={item.icon} 
                text={item.text} 
                className={styles["game-end-results__prize-item"]} 
                key={ind}
              />
            )}
          </div>
          <Button 
            className={styles["game-end-results__all-gifts-button"]}
            isRed
            noDelay
            text="Все мои подарки"
            onClick={allGiftsButtonClick}
          />
          <div className={styles["game-end-results__form-container"]}>
            <div className={styles["game-end-results__form-label"]}>
              Приглашаем вас поделиться впечатлениями и пожеланиями, ваша обратная 
              связь поможет нам сделать следующие игры еще интереснее!
            </div>
            <FeedbackForm 
              firstNameInitial={userInfo?.firstName || ""}
              loginInitial={userInfo?.login || ""}
              emailInitial={userInfo?.email || ""}
              selectCategories={categories}
              isAuthorized={isAuthorized}
              onSubmit={handleFeedback}
            />
          </div>
        </div>
      </div>
      <Modal />
    </>
  )
}

export default GameEndResults