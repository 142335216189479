import { FC } from 'react'
import { IModalNotificationsContent } from '../../../../interfaces/ModalNotificatioinsContent'
import Button from '../../Button'
import ModalBlock from '../../ModalBlock'
import styles from './ModalNotificationView.module.scss'

interface IModalNotificationViewProps extends IModalNotificationsContent {
  buttonOnClickHandler?: () => void
}

const ModalNotificationView: FC<IModalNotificationViewProps> = ({header, description, buttonText, buttonOnClickHandler}) => {
  
  return (
    <ModalBlock kind="common" className={styles["notification-view"]}>
      <h4 className={styles["notification-view__header"]}>{header}</h4>
      <p className={styles["notification-view__description"]}>{description}</p>
      <Button 
        className={styles["notification-view__accept-button"]}
        onClick={buttonOnClickHandler}
      >
        {buttonText}
      </Button>
    </ModalBlock>
  )
}

export default ModalNotificationView