import {FC} from 'react'
import styles from './SendMessageButton.module.scss'
import sendMessageArrow from '../../../../images/svg/send-message-arrow.svg'
import classNames from 'classnames'

type SendMessageButtonProps = {
  onClick: () => void
  className?: string
}

const SendMessageButton: FC<SendMessageButtonProps> = ({onClick, className}) => {

  return (
    <button 
      className={classNames(styles["send-message-button"], className)} 
      onClick={onClick}
    >
      <img 
        src={sendMessageArrow} 
        alt="send" 
        className={styles["send-message-button__arrow"]}
      />
    </button>
  )
}

export default SendMessageButton