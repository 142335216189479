import { CITIES } from "../enums/cities";
import { COMMUNICATION_LINES } from "../enums/communication-lines";

interface ICoords {
  x: number;
  y: number;
}

export const warningPoints: {[key in CITIES | COMMUNICATION_LINES]: ICoords} = {
  LINE_1: {x: 660, y: 2566},
  LINE_2: {x: 1201, y: 3400},
  LINE_3: {x: 1044, y: 4615},
  LINE_4: {x: 2920, y: 2690},
  LINE_5: {x: 3784, y: 2266},
  LINE_6: {x: 3291, y: 748},
  CITY_1: {x: 1117, y: 1763},
  CITY_2: {x: 2004, y: 2336},
  CITY_3: {x: 1542, y: 3835},
  CITY_4: {x: 2131, y: 4600},
  CITY_5: {x: 3780, y: 2644},
  CITY_6: {x: 3373, y: 1162},
  CITY_7: {x: 2144, y: 90},
}