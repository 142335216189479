import React, { useContext } from "react";
import ROUTES from "../../../../enums/routes";
import { HashLink } from 'react-router-hash-link';
// import { investStartupAwards } from "../../../../constants/investStartupAwards";
import { IQuest } from "../../../../interfaces/common";
import { ToastStoreContext } from "../../../../store/Toast";
// import AwardList from "../../AwardList/AwardList";
import Button from "../../Button";
import ModalBlock from "../../ModalBlock";

import styles from "./QuestInfo.module.scss";

type eventSponsorshipProps = {
  action: IQuest;
};

const InvestStartup: React.FC<eventSponsorshipProps> = ({ action }) => {
    const { pushToast } = useContext(ToastStoreContext);

  const copyLink = () => {
    const text = window.location.origin + "/invite/" + action.params.referralCode
    let inputElement = document.createElement('input')
    inputElement.setAttribute('value', text)
    document.body.appendChild(inputElement)
    inputElement.select()
    document.execCommand("copy")
    inputElement.parentNode?.removeChild(inputElement)
    pushToast("success", "Ссылка скопирована")
  };

  return (
    <>
      {action && (
        <div>
          <ModalBlock kind={"common"}>
            <div className={styles.questInfo}>
              <h4>{action.params.eventName}</h4>
              <p>
                {action.params.eventDescription}
              </p>
            </div>
            <div className={styles.questInfo}>
              <h4>Пригласить друга</h4>
              <p>
                Подробнее об этой возможности вы можете узнать в разделе{" "}
                <HashLink
                  to={ROUTES.ABOUT + "#9" }
                  scroll={el => el.scrollIntoView({behavior: "smooth", block: "center"})}
                >
                  Об игре
                </HashLink>
              </p>
              <div className={styles.questInfo__startup__button}>
                <Button onClick={copyLink}>Скопировать ссылку</Button>
              </div>
            </div>
          </ModalBlock>
          {/* <ModalBlock kind={"common"}>
            <div className={styles.questInfo}>
              <h4>У вас есть шанс выиграть:</h4>
              <AwardList awards={investStartupAwards} />
            </div>
          </ModalBlock> */}
        </div>
      )}
    </>
  );
};

export default InvestStartup;
