import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import ReactDOM from "react-dom";

import ModalFooterSelector from "../../modalFooterSelector/ModalFooterSelector";
import * as MarketData from "../../../../constants/market";

import { GameStateStoreContext } from "../../../../store/GameState";
import {
  IBooster,
  IBoosterKit,
  storeTabs,
} from "../../../../interfaces/common";
import MarketBoosters from "./Market.boosters";
import MarketCurrency from "./Market.currency";
import MarketKit from "./Market.kit";

import Currency from "../../Currency/Currency";
import ModalBlock from "../../ModalBlock";

import modalStyles from "../SimpleModal.module.scss";
import styles from "./Market.module.scss";
import { getProducts } from "../../../../services/store";
import { MarketStoreContext } from "../../../../store/Market";
import useScroller from "../../../hooks/useScroller";
import ScrollArrow from "../../../ui/ScrollArrow/ScrollArrow";

type storePropsType = {
  initialTab?: storeTabs;
};

const Market: React.FC<storePropsType> = ({ initialTab }) => {
  const [tabVariable, setTabVariable] = useState<storeTabs>(
    initialTab || "kit"
  );

  const { setMarketCrystalKits, setMarketGoldKits, goldKits, crystalKits } =
    useContext(MarketStoreContext);

  const [marketBoosters, setMarketBoosters] = useState<
    {
      name: string;
      items: { price: { GOLD: number; CRYSTAL: number }; booster: IBooster }[];
    }[]
  >();
  const [marketBoosterKits, setMarketBoosterKits] = useState<IBoosterKit[]>();

  const { gold, crystal, isTutorial } = useContext(GameStateStoreContext);
  
  const { ScrollContainer, isShowArrow } = useScroller();

  const [selectedTab, setSelectedTab] = useState(
    MarketData.tabs.find((tab) => {
      return tab.value === tabVariable;
    })
  );

  const onTabSelected = (value: string) => {
    if (isValueTabVariable(value)) {
      setTabVariable(value);
    }
  };

  const isValueTabVariable = (value: string): value is storeTabs => {
    if (value) {
      const tabsArray: string[] = MarketData.tabs.map((tab) => tab.value);
      return tabsArray.indexOf(value) !== -1;
    }
    return false;
  };

  const getMarketProducts = () => {
    getProducts().then((response) => {
      const products = response.results.products.data;
      setMarketGoldKits(products.gold_pack);
      setMarketCrystalKits(products.crystal_pack);
      setMarketBoosterKits(products.buster_pack);

      setMarketBoosters(
        MarketData.storeBoosters.map((boosterType) => {
          return {
            name: boosterType.name,
            items: boosterType.items.map((booster) => {
              return {
                ...booster,
                price: products.buster.find(
                  (product: { code: string; price: { CRYSTAL: number } }) => {
                    if (booster.booster.type !== "repairCrash") {
                      return (
                        product.code ===
                        booster.booster.type +
                          booster.booster.size[0].toUpperCase() +
                          booster.booster.size.slice(1)
                      );
                    }
                    return product.code === booster.booster.type;
                  }
                )?.price,
              };
            }),
          };
        })
      );
    });
  };

  useEffect(() => {
    getMarketProducts();
    // we are sure that this hook is only called when partnerVariable changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelectedTab(
      MarketData.tabs.find((tab) => {
        return tab.value === tabVariable;
      })
    );
  }, [tabVariable, initialTab]);

  return (
    <div>
      <div className={modalStyles.modal__body}>
        <ScrollContainer>
          <div className={styles.store}>
            {!isTutorial && document.getElementById("root") &&
              ReactDOM.createPortal(
                <div className={styles.store__balance}>
                  <Currency
                    currency="gold"
                    count={gold}
                    onAdd={() => setTabVariable("gold")}
                  />
                  <Currency
                    currency="crystal"
                    count={crystal}
                    onAdd={() => setTabVariable("crystal")}
                  />
                </div>,
                document.getElementById("root")!
              )}

            <ModalBlock kind="common">
              <div className={styles.cards}>
                <h5>{selectedTab?.text}</h5>

                {selectedTab?.value === "kit" && (
                  <>
                    {marketBoosterKits?.map((kit, index) => {
                      return (
                        <MarketKit
                          onBuy={getMarketProducts}
                          key={index}
                          kit={kit}
                          balance={{ gold, crystal }}
                        />
                      );
                    })}
                  </>
                )}

                {selectedTab?.value === "booster" && (
                  <>
                    {marketBoosters?.map((booster, index) => {
                      return (
                        <MarketBoosters
                          balance={{ gold, crystal }}
                          key={index}
                          {...booster}
                        />
                      );
                    })}
                  </>
                )}

                {(selectedTab?.value === "gold" ||
                  selectedTab?.value === "crystal") && (
                  <>
                    <MarketCurrency
                      balance={{ gold, crystal }}
                      currency={selectedTab?.value}
                      currencyPacks={
                        selectedTab?.value === "gold" ? goldKits : crystalKits
                      }
                    />
                  </>
                )}
              </div>
            </ModalBlock>
          </div>
        </ScrollContainer>
      </div>

      <div className={modalStyles.modal__footer + " " + styles.selector}>
        <ModalFooterSelector
          selected={tabVariable}
          onSelected={(value) => onTabSelected(value)}
          variables={MarketData.tabs}
        />
      </div>
      
      {isShowArrow && <ScrollArrow />}
    </div>
  );
};

export default observer(Market);
