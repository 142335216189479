import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";

import ModalBlock from "../../ModalBlock";

import { checkSession, logout, signIn } from "../../../../services/auth";
import AuthForm from "./AuthForm";
import Terms from "./Terms";
import ErrorMessage from "./ErrorMessage";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { AuthorizationContext } from "../../../../store/Auth";
import { getFullProfile } from "../../../../services/user";
import { UserStoreContext } from "../../../../store/User";
import BonusWarning from "./BonusWarning";
import ROUTES from "../../../../enums/routes";
import useScroller from "../../../hooks/useScroller";

import modalStyles from "../SimpleModal.module.scss";
import styles from "./Auth.module.scss";
import { observer } from "mobx-react-lite";
import RTSignUpActionSteps from "./RTSignUpActionSteps";
import { IFormState } from "../../../../interfaces/common";
import ScrollArrow from "../../../ui/ScrollArrow/ScrollArrow";

const Auth: React.FC = () => {
  type formElementType = "password" | "login";

  const [terms, setTerms] = useState(false);
  const [bonus, setBonus] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { setIsAuthorized, setWithError, withError, isShowRTSignUpActionSteps } = useContext(AuthorizationContext);
  const { user, setUser } = useContext(UserStoreContext);

  const [formState, setFormState] = useState<IFormState>({
    login: { withError: false },
    password: { withError: false },
  });

  const history = useHistory();
  const params = useParams<{ ref: string }>();

  const { ScrollContainer, isShowArrow } = useScroller();

  const handleProceed = () => {
    setIsAuthorized(true);
    history.push(ROUTES.APP);
  };

  useEffect(() => {
    user && setBonus(user.bonusAvailable === false);
  }, [user]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form = Object.fromEntries(new FormData(e.currentTarget));

    if (!Object.values(form).includes("") && executeRecaptcha) {
      executeRecaptcha("login").then((captcha) =>
        signIn(
          form.login.toString(),
          form.password.toString(),
          captcha,
          params.ref
        ).then((response) => {
          if (response?.status === 200) {
            getFullProfile().then((response) => {
              setUser(response.results?.user?.data);
              if (response.results.user.data.bonusAvailable === false) {
                setBonus(true);
                logout().then(() => {
                  checkSession();
                });
                return;
              }
              if (!localStorage.getItem("terms")) setTerms(true);
              else handleProceed();
            });
          } else setWithError(true);
        })
      );
    } else {
      for (const entry in form)
        if (!e.currentTarget[entry].value)
          setFormState({
            ...formState,
            [e.currentTarget[entry].name as formElementType]: {
              withError: true,
            },
          });
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (
      formState[e.currentTarget.name as formElementType].withError &&
      e.currentTarget.value
    )
      setFormState({
        ...formState,
        [e.currentTarget.name as formElementType]: { withError: false },
      });
  };

  return (
    <div className={styles.auth__body}>
      <div className={modalStyles.modal__body}>
        <ScrollContainer>
          <ModalBlock kind="common">
            {(
              (withError && <ErrorMessage handleProceed={() => setWithError(false)} />) ||
                (terms && <Terms handleProceed={handleProceed} />) ||
                  (bonus && <BonusWarning />) ||
                    (isShowRTSignUpActionSteps ? <RTSignUpActionSteps/> : <AuthForm
                      formState={formState}
                      handleSubmit={handleSubmit}
                      handleBlur={handleBlur}
                    />)
              )
            }
          </ModalBlock>
        </ScrollContainer>
      </div>
      {isShowArrow && <ScrollArrow />}
    </div>
  );
};

export default observer(Auth);
