import { FC, useEffect, useMemo, useState } from 'react'
import styles from './FeedbackForm.module.scss'
import classNames from 'classnames'
import { Controller, useForm } from 'react-hook-form'
import { IFeedbackForm } from '../../../interfaces/Feedback'
import Select from 'react-dropdown-select'
import { IDropdownValue } from '../../../interfaces/common'
import Button from '../Button'

interface IFeedbackFormProps {
  onSubmit: (data: any, files?: File[]) => Promise<void>
  firstNameInitial: string
  loginInitial: string
  emailInitial: string
  selectCategories: IDropdownValue[]
  isAuthorized: boolean
  canAttachFiles?: boolean
  className?: string
}

const FeedbackForm: FC<IFeedbackFormProps> = ({
  firstNameInitial, 
  loginInitial, 
  emailInitial, 
  onSubmit, 
  isAuthorized, 
  selectCategories,
  canAttachFiles,
  className
}) => {
  const { register, handleSubmit, control, reset, formState: { errors }} = useForm<IFeedbackForm>()

  const [files, setFiles] = useState<File[]>([])

  const defaultValues = useMemo(() => ({
    firstName: firstNameInitial || "",
    login: loginInitial || "",
    email: emailInitial || "",
    comment: "",
  }), [firstNameInitial, loginInitial, emailInitial])

  useEffect(() => {
    reset(defaultValues)
  }, [reset, defaultValues])

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files !== null && e.target.files[0] !== null) {
      const file = e.target.files[0];
      setFiles((files) => [...files, file]);
    }
    e.target.value = "";
  };

  const handleDeleteFile = (index: number) => {
    setFiles((files) => files.filter((file, i) => i !== index));
  };

  const handleResult = (data: any) => {
    onSubmit(data, files).finally(() => {
      reset(defaultValues)
    })
    .finally(() => {
      setFiles([])
    })
  }

  return (
    <form className={classNames(styles.feedbackForm, className)} onSubmit={handleSubmit(data => handleResult(data))}>
      <div className={styles.feedbackForm__inputs}>
        <input
          {...register("firstName", {
            required: true
          })}
          className={classNames("input", {"input--error": errors.firstName})}
          placeholder="Ваше имя"
          disabled={isAuthorized === true}
        />
        <input
          {...register("login", {
            required: true
          })}
          className={classNames("input", {"input--error": errors.login})}
          placeholder="Ваш логин"
          disabled={isAuthorized === true}
        />
      </div>
      <div className={styles.feedbackForm__inputs}>
        <input
          {...register("email", {
            required: true,
          })}
          className={classNames("input", {"input--error": errors.email})}
          type="email"
          placeholder="Email"
        />
        <Controller
          name="requestType"
          control={control}
          rules={{
            required: true
          }}
          render={(controllerProps) => 
            <Select
              placeholder="Тематика обращения"
              className={classNames(styles.select, {
                [styles["select--error"]]: errors.requestType,
              })}
              color={"inherit"}
              values={controllerProps.field.value}
              searchable={false}
              onDropdownClose={controllerProps.field.onBlur}
              onChange={controllerProps.field.onChange}
              options={selectCategories}
              valueField="value"
              labelField="label"
            />
          }
        />
      </div>
      <textarea
        {...register("comment", {
          required: true,
        })}
        className={classNames(styles.feedbackForm__textarea, {[styles["feedbackForm__textarea--error"]]: errors.comment})}
        placeholder="Ваше сообщение"
      />
      {canAttachFiles && 
        <>
          <div className={styles.feedbackForm__files}>
            {files?.map((file, i) => (
              <div
                key={"file" + i}
                className={styles.feedbackForm__files__file}
              >
                <div
                  className={styles.feedbackForm__files__minus}
                  onClick={() => handleDeleteFile(i)}
                />
                {file.name}
              </div>
            ))}
          </div>
          <label>
            <input
              type="file"
              placeholder="Добавить файл"
              onChange={handleFileInputChange}
            />
          </label> 
        </>
      }
      <div className={styles.feedbackForm__inputs}>
        <Button text="Отправить" type="submit" noDelay={true} orange />
        <p className={styles.policy}>
          Нажимая кнопку «Отправить» вы соглашаетесь с{" "}
          <a
            href={process.env.PUBLIC_URL + "/assets/files/" + encodeURIComponent("Правила проведения онлайн-игры Tycoon.pdf")}
            className={styles.rules__pdf}
            download
          >
            Правилами игры.
          </a>
        </p>
      </div>
    </form>
  )
}

export default FeedbackForm