import { questTypes } from "../interfaces/common";


import crashBanner from "../images/quest/banners/lineCrash-banner.jpg";
import crashBannerTiny from "../images/quest/banners/lineCrash-banner-tiny.jpg";
import crashIcon from "../images/quest/icons/ES1-icon.png";

import clientsGoneBanner from "../images/quest/banners/clientsGone-banner.jpg";
import clientsGoneBannerTiny from "../images/quest/banners/clientsGone-banner-tiny.jpg";
import clientsGoneIcon from "../images/quest/icons/clientsGone-icon.png";

import lineCrashBanner from "../images/quest/banners/lineCrash-banner.jpg";
import lineCrashBannerTiny from "../images/quest/banners/lineCrash-banner-tiny.jpg";
import lineCrashIcon from "../images/quest/icons/lineCrash-icon.png";

import nodeCrashBanner from "../images/quest/banners/nodeCrash-banner.jpg";
import nodeCrashBannerTiny from "../images/quest/banners/nodeCrash-banner-tiny.jpg";
import nodeCrashIcon from "../images/quest/icons/nodeCrash-icon.png";

import managerGoneBanner from "../images/quest/banners/managerGone-banner.jpg";
import managerGoneBannerTiny from "../images/quest/banners/managerGone-banner-tiny.jpg";
import managerGoneIcon from "../images/quest/icons/managerGone-icon.png";

import newEmployeeIcon from "../images/quest/icons/newEmployee-icon.png";
import newEmployeeBanner from "../images/quest/banners/newEmployee-banner.jpg";
import newEmployeeBannerTiny from "../images/quest/banners/newEmployee-banner-tiny.jpg";

import ES1Icon from "../images/quest/icons/ES1-icon.png"
import ES1Banner from "../images/quest/banners/ES1-banner.jpg"
import ES1BannerTiny from "../images/quest/banners/ES1-banner-tiny.jpg"

import ES2Icon from "../images/quest/icons/ES2-icon.png"
import ES2Banner from "../images/quest/banners/ES2-banner.jpg"
import ES2BannerTiny from "../images/quest/banners/ES2-banner-tiny.jpg"

import ES3Icon from "../images/quest/icons/ES3-icon.png"
import ES3Banner from "../images/quest/banners/ES3-banner.jpg"
import ES3BannerTiny from "../images/quest/banners/ES3-banner-tiny.jpg"

import ES4Icon from "../images/quest/icons/ES4-icon.png"
import ES4Banner from "../images/quest/banners/ES4-banner.jpg"
import ES4BannerTiny from "../images/quest/banners/ES4-banner-tiny.jpg"

import ES5Icon from "../images/quest/icons/ES5-icon.png"
import ES5Banner from "../images/quest/banners/ES5-banner.jpg"
import ES5BannerTiny from "../images/quest/banners/ES5-banner-tiny.jpg"

import ES6Icon from "../images/quest/icons/ES6-icon.png"
import ES6Banner from "../images/quest/banners/ES6-banner.jpg"
import ES6BannerTiny from "../images/quest/banners/ES6-banner-tiny.jpg"

import ES7Icon from "../images/quest/icons/ES7-icon.png"
import ES7Banner from "../images/quest/banners/ES7-banner.jpg"
import ES7BannerTiny from "../images/quest/banners/ES7-banner-tiny.jpg"

import ES8Icon from "../images/quest/icons/ES8-icon.png"
import ES8Banner from "../images/quest/banners/ES8-banner.jpg"
import ES8BannerTiny from "../images/quest/banners/ES8-banner-tiny.jpg"

import ES9Icon from "../images/quest/icons/ES9-icon.png"
import ES9Banner from "../images/quest/banners/ES9-banner.jpg"
import ES9BannerTiny from "../images/quest/banners/ES9-banner-tiny.jpg"

import ES10Icon from "../images/quest/icons/ES10-icon.png"
import ES10Banner from "../images/quest/banners/ES10-banner.jpg"
import ES10BannerTiny from "../images/quest/banners/ES10-banner-tiny.jpg"

import ES11Icon from "../images/quest/icons/ES11-icon.png"
import ES11Banner from "../images/quest/banners/ES11-banner.jpg"
import ES11BannerTiny from "../images/quest/banners/ES11-banner-tiny.jpg"

import ES12Icon from "../images/quest/icons/ES12-icon.png"
import ES12Banner from "../images/quest/banners/ES12-banner.jpg"
import ES12BannerTiny from "../images/quest/banners/ES12-banner-tiny.jpg"

import StartupIcon from "../images/quest/icons/investments-icon.png"
import StartupBanner from "../images/quest/banners/investments-banner.jpg"
import StartupBannerTiny from "../images/quest/banners/investments-banner-tiny.jpg"

import OfferIcon from "../images/quest/icons/fpl-icon.png"
import OfferBanner from "../images/quest/banners/fpl-banner.jpg"
import OfferBannerTiny from "../images/quest/banners/fpl-banner-tiny.jpg"

import maxClientGrowBanner from "../images/quest/banners/max-client-grow-quest.png" // TODO: Определиться нужно ли это тут для этого квеста
import financeAssistantNotificationBanner from "../images/quest/banners/finance-assistent-notification-banner.jpg"
import supportAssistantNotificatoinBanner from "../images/quest/banners/support-assistant-notification-banner.png"

import { blurUpImageType } from "../interfaces/SimpleModal";

export const actionDataByType: {
  [key in questTypes]: { banner: string | blurUpImageType; icon: string };
} = {
  CRASH: {
    banner: {
      src: crashBanner,
      srcTiny: crashBannerTiny,
    },
    icon: crashIcon,
  },
  CLIENTS_GONE: {
    icon: clientsGoneIcon,
    banner: {
      src: clientsGoneBanner,
      srcTiny: clientsGoneBannerTiny,
    },
  },
  MANAGER_GONE: {
    icon: managerGoneIcon,
    banner: {
      src: managerGoneBanner,
      srcTiny: managerGoneBannerTiny
    },
  },
  NEW_EMPLOYEE: {
    icon: newEmployeeIcon,
    banner: {
      src: newEmployeeBanner,
      srcTiny: newEmployeeBannerTiny,
    },
  },
  SERVICE_OFFER: {
    banner: {
      src: OfferBanner,
      srcTiny: OfferBannerTiny,
    },
    icon: OfferIcon,
  },
  TENDER: {
    banner: {
      src: crashBanner,
      srcTiny: crashBannerTiny,
    },
    icon: crashIcon,
  },
  INVEST_STARTUP: {
    banner: {
      src: StartupBanner,
      srcTiny: StartupBannerTiny,
    },
    icon: StartupIcon,
  },
  EVENT_SPONSORSHIP: {
    banner: {
      src: crashBanner,
      srcTiny: crashBannerTiny,
    },
    icon: crashIcon,
  },
  MAX_CLIENT_GROW: {
    banner: maxClientGrowBanner,
    icon: maxClientGrowBanner,
  },
  ASSISTANT_FINANCE: {
    banner: financeAssistantNotificationBanner,
    icon: financeAssistantNotificationBanner
  },
  ASSISTANT_SUPPORT: {
    banner: supportAssistantNotificatoinBanner,
    icon: supportAssistantNotificatoinBanner
  }
};

export const crashImages = {
  city: {
    banner: {
      src: nodeCrashBanner,
      srcTiny: nodeCrashBannerTiny,
    },
    icon: nodeCrashIcon,
  },
  line: {
    banner: {
      src: lineCrashBanner,
      srcTiny: lineCrashBannerTiny,
    },
    icon: lineCrashIcon,
  }
}

export const eventSponsorshipImages: {[key: string]: {banner: string | blurUpImageType, icon: string}} = {
  ES1: {
    banner: {
      src: ES1Banner,
      srcTiny: ES1BannerTiny,
    },
    icon: ES1Icon,
  },
  ES2: {
    banner: {
      src: ES2Banner,
      srcTiny: ES2BannerTiny,
    },
    icon: ES2Icon,
  },
  ES3: {
    banner: {
      src: ES3Banner,
      srcTiny: ES3BannerTiny,
    },
    icon: ES3Icon,
  },
  ES4: {
    banner: {
      src: ES4Banner,
      srcTiny: ES4BannerTiny,
    },
    icon: ES4Icon,
  },
  ES5: {
    banner: {
      src: ES5Banner,
      srcTiny: ES5BannerTiny,
    },
    icon: ES5Icon,
  },
  ES6: {
    banner: {
      src: ES6Banner,
      srcTiny: ES6BannerTiny,
    },
    icon: ES6Icon,
  },
  ES7: {
    banner: {
      src: ES7Banner,
      srcTiny: ES7BannerTiny,
    },
    icon: ES7Icon,
  },
  ES8: {
    banner: {
      src: ES8Banner,
      srcTiny: ES8BannerTiny,
    },
    icon: ES8Icon,
  },
  ES9: {
    banner: {
      src: ES9Banner,
      srcTiny: ES9BannerTiny,
    },
    icon: ES9Icon,
  },
  ES10: {
    banner: {
      src: ES10Banner,
      srcTiny: ES10BannerTiny,
    },
    icon: ES10Icon,
  },
  ES11: {
    banner: {
      src: ES11Banner,
      srcTiny: ES11BannerTiny,
    },
    icon: ES11Icon,
  },
  ES12: {
    banner: {
      src: ES12Banner,
      srcTiny: ES12BannerTiny
    },
    icon: ES12Icon,
  }
}