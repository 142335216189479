import { Sprite } from '@inlet/react-pixi';
import { InteractionEvent, Point } from 'pixi.js'
import React, { useContext } from 'react';
import { IBuilding } from '../../../interfaces/CityObject';
import { ICommonModal } from '../../../interfaces/common';
import { GameStateStoreContext } from '../../../store/GameState';
import ModalContextStore from '../../../store/Modal';
import { observer } from 'mobx-react-lite'
import { getPointsDistance } from '../../../utils/points/distance';

let offsetBefore: Point | null = null 

const Building: React.FC<IBuilding> = ({ value, resource, xPos, yPos, onBuildingClick }) => {
	const { openModal } = useContext(ModalContextStore);	
  	const { isTutorial } = useContext(GameStateStoreContext);

	const handlePointerup = (e: InteractionEvent) => {
		onBuildingClick && onBuildingClick()
		const currentOffset = new Point(e.data.global.x, e.data.global.y)
		if (!offsetBefore || getPointsDistance(offsetBefore, currentOffset) < 50) {
			if (!isTutorial) openModal(`partnership/${value}` as ICommonModal["kind"])
		}
	};

	const handlePointerdown = (e: InteractionEvent) => {
		offsetBefore = new Point(e.data.global.x, e.data.global.y)
	}

	return (
		<Sprite
			image={resource}
			x={xPos}
			y={yPos}
			interactive={true}
			pointertap={handlePointerup}
			pointerdown={handlePointerdown}
			zIndex={1}
		/>
	);
};

export default observer(Building);
