import React, { useContext } from "react";

import diamondIcon from "../../../../images/svg/diamond.svg";
import Button from "../../Button";
import Booster from "../../Booster/Booster";
import ModalContextStore from "../../../../store/Modal";

import { IBoosterKit } from "../../../../interfaces/common";
import { ToastStoreContext } from "../../../../store/Toast";
import { GameStateStoreContext } from "../../../../store/GameState";
import { buyKit } from "../../../../services/store";

import { confirmModalStoreContext } from "../../../../store/ConfirmModal";

import styles from "./Market.module.scss";
import classNames from "classnames";
import SplittedNumber from "../../../ui/SplittedNumber/SplittedNumber";
import { getInventory } from "../../../../services/scene";
import { InventoryContext } from "../../../../store/Inventory";
import CountBadge from "../../CountBadge/CountBadge";

type storeKitProps = {
  kit: IBoosterKit;
  balance: { gold: number; crystal: number };
  onBuy: () => void
};

const StoreKit: React.FC<storeKitProps> = ({ kit, balance, onBuy }) => {
  const { openModal, closeModal } = useContext(ModalContextStore);
  const { setConfirm } = useContext(confirmModalStoreContext);
  const { pushToast } = useContext(ToastStoreContext);
  const { setCrystal } = useContext(GameStateStoreContext);
  const { setInventory} = useContext(InventoryContext);

  const isNotAvailable = kit.availableCount !== undefined && kit.availableCount === 0;

  const kitClassName = classNames(styles.store__kit, [
    {
      [styles[`store__kit--notAvailable`]]: isNotAvailable,
    },
  ]);

  const onKitBuy = () => {
    setConfirm(
      () => {
        if (balance.crystal >= kit.price.CRYSTAL) {
          buyKit(kit.code)
            .then((response) => {
              if (!response || !response.data) throw new Error();
              if (response?.data.status === "SUCCESS") {
                getInventory().then((response) => {
                  setInventory(response.results.inventory.data);
                  pushToast("success", "Покупка прошла успешно!");
                });
                onBuy();
                setCrystal(
                  response?.data.results.messages.data[0].scene.crystal
                );
              }
            })
            .catch(() => {
              pushToast("error", "Что-то произошло");
            })
            .finally(() => {
              closeModal()
            });
        } else {
          openModal("notEnoughCrystals");
        }
      },
      {
        confirmHeader: `Купить набор бустеров «${kit.label}» ?`,
        confirmText: `Набор бустеров «${kit.label}» за ${kit.price.CRYSTAL} кристаллов`
      }
    );
    openModal("confirm");
  };

  return (
    <div onClick={() => onKitBuy()}>
      <div className={kitClassName}>
        <div>
          <h2>
            {kit.label}
            {kit.availableCount !== undefined && <CountBadge value={kit.availableCount}/>}
          </h2>
        </div>
        <div>
          {kit.productSet.map((item, index) => {
            return (
              <Booster
                key={index}
                count={isNotAvailable ? undefined : item.amount}
                code={item.productCode}
              ></Booster>
            );
          })}
        </div>

        <Button
          disabled={isNotAvailable}
        >
          {
            isNotAvailable ?
              "Доступно дважды в день"
            :
            <>
              <img src={diamondIcon} alt="diamond" />
              <SplittedNumber value={kit.price.CRYSTAL} className={styles["price-text"]} isAdaptive/>
            </>
          }
        </Button>
      </div>
    </div>
  );
};

export default StoreKit;
