import {FC, useEffect, useState} from 'react'
import styles from './CookieAlert.module.scss'
import classNames from 'classnames'
import Button from '../Button'
import { getIsAcceptedLS, setIsAcceptedLS } from '../../../utils/localStorage/cookie-alert'

type cookieAlertProps = {
  className?: string
}

const CookieAlert: FC<cookieAlertProps> = ({className}) => {
  const [isAccepted, setIsAccepted] = useState(true)

  useEffect(() => {
    setIsAccepted(Boolean(getIsAcceptedLS()))
  }, [])

  const acceptOnClick = () => {
    setIsAccepted(true)
    setIsAcceptedLS()
  }
  
  return (
    <div 
      className={
        classNames(
          styles["cookie-alert"],
          {[styles["cookie-alert--accepted"]]: isAccepted},
          className
        )
      }
    >
      <div 
        className={styles["cookie-alert__text"]}
      >
        {`Мы используем cookies и аналогичные технологии для сбора и анализа информации о 
        производительности веб-сайта и обеспечения его функциональности. 
        Продолжая использование сайта вы выражаете согласие с `}
        <a
          href={process.env.PUBLIC_URL + "/assets/files/" + encodeURIComponent("Правила проведения онлайн-игры Tycoon.pdf")}
          className={styles["cookie-alert__rule-link"]}
          download
        >
          Правилами игры
        </a>
      </div>
      <Button 
        onClick={acceptOnClick}
        isRed
      >
        Принять
      </Button>
    </div>
  )
}

export default CookieAlert