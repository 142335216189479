export enum COMMUNICATION_LINES {
  LINE_1 = "LINE_1",
  LINE_2 = "LINE_2",
  LINE_3 = "LINE_3",
  LINE_4 = "LINE_4",
  LINE_5 = "LINE_5",
  LINE_6 = "LINE_6",
}

export enum COMMUNICATION_LINES_STATUSES {
  ACTIVE = "active",
  INACTIVE = "inactive",
  CRASHED = "crashed",
}
