import { paymentProblemModalType } from "../interfaces/PaymentProblemModal";

export const paymentModal: paymentProblemModalType = {
  "bonus": {
    "U0206": {
      header: "Похоже, на вашем бонусном счете не хватает бонусов",
      text: "Получите их и возвращайтесь. Ждем!",
      buttonLink: {
        "RT": "https://lk.rt.ru/new/#bonus",
        "OLM": "https://my.rt.ru/bonus/"
      },
      buttonText: "Узнать, как получить бонусы",
      smallText: "Вы также можете приобрести игровую валюту за денежные средства с лицевого счета."
    }
  },
  "money": {
    "U0206": {
      header: "Возникли трудности с оплатой",
      text: "К сожалению, на вашем лицевом счёте недостаточно средств для покупки. Пополните лицевой счет и возвращайтесь в игру!",
      buttonLink: {
        "RT": "https://moscow.rt.ru/payment",
        "OLM": "https://my.onlime.ru/billing/prepay/"
      },
      buttonText: "Пополнить счет"
    },
    "U0208": {
      header: "Лицевой счет заблокирован",
      text: "Оплатите, пожалуйста, счёт или отключите добровольную блокировку, и все возможности игры станут вам доступны.",
      buttonLink: {
        "RT": "https://moscow.rt.ru/payment",
        "OLM": "https://my.onlime.ru/billing/prepay/"
      },
      buttonText: "Перейти в Личный кабинет"
    }
  }
};
