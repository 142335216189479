import { IAssistantsInfo, IDepartment } from "../../interfaces/common";
import { deparmentVariableKeysVals, departmentVariableKeysType, otherStaffPageKeysType, otherStaffPageKeysVals } from "../../interfaces/GameState";
import { IModal, IModalsGroup } from "../../store/Modal";

export function isInstanceOfIModalsGroup(modal: IModal | IModalsGroup): modal is IModalsGroup {
  return "groupId" in modal
}

export function isInstanceOfIDepartment(object: IDepartment | IAssistantsInfo): object is IDepartment {
  return "value" in object  // TODO: переделать эту проверку
}

export function isInstanceOfDepartmentVariableKeysType(
  value: string
): value is departmentVariableKeysType {
  return deparmentVariableKeysVals.includes(value as any)
}

export function isInstanceOfOtherStaffPageKeysType(
  value: string
): value is otherStaffPageKeysType {
  return otherStaffPageKeysVals.includes(value as any)
}

export function isInstanceOfIAssistantsInfo(object: IDepartment | IAssistantsInfo): object is IAssistantsInfo {
  return "assistants" in object
}