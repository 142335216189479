import { FC } from 'react'
import classNames from 'classnames'
import useBlurredUpImage from '../../hooks/useBlurredUpImage'
import styles from './BlurUpImage.module.scss'

type BlurUpImageProps = {
    placeholderImageUrl: string
    originalImageUrl: string
    alt: string
    className?: string
}

const BlurUpImage: FC<BlurUpImageProps> = ({ placeholderImageUrl, originalImageUrl, alt, className }) => {
    const { src, isBlur } = useBlurredUpImage(placeholderImageUrl, originalImageUrl)
    
    return (
        <img 
            src={src} 
            alt={alt} 
            className={
                classNames(
                    styles["blur-up-image"], 
                    {
                        [styles["blur-up-image--blur"]]: isBlur
                    }, 
                    className
                )
            } 
        />
    )
}

export default BlurUpImage