import API from "../../api";

export const sendFeedback = (data: Object) => {
    return API.postRequest('/public/feedback', data,
        {
            baseURL: window.location.origin,
            headers: {
                'Content-Type': 'multipart/form-data',
                Accept: '*/*',
                'accept-encoding': 'gzip, deflate',
            }
        });
};

export const getFeedbackCategories = () => {
  return API.getRequest('/public/feedback/categories', {baseURL: window.location.origin});
};

export const getFeedbacks = () => {
	return API.getRequest('/feedbacks');
};

export const getFeedbackById = (feedbackId: number) => {
  return API.getRequest(`/feedbacks/${feedbackId}`)
}

export const postSendFeedbackMessage = (id: number, comment: string) => {
  return API.postRequest(`/feedbacks/${id}/comment`, {comment})
}