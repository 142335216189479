import { createContext } from "react";
import { makeObservable, observable, action } from "mobx";

export class Auth {
  isAuthorized: boolean = false;
  withError = false;

  isShowRTSignUpActionSteps = false

  constructor() {
    makeObservable(this, {
      isAuthorized: observable,
      withError: observable,
      isShowRTSignUpActionSteps: observable,
      setWithError: action.bound,
      setIsAuthorized: action.bound,
      setIsShowRTSignUpActionSteps: action.bound
    });
  }

  setWithError(withError: boolean){
    this.withError = withError
  }
  
  setIsAuthorized(isAuth: boolean) {
    if (this.isAuthorized !== isAuth)
      this.isAuthorized = isAuth;
  }

  setIsShowRTSignUpActionSteps(isShowRTSignUpActionSteps: boolean){
    this.isShowRTSignUpActionSteps = isShowRTSignUpActionSteps
  }
}

export const AuthorizationContext = createContext(new Auth());
