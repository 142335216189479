import { FC } from 'react'
import layoutStyles from '../../../../layouts/Promo/Promo.module.scss'
import styles from './SideBanner.module.scss'
import banner from '../../../../images/promo/game-city-hero.jpg'
import bannerLow from '../../../../images/promo/game-city-hero-tiny.jpg'
import BlurUpImage from '../../BlurUpImage/BlurUpImage'
import classNames from 'classnames'

type SideBannerProps = {
  className?: string
}

// TODO Исправить вёрстку на промо страницах, убрать position: absolute и зависимость от Promo.module.scss
const SideBanner: FC<SideBannerProps> = ({ className }) => {
    
  return (
      <div className={classNames(styles["side-banner"], layoutStyles["promo__side-banner-img"], className)}>
        <BlurUpImage 
          placeholderImageUrl={bannerLow}
          originalImageUrl={banner}
          className={styles["side-banner__image"]}
          alt=""
        />
      </div>
  )
}

export default SideBanner