import {FC} from 'react'
import styles from './Message.module.scss'
import rtkIcon from '../../../../images/svg/rtk-short-icon.svg'
import classNames from 'classnames'
import { parseDate } from '../../../../utils/parseDate'

export interface IMessage {
  id: string
  createAt: string
  text: string
  supportMessage: boolean  // true - сообщение от поддержки(отображается слева)
}

interface IMessageProps extends IMessage {
  className?: string
}

const Message: FC<IMessageProps> = ({createAt, text, supportMessage, className}) => {
  return (
    <div 
      className={
        classNames(
          styles["message"], 
          {
            [styles["message--user-message"]]: !supportMessage
          },
          className
        )
      }
    >
      {
        supportMessage &&
          <img src={rtkIcon} alt="rtk" className={styles["message__rtk-icon"]}/>
      }
      <div 
        className={
          classNames(
            styles["message__text"], 
            {
              [styles["message__text--user-message"]]: !supportMessage
            }
          )
        }
      >
        {text}
      </div>
      <div 
        className={
          classNames(
            styles["message__date"],
            {
              [styles["message__date--user-message"]]: !supportMessage
            }
          )
        }
      >
        {parseDate(createAt)}
      </div>
    </div>
  )
}

export default Message