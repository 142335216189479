import React, { useContext } from "react";
import { IQuest } from "../../../interfaces/common";
import Button from "../Button";
import ModalBlock from "../ModalBlock";

import goldIcon from "../../../images/svg/coin.svg";

import styles from "./Crash.module.scss";
import { confirmModalStoreContext } from "../../../store/ConfirmModal";
import ModalContextStore from "../../../store/Modal";
import { fixCrashForMoney } from "../../../services/quest";
import { ToastStoreContext } from "../../../store/Toast";
import { UseBoosterStoreContext } from "../../../store/UseBooster";
import { GameStateStoreContext } from "../../../store/GameState";
import SplittedNumber from "../../ui/SplittedNumber/SplittedNumber";
import { digitsDivide } from "../../../utils/digitsDivide";
import classNames from 'classnames'

type crashPropsType = {
  action: IQuest;
  onFix: () => void;
  filling?: number;
};

const Crash: React.FC<crashPropsType> = ({ action, onFix }) => {
  const { setConfirm } = useContext(confirmModalStoreContext);
  const { openModal } = useContext(ModalContextStore);
  const { pushToast } = useContext(ToastStoreContext);
  const { setConstruction, setAfterUseBooster } = useContext(UseBoosterStoreContext);
  const { gold } = useContext(GameStateStoreContext)

  const fixForMoney = () => {
    setConfirm(
      () => {
        if(gold >= action.resolvePrice.GOLD){
          fixCrashForMoney(action).then(() => {
            pushToast("success", "Авария будет устранена");
            onFix();
          });
        }else{
          openModal("notEnoughGold")
        }
      },
      {
        confirmHeader: "Устранить аварию?",
        confirmText: `Устранить аварию за ${ digitsDivide(action.resolvePrice.GOLD) } монет?`
      }
    );
    openModal("confirm");
  };

  const fixForBooster = () => {
    setConstruction(action.params.constructionIndex);
    setAfterUseBooster(onFix);
    openModal("crash");
  };

  return (
    <ModalBlock kind="warning" className={styles.crash}>
      <div className={classNames(styles.crash__description)}>
        <h4>{action.params.eventName}</h4>
        <p>{action.params.eventDescription}</p>
      </div>
      <div className={classNames(styles.crash__fix)}>
        <h4>Устранить аварию:</h4>
        <div>
          <Button onClick={() => fixForMoney()}>
            <img src={goldIcon} alt="Coin" />
            <SplittedNumber value={action.resolvePrice.GOLD || 0} isAdaptive/>
          </Button>
          <Button onClick={() => fixForBooster()} bordered>
            За бустер
          </Button>
        </div>
      </div>
    </ModalBlock>
  );
};

export default Crash;
