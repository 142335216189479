import React, { useContext, useEffect, useRef, useState } from "react";

import {
  IMessage,
  IPartner,
  IPartnerTransition,
} from "../../../interfaces/common";

import ModalFooterSelector from "../modalFooterSelector/ModalFooterSelector";
import ModalBlock from "../ModalBlock";

import coinIcon from "../../../images/svg/coin.svg";

import modalStyles from "../SimpleModal/SimpleModal.module.scss";
import modalBlockStyles from '../ModalBlock/ModalBlock.module.scss'
import Button from "../Button";

import styles from "./Partnership.module.scss";
import { partnerAwards, partnership } from "../../../constants/partners";
import {
  getPartnerTransitions,
  partnerTransition,
} from "../../../services/store";
import AwardList from "../AwardList/AwardList";
import SplittedNumber from "../../ui/SplittedNumber/SplittedNumber";
import { IPrize } from "../../../interfaces/Prize";
import { prizeModalStoreContext } from "../../../store/PrizeModal";
import ModalContextStore from "../../../store/Modal";
import { prizeModalIcon } from "../../../constants/prizes";
import classNames from "classnames";
import useScroller from "../../hooks/useScroller";
import { blurUpImageType } from "../../../interfaces/SimpleModal";
import BlurUpImage from "../BlurUpImage/BlurUpImage";
import ScrollArrow from "../../ui/ScrollArrow/ScrollArrow";

interface IPartnershipProps {
  defaultPartner?: IPartner["value"]
}

const Partnership: React.FC<IPartnershipProps> = ({defaultPartner = "mvideo"}) => {
  const [partnerVariable, setPartnerVariable] = useState<string>(defaultPartner);
  const [partner, setPartner] = useState<IPartner>();
  const [partnerTransitions, setPartnerTransitions] =
    useState<IPartnerTransition>();

  const partnerLinkRef = useRef<HTMLAnchorElement>()
  
  const { setPrize } = useContext(prizeModalStoreContext);
  const { openModal } = useContext(ModalContextStore);
  const { ScrollContainer, isShowArrow } = useScroller();

  const partnerSelectorClass = classNames(
    modalStyles.modal__footer,
    styles.selector
  );

  const responsePartnerTransition = () => {
    getPartnerTransitions().then((response) => {
      setPartnerTransitions(response.results.products.data[0]);
    });
  };

  const followPartner = () => {
    if (partner) {
      if (partnerTransitions?.availableCount) {
        partnerTransition().then((response) => {
          responsePartnerTransition();
          const prizeWined: IPrize | undefined =
            response?.data.results?.messages?.data.find(
              (message: IMessage) => message.messageType === "prizeWinMessage"
            )?.prizeWined;

          if (prizeWined) {
            setPrize(prizeWined);
            openModal("prize", {modalImage: prizeModalIcon[prizeWined.iconName]})
          }
        });
      }
      partnerLinkRef.current?.click()
    }
  };

  useEffect(() => {
    responsePartnerTransition();
  }, []);

  useEffect(() => {
    if (partnerVariable) {
      setPartner(
        partnership.find((partner) => partner.value === partnerVariable)
      );
    }

    // we are sure that this hook is only called when partnerVariable changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerVariable]);

  const renderBanner = (image: string | blurUpImageType) => {
    if (typeof image === "string") {
      return (
        <img
          className={styles.partnership__banner}
          src={image}
          alt="banner"
        />    
      )
    }
    else {
      return (
        <div className={classNames(styles.partnership__banner, modalBlockStyles["without-padding"])}>
            <BlurUpImage 
              placeholderImageUrl={image.srcTiny}
              originalImageUrl={image.src}
              className={styles["partnership__banner-image"]}
              alt="banner"
            />
        </div>
      )
    }
  }

  return (
    <>
      <div className={modalStyles.modal__body}>
        <ScrollContainer>
          {partner && (
            <div className={styles.partnership}>
              <ModalBlock kind="common">
                {renderBanner(partner.image)}
                <div className={classNames(styles.partnership__description)}>
                  <h5 className={styles["partnership__descr-head-text"]}>
                    Бонус за покупку у партнера
                  </h5>
                  <p className={styles["partnership__text-before-prizes-list"]}>
                    Совершите покупку на сайте партнера и получите шанс выиграть
                    один из призов:
                  </p>

                  <AwardList awards={partnerAwards} />
                  <p className={styles["partnership__text-after-prizes-list"]}>
                    Призы распределяются среди игроков в течение 48 часов с
                    момента совершения покупки.
                  </p>
                </div>
                <div className={classNames(styles.partnership__bonus)}>
                  <div className={styles["partnership__reward-container"]}>
                    <div className={styles["partnership__reward-label"]}>
                      Награда за переход:
                    </div>
                    <div
                      className={styles["partnership__reward-coins-container"]}
                    >
                      <div
                        className={
                          styles["partnership__reward-coins-icon-container"]
                        }
                      >
                        <img
                          src={coinIcon}
                          alt="coin-icon"
                          className={styles["partnership__reward-coins-icon"]}
                        />
                      </div>
                      <span
                        className={styles["partnership__reward-coins-text"]}
                      >
                        <SplittedNumber
                          value={partnerTransitions?.amount || 0}
                          isAdaptive
                          className={styles["partnership__price-text"]}
                        />
                      </span>
                    </div>
                  </div>
                  <a 
                    href={partner && partner.link} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className={styles["partnership__link"]}
                    ref={partnerLinkRef as any}
                  >
                    Перейти на сайт
                  </a>
                  <Button text="Перейти на сайт" onClick={followPartner} />
                </div>

              </ModalBlock>
            </div>
          )}
        </ScrollContainer>

      </div>

      {partnerVariable && (
        <div className={partnerSelectorClass}>
          <ModalFooterSelector
            brightness={true}
            selected={partnerVariable}
            onSelected={setPartnerVariable}
            variables={partnership}
          />
          {isShowArrow && <ScrollArrow />}
        </div>
      )}
    </>
  );
};

export default Partnership;
