import React, { useContext, useEffect, useMemo } from "react";

import ModalBlock from "../../ModalBlock";
import { observer } from "mobx-react-lite";

import useScroller from "../../../hooks/useScroller";

import coinIcon from "../../../../images/svg/coin.svg";
import crystalIcon from "../../../../images/svg/diamond.svg";

import styles from "./OfflineProfit.module.scss";
import Button from "../../Button";
import { buyDoubleProfit } from "../../../../services/store";
import { ToastStoreContext } from "../../../../store/Toast";
import ModalContextStore from "../../../../store/Modal";
import { GameStateStoreContext } from "../../../../store/GameState";
import { digitsDivide } from "../../../../utils/digitsDivide";
import ScrollArrow from "../../../ui/ScrollArrow/ScrollArrow";
import { IOfflineProfitMessage } from "../../../../interfaces/common";
import { formatToDdHh, formatToHhMm } from "../../../../utils/time-formats";
import { IModalWithId } from '../../../../interfaces/QuestNotificationsModals'

const OfflineProfit: React.FC<IModalWithId> = ({modalId}) => {
  const { ScrollContainer, isShowArrow } = useScroller();
  const { pushToast } = useContext(ToastStoreContext);
  const { closeModal, openModal, setOnModalClose } = useContext(ModalContextStore);
  const { messageAboutOfflineProfit, setMessageAboutOfflineProfit } = useContext(GameStateStoreContext);
  
  const isTwoHours = useMemo((() => messageAboutOfflineProfit?.reduceType === "TWO_HOURS"), [messageAboutOfflineProfit])
  const isFullSaved = useMemo((() => messageAboutOfflineProfit?.reduceType === "FULL_SAVED"), [messageAboutOfflineProfit])
  const isAssistantWasGone = useMemo((() => messageAboutOfflineProfit?.reduceType === "ASSISTANT_WAS_GONE"), [messageAboutOfflineProfit])

  const handleCloseModal = () => {
    setMessageAboutOfflineProfit(null);
    closeModal(modalId);
  };

  const viewIncomeOfflineProfit = () => {
    buyDoubleProfit()
      .then((response) => {
        if (response?.data.status === "SUCCESS") {
          pushToast("success", "Доход удвоен");
          handleCloseModal();
        }
        else if (response?.data.status === "FAIL") {
          if (response.data.problem.title === "Недостаточно средств")
            openModal("notEnoughCrystals");
          else {
            pushToast("error", response.data.problem.title);
            handleCloseModal();
          }
        }
      })
      .catch(() => {
        pushToast("error", `Что-то произошло`);
      });
  };

  useEffect(() => {
    setOnModalClose(() => handleCloseModal());

    // we are sure that this hook is called only once when the component is mounted
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openAssistantModal = () => {
    openModal("staff/offlineAssistant")
  }

  const renderHeader = () => {
    if (isFullSaved) {
      return `Ассистент работал: ${formatToHhMm(
        (messageAboutOfflineProfit as IOfflineProfitMessage)?.realSeconds || 0, 
        {hoursLabel: "ч.", minutesLabel: "мин."},
        true,
      )}`
    }
    else {
      return `Вы не заходили в игру: ${formatToDdHh(
        (messageAboutOfflineProfit as IOfflineProfitMessage)?.realSeconds || 0, 
        {daysLabel: "д.", hoursLabel: "ч."},
      )}`
    }
  }

  const renderDescription = () => {
    if (isTwoHours) {
      return "На ваш счёт поступила прибыль только за два часа отсутствия."
    }
    else if (isFullSaved) {
      return "Благодаря работе ассистента на ваш счёт поступила прибыль."
    }
    else if (isAssistantWasGone) {
      return "За время вашего отсутствия закончился договор найма ассисента. Вы получаете доход от ассистента и прибыль за время вашего отсутствия."
    }
    return ""
  }

  const renderFooter = () => {
    if (isTwoHours) {
      return "Заходите чаще, чтобы не упускать прибыль."
    }
    else {
      return (
        <>
          {`Управлять ассистентами вы можете в `}
          <span 
            onClick={openAssistantModal}
            className={styles["offlineProfit__assistant-link"]}
          >
            Центре развития персонала
          </span>
        </>
      )
    }
  }

  return (
    <div className={styles.offlineProfit}>
      {messageAboutOfflineProfit && (
        <ScrollContainer>
          <ModalBlock kind="common">
            <div>
              <h4 className={styles.offlineProfit__mainHeader}>
                {renderHeader()}
              </h4>
              <p>
                {renderDescription()}
              </p>
              {
                messageAboutOfflineProfit.reduceType === "ASSISTANT_WAS_GONE" &&
                  <h4>
                    {`Заработок за: ${formatToHhMm(messageAboutOfflineProfit.realSeconds || 0, {hoursLabel: "ч.", minutesLabel: "мин."}, true)}`}
                  </h4>
              }
              <h4 className={styles.offlineProfit__income}>
                <span>Ваш доход: </span>
                <img src={coinIcon} alt="" />
                {messageAboutOfflineProfit.reducedAmountGold && (
                  <span>
                    {digitsDivide(messageAboutOfflineProfit.reducedAmountGold)}
                  </span>
                )}
              </h4>
            </div>
          </ModalBlock>
          <ModalBlock kind="common">
            <div>
              <div className={styles.offlineProfit__doubleIncome}>
                <h4>Удвоить доход</h4>
                <Button onClick={viewIncomeOfflineProfit}>
                  <img src={crystalIcon} alt="" />
                  {messageAboutOfflineProfit.doubleProfitPriceCrystal}
                </Button>
              </div>
              <p>{renderFooter()}</p>
            </div>
          </ModalBlock>
        </ScrollContainer>
      )}

      {isShowArrow && <ScrollArrow />}
    </div>
  );
};

export default observer(OfflineProfit);
