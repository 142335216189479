import { useState, useEffect } from 'react'

interface IUseBlurredUpImage {
    src: string
    isBlur: boolean
}

type blurredUpImage = (lowQualitySrc: string, highQualitySrc: string) => IUseBlurredUpImage

const useBlurredUpImage: blurredUpImage = (lowQualitySrc, highQualitySrc) => {
    const [src, setSrc] = useState<string>(lowQualitySrc)
    const [isBlur, setIsBlur] = useState<boolean>(true)

    useEffect(() => {
        setSrc(lowQualitySrc)
        setIsBlur(true)
        const img = new Image()
        img.src = highQualitySrc
        img.onload = () => {
            setSrc(highQualitySrc)
            setIsBlur(false)
        }
    }, [lowQualitySrc, highQualitySrc, setSrc, setIsBlur])

    return { src, isBlur }
}

export default useBlurredUpImage