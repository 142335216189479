import { createContext } from "react";
import { makeObservable, observable, action, computed } from "mobx";
import { ISimpleModal, blurUpImageType } from "../interfaces/SimpleModal";
import { flatModalsArray } from "../utils/modals/modals-array";
import uniqid from 'uniqid'
import { isInstanceOfIModalsGroup } from "../utils/type-guards/type-guards";

export interface IModal {
  kind: ISimpleModal["kind"];
  modalId: string
  options?: IModalOptions
}

export interface IModalsGroup {
  groupId: string
  items: IModal[]
}

interface IModalOptions {
  modalImage?: string | blurUpImageType
  chevron?: number;
  gameObjectId?: string;
  questId?: string
}

export class ModalStore {
  modals: (IModal | IModalsGroup)[];

  onModalClose?: () => void

  constructor() {
    makeObservable(this, {
      modals: observable,
      onModalClose: observable,
      openModal: action.bound,
      closeModal: action.bound,
      setOnModalClose: action.bound,
      closeModals: action.bound,
      isMarketModalOpen: computed,
    });

    this.modals = [];
  }

  setOnModalClose(onModalClose?: ()=>void){
    this.onModalClose = onModalClose
  }


  /**
   * @description Открывает модальное окно
   * @param kind Вид модального окна (в зависимости от него отображается тот или иной контент)
   * @param options Параметры (url баннера, число для отображения в шевроне, идентификатор игрового объекта, id квеста)
   * @param groupId id группы (модалки в группе отображаются последовательно: следующая только после закрытия предыдущей)
   */
  openModal(kind: ISimpleModal["kind"], options?: IModalOptions, groupId?: string) {
    let newItem: IModal = {kind, modalId: uniqid(), options}
    if (groupId) {
      const isGroupExists = this.modals.some(data => isInstanceOfIModalsGroup(data) && data.groupId === groupId)
      if (isGroupExists) {
        this.modals.forEach(data => {
          if (isInstanceOfIModalsGroup(data) && data.groupId === groupId) {
            data.items.push(newItem)
          }
        })
      }
      else {
        this.modals = [...this.modals, {groupId, items: [newItem]}]
      }
    }
    else {
      this.modals = [...this.modals, newItem];
    }
  }

  closeModal(modalId?: string) {
    if (modalId !== undefined) {
      const isModalInGroup = this.modals.some(modalData => {
        if (isInstanceOfIModalsGroup(modalData)) {
          return modalData.items.some(modal => modal.modalId === modalId)
        }
        return false
      })
      if (isModalInGroup) {
        let emptyGroupId: string | undefined
        this.modals = this.modals.map(modalData => {
          if (isInstanceOfIModalsGroup(modalData)) {
            const filtredItems = modalData.items.filter(modal => modal.modalId !== modalId)
            if (filtredItems.length === 0) {
              emptyGroupId = modalData.groupId
            }
            const modalsGroup: IModalsGroup = {
              groupId: modalData.groupId,
              items: filtredItems
            }
            return modalsGroup
          }
          return modalData
        })
        if (emptyGroupId) {
          this.modals = this.modals.filter(modalData => isInstanceOfIModalsGroup(modalData) ? modalData.groupId !== emptyGroupId : true)
        }
      }
      else {
        this.modals = this.modals.filter(modal => {
          if (!(isInstanceOfIModalsGroup(modal))) {
            return modal.modalId !== modalId
          }
          return true
        });
      }
    } else {
      if (this.modals.length > 0) {
        if (isInstanceOfIModalsGroup(this.modals[this.modals.length - 1])) {
          if ((this.modals[this.modals.length - 1] as IModalsGroup).items.length === 1) {
            this.modals.pop();
          } 
          else {
            (this.modals[this.modals.length - 1] as IModalsGroup).items.pop()
          }
        }
        else {
          this.modals.pop();
        }
      }
    }
  }

  closeModals() {
    this.modals = [];
  }

  // открыта ли модалка магазина, нужно, чтобы скрывать валюты на главном экране, при открытой модалке в мобильной версии
  get isMarketModalOpen() {
    const flatModals = flatModalsArray(this.modals)
    return flatModals.some(modal => modal.kind.startsWith("store"))
  }
}

const modalStore = new ModalStore()

const ModalContextStore = createContext(modalStore);

export default ModalContextStore;
