import { createContext } from "react";
import { makeObservable, observable, action } from "mobx";

export class SkipTimer {
  skipPrice?: number;
  onSkip?: () => void;

  constructor() {
    makeObservable(this, {
      skipPrice: observable,
      onSkip: observable,
      setSkipTimer: action.bound,
    });
  }

  setSkipTimer(skipPrice: number, onSkip: () => void) {
    this.skipPrice = skipPrice;
    this.onSkip = onSkip;
  }
}

export const SkipTimerContext = createContext(new SkipTimer());
