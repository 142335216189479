import React, { useContext, useState } from "react";

import { observer } from "mobx-react-lite";
import { skipDepartmentUpLevel, upLevelDepartment } from "../../../../services/scene";
import { ToastStoreContext } from "../../../../store/Toast";

import { GameStateStoreContext } from "../../../../store/GameState";

import { IGameStateLevel, staffPagesKeysType } from "../../../../interfaces/GameState";

import {staffPagesStaticData} from "../../../../utils/departments/departments";
import ModalFooterSelector from "../../modalFooterSelector/ModalFooterSelector";
import { getGameState } from "../../../../services/scene";

import styles from "./Staff.module.scss";
import modalStyles from "../../SimpleModal/SimpleModal.module.scss";
import ModalContextStore from "../../../../store/Modal";
import useScroller from "../../../hooks/useScroller";
import ScrollArrow from "../../../ui/ScrollArrow/ScrollArrow";
import DepartmentPage from "./DepartmentPage/DepartmentPage";
import { isInstanceOfDepartmentVariableKeysType, isInstanceOfOtherStaffPageKeysType } from "../../../../utils/type-guards/type-guards";
import OfflineAssistants from "./OfflineAssistants/OfflineAssistants";
import { IAssistantsInfo } from "../../../../interfaces/common";

const Staff: React.FC<{department?: staffPagesKeysType}> = ({department}) => {
  const [currentPageName, setCurrentPageName] =
    useState<staffPagesKeysType>(department || "CUSTOMER_SERVICE");

  const { pushToast } = useContext(ToastStoreContext);
  const { setGameState, gold, departments, isTutorial } = useContext(GameStateStoreContext);
  const { openModal } = useContext(ModalContextStore);
  
  const { ScrollContainer, isShowArrow } = useScroller();

  const isDepartment = departments && isInstanceOfDepartmentVariableKeysType(currentPageName)

  const onPageSet = (pageName: string) => {
    if (isInstanceOfDepartmentVariableKeysType(pageName) || isInstanceOfOtherStaffPageKeysType(pageName)) {
      setCurrentPageName(pageName);
    }
  };

  const onSkipUpLevelDepartment = () => {
    if (isInstanceOfDepartmentVariableKeysType(currentPageName)) {
      skipDepartmentUpLevel(currentPageName).then(()=>{
        getGameState().then(
          (response) => response && setGameState(response.results.scene.data)
        );
      })
    }
  }

  const getCanUpdateDepartment = (): boolean => {
    if (isDepartment && departments[currentPageName].nextLevel) {
      if(departments[currentPageName].employees.length === 3){
        return (
          departments[currentPageName].employees.filter(
            (employee) => employee.currentLevel.levelId >= (departments[currentPageName].nextLevel as IGameStateLevel).levelId
          ).length === departments[currentPageName].employees.length
        );
      }
      
    }
    return false;
  };

  const upLevel = () => {
    if (!getCanUpdateDepartment()) {
      pushToast(
        "error",
        "Чтобы повысить весь отдел, вы должны повысить каждого сотрудника до 5 уровня"
      );
    } else {
      if (isDepartment && departments[currentPageName].nextLevel && gold >= (departments[currentPageName].nextLevel as IGameStateLevel)?.priceUpLevel.GOLD) {
        upLevelDepartment(currentPageName)
          .then((response) => {
            if (!response) throw new Error();
            getGameState().then((response) => {
              response && setGameState(response.results.scene.data);
            });
            pushToast("success", "Уровень отдела будет повышен");
          })
          .catch((err) => {
            pushToast("error", "Что-то произошло");
          })
          
      } else {
        openModal("notEnoughGold");
      }
    }
  };

  return (
    <div className={styles.body}>
      <ScrollContainer>
        <div className={modalStyles.modal__body}>
            {isDepartment ? (
              <DepartmentPage 
                departments={departments}
                departmentVariable={currentPageName}
                getCanUpdateDepartment={getCanUpdateDepartment}
                onSkipUpLevelDepartment={onSkipUpLevelDepartment}
                upLevel={upLevel}
              />
            ) : (
              <OfflineAssistants 
                assistantsInfoBlocks={(staffPagesStaticData[currentPageName] as IAssistantsInfo).assistantsBlocks}
              />
            )
            }
        </div>
      </ScrollContainer>
      <div className={modalStyles.modal__footer}>
        <ModalFooterSelector
          selected={currentPageName}
          onSelected={onPageSet}
          footerKind="staff"
          variables={Object.values(staffPagesStaticData)}
        />
      </div>
      {!isTutorial && isShowArrow && <ScrollArrow />}
    </div>
  );
};

export default observer(Staff);
