import React, { FC } from 'react'
import styles from './MessageInput.module.scss'
import classNames from 'classnames'

type MessageInputProps = {
  onChange: React.ChangeEventHandler<HTMLTextAreaElement>
  onKeyUp?: React.KeyboardEventHandler<HTMLTextAreaElement>
  value: string
  className?: string
}

const MessageInput: FC<MessageInputProps> = ({onChange, onKeyUp, value, className}) => {

  const onKeyDown: React.KeyboardEventHandler<HTMLTextAreaElement> = (e) => {
    if (e.nativeEvent.target) {
      (e.nativeEvent.target as HTMLTextAreaElement).style.height = "auto";
      (e.nativeEvent.target as HTMLTextAreaElement).style.height = `${Math.min((e.nativeEvent.target as HTMLTextAreaElement).scrollHeight, 92)}px`
    }
  }

  return (
    <textarea
      onChange={onChange}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}  
      className={classNames(styles["message-input"], className)} 
      value={value}
    >
    </textarea>    
  )
}

export default MessageInput
