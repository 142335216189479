import React, { useContext, useEffect, useMemo, useState } from "react";

import styles from "./Auth.module.scss";
import Button from "../../Button";
import classNames from "classnames";
import { LK, LK_MSK, PASS_RESET } from "../../../../constants/links";
import { getZone } from "../../../../services/auth";
import { AuthorizationContext } from "../../../../store/Auth";
import { observer } from "mobx-react-lite";
import { IFormState } from "../../../../interfaces/common";

interface IAuthForm {
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  handleBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  formState: IFormState
}

const AuthForm: React.FC<IAuthForm> = ({
  handleSubmit,
  handleBlur,
  formState
}) => {
  const [isRt, setIsRt] = useState(true);
  const [zone, setZone] = useState(false);
  const { setIsShowRTSignUpActionSteps } = useContext(AuthorizationContext);

  const onlime = useMemo(() => zone ? 'https://my.rt.ru/' : 'https://myqas.onlime.ru/bonus/game', [zone]);

  const radioButtonStyle = (active: boolean) => classNames(styles.radioButton, {[styles["radioButton--active"]]: active});
  const handleRadioButtonClick = () => {
    setIsRt(value => !value);
  };
  
  useEffect(() => {
    getZone().then(res => { if (res?.results?.settings?.data) setZone(res.results.settings.data.zone)});
  }, []);

  const getFormElementClass = (formElementType: "password" | "login") => {
    return classNames(["input", {"input--error": formState[formElementType].withError}])
  }
  
  const onSignUp = () => {    
    setIsShowRTSignUpActionSteps(true);
  }

  return (
    <div className={styles.auth}>
      <h4>Авторизация</h4>
      <div className={styles.auth__radio}>
        <div>
          <div className={radioButtonStyle(isRt)} onClick={handleRadioButtonClick} />
          Я абонент Ростелекома
        </div>
        <div>
          <div className={radioButtonStyle(!isRt)} onClick={handleRadioButtonClick} />
          Я абонент Ростелекома г. Москва
        </div>
      </div>
      <div className={styles.auth__text}>
        Войдите в игру, используя профиль<br/> 
        <a href={isRt ? LK : LK_MSK} target="_blank" rel="noopener noreferrer">Личного кабинета</a>
      </div>
      {isRt ?
          <form className={styles.auth__form} onSubmit={handleSubmit}>
            <input
              className={getFormElementClass('login')}
              name="login"
              placeholder="Логин"
              onBlur={handleBlur}
            />
            <input
              className={getFormElementClass('password')}
              name="password"
              placeholder="Пароль"
              type="password"
              onBlur={handleBlur}
            />
            <div className={classNames(styles.auth__contentLine, styles.auth__contentLine__login)}>
              <Button text="Войти" type="submit" noDelay orange />
              <a href={PASS_RESET} target="_blank" rel="noreferrer">Забыли пароль?</a>
            </div>
            <div className={classNames(styles.auth__contentLine, styles.auth__contentLine__signUp)}>
              <span>Нет аккаунта в Личном кабинете?</span>
            </div>
            <Button
                onClick={onSignUp}
                text="Зарегистрироваться"
                orange
                type="button"
                bordered
                noDelay
              />
          </form>
        : <div className={styles.auth__frameWrapper}>
            <iframe title="Onlime" src={onlime} />
          </div>
      }
      {isRt &&
        <p className={styles.auth__policy}>
          Данный сайт использует reCAPTCHA v3 с применением
          <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">
            {' '}Политики конфиденциальности
          </a>
          {' '}и{' '}
          <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">
            Условий использования
          </a>
          {' '}Google
        </p>
      }
    </div>
  );
};

export default observer(AuthForm);
