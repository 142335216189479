import React, {FC, MutableRefObject, useEffect, useRef, useState} from 'react'
import classNames from 'classnames'
import styles from './SupportRequestItem.module.scss'
import GameHistoryLine from '../GameHistoryLine'
import ExpandArrowButton from './ExpandArrowButton/ExpandArrowButton'
import MessageList from './MessageList/MessageList'
import MessageInput from './MessageInput/MessageInput'
import SendMessageButton from './SendMessageButton/SendMessageButton'
import { IMessage } from './Message/Message'
import { postSendFeedbackMessage } from '../../../services/feedback'
import uniqid from 'uniqid'

export interface IFeedback {
  id: number
  createDate: string
  description: string
  messages: IMessage[]
  resolved: boolean
  updateFeedbackItem: (feedbackId: number) => void
}

interface ISupportRequestItemProps extends IFeedback{
  className?: string
}

const SupportRequestItem: FC<ISupportRequestItemProps> = ({id, createDate, description, messages: messagesProps, resolved, updateFeedbackItem, className}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>("")
  const [messages, setMessages] = useState<IMessage[]>(messagesProps)
  const messagesListRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (messagesListRef.current) {
      messagesListRef.current.scrollTop = messagesListRef.current.scrollHeight
    }
  }, [messages, messagesListRef])

  const expandButtonOnClick = () => {
    setIsOpen(!isOpen)
  }

  const inputOnChange: React.ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    setInputValue(event.target.value || "")
  } 

  const sendMessage = () => {
    if (inputValue === "") {  // пустое сообщение отправлять нельзя, TODO: добавить очистку невидимых символов для проверки
      return
    }
    postSendFeedbackMessage(id, inputValue)
    setMessages(messages => {
      const messCp = [...messages]
      messCp.push({text: inputValue, id: uniqid(), supportMessage: false, createAt: (new Date()).toISOString()})
      return messCp
    })
    setInputValue("")
  }

  const sendMessageClick = () => {
    sendMessage()
  }

  /*  // отправке сообщения по нажатию Enter
  const onInputKeyUp: React.KeyboardEventHandler<HTMLTextAreaElement> = (e) => {
    e.preventDefault()
    if (e.code === "Enter") {
      sendMessage()
    }
  }
  */
  return (
    <div className={classNames(styles["support-request-item"], className)}>
      <div className={styles["support-request-item__head"]}>
        <GameHistoryLine createDate={createDate} description={description} />
        <ExpandArrowButton 
          isOpen={isOpen} 
          onClick={expandButtonOnClick}
          className={styles["support-request-item__expand-arrow-button"]}
        />
      </div>
      <div 
        className={
          classNames(
            styles["support-request-item__expanded-content-container"],
            {
              [styles["support-request-item__expanded-content-container--open"]]: isOpen
            }
          )
        }
      >
        <MessageList 
          messages={messages}
          containerRef={messagesListRef as MutableRefObject<HTMLDivElement>}
          className={styles["support-request-item__messages"]}
        />
        {
          !resolved &&
            (
            <div className={styles["support-request-item__input-container"]}>
              <MessageInput 
                onChange={inputOnChange} 
                value={inputValue}
                className={styles["support-request-item__input"]}
              />
              <SendMessageButton 
                onClick={sendMessageClick}
                className={styles["support-request-item__send-button"]}
              />
            </div>
          )
        }
      </div>
    </div>
  )
}

export default SupportRequestItem