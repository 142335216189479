import { FC } from 'react'
import Chevron from '../Сhevron/Сhevron'
import classNames from 'classnames'
import styles from './EmployeeView.module.scss'

interface IEmployeeViewProps {
  name: string
  avatarUrl: string
  chevronVal?: string | number
  onClick?: () => void
  className?: string
}

const EmployeeView: FC<IEmployeeViewProps> = ({name, avatarUrl, chevronVal, onClick, className}) => {
  return (
    <div 
      onClick={onClick} 
      className={classNames(styles["employee-view"], className)}
    >
      {chevronVal !== undefined && <Chevron size='small' value={chevronVal}/>}
      <img 
        src={avatarUrl} 
        alt="person"
        className={styles["employee-view__avatar"]} 
      />
      <span className={styles["employee-view__name"]}>{name}</span>
    </div>
  )
}

export default EmployeeView