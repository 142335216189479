import classNames from 'classnames';
import React from 'react';

import { IModalBlock } from '../../../interfaces/ModalBlock';

import styles from './ModalBlock.module.scss';

const ModalBlock: React.FC<IModalBlock> = ({ kind, modifier, children, className }) => {

	const blockClassName = classNames(
			styles["background"],
			styles[`background--${kind}`],
			{
				[styles[`background--${modifier}`]]: modifier
			},
			className
		);

	return (
		<div className={blockClassName}>
			{children}
		</div>
	);
};

export default ModalBlock;
