import { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import PIXI from "pixi.js";
import { Stage } from "@inlet/react-pixi";
import { observer } from "mobx-react-lite";

import { options } from "../constants/options";
import { AppStoreContext } from "../store/App";
import { FieldStoreContext } from "../store/Field";
import { GameStateStoreContext } from "../store/GameState";

import Game from "./Game";
import GamePreloader from "./common/GamePreloader";
import { getGameState, getTutorialStep } from "../services/scene";
import GameHud from "./common/GameHud/GameHud";
import Toast from "./common/Toast/Toast";
import { getQuest } from "../services/quest";
import { QuestStoreContext } from "../store/Quest";
import { ToastStoreContext } from "../store/Toast";
import ModalContextStore from "../store/Modal";
import { warningPoints } from "../constants/warning";
import { CITIES } from "../enums/cities";
import Modal from "./Modal";
import { NotificationsContext } from "../store/Notifications";
import { getNotifications, viewNotification } from "../services/notification";
import { IIncomingAward, ISimplePrize, prizeIconNameType } from "../interfaces/Prize";
import { incomingPrizeReasons, prizeModalIcon } from "../constants/prizes";
import Tutorial from "./Tutorial";
import { TutorialStoreContext } from "../store/Tutorial";
import { prizeModalStoreContext } from "../store/PrizeModal";
import maxClientGrowQuestBanner from "../images/quest/banners/max-client-grow-quest.png"
import financeAssistantNotificationBanner from "../images/quest/banners/finance-assistent-notification-banner.jpg"
import supportAssistantNotificatoinBanner from "../images/quest/banners/support-assistant-notification-banner.png"

import offlineProfitBanner from "../images/offlineProfit/offlineProfitBanner.png";
import { IMessage, IQuest } from "../interfaces/common";
import { logos_prizes } from "../constants/logos";
import { GameResultsStoreContext } from "../store/GameResults";
import { GameIntervalsStoreContext } from "../store/GameIntervals";

const MODALS_ON_START_GROUP_ID = "modals-on-start"

const App: FC = () => {
  const { mount, unmount, application, isTilesLoading } =
    useContext(AppStoreContext);
  const { windowSize, setCoords } = useContext(FieldStoreContext);
  const { setGameState, resetGameState, isTutorial, messageAboutOfflineProfit, setMessageAboutOfflineProfit } =
    useContext(GameStateStoreContext);
  const { setStep } = useContext(TutorialStoreContext);
  const { pushToast } = useContext(ToastStoreContext);
  const { openModal } = useContext(ModalContextStore);

  const { setIncomingAward, incomingAward } = useContext(NotificationsContext);

  const { setQuests, setNewQuests } = useContext(QuestStoreContext);

  const { setPrize } = useContext(prizeModalStoreContext);

  const { setGameResultsData, isGameEnded } = useContext(GameResultsStoreContext)

  const { setQuestIntervalId, setGameStateIntervalId, setNotificationsIntervalId, clearIntervals} = useContext(GameIntervalsStoreContext)

  const [isResourceLoaded, setIsResourceLoaded] = useState(false);

  const renderedNotificationsModalsRef = useRef<string[]>([])

  const setGameResultsOnResponceFail = (res: {
    problem: {
      extraData: {
        hoursOfPlaying: number
        countClients: number
        prizeList: {
          iconName: string
          winPopupLabel: string
        }[]
      }
    }
  }) => {
    const resData = res.problem.extraData
    const prizes: ISimplePrize[] = resData.prizeList.map((resPrize) => (
      {
        icon: logos_prizes[resPrize.iconName as prizeIconNameType],
        text: resPrize.winPopupLabel
      }
    ))
    const gameResultsData = {
      totalHours: resData.hoursOfPlaying,
      totalAbonents: resData.countClients,
      prizes
    }
    setGameResultsData(gameResultsData)
  }

  const initQuestsAndGameStateIntervals = () => {
    setTimeout(() => {
      const questIntID = setInterval(() => {
        getQuest()
          .then((response) => {
            if (!response) throw new Error();
            const quests: IQuest[] = response.results.quests.data
            const maxClientGrowQuest = quests.filter(quest => quest.questType === "MAX_CLIENT_GROW")
            if (maxClientGrowQuest.length > 0 && !renderedNotificationsModalsRef.current.includes("questMaxClientGrow")) {
              openModal("questMaxClientGrow", {modalImage: maxClientGrowQuestBanner, questId: maxClientGrowQuest[0].id}, MODALS_ON_START_GROUP_ID)
              renderedNotificationsModalsRef.current.push("questMaxClientGrow")
            }
            const financeAssistantQuest = quests.filter(quest => quest.questType === "ASSISTANT_FINANCE")
            if (financeAssistantQuest.length > 0 && !renderedNotificationsModalsRef.current.includes("questFinanceAssistant")) {
              openModal("questFinanceAssistant", {modalImage: financeAssistantNotificationBanner, questId: financeAssistantQuest[0].id}, MODALS_ON_START_GROUP_ID)
              renderedNotificationsModalsRef.current.push("questFinanceAssistant")
            }
            const supportAssistantQuest = quests.filter(quest => quest.questType === "ASSISTANT_SUPPORT")
            if (supportAssistantQuest.length > 0 && !renderedNotificationsModalsRef.current.includes("questSupportAssistant")) {
              openModal("questSupportAssistant", {modalImage: supportAssistantNotificatoinBanner, questId: supportAssistantQuest[0].id}, MODALS_ON_START_GROUP_ID)
              renderedNotificationsModalsRef.current.push("questSupportAssistant")
            }
            setQuests(response.results.quests.data);
            setNewQuests(response.results.unreadCount.data);
          })
          .catch((err) => {
            pushToast("error", `Cоединение потеряно ${window.location.origin}`);
          });
      }, 5000);
      setQuestIntervalId(questIntID)
    }, 10000);

    const gameStateIntrvID = setInterval(() => {
      getGameState()
        .then((response) => {
          if (response) {
            if (response.status === "FAIL") {
              setGameResultsOnResponceFail(response)
            }
            else {
              setGameState(response.results.scene.data);
            }
          }
        })
        .catch(() => {
          pushToast("error", `Cоединение потеряно ${window.location.origin}`);
        })
        .finally(() => setIsResourceLoaded(true));
    }, 5000);
    setGameStateIntervalId(gameStateIntrvID)
  }

  const onMount = (app: PIXI.Application) => {
    mount(app);
    setCoords(warningPoints[CITIES.CITY_1].x, warningPoints[CITIES.CITY_1].y);
    setIncomingAward(undefined);
    getTutorialStep().then((res) => {
      if (res) {
        if (res.status === "FAIL") {
          setGameResultsOnResponceFail(res)
        }
        else {
          setStep(res.results.scene.data.tutorialLevelId);
          const offlineProfitMessage = res.results.events.data.find((message: IMessage)=>{
            return message.messageType === "reducedIncomeMessage"
          })
          offlineProfitMessage && setMessageAboutOfflineProfit(offlineProfitMessage);
        }
      }
    });
    initQuestsAndGameStateIntervals()
  };

  const clearGame = useCallback(() => {
    clearIntervals();
    resetGameState();
  },[clearIntervals, resetGameState])

  const onUnmount = (app: PIXI.Application) => {
    unmount();
    clearGame();
  };

  const isSceneLoaded = useMemo(
    () => !isTilesLoading && isResourceLoaded,
    [isResourceLoaded, isTilesLoading]
  );

  useEffect(() => {
    if (!isTutorial && isSceneLoaded && !isGameEnded) {
      const notificationsIntrvID = setInterval(() => {
        getNotifications("firstUnread").then((response) => {
          const newPrize: IIncomingAward = response.results.notification?.data;
          if (newPrize) {
            setPrize(newPrize.prizeWined);
            openModal("prize", {modalImage: prizeModalIcon[newPrize.prizeWined.iconName]}, MODALS_ON_START_GROUP_ID)
            viewNotification(newPrize.id);
          }
        });
      }, 30000);
      setNotificationsIntervalId(notificationsIntrvID)

      setTimeout(() => {
        getNotifications("currentDay").then((response) => {
          const notifications: IIncomingAward[] =
            response.results.notifications.data;
          const incomingAward = notifications.find((prize) =>
            incomingPrizeReasons.includes(prize.prizeWined.reasonPrizeType)
          );

          if (incomingAward) {
            setPrize(notifications[0].prizeWined);
            setIncomingAward(incomingAward);
          }
        });
      }, 3000);

      if (messageAboutOfflineProfit) {
        openModal("offlineProfit", {modalImage: offlineProfitBanner}, MODALS_ON_START_GROUP_ID)
      }
    }
  }, [isTutorial, openModal, setIncomingAward, setPrize, setNotificationsIntervalId, messageAboutOfflineProfit, isSceneLoaded, isGameEnded]);

  useEffect(() => {
    if (isGameEnded) {
      clearGame()
    }
  }, [clearGame, isGameEnded])

  useEffect(() => {
    if (isSceneLoaded && incomingAward && !isTutorial) {
      openModal("calendar", {}, MODALS_ON_START_GROUP_ID);
    }
  }, [isSceneLoaded, openModal, incomingAward, isTutorial, messageAboutOfflineProfit]);

  const stageProps = {
    width: windowSize.width,
    height: windowSize.height,
    raf: false,
    options: options,
  };

  return (
    <>
      {!isSceneLoaded && <GamePreloader />}

      <Stage 
        {...stageProps} 
        onMount={onMount} 
        onUnmount={onUnmount}
      >
        {application && <Game />}
      </Stage>

      {isSceneLoaded && <GameHud />}

      {isSceneLoaded && isTutorial && (
        <Stage
          {...stageProps}
          options={{ ...options, backgroundColor: 0x000000, backgroundAlpha: 0.5 }}
          className="tutorial-canvas"
        >
          {application && <Tutorial />}
        </Stage>
      )}

      <Modal />
      <Toast />
    </>
  );
};

export default observer(App);
