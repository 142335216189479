import classNames from "classnames";
import React from "react";

import shevron from "../../../images/svg/shevron.svg";

import styles from "./Chevron.module.scss";

type ChevronProps = {
  value: number | string;
  size?: "normal" | "small";
};

const Chevron: React.FC<ChevronProps> = ({ value, size = "normal" }) => {
    
  var chevronClass = classNames(
      styles.chevron,
      styles[`chevron--${size}`]
  );

  return (
    <div className={chevronClass}>
      <span>{value}</span>
      <img src={shevron} alt="" />
    </div>
  );
};

export default Chevron;
