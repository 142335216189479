import classNames from 'classnames';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

interface IUseScroller {
  ScrollContainer: React.FC<{className?: string; children: React.ReactNode}>;
  isShowArrow: boolean
}

type scroller = () => IUseScroller;

const useScroller: scroller = () => {
  const [isShowArrow, setIsShowArrow] = useState(false);

  const bodyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (bodyRef && bodyRef.current) {
      setIsShowArrow(bodyRef.current.scrollHeight - Math.ceil(bodyRef.current.scrollTop) > bodyRef.current.clientHeight)
    }
  }, [setIsShowArrow, bodyRef]);
  
  const handleScroll = useCallback((e: React.UIEvent<HTMLDivElement>) => {
    const target = e.currentTarget;

    setIsShowArrow(target.scrollHeight - Math.ceil(target.scrollTop) > target.clientHeight)
  }, []);

  const minHeightStyle: React.CSSProperties = useMemo(
    () => bodyRef.current
      ? { minHeight: bodyRef.current.clientHeight < 230 ? 'unset' : 230 }
      : {},
    []
  );

  const container: React.FC<{className?: string; children: React.ReactNode}> = useCallback(({ children, className }) => (
    <div ref={bodyRef} className={classNames("scroll-container", className)} onScroll={handleScroll} style={minHeightStyle}>
      {children}
    </div>
  ), [handleScroll, minHeightStyle]);

  return {
    ScrollContainer: container,
    isShowArrow,
    bodyRef,
    // if u've to export something - export it here
  };
};

export default useScroller;
