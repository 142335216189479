import { FC, useContext } from "react";
import { ICurrency } from "../../../interfaces/common";

import plus from "../../../images/svg/plus.svg";
import styles from "./Currency.module.scss";
import { makeFriendly } from "../../../utils/balance";

import goldIcon from "../../../images/svg/coin.svg";
import crystalIcon from "../../../images/svg/diamond.svg";
import { TutorialStoreContext } from "../../../store/Tutorial";

const Currency: FC<ICurrency> = ({ currency, count, onAdd, id }) => {

  const { activeUIElement, nextStep } = useContext(TutorialStoreContext);

  const handleClick = () => {
    onAdd();
    if (id === activeUIElement) nextStep();
  };

  return (
    <button onClick={handleClick} id={id} className={styles["currency"]}>
      <img src={currency === "gold" ? goldIcon : crystalIcon} alt="" />
      <span>{makeFriendly(count)}</span>
      <div className={styles.currency__plus}>
        <img src={plus} alt="plus icon" />
      </div>
    </button>
  );
};

export default Currency;
