import React, { useContext, useEffect, useState } from "react";
import { GameStateStoreContext } from "../../../../store/GameState";
import Button from "../../Button";
import ModalContextStore from "../../../../store/Modal";
import { getGameState, skipEpoch } from "../../../../services/scene";
import diamondIcon from "../../../../images/svg/diamond.svg";

import ModalBlock from "../../ModalBlock";

import styles from "./Epoch.module.scss";
import { ToastStoreContext } from "../../../../store/Toast";
import { observer } from "mobx-react-lite";
import SplittedNumber from "../../../ui/SplittedNumber/SplittedNumber";
import useScroller from "../../../hooks/useScroller";
import ScrollArrow from "../../../ui/ScrollArrow/ScrollArrow";

const Epoch: React.FC = () => {
  const { getEpoch, getTechnologies, network } = useContext(
    GameStateStoreContext
  );
  const [epoch, setEpoch] = useState(getEpoch());
  const { closeModal, openModal } = useContext(ModalContextStore);
  const [technologies] = useState(getTechnologies());
  const { setGameState } = useContext(GameStateStoreContext);
  const { pushToast } = useContext(ToastStoreContext);
  const { crystal } = useContext(GameStateStoreContext);
  
  const { ScrollContainer, isShowArrow } = useScroller();

  useEffect(() => {
    setEpoch(getEpoch());
  }, [getEpoch, network]);

  const handleSkipEpoch = () => {
    if (
      epoch?.timer.priceForSkip.CRYSTAL &&
      crystal >= epoch?.timer.priceForSkip.CRYSTAL
    ) {
      skipEpoch()
        .then((response) => {
          if (!response) throw new Error();
          getGameState().then(
            (response) => response && setGameState(response.results.scene.data)
          );
          pushToast("success", `Эра пропущена`);
        })
        .catch((err) => {
          pushToast(
            "error",
            `Соединение потеряно ${process.env.REACT_APP_BASE_URL}`
          );
        })
        .finally(() => {
          closeModal()
        })
    } else {
      openModal("notEnoughCrystals");
    }
  };

  return (
    <div className={styles.epoch}>
      <ScrollContainer>
        <ModalBlock kind="common">
          <div>
            {epoch?.texts.map((text, i) => {
              return <p key={i}>{text}</p>;
            })}
          </div>

          <div>
            <h5>Получены технологии:</h5>

            <div className={styles.epoch__technologies}>
              {technologies &&
                epoch?.technologyTypes.map((technology, index) => {
                  return <div key={index}>{technologies[technology]?.name}</div>;
                })}
            </div>
          </div>

          <div className={styles.epoch__buttons}>
            {epoch?.epochType !== "E_5G" && (
              <Button
                onClick={handleSkipEpoch}
              >
                <span>Следующая эра</span>
                <img src={diamondIcon} alt="diamond" />
                <SplittedNumber value={epoch?.timer.priceForSkip.CRYSTAL || 0} isAdaptive/>
              </Button>
            )}

            <Button onClick={() => closeModal()} bordered text={"Ок"} />
          </div>
        </ModalBlock>
      </ScrollContainer>
      {isShowArrow && <ScrollArrow />}
    </div>      
  );
};

export default observer(Epoch);
