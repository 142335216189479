import React, { useContext } from "react";
import { observer } from "mobx-react-lite";

import { SkipTimerContext } from "../../../../store/SkipTimer";
import ModalBlock from "../../ModalBlock";
import Button from "../../Button";

import crystalIcon from "../../../../images/svg/diamond.svg";

import modalStyles from "../SimpleModal.module.scss";
import styles from "./SkipTimer.module.scss";
import { GameStateStoreContext } from "../../../../store/GameState";
import ModalContextStore from "../../../../store/Modal";

const SkipTimer: React.FC = () => {
  const { skipPrice, onSkip } = useContext(SkipTimerContext);
  const { crystal } = useContext(GameStateStoreContext);
  const { openModal, closeModal } = useContext(ModalContextStore);

  const handleSkip = () => {
    if (skipPrice && crystal >= skipPrice) {
      onSkip && onSkip();
      closeModal();
    } else {
      openModal("notEnoughCrystals")
    }
  };

  return (
    <div className={styles.skipTimer}>
      <div className={modalStyles.modal__body}>
        <ModalBlock kind="common">
          <div>
            <h4>Завершить сейчас</h4>
            <Button onClick={handleSkip}>
              За <img className={styles.price} src={crystalIcon} alt="" /> {skipPrice}
            </Button>
          </div>
        </ModalBlock>
      </div>
    </div>
  );
};

export default observer(SkipTimer);
