const secondsInHour = 3600
const secondsInDay = secondsInHour * 24;

interface ITimeLabels {
  hoursLabel?: string; 
  minutesLabel?: string;
  daysLabel?: string;
}

export function formatToHhMm(
  seconds: number, 
  labels: ITimeLabels,
  minimumOneMinute?: boolean
): string {
  if (minimumOneMinute && seconds < 60) {
    seconds += 60
  }
  const hours = Math.floor(seconds / secondsInHour)
  const minutes = Math.floor((seconds - (hours * secondsInHour)) / 60)
  
  return `${hours}${labels.hoursLabel} ${minutes}${labels.minutesLabel}` 
}

export function formatToDdHh(seconds: number, labels: ITimeLabels): string {
  const days = Math.floor(seconds / secondsInDay);
  const hours = Math.floor((seconds - (days * secondsInDay)) / secondsInHour);
  return `${days}${labels.daysLabel} ${hours}${labels.hoursLabel}`
}